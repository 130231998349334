//DON’T USE THIS WAY OF ACCESSING THE STORE WITH SERVER-SIDE RENDERING!
//HOWEVER, IT"S FINE FOR US.
import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'

import { reducers } from './reducers/reducers'

//const applyMiddleware =

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION__COMPOSE_ || compose
let middleware = storeEnhancers(applyMiddleware(thunk))

//Create our redux store, and connect to the devtools. :)
//The key here is to export the store.
export const store = createStore(reducers, middleware)
