import React from 'react'
import { useDispatch } from 'react-redux'
import { upload_file } from '../../../../api/calls'
import { setAlert } from '../../../../asyncActions/alert'
import FilesComponent from '../../../customComponents/files'
import InputComponent from '../../../customComponents/input'

const OpenDayForm = ({ formValues, onChange }) => {
  const dispatch = useDispatch()
  return (
    <div className="flex flex-col gap-2">
      <InputComponent
        theme="dark"
        title="Stationary (optional)"
        value={formValues?.stationary_required}
        onChange={v => {
          onChange({ ...formValues, stationary_required: v })
        }}
      />
      <div className="grid grid-rows-1">
        <FilesComponent
          label="Attach Form (optional)"
          theme="light"
          onChange={v => {
            upload_file({ file: v, name: v.name }).then(res => {
              const document = {
                name: v?.name,
                url: res.data.content.location,
                etag: res.data.content.etag
              }
              if (res.data.success) {
                // setFileUploads({ openDayForm: document })
                onChange({ ...formValues, documents: [...formValues.documents, document] })
                dispatch(
                  setAlert({
                    success: true,
                    show: true,
                    message: 'Upload successful.'
                  })
                )
              } else {
                dispatch(
                  setAlert({
                    success: false,
                    show: true,
                    message: 'Upload failed.'
                  })
                )
              }
            })
          }}
        />
      </div>

      <InputComponent
        theme="dark"
        title="Partners Involved (optional)"
        value={formValues?.partners_involved}
        onChange={v => {
          onChange({ ...formValues, partners_involved: v })
        }}
      />
      <FilesComponent
        label="Flyer(s) (optional)"
        theme="light"
        onChange={v => {
          upload_file({ file: v, name: v.name }).then(res => {
            const document = {
              name: v?.name,
              url: res.data.content.location,
              etag: res.data.content.etag
            }
            if (res.data.success) {
              // setFileUploads({ openDayFlyers: document, ...fileUploads })
              onChange({ ...formValues, documents: [...formValues.documents, document] })
              dispatch(
                setAlert({
                  success: true,
                  show: true,
                  message: 'Upload successful.'
                })
              )
            } else {
              dispatch(
                setAlert({
                  success: false,
                  show: true,
                  message: 'Upload failed.'
                })
              )
            }
          })
        }}
      />
      <br />
    </div>
  )
}

export default OpenDayForm
