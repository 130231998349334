import { setMainState } from '../actions/main'
import { get_home } from '../api/calls/get_home'
import { store } from '../store'

const state = store.getState()
const { mainState } = state

export const getHome = () => {
  return dispatch => {
    get_home()
      .then(response => {
        if (response.data.success) {
          dispatch(
            setMainState({
              ...mainState,
              home: response.data.content,
              homeLoaded: true
            })
          )
        } else {
        }
      })
      .catch(error => {})
  }
}
