import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

const ITEM_HEIGHT = 56
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    }
  }
}

function getStyles(item, selectedValues, theme, displayKey) {
  const itemValue = item[displayKey]
  return {
    fontWeight: selectedValues.includes(itemValue)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular
  }
}

export default function MultipleSelectComponent({
  title,
  data = [],
  onMenuItemClick,
  displayKey,
  color = '#000',
  required = false,
  selectedItems: initialSelectedItems = []
}) {
  const theme = useTheme()

  // Make sure the state holds an array of strings
  const initialValues = initialSelectedItems.map(item =>
    typeof item === 'object' ? item[displayKey] : item
  )
  const [selectedValues, setSelectedValues] = React.useState(initialValues)

  const handleChange = event => {
    const {
      target: { value }
    } = event

    // value is either a string (from autofill) or an array of strings.
    const newSelected = typeof value === 'string' ? value.split(',') : value

    setSelectedValues(newSelected)

    // If a callback is provided, map back the selected values to the original objects.
    if (onMenuItemClick) {
      const selectedObjects = data.filter(item => newSelected.includes(item[displayKey]))
      onMenuItemClick(selectedObjects)
    }
  }

  return (
    <div className="ignore-click-outside" onClick={e => e.stopPropagation()}>
      <FormControl className="w-full flex" style={{ color }} required={required}>
        <InputLabel id="multiple-select-label">{title}</InputLabel>
        <Select
          labelId="multiple-select-label"
          id="multiple-select"
          multiple
          value={selectedValues}
          onChange={handleChange}
          input={<OutlinedInput label={title} />}
          style={{
            height: 56,
            color: color,
            fontSize: 14,
            fontWeight: 400,
            lineHeight: 1.5
          }}
          MenuProps={MenuProps}
        >
          {data.map(item => (
            <MenuItem
              key={item[displayKey]}
              value={item[displayKey]}
              style={{
                ...getStyles(item, selectedValues, theme, displayKey),
                padding: '10px 20px', // Add spacing between items
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '4px' // Add gap for better spacing between lines
              }}
            >
              {item[displayKey]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}
