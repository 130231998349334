import {
  EDIT_RECRUITOR_STATE,
  SET_RECRUITOR_STATE,
  UPDATE_RECRUITOR_STATE
} from '../../constants/redux_types'

export function recruiterStateReducer(
  state = {
    recruitersLoaded: false,
    recruiters: [] //RECRUITER-ACC
  },
  action
) {
  switch (action.type) {
    case SET_RECRUITOR_STATE:
      return {
        ...state,
        ...action.payload
      }
    case EDIT_RECRUITOR_STATE:
      const _recruiters = state.recruiters.map(x => {
        if (x._id === action.payload._id) {
          return action.payload
        } else {
          return x
        }
      })

      return {
        ...state,
        recruiters: _recruiters
      }
    case UPDATE_RECRUITOR_STATE:
      return {
        ...state,
        recruiters: [action.payload, ...state.recruiters]
      }
    default:
      return state
  }
}
