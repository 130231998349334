import React, { useEffect, useState } from 'react'

import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { delete_facilitator } from '../../api/calls/users/delete_facilitator'
import { getFacilitators } from '../../asyncActions/facilitators'
import LoaderControl from '../../controls/loader/loader'
import NotificationControl from '../../controls/notification/notification'
import { selectPaginateState } from '../../selectors/selectors'
import { selectNotificationState } from '../notifications/selectors'
import AddUserComponent from '../user/components/addUser'
import { selectFacilitators, selectFacilitatorsLoaded, selectFacilitatorsState } from './selectors'
import { setNotification } from '../../asyncActions/notification'
import { faPen, faRedo, faRepeat, faTrash } from '@fortawesome/pro-light-svg-icons'
import TableComponent from '../UIComponents/components/tables/table'
import ButtonComponentContained from '../UIComponents/components/buttons/buttonContained'

const FacilitatorComponent = ({ mainState }) => {
  const history = useHistory()
  const userType = 'FACILITATOR-ACC'

  const [showAddFacilitator, setShowAddFacilitator] = useState(false)

  const facilitatorState = useSelector(selectFacilitatorsState)
  const facilitators = useSelector(selectFacilitators)
  const facilitatorsLoaded = useSelector(selectFacilitatorsLoaded)
  const paginate = selectPaginateState(facilitatorState)

  const notificationData = useSelector(selectNotificationState)
  const [isBusy, setIsBusy] = useState(false)
  const dispatch = useDispatch()
  const [selectedUser, setSelectedUser] = useState({})
  const [edit, setEdit] = useState(false)

  useEffect(() => {
    dispatch(getFacilitators())

    //default notification
    dispatch(setNotification({}))
  }, [])

  const _onRefresh = _ => {
    dispatch(getFacilitators())
  }

  const _handleAddFacilitator = _ => {
    setEdit(false)
    setSelectedUser({})
    setShowAddFacilitator(true)
  }

  return (
    <div className="flex flex-col">
      <TableComponent
        search={true}
        title="Facilitators"
        displayKeys={[
          { value: 'user_name', label: 'Name' },
          { value: 'user_surname', label: 'Surname' },
          { value: 'user_email', label: 'Email' },
          { value: 'user_cell_number', label: 'Phone Number' },
          {
            value: 'status_code',
            label: 'Status'
            // isChip: true,
            // expression: [
            //   { value: 'ACTIVE', color: 'green' },
            //   { value: 'INACTIVE', color: 'red' }
            // ]
          }
        ]}
        data={facilitators}
        onRowClick={item => {
          console.log('item', item)
          history.push(`facilitator-details/${item._id}`)
        }}
        actions={[
          {
            label: 'Edit',
            hide: item => item.status_code === 'INACTIVE',
            action: item => {
              setEdit(true)
              setSelectedUser(item)
              setShowAddFacilitator(true)
            },
            icon: faPen
          },
          {
            label: 'Delete',
            hide: item => item.status_code === 'INACTIVE',
            action: item => {
              delete_facilitator(item._id).then(response => {
                if (response.data.success) {
                  dispatch(
                    setNotification({
                      title: 'Success',
                      messages: ['Facilitator Successfully Disabled.']
                    })
                  )
                }
              })
            },
            icon: faTrash
          }
        ]}
      >
        <ButtonComponentContained
          title="Add Facilitator"
          onClick={_handleAddFacilitator}
          startIcon={faPen}
          endIcon={undefined}
        />
        <ButtonComponentContained
          title="Refresh"
          onClick={_onRefresh}
          startIcon={faRepeat}
          endIcon={undefined}
        />
      </TableComponent>

      <LoaderControl show={isBusy} />

      <AddUserComponent
        open={showAddFacilitator}
        setShow={setShowAddFacilitator}
        type={userType}
        user={selectedUser}
        edit={edit}
        onSuccess={_onRefresh}
      />
      <NotificationControl notificationData={notificationData} />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    mainState: state.mainState,
    facilitatorState: state.facilitatorState
  }
}

export default connect(mapStateToProps)(FacilitatorComponent)
