import React, { useEffect } from 'react'

const CardControl = props => {
  const {
    children,
    title,
    style,
    backgroundColor = 'bg-white',
    titleColor = 'text-black',
    css = {},
    rounded = 'rounded-xl'
  } = props
  //Did mount...
  useEffect(() => {}, [])

  //Will unmount...
  useEffect(() => {
    return () => {
      console.log('Cleaning up...')
    }
  }, [])

  return (
    <div
      className={`w-full shadow-md ${backgroundColor} flex flex-col p-4 space-y-4 ${rounded} ${style}`}
      style={css}
    >
      {title && (
        <div className={`${titleColor} font-bold text-lg`}>{title.toString().toUpperCase()}</div>
      )}
      <div className="flex flex-col flex-auto">{children}</div>
    </div>
  )
}

export default CardControl
