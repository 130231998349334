import { RESET_ALERT, SET_ALERT } from '../constants/redux_types'

export const setAlertState = payload => {
  return {
    type: SET_ALERT,
    payload: payload
  }
}

export const resetAlertState = payload => {
  return {
    type: RESET_ALERT,
    payload: payload
  }
}
