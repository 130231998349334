import React from 'react'
import { CSVLink, CSVDownload } from 'react-csv'


export const CSVDownloaderControl =  ({headers, data, title})=> {
  

  return (
    <button
      type="button"
      className={`w-full gap-2 hover:underline text-md px-5 py-2.5 font-medium hover:bg-blue-400 text-white bg-textinput-dark rounded-md h-12 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`}
   
    >
        <CSVLink data={data} headers={headers} filename={title}>
            Download CSV
        </CSVLink>
    </button>
  )
}

export default CSVDownloaderControl
