import React, { useEffect, useState } from 'react'

import { connect, useDispatch } from 'react-redux'

import { editLearnerState } from '../../../actions/learner'
import { save_document } from '../../../api/calls/documents/save_document'
import { upload_file } from '../../../api/calls/upload_file'
import ButtonControl from '../../../controls/button/button'
import FileInputControl from '../../../controls/fileinput/fileinput'
import LoaderControl from '../../../controls/loader/loader'
import PopupControl from '../../../controls/popup/popup'
import TextInputControl from '../../../controls/textinput/textinput'
import InputComponent from '../../customComponents/input'
import FilesComponent from '../../customComponents/files'
import ButtonComponent from '../../customComponents/button'
import { faSave } from '@fortawesome/pro-light-svg-icons'

const AddDocumentComponent = ({ mainState, userState, open = false, setShow, user = {} }) => {
  const dispatch = useDispatch()

  const [isBusy, setIsBusy] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [values, setValues] = useState({})
  const [status, setStatus] = useState({
    id: 1,
    status: 'To Do'
  })

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  useEffect(() => {}, [user])

  return (
    <div>
      <PopupControl
        title="Add Document"
        isOpen={open}
        close={{
          showClose: true,
          onClose: () => {
            setShow(false)
          }
        }}
      >
        <div className="flex flex-col gap-4 justify-center items-center">
          <InputComponent
            theme="dark"
            title="Document Name"
            value={values.name}
            onChange={v =>
              setValues({
                ...values,
                name: v
              })
            }
          />

          <InputComponent
            theme="dark"
            title="Document Description"
            value={values.description}
            onChange={v =>
              setValues({
                ...values,
                description: v
              })
            }
          />

          <FilesComponent
            label="Attach Document"
            // theme="light"
            // placeholder={values.document ? values.document : 'Upload a file'}
            onChange={v => {
              upload_file({ file: v, name: v.name }).then(res => {
                if (res.data.success) {
                  setValues({
                    ...values,
                    document: res.data.content.location,
                    etag: res.data.content.etag
                  })
                }
              })
            }}
          />

          <div className="justify-center flex gap-4">
            <ButtonComponent
              title={'Save'}
              icon={faSave}
              isLoading={false}
              //   type="primary"
              onClick={v => {
                save_document({
                  ...values,
                  user_id: user._id
                }).then(res => {
                  if (res.data.success) {
                    setShow(false)
                    let _documents = user.documents ? user.documents : []
                    user.documents = [values, ..._documents]
                    dispatch(editLearnerState({ ...user }))
                    setValues({})
                  }
                })
              }}
            />
          </div>
        </div>
      </PopupControl>
      <LoaderControl show={isBusy} />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    mainState: state.mainState,
    userState: state.userState
  }
}

export default connect(mapStateToProps)(AddDocumentComponent)
