import TableContentWrapper from '../../controls/tableContentWrapper/tableContentWrapper'
import { MyTableControl } from '../../controls/myTableControl/myTableControl'
import { actionButtonItems } from '../user/constants'
import { absenteesColumns, dateValues } from './constants'
import React, { useEffect, useState } from 'react'
import DropdownButtonComponent from '../customComponents/dropdownButton'
import { connect, useDispatch } from 'react-redux'
import { absentee_history, get_absentees } from '../../api/calls'
import { getGroups, getPrograms } from '../../asyncActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faFilter, faTimes } from '@fortawesome/pro-light-svg-icons'
import { useHistory } from 'react-router-dom'
import ButtonComponent from '../customComponents/button'

const AbsenteesComponent = ({ programsState, groupState }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [formValues, setFormValues] = useState()
  const [tableData, setTableData] = useState([])
  const [historyData, setHistoryData] = useState([])
  const [selectedDateValue, setSelectedDateValue] = useState('')
  const [index, setIndex] = useState(0)

  useEffect(() => {
    dispatch(getGroups())
    dispatch(getPrograms())
  }, [])

  const _onFormValuesChange = e => {
    setFormValues({ ...formValues, ...e })
  }

  const _fetchAbsentees = () => {
    //write operations here
    get_absentees(formValues).then(response => {
      if (response.data.success) {
        let attendances =
          response.data.content.map(elem => {
            return {
              _id: elem.user?._id,
              name: elem.user?.user_name,
              surname: elem.user?.user_surname,
              cell_number: elem.user?.user_cell_number,
              group: elem.group?.name,
              interaction: elem.register?.interaction?.codes,
              date: elem.register?.date,
              oi_type: elem.register?.level_type,
              is_in_history: elem.is_in_history
            }
          }) || []

        setTableData(attendances)
      }
    })
  }

  const _fetchAbsenteeHistory = () => {
    //write operations here
    absentee_history().then(response => {
      if (response.data.success) {
        //map out the data so that it is accepted by the table
        let absentees =
          response.data.content.map(elem => {
            return {
              _id: elem.user?._id,
              name: elem.user?.user_name,
              surname: elem.user?.user_surname,
              cell_number: elem.user?.user_cell_number,
              group: elem.group?.name,
              interaction: elem.interaction?.interaction?.codes,
              date: elem.date,
              oi_type: elem.level_type
            }
          }) || []

        setHistoryData(absentees)
      }
    })
  }

  const _onClickActionButton = (item, e) => {
    if (e.id == 1) {
      //on view, go to learner profile
      history.push(`learner-info/${item._id}/LEARNER-ACC`)
    }
  }

  const _onViewHistoryChange = index => {
    setIndex(index)
  }

  useEffect(() => {
    if (formValues) {
      _fetchAbsentees()
    }
  }, [formValues])

  useEffect(() => {
    if (index == 1) {
      historyData.splice
      tableData.splice
      _fetchAbsenteeHistory()
    }
  }, [index])

  return (
    <TableContentWrapper title={'Absenteeism'}>
      {/* ----------Table */}
      <div className="flex flex-row gap-8 items-end">
        {index == 0 && (
          <div className="w-full rounded-md shadow-sm bg-indigo">
            <div className="p-4 flex gap-2">
              <div className="flex flex-row gap-1">
                <span>
                  <FontAwesomeIcon size="sm" icon={faFilter} color="white" />
                  <div className="text-white">Filter</div>
                </span>
              </div>
              <DropdownButtonComponent
                placeholder="Select Programme"
                isBusy={false}
                type="primary"
                theme="dark"
                displayKey="program_name"
                dropdownItems={programsState?.programs}
                selectedItem={
                  programsState?.programs?.length > 0
                    ? programsState?.programs?.find(
                        x => x.program_name == formValues?.program?.program_name
                      )?.program_name
                    : ''
                }
                onClick={item => {
                  _onFormValuesChange({
                    program: {
                      _id: item._id,
                      program_name: item.program_name
                    }
                  })
                }}
              />

              <DropdownButtonComponent
                placeholder="Select Group"
                isBusy={false}
                type="primary"
                theme="dark"
                displayKey="name"
                dropdownItems={groupState?.groups}
                selectedItem={
                  groupState?.groups.length > 0
                    ? groupState?.groups.find(x => x.name == formValues?.group?.group_name)?.name
                    : ''
                }
                onClick={item => {
                  _onFormValuesChange({
                    group: {
                      _id: item._id,
                      group_name: item.name
                    }
                  })
                }}
              />

              {formValues?.program?.program_name || formValues?.group?.group_name ? (
                <div className="h-30 rounded-md bg-main flex items-center justify-end">
                  <div className="flex flex-row gap-4 items-center justify-end p-2">
                    <FontAwesomeIcon size="sm" icon={faCalendar} />
                    {dateValues.map((value, i) => (
                      <div
                        onClick={() => {
                          switch (value) {
                            case 'Custom':
                              // _onFormValuesChange({
                              //     date_filter: {
                              //       _id: item._id,
                              //       program_name: item.program_name
                              //     }
                              //   })
                              break
                            case 'Day':
                            case 'Week':
                            case 'Month':
                            case 'Year':
                              _onFormValuesChange({
                                date_filter: value
                              })
                              break

                            default:
                              break
                          }
                        }}
                        className={
                          formValues?.date_filter === value
                            ? 'cursor-pointer underline text-blue-400'
                            : 'cursor-pointer'
                        }
                        key={i}
                      >
                        {value}
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div></div>
              )}

              <div
                className="h-30 flex justify-center items-center p-2 bg-red-500 rounded-full gap-3 cursor-pointer hover:bg-red-300"
                onClick={v => {
                  setFormValues(undefined)
                }}
              >
                <FontAwesomeIcon size="sm" icon={faTimes} color="white" />
                <div className="text-white">Clear</div>
              </div>
            </div>
          </div>
        )}
      </div>
      {index == 0 && (
        <MyTableControl
          title={'Absentees'}
          search={{ show: true, search_by: 'name' }}
          showPagination={true}
          data={tableData}
          displayKeys={absenteesColumns}
          actionButton={{
            side: 'right',
            title: 'Details',
            items: actionButtonItems,
            isDropdown: true,
            onClick: _onClickActionButton
          }}
          mainButton={
            <div className="flex-none mr-4">
              <ButtonComponent
                title={'View History'}
                isBusy={false}
                type="primary"
                onClick={v => {
                  _onViewHistoryChange(1)
                }}
              />
            </div>
          }
        />
      )}

      {index == 1 && (
        <MyTableControl
          title={'Absentees'}
          search={{ show: true, search_by: 'name' }}
          showPagination={true}
          data={historyData}
          displayKeys={absenteesColumns}
          actionButton={{
            side: 'right',
            title: 'Details',
            items: actionButtonItems,
            isDropdown: true,
            onClick: _onClickActionButton
          }}
          mainButton={
            <div className="flex-none mr-4">
              <ButtonComponent
                title={'Reload Absentees'}
                isBusy={false}
                type="primary"
                onClick={v => {
                  _onViewHistoryChange(0)
                }}
              />
            </div>
          }
        />
      )}
    </TableContentWrapper>
  )
}
const mapStateToProps = state => {
  return {
    mainState: state.mainState,
    groupState: state.groupState,
    programsState: state.programsState
  }
}

export default connect(mapStateToProps)(AbsenteesComponent)
