import React, { useEffect } from 'react'
import InputComponent from '../../../customComponents/input'

const CatchUpDayForm = ({ formValues, onChange, programs }) => {
  return (
    <div className="flex flex-col gap-2">
      <InputComponent
        theme="dark"
        title="Catch Up Days Used"
        value={formValues?.catch_up_days_used}
        onChange={v =>
          onChange({
            catch_up_days_used: v
          })
        }
      />
      <br />
    </div>
  )
}

export default CatchUpDayForm
