import React, { useEffect, useRef, useState } from 'react'
import { get_all_recruits } from '../../api/calls/recruitment/get_all_recruits'
import TableContentWrapper from '../../controls/tableContentWrapper/tableContentWrapper'
import { MyTableControl } from '../../controls/myTableControl/myTableControl'
import { toTitleCase } from '../../utils'
import DropdownButtonComponent from '../customComponents/dropdownButton'
import { get_all_groups } from '../../api/calls'
import CSVDownloaderControl from '../../controls/csv_downloader'

const displayKeys = [
  { key: 'name', display: 'Name' },
  { key: 'surname', display: 'Surname' },
  { key: 'phone_number', display: 'Phone Number' },
  { key: 'email', display: 'Email Address' },
  { key: 'group', display: 'Group' },
  { key: 'enrolled', display: 'Enrolled' },
  { key: 'open_day', display: 'Open Day' },
  { key: 'oi_workshop', display: 'OI Workshop' },
  { key: 'interview', display: 'Interview' },
  { key: 'tech_interview', display: 'Tech Interview' },
  { key: 'green_light_survey', display: 'Green Light Survey' },
  { key: 'shadow_week', display: 'Shadow Week' }
]

const RecruitmentHistory = () => {
  const [recruits, setRecruits] = useState([])
  const [groups, setGroups] = useState([])
  const [selectedGroup, setSelectedGroup] = useState({})

  useEffect(() => {
    //get all recruits
    getRecruits()

    //get groups
    get_all_groups().then(e => {
      if (e.data.success) {
        setGroups(e.data.content)
      }
    })
  }, [])

  const getRecruits = async group_id => {
    get_all_recruits(group_id).then(e => {
      if (e.data.success) {
        const recruits = e.data.content.map(recruit => {
          return {
            _id: recruit._id,
            name: toTitleCase(recruit.user.user_name),
            surname: toTitleCase(recruit.user.user_surname),
            email: recruit.user.user_email,
            phone_number: recruit.user.user_cell_number,
            enrolled: recruit.enrolled ? 'Yes' : 'No',
            group: recruit.group_name ?? '-',
            open_day:
              toTitleCase(recruit.open_day.status) == 'No status'
                ? '-'
                : toTitleCase(recruit.open_day.status),
            oi_workshop:
              toTitleCase(recruit.oi_workshop.status) == 'No status'
                ? '-'
                : toTitleCase(recruit.oi_workshop.status),
            interview:
              toTitleCase(recruit.interview.status) == 'No status'
                ? '-'
                : toTitleCase(recruit.interview.status),
            tech_interview:
              toTitleCase(recruit.tech_interview.status) == 'No status'
                ? '-'
                : toTitleCase(recruit.tech_interview.status),
            green_light_survey:
              toTitleCase(recruit.green_light_survey.status) == 'No status'
                ? '-'
                : toTitleCase(recruit.green_light_survey.status),
            shadow_week:
              toTitleCase(recruit.shadow_week.status) == 'No status'
                ? '-'
                : toTitleCase(recruit.shadow_week.status)
          }
        })
        setRecruits(recruits)
      }
    })
  }

  return (
    <TableContentWrapper>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-4">
          {/* <div className="w-96">
            <ButtonComponent
              title="Download as PDF"
              icon={faSave}
              onClick={() => {
                generatePDF(targetRef, {
                  filename: 'recruits.pdf'
                })
              }}
              isBusy={false}
              type="primary"
            />
          </div> */}
          <div>
            <MyTableControl
              title={'All Recruits'}
              search={{ show: true, search_by: 'name' }}
              showPagination={true}
              data={recruits}
              mainButton={
                <div className="flex flex-row gap-2">
                  <DropdownButtonComponent
                    placeholder={'Filter by Group'}
                    theme="light"
                    displayKey="name"
                    dropdownItems={groups ?? []}
                    selectedItem={groups.find(x => x._id == selectedGroup._id)?.name}
                    onClick={item => {
                      setSelectedGroup(item)
                      getRecruits(item._id)
                    }}
                  />
                  <CSVDownloaderControl
                    headers={[
                      { key: 'name', label: 'Name' },
                      { key: 'surname', label: 'Surname' },
                      { key: 'phone_number', label: 'Phone Number' },
                      { key: 'email', label: 'Email Address' },
                      { key: 'group', label: 'Group' },
                      { key: 'enrolled', label: 'Enrolled' },
                      { key: 'open_day', label: 'Open Day' },
                      { key: 'oi_workshop', label: 'OI Workshop' },
                      { key: 'interview', label: 'Interview' },
                      { key: 'tech_interview', label: 'Tech Interview' },
                      { key: 'green_light_survey', label: 'Green Light Survey' },
                      { key: 'shadow_week', label: 'Shadow Week' }
                    ]}
                    data={recruits}
                    title={`Recruits${
                      selectedGroup.group_name ? `_${selectedGroup.group_name}` : '_all'
                    }`}
                  />
                </div>
              }
              displayKeys={displayKeys}
            />
          </div>
        </div>
      </div>
    </TableContentWrapper>
  )
}

export default RecruitmentHistory
