import { setCategoriesState } from '../actions'
import { setTaskState } from '../actions/task'
import { get_all_categories } from '../api/calls/tasks/get_all_categories'
import { get_all_tasks } from '../api/calls/tasks/get_all_tasks'
import { get_task_by_status } from '../api/calls/tasks/get_tasks_by_status'

export const getAllTasks = () => {
  return dispatch => {
    get_all_tasks()
      .then(res => {
        if (res.data.success) {
          dispatch(
            setTaskState({
              tasksLoaded: true,
              tasks: res.data.content
            })
          )
        }
      })
      .catch(error => {})
  }
}

export const getCategories = () => {
  return dispatch => {
    get_all_categories()
      .then(res => {
        if (res.data.success) {
          dispatch(
            setCategoriesState({
              categoriesLoaded: true,
              categories: res.data.content
            })
          )
        }
      })
      .catch(error => {})
  }
}

export const getTasksByStatus = data => {
  return dispatch => {
    get_task_by_status(data)
      .then(res => {
        if (res.data.success) {
          dispatch(
            setTaskState({
              tasksLoaded: true,
              tasks: res.data.content
            })
          )
        }
      })
      .catch(error => {})
  }
}
