import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getAllTasks, setNotification } from '../../../asyncActions'
import events from '../events'
import PopupControl from '../../../controls/popup/popup'
import ButtonComponent from '../../customComponents/button'
import { delete_event } from '../../../api/calls/tasks/delete_task'
import NotificationControl from '../../../controls/notification/notification'
import { faTimes } from '@fortawesome/pro-light-svg-icons'

export default function ConfirmDeletePopUp({ show, close, event, onDeleteSuccess }) {
  const [notificationData, setNotificationData] = useState({})

  const _deleteEvent = id => {
    delete_event(id).then(response => {
      if (response.data.success) {
        setNotificationData({
          title: 'Success',
          messages: ['Event successfully deleted.']
        })
        close(false)
        onDeleteSuccess(true)
      } else {
        setNotificationData({
          title: 'Failed',
          messages: ['Failed to delete event.']
        })
        onDeleteSuccess(false)
      }
    })
  }

  return (
    <div>
      <PopupControl
        title="Confirm Delete"
        isOpen={show}
        close={{
          showClose: true,
          onClose: () => {
            close(false)
          }
        }}
      >
        <div className="CalendarTheme flex flex-col gap-12 w-80 justify-center">
          <div className="text-l text-white">"Are you sure you want to delete this event?"</div>
          <ButtonComponent
            title={'DELETE'}
            icon={faTimes}
            onClick={() => {
              _deleteEvent(event._id)
            }}
          />
        </div>
      </PopupControl>
      <NotificationControl notificationData={notificationData} />
    </div>
  )
}
