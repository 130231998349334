import { SAVE_VENUES_STATE, SET_VENUES_STATE } from '../constants/redux_types'

export const setVenuesState = payload => {
  return {
    type: SET_VENUES_STATE,
    payload: payload
  }
}

export const saveVenuesState = payload => {
  return {
    type: SAVE_VENUES_STATE,
    payload: payload
  }
}
