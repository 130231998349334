import { setLearnerState } from '../actions/learner'
import { get_all_users } from '../api/calls/get_all_users'
import { initialPageNumber, itemsPerPage } from '../constants/constants'
import { editEntityState, setEntityState } from '../actions/entities'
import { get_entity_programs } from '../api/calls/programs/get_entity_programs'
import { editFacilitatorState } from '../actions'
import { all_transactions } from '../api/calls'

export const getEntities = (pageNumber = initialPageNumber, search = '') => {
  //   const filters = `type_code=ENTITY-ACC&page=${pageNumber}&limit=${itemsPerPage}&search=${search}`
  const filters = `type_code=ENTITY-ACC&search=${search}`
  return async dispatch => {
    await get_all_users(`${filters}`)
      .then(response => {
        if (response.data.success) {
          dispatch(
            setEntityState({
              entitiesLoaded: true,
              entities: response.data.content,
              paginate: response.data.paginate
            })
          )
        } else {
        }
      })
      .catch(error => {})
  }
}

export const getEntityPrograms = user => {
  return dispatch => {
    get_entity_programs(user._id)
      .then(res => {
        if (res.data.success) {
          dispatch(
            editEntityState({
              ...user,
              programs: res.data.content
            })
          )
        }
      })
      .catch(error => {})
  }
}

export const getEntityTransactions = (user, program) => {
  const programQuery = program?._id ? `&program_id=${program._id}` : ''

  return dispatch => {
    all_transactions(`user=${user._id}${programQuery}`)
      .then(res => {
        if (res.data.success) {
          dispatch(
            editEntityState({
              ...user,
              transactions: res.data.content
            })
          )
        }
      })
      .catch(error => {})
  }
}
