import React, { useState } from 'react'

import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { setNotification } from '../../asyncActions/notification'
import { saveStoplightSurvey } from '../../asyncActions/stoplight'
import { stoplightSurveyIndicators, surveyIndicators } from '../../constants/constants'
import ButtonControl from '../../controls/button/button'
import { CircleIconText } from '../../controls/circleIconText/circleIconText'
import TableControl from '../../controls/table/table'
import TableContentWrapper from '../../controls/tableContentWrapper/tableContentWrapper'
import TextInputControl from '../../controls/textinput/textinput'
import { useFormActionHook } from '../../hooks/useFormActionHook'
import { getDataWithIndicatorsIcons, getIndicatorAverages } from '../../utils/surveyUtils'
import AveragesWidget from './components/averagesWidget'
import {
  initialStoplightValues,
  mockData,
  stoplightColumns,
  stoplightValidationConfig
} from './constants'
import AddResultsModal from './modals/addResultsModal'
import ImportCsvModal from './modals/importCsvModal'
import DatePickerComponent from '../customComponents/datepicker'
import InputComponent from '../customComponents/input'
import ButtonComponent from '../customComponents/button'
import { faFileImport, faPlus, faSave } from '@fortawesome/pro-light-svg-icons'

const stoplightGraphRoute = 'stoplight-graph'

const StoplightResults = ({ notificationState: notificationData }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [showAddResults, setShowAddResults] = useState(false)
  const [showImportCsv, setShowImportCsv] = useState(false)
  const [formValues, setFormValues] = useState(initialStoplightValues)
  const { handleSubmit } = useFormActionHook(formValues, stoplightValidationConfig)

  const stoplightTableData = getDataWithIndicatorsIcons({
    data: formValues.results,
    iconConfig: stoplightSurveyIndicators,
    lookup: 'doing_well.struggle.stuck'
  })

  const stoplightIndicatorsAverages = getIndicatorAverages({
    data: formValues.results,
    lookup: 'doing_well.struggle.stuck'
  })

  const _toggleAddResults = () => {
    setShowAddResults(!showAddResults)
  }

  const _toggleImportCsv = () => {
    setShowImportCsv(!showImportCsv)
  }

  const _onInputChange = value => {
    setFormValues({ ...formValues, ...value })
  }

  const _onSubmitSurveyResults = values => {
    setFormValues({ ...formValues, results: [...formValues.results, values] })
  }
  const _onSubmitCsv = values => {
    setFormValues({ ...formValues, results: [...formValues.results, ...values] })
  }

  const _saveSurvey = () =>
    handleSubmit({
      formAction: () => {
        dispatch(saveStoplightSurvey(formValues))
      },
      onFailedValidation: errors => {
        dispatch(
          setNotification({
            title: 'Validation Failed',
            messages: errors
          })
        )
      }
    })

  return (
    <TableContentWrapper title="Stoplight Results" notificationData={notificationData}>
      <div className="w-full ">
        <div className="flex w-full flex-col items-center justify-center gap-16">
          <div className="flex flex-row gap-4">
            {surveyIndicators.map((item, i) => (
              <div key={i} className="">
                <CircleIconText
                  text={item.name}
                  className={item.color}
                  iconWithText={true}
                  iconColor={item.iconColor}
                  iconText={item.iconText}
                />
              </div>
            ))}
          </div>
          <div className="flex flex-col w-full gap-12 px-52">
            <div className="flex justify-between w-full gap-4">
              <div className="flex flex-col gap-2 w-full ">
                <InputComponent
                  title="Name"
                  theme="form"
                  value={formValues.survey_name}
                  onChange={survey_name => _onInputChange({ survey_name })}
                />
                <InputComponent
                  title="Office"
                  theme="form"
                  value={formValues.office}
                  onChange={office => _onInputChange({ office })}
                />

                <DatePickerComponent
                  title="Date"
                  date={formValues.date}
                  onChange={date => _onInputChange({ date })}
                />
              </div>
              <div className="w-full"></div>
            </div>

            <TableControl
              columns={stoplightColumns}
              data={stoplightTableData}
              hideEmptyTableData={false}
              width="full"
              rowClicked={id => {}}
            />
            <div className="flex flex-row justify-center items-center w-full gap-4">
              <ButtonComponent
                title="Save"
                icon={faSave}
                onClick={_saveSurvey}
                isBusy={false}
                type="primary"
              />
              <ButtonComponent
                title="Add Results"
                icon={faPlus}
                onClick={_toggleAddResults}
                isBusy={false}
                type="primary"
              />
              <ButtonComponent
                onClick={_toggleImportCsv}
                icon={faFileImport}
                title="Import CSV"
                isBusy={false}
                type="primary"
              />
            </div>
          </div>
        </div>
      </div>
      <AddResultsModal
        onSuccess={_onSubmitSurveyResults}
        open={showAddResults}
        setShow={_toggleAddResults}
      />
      <ImportCsvModal onSuccess={_onSubmitCsv} open={showImportCsv} setShow={_toggleImportCsv} />
    </TableContentWrapper>
  )
}

const mapStateToProps = state => {
  return {
    notificationState: state.notificationState,
    stoplightState: state.stoplightState
  }
}

export default connect(mapStateToProps)(StoplightResults)
