import { editLearnerState } from '../actions/learner'
import { all_documents } from '../api/calls/documents/all_documents'

export const getUserDocuments = user => {
  return dispatch => {
    all_documents(`user=${user._id}`)
      .then(res => {
        if (res.data.success) {
          dispatch(
            editLearnerState({
              ...user,
              documents: res.data.content
            })
          )
        }
      })
      .catch(error => {})
  }
}
