export const selectCurriculums = ({ curriculumState }) => {
  return curriculumState?.curriculums
}

export const selectCurriculumsLoaded = ({ curriculumState }) => {
  return curriculumState.curriculumsLoaded
}
export const selectCurriculumById =
  ({ curriculumState }) =>
  id =>
    curriculumState?.curriculums?.find(curriculum => id === curriculum._id)

export const selectCurriculumByProgramId =
  ({ curriculumState }) =>
  programId =>
    curriculumState?.curriculums?.filter(curriculum => curriculum.program_id === programId)

export const selectCurriculumInteractionsWithIds =
  ({ curriculumState }) =>
  id => {
    const curriculum = curriculumState?.curriculums.find(_ => _._id === id)
    if (!curriculum) return [] //expects array
    return curriculum?.interactions.map(interaction => ({
      id: interaction._id,
      name: interaction.interaction_name
    }))
  }
