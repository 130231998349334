import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  faCheck, faChevronDown, faEllipsisV
} from "@fortawesome/pro-light-svg-icons";
import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transition } from "@headlessui/react";

const DropdownControl = (props) => {
  const {
    id,
    theme = "light",
    subItems,
    onClick,
    disabled,
    type,
    isBusy,
    title,
    placeholder,
    dropdownOpen = false,
    allItem,
    multiple,
    selectedItem,
    selectedItems,
    displayKey,
    secondaryDisplayKey,
    selector = 'id',
    style = {},
    buttonBg,
  } = props; 
  const [isOpen, setIsOpen] = useState(dropdownOpen);
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);
  const [, updateState] = React.useState();
  const forceUpdate = useCallback(() => updateState({}), []);

 
  useEffect(() => { 
    function handleClickOutside(event) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        // alert("You clicked outside of me!");
        setIsOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); 
 
  if (multiple) {
    if (subItems && selectedItems) {
      for (let sub of subItems) {
        sub.selected = false;
        for (let si of selectedItems) {
          if (sub[selector] == si[selector]) {
            sub.selected = true;
            break;
          }
        }
      }
    } else {
      for (let sub of subItems) {
        sub.selected = false;
      }
    }
  }

  const options = {
    light: {
      title: "text-dark",
      placeholder: "text-white",
      buttonBg: disabled? "bg-transparent": buttonBg? buttonBg: "bg-theme",
    },
    dark: {
      title: "text-white",
      placeholder: "text-gray",
      buttonBg: disabled? "bg-textinput-dark": buttonBg? buttonBg: "bg-textinput-dark",
    },
  };

  return (
    <div className="controls-dropdown flex-1">
      {title && (
        <div
          className={`${options[theme].title} text-left text whitespace-nowrap`}
        >
          {title}
        </div>
      )}

      {type == "main" && (
        <button
          id={`button-${id}`}
          className={`focus:outline-none`}
          onClick={() => setIsOpen(!isOpen)}
          disabled={disabled}
          onBlur={() => {
            setTimeout(() => {
              setIsOpen(false);
            }, 200);
          }}
        >
          {isBusy ? (
            "BUSY ..."
          ) : (
            <div className="flex flex-col justify-center">
              <div className={`flex justify-center items-center`}>
                <span className="font-bold pr-2 text-white">{placeholder}</span>
                {subItems && (
                  <div className="">
                    <FontAwesomeIcon
                      className="text-lg text-white"
                      icon={faChevronDown}
                    />
                  </div>
                )}
              </div>
              <div className="text-white">
                {/* {selected.name} */}
                {selectedItem
                  ? selectedItem[displayKey]
                  : allItem
                  ? allItem[displayKey]
                  : null}
              </div>
            </div>
          )}
        </button>
      )}

      {type == "ellipsis" && (
        <button
          id={`button-${id}`}
          className={`focus:outline-none`}
          onClick={() => setIsOpen(!isOpen)}
          disabled={disabled}
          onBlur={() => {
            setTimeout(() => {
              setIsOpen(false);
            }, 200);
          }}
        >
          <div className="text-lg px-4">
            <FontAwesomeIcon className="text-l text-dark" icon={faEllipsisV} />
          </div>
        </button>
      )}

      {type == "account" && (
        <button
          ref={buttonRef}
          id={`button-${id}`}
          className={`focus:outline-none`}
          onClick={() => setIsOpen(!isOpen)}
          disabled={disabled}
        >
          <div className="border-l border-gray flex flex-row items-center p-4 h-10">
            <FontAwesomeIcon className="text-2xl text-dark" icon={faUser} />
            <div className="text-dark px-4">{placeholder}</div>
            <FontAwesomeIcon
              className="text-xl text-dark"
              icon={faChevronDown}
            />
          </div>
        </button>
      )}

      {type == "primary" && (
        <button
          ref={buttonRef}
          id={`button-${id}`}
          className={`rounded-full  ${options[theme].buttonBg} 
                    w-full h-9 px-4 ${options[theme].title} focus:outline-none`}
          onClick={
            subItems && subItems.length > 0
              ? () => setIsOpen(!isOpen)
              : () => onClick()
          }
          disabled={disabled}
        >
          <div
            className={`flex  flex-none flex-nowrap ${
              subItems ? "justify-between" : "justify-center"
            } items-center`}
          >
            <span
              className={`${options[theme].placeholder} whitespace-nowrap overflow-hidden pr-2`}
            >
              {(() => {
                if (multiple) {
                  //TODO: There is probably an easier way to do this --v
                  let arr = [];
                  for (let subItem of subItems) {
                    if (selectedItems) {
                      for (let selectedItem of selectedItems) {
                        if (subItem[selector] == selectedItem[selector]) {
                          arr.push(subItem);
                          break;
                        }
                      }
                    }
                  }
                  return arr
                    ?.map((x) =>
                      x[displayKey] ? x[displayKey] : x[secondaryDisplayKey]
                    )
                    .join(", ");
                } else {
                  // return selectedItem ? selectedItem[displayKey] ? selectedItem[displayKey] : selectedItem[secondaryDisplayKey] : placeholder;
                  if (selectedItem) {
                    let idx = subItems.findIndex(
                      (x) => x[selector] == selectedItem[selector]
                    );
                    // console.log("selectedItem", selectedItem);
                    return idx > -1
                      ? subItems[idx][displayKey]
                        ? subItems[idx][displayKey]
                        : subItems[idx][secondaryDisplayKey]
                      : "";
                  } else {
                    return placeholder;
                  }
                  // let idx = subItems.findIndex(x => x[selector] == selectedItem[selector]);
                  // return selectedItem ?  : placeholder;
                }
              })()}
              {/* {multiple ? selectedItems?.map(x => x[displayKey]).join(", ") : selectedItem ? selectedItem[displayKey] : ""} */}
            </span>
            {subItems && (
              <FontAwesomeIcon
                className="text-lg flex-none text-white"
                icon={faChevronDown}
              />
            )}
          </div>
        </button>
      )}

      {subItems && subItems.length > 0 && (
        <div className="relative z-20" ref={dropdownRef}>
          <Transition
            id={`subitems-${id}`}
            show={isOpen}
            enter="transition ease-out duration-100 transform"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75 transform"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={`${type == "ellipsis" ? "left-0 w-28" : "w-56"}
                            max-h-dropdown-content overflow-y-auto
                            absolute mt-2  rounded-md shadow-lg 
                            bg-white ring-1 ring-black ring-opacity-5 text-left
                            `}
              style={style}
            >
              <div className="py-1">
                {allItem && (
                  <div
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                    onClick={async (e) => {
                      // setClickedDropdown(true);
                      e.stopPropagation();
                      onClick(allItem);
                      setIsOpen(false);
                    }}
                  >
                    {allItem[displayKey]
                      ? allItem[displayKey]
                      : allItem[secondaryDisplayKey]}
                  </div>
                )}
                {subItems.map((item, index) => {
                  if (item) {
                    return (
                      <div
                        key={`${
                          item[displayKey]
                            ? item[displayKey]
                            : item[secondaryDisplayKey]
                        }-${index}`}
                        className={`
                                                    px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 
                                                    cursor-pointer flex flex-row justify-between`}
                        onClick={
                          multiple
                            ? undefined
                            : (e) => {
                                e.stopPropagation();
                                onClick(item);
                                setIsOpen(false);
                              }
                        }
                      >
                        <div className="flex-auto">
                          {item[displayKey]
                            ? item[displayKey]
                            : item[secondaryDisplayKey]}
                        </div>
                        {multiple && (
                          <div
                            className="flex-none w-5 h-5 bg-theme rounded-sm flex justify-center items-center"
                            onClick={async (e) => {
                              e.stopPropagation();
                              // item.selected = !item.selected;
                              subItems[index].selected = !item.selected;
                              forceUpdate();

                              //Now lets send all the selected items to the caller.
                              if (onClick) {
                                onClick(subItems.filter((x) => x.selected));
                              }
                            }}
                          >
                            {item.selected ? (
                              <FontAwesomeIcon
                                className="text-lg text-white"
                                icon={faCheck}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </Transition>
        </div>
      )}
    </div>
  );
};

export default DropdownControl;
