import React, { useEffect, useState } from 'react'

import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { setMainState } from '../../actions/main'
import { getFacilitators } from '../../asyncActions/facilitators'
import { initialPageNumber } from '../../constants/constants'
import ButtonControl from '../../controls/button/button'
import LoaderControl from '../../controls/loader/loader'
import NotificationControl from '../../controls/notification/notification'
import TableControl from '../../controls/table/table'
import { selectPaginateState } from '../../selectors/selectors'
import { selectNotificationState } from '../notifications/selectors'
import AddUserComponent from '../user/components/addUser'
import { actionButtonItems, facilitatorsColumn } from '../user/constants'
import { selectEntities, selectEntitiesLoaded, selectEntitiesState } from './selectors'
import { getEntities } from '../../asyncActions/entities'
import { MyTableControl } from '../../controls/myTableControl/myTableControl'
import ButtonComponent from '../customComponents/button'
import { faPen, faRedo } from '@fortawesome/pro-light-svg-icons'
import TableComponent from '../UIComponents/components/tables/table'
import ButtonComponentOutlined from '../UIComponents/components/buttons/buttonOutlined'
import AddEntityComponent from './components/addEntity'

const EntitiesComponent = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const userType = 'ENTITY-ACC'
  const [ShowAddEntity, setShowAddEntity] = useState(false)
  const entitiesLoaded = useSelector(selectEntitiesLoaded)
  const notificationData = useSelector(selectNotificationState)
  const [isBusy, setIsBusy] = useState(false)
  const [selectedUser, setSelectedUser] = useState({})
  const e = useSelector(selectEntities)
  const entities = e.map(_ => ({
    ..._,
    full_name: `${_?.user_name} ${_?.user_surname}`,
    business_name: _?.entity?.business_name,
    owner: _?.entity?.owner,
    position: e.indexOf(_) + 1
  }))

  useEffect(() => {
    if (!entitiesLoaded) dispatch(getEntities())
  }, [entitiesLoaded])

  const _onRefresh = _ => {
    dispatch(getEntities())
  }

  const _handleAddEntity = _ => {
    setSelectedUser({})
    setShowAddEntity(true)
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-4">
        <div className="bg-white shadow-md p-4">
          <TableComponent
            title={'Entities'}
            data={entities}
            displayKeys={[
              { value: 'position', label: 'ID' },
              { value: 'business_name', label: 'Business Name' },
              { value: 'owner', label: 'Owner' },
              { value: 'user_cell_number', label: 'Phone Number' },
              { value: 'user_email', label: 'Email' },
              { value: 'status_code', label: 'Status' }
            ]}
            actions={[
              {
                label: 'Details',
                action: item => {
                  dispatch(
                    setMainState({
                      selectedUser: item
                    })
                  )
                  history.push(`entity-details/${item._id}`)
                }
              }
            ]}
          >
            <div className="flex gap-2">
              <ButtonComponentOutlined
                title="Add New Entity"
                startIcon={faPen}
                onClick={_handleAddEntity}
              />
              <ButtonComponentOutlined title="Refresh" startIcon={faRedo} onClick={_onRefresh} />
            </div>
          </TableComponent>
        </div>
      </div>
      <LoaderControl show={isBusy} />
      <AddEntityComponent
        open={ShowAddEntity}
        setShow={setShowAddEntity}
        user={selectedUser}
        onSuccess={_onRefresh}
      />
      <NotificationControl notificationData={notificationData} />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    mainState: state.mainState,
    entityState: state.entityState
  }
}

export default connect(mapStateToProps)(EntitiesComponent)
