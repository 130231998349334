import { resetAlertState, setAlertState } from '../actions'

export const setAlert = payload => {
  return dispatch => {
    dispatch(setAlertState({ onConfirm: () => dispatch(resetAlert()), ...payload }))
  }
}

export const resetAlert = () => {
  return dispatch => {
    dispatch(resetAlertState())
    // window.location.reload()
  }
}
