import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltLeft } from '@fortawesome/pro-light-svg-icons'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

const BreadCrumbs = ({ title, onClick, disabled = false }) => {
  return (
    <div className="flex flex-row gap-2 items-center">
      {/* <FontAwesomeIcon icon={faArrowLeft} /> */}
      <div
        className={`${
          disabled
            ? 'text-gray cursor-not-allowed '
            : 'text-blue-600 cursor-pointer hover:text-blue-400'
        }  hover:underline`}
        onClick={e => {
          if (!disabled) {
            onClick()
          }
        }}
      >
        {title}
      </div>
      /
    </div>
  )
}

export default BreadCrumbs
