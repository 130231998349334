import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { title } from 'process'

export const MultiSelectDropdown = ({ options, theme, onSelect, displayKey, title }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState([])
  const themeType = {
    light: {
      titleColor: 'text-white',
      placeholder: 'text-white',
      color: 'bg-theme text-white',
      hover: 'hover:bg-blue-400'
    },
    dark: {
      titleColor: 'text-white',
      placeholder: 'text-gray',
      color: 'bg-textinput-dark border border-gray text-white',
      hover: 'hover:bg-blue-400'
    }
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleOptionClick = option => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter(item => item !== option))
    } else {
      setSelectedOptions([...selectedOptions, option])
      onSelect([...selectedOptions, option])
    }
  }


  return (
    <div className="overflow-visible relative inline-block text-left">
      <div>
        <button
          onClick={toggleDropdown}
          className={`inline-flex w-full h-12 px-2 py-2 ${themeType[theme].color} ${themeType[theme].hover} cursor-pointer justify-between rounded-md border shadow-sm bg-white text-gray-700 hover:bg-gray-50 focus:outline-none`}
        >
          {selectedOptions.length > 0
            ? selectedOptions
                .map(e => {
                  return e.name
                })
                .join(', ')
            : title ?? 'Select Options'}
          {!isOpen ? (
            <FontAwesomeIcon icon={faChevronDown} />
          ) : (
            <FontAwesomeIcon icon={faChevronUp} />
          )}
        </button>
      </div>

      {isOpen && (
        <div className="w-full max-h-80 absolute z-10 top-12 bg-white text-black border-gray shadow-md flex flex-col gap-2 origin-top-right right-0 mt-2 rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {options.map(option => (
              <div key={option}>
                <label className="flex items-center cursor-pointer text-sm w-full justify-between rounded-md hover:bg-blue-300  border-transparent hover:border-blue-700 border-l-2 py-2 px-4">
                  <input
                    type="checkbox"
                    checked={selectedOptions.includes(option)}
                    onChange={() => handleOptionClick(option)}
                    className="mr-2"
                  />
                  {option[displayKey]}
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
