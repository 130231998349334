import { RESET_APPLICATION_STATE, SET_APPLICATION_STATE } from '../constants/redux_types'

export const setApplicationState = payload => {
  return {
    type: SET_APPLICATION_STATE,
    payload: payload
  }
}

export const resetApplicationState = payload => {
  return {
    type: RESET_APPLICATION_STATE,
    payload: payload
  }
}
