export const selectVenuesState = state => state.venuesState

export const selectVenuesLoaded = ({ venuesState }) => {
  return venuesState.venuesLoaded
}
export const selectVenuesWithIds = ({ venuesState }) =>
  venuesState.venues.map(venue => ({ id: venue._id, name: venue.name }))

export const selectVenues = ({ venuesState }) => venuesState.venues

export const selectVenueById =
  ({ venuesState }) =>
  id =>
    venuesState.venues.find(venue => id === venue._id)
