import React, { useState } from 'react'

import { connect, useDispatch, useSelector } from 'react-redux'

import { setNotification } from '../../../asyncActions'
import { surveyIndicators } from '../../../constants/constants'
import AddMultipleInputControl from '../../../controls/addMultipleTextInput/AddMultipleInput'
import ButtonControl from '../../../controls/button/button'
import DropdownControl from '../../../controls/dropdown/dropdown'
import ModalFormWrapper from '../../../controls/modalFormWrapper/modalFormWrapper'
import PopupControl from '../../../controls/popup/popup'
import { useFormActionHook } from '../../../hooks/useFormActionHook'
import { INITIAL_STATE as initialNotificationData } from '../../../reducers/states/notification'
import { selectNotificationState } from '../../notifications/selectors'
import { dimensionsValidationConfig, initialDimensionsValues } from '../constants'
import InputComponent from '../../customComponents/input'
import DropdownButtonComponent from '../../customComponents/dropdownButton'
import ButtonComponent from '../../customComponents/button'
import { faSave } from '@fortawesome/pro-light-svg-icons'

const AddDimensionsModal = ({ open = false, setShow, onSuccess }) => {
  const dispatch = useDispatch()
  const notificationData = useSelector(selectNotificationState)
  const [formValues, setFormValues] = useState(initialDimensionsValues)
  const { handleSubmit } = useFormActionHook(formValues, dimensionsValidationConfig)

  const _onInputChange = value => {
    setFormValues({ ...formValues, ...value })
  }

  const _getSelectedItem = value => {
    if (value) {
      const item = surveyIndicators.find(item => item.id === value)
      return item
    }
  }
  const _onSubmitHandler = () =>
    handleSubmit({
      formAction: () => {
        onSuccess(formValues)
        setShow(false)
        setFormValues(initialDimensionsValues)
      },
      onFailedValidation: errors =>
        dispatch(
          setNotification({
            title: 'Validation Failed',
            messages: errors
          })
        )
    })

  return (
    <div>
      <PopupControl
        title="Adding a new Dimension"
        isOpen={open}
        close={{
          showClose: true,
          onClose: () => {
            setShow(false)
          }
        }}
      >
        <ModalFormWrapper notificationData={notificationData}>
          <div>
            {/* -------form fields */}
            <div className="flex flex-row gap-2">
              <div className="w-72 flex flex-col gap-4">
                <InputComponent
                  theme="dark"
                  title="Dimension"
                  value={formValues.dimension}
                  onChange={dimension => _onInputChange({ dimension })}
                />
                {/* -------multiple input fields */}
                <AddMultipleInputControl
                  onChange={questions => _onInputChange({ questions })}
                  values={formValues.questions}
                >
                  {(onFieldChange, index) => {
                    const questionValue = formValues.questions[index]?.question
                    const indicatorValue = formValues.questions[index]?.indicator
                    return (
                      <div className="flex flex-col justify-between align-middle gap-2">
                        <InputComponent
                          title="Question"
                          theme="dark"
                          value={questionValue}
                          onChange={question => onFieldChange({ question }, index)}
                        />
                        <DropdownButtonComponent
                          title="Indicator"
                          theme="dark"
                          type="primary"
                          displayKey="name"
                          dropdownItems={surveyIndicators}
                          selectedItem={_getSelectedItem(indicatorValue)?.name}
                          onClick={x => onFieldChange({ indicator: x.id }, index)}
                        />
                      </div>
                    )
                  }}
                </AddMultipleInputControl>
              </div>
            </div>
            <div className="flex justify-center items-center">
              <div className="w-62 mt-8">
                {/* -------button */}
                <ButtonComponent
                  title="Save Dimensions"
                  icon={faSave}
                  isLoading={false}
                  onClick={_onSubmitHandler}
                />
              </div>
            </div>
          </div>
        </ModalFormWrapper>
      </PopupControl>
    </div>
  )
}

export default AddDimensionsModal
