import React, { useEffect, useState } from 'react'
import DropdownButtonComponent from '../../customComponents/dropdownButton'

const ValueExchange = ({ items, onSelect, selected }) => {
  const [programs, setPrograms] = useState([])

  useEffect(() => {
    if (items) {
      setPrograms(items)
    }
  }, [items])

  return (
    <div className="w-80 pb-10">
      <div className="w-72 flex flex-col gap-4">
        <DropdownButtonComponent
          placeholder="Value Exchange for Programmes"
          disabled={programs.length > 0 ? false : true}
          isLoading={false}
          displayKey="program_name"
          theme="light"
          onClick={onSelect}
          dropdownItems={programs}
          selectedItem={programs?.find(x => x?._id == selected?._id)?.program_name}
        />
      </div>
    </div>
  )
}

export default ValueExchange
