export const selectGroupsState = ({ groupState }) => {
  return groupState
}

export const selectGroupsLoaded = ({ groupState }) => {
  return groupState.groupsLoaded
}

export const selectGroupById =
  ({ groupState }) =>
  id =>
    groupState.groups.find(group => id === group._id)

export const selectGroups = ({ groupState }) => {
  return groupState.groups
}

export const selectGroupDashboard = ({ groupState }) => {
  return groupState.groupDashboard
}
export const selectGroupsWithIds = ({ groupState }) =>
  groupState.groups.map(groups => ({
    id: groups._id,
    name: groups.name
  }))
