import React from 'react'

import TableControl from '../table/table'
 
const EntityTable = ({ name, data, columnConfig, hasViewBtn = true, onViewTable = () => {}, tableActionButton = <></> }) => (
  <div className="">
    <div className="flex justify-between mt-12 mb-2">
      <div className="">
        <h3 className="font-bold text-xl">{name}</h3>
      </div>
      <div className="w-60">
        {tableActionButton}
      </div>
    </div>

    <div className="bg-white shadow-md">
      <TableControl
        search={false}
        actionButton={hasViewBtn ? {
          side: 'right',
          title: 'View',
          isDropdown: false,
          onClick: onViewTable
        }: undefined}
        columns={columnConfig}
        width="full"
        data={data}
        rowClicked={id => {}}
      />
    </div>
  </div>
)
export default EntityTable
