import { save_register } from '../api/calls/attendance'
import { resetAlert, setAlert } from './alert'

export const saveRegister = data => {
  return dispatch => {
    save_register(data)
      .then(res => {
        if (res.data.success) {
          dispatch(
            setAlert({
              show: true,
              success: true,
              message: 'You have successfully saved register.'
            })
          )
        } else {
          dispatch(
            setAlert({
              show: true,
              success: false,
              message: 'The register could not be saved.'
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          setAlert({
            show: true,
            success: false,
            message: error.message
          })
        )
      })
    dispatch(resetAlert())
  }
}
