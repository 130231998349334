import React, { useEffect, useState } from "react";

const BarGraphControl = props => {
    const {
        items,
    } = props;

    const [barItems, setBarItems] = useState([]);
    
    useEffect(() => {
        setBarItems(items);
    }, [items]);

    return (
        <div className="flex h-full gap-4">
            {(barItems && barItems.length > 0) && barItems.map((item, index) => {
                return (
                    <div key={index} className="w-4 flex flex-col gap-2 items-center">
                        <div className="w-2 flex-auto flex items-end">
                            <div className="bg-green w-full rounded-full" style={{height: `${(item.percentage * 100)}%`}} />
                        </div>
                        <div className="w-2 h-2 rounded-full bg-green" />
                        <div className="text-xs">
                            {item.title}
                        </div>
                    </div>
                );
            })}
        </div>
    )
}

export default BarGraphControl;