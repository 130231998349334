import {
  EDIT_ENTITY_STATE,
  SET_ENTITY_STATE,
  UPDATE_ENTITY_STATE
} from '../../constants/redux_types'

export function entityStateReducer(
  state = {
    entitiesLoaded: false,
    entities: []
  },
  action
) {
  switch (action.type) {
    case SET_ENTITY_STATE:
      return {
        ...state,
        ...action.payload
      }

    case EDIT_ENTITY_STATE:
      const _entities = state.entities.map(x => {
        if (x._id === action.payload._id) {
          return action.payload
        } else {
          return x
        }
      })

      return {
        ...state,
        entities: _entities
      }

    case UPDATE_ENTITY_STATE:
      return {
        ...state,
        entities: [action.payload, ...state.entities]
      }
    default:
      return state
  }
}
