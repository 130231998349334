import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

const RadioButtonGroupControl = props => {
    // console.log("1",props);
    const { group, values, onChange, value } = props;
    const [selectedValue, setSelectedValue] = useState(value);

    //Will receive props...
    useEffect(() => {
        setSelectedValue(value);
    }, [value]);

    //Will unmount...
    useEffect(() => {
        return () => {
            console.log("Cleaning up...");
        }
    }, []);

    return (
        <div className="flex flex-row gap-20 justify-center items-center">
            {values.map((item, index) => {
                return (
                    <label key={index} className="flex flex-row items-center gap-4 h-9 cursor-pointer">
                        <span className="text-white">{item.title}</span>
                        <input className="cursor-pointer" type="radio" name={group} value={item.value}
                            checked={item.value == selectedValue}
                            onChange={() => {
                                if (onChange) {
                                    onChange(item.value);
                                }
                        }}></input>
                    </label>
                )
            })}
        </div>
    )
}

export default RadioButtonGroupControl;