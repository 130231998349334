import React from 'react'

import LoaderControl from '../loader/loader'
import NotificationControl from '../notification/notification'

const TableContentWrapper = ({
  children,
  title,
  actionButtons = [],
  loaderIsActive = false,
  notificationData = { title: '', messages: [], type: 0 }
}) => {
  return ( 
    <div className="flex flex-col gap-4">
      <div className="flex flex-row">
        <div className="flex-auto text-xl font-bold">{title}</div>
         <div className="flex justify-between mr-4">
          {actionButtons.map((item, id) => (<div key={id}> {item}</div>))}
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="bg-white shadow-md p-4">{children}</div>
      </div>
      <LoaderControl show={loaderIsActive} />
      {notificationData && <NotificationControl notificationData={notificationData} />}
      </div> 
  )
}

export default TableContentWrapper
