export const selectApplicationsState = state => state.applicationState

export const selectApplicationsLoaded = ({ applicationState }) => {
  return applicationState.applicationsLoaded
}
export const selectApplicationsWithIds = ({ applicationState }) =>
  applicationState.applications.map(application => ({
    id: application._id,
    name: application.name
  }))

export const selectApplications = ({ applicationState }) => applicationState.applications

export const selectApplicationById =
  ({ applicationState }) =>
  id =>
    applicationState.applications.find(application => id === application._id)
