import { SET_PROJECT_STATE } from '../../constants/redux_types'

export function projectStateReducer(
  state = {
    general_info: {},
    offset_categories: {
      carbon_farming_activities: []
    }
  },
  action
) {
  if (action.type == SET_PROJECT_STATE) {
    state = action.payload
  }
  return state
}
