import React from 'react'

import moment from 'moment'

import DropdownControl from '../controls/dropdown/dropdown'
import TextInputControl from '../controls/textinput/textinput'
import { getStringFromField } from './utils'

export const validateFormValues = ({ formValues, validationsConfig, parseOnly = false }) => {
  let errors = []
  let newValues = formValues

  validationsConfig.forEach(validation => {
    const { field, types: validationTypes } = validation
    const fieldName = getStringFromField(field)
    // ---------
    const isEmptyValue = formValues[field].length == 0 && !parseOnly
    const isNotNumber = typeof formValues[field] !== 'number' && !parseOnly

    validationTypes.forEach(type => {
      if (type == 'required') {
        if (isEmptyValue) {
          errors.push(`- ${fieldName} is required`)
        } else {
          newValues[field] = formValues[field]
        }
      } else if (type == 'number') {
        if (isNotNumber) {
          errors.push(`- ${fieldName} should be a number`)
        } else {
          newValues[field] = parseInt(formValues[field])
        }
      } else if (type == 'date') {
        newValues[field] = moment(formValues[field]).format('YYYY-MM-DD')
      }
      //---add more validations here---
    })
  })

  if (errors.length > 0) {
    return [false, errors]
  }
  return [newValues, errors]
}

export const prepareFormFields = ({ values, overrides }) => {
  return Array.from(Object.keys(values), (value, index) => {
    const _override = overrides.find(override => override.name === value)

    return Object.assign(
      {},
      !_override
        ? {
            name: value,
            label: value,
            value: '',
            props: { type: 'text' }
          }
        : _override
    )
  })
}

export const CreateControlFormFields = ({ initialValues, stateValues, onChange }) => {
  return (
    <>
      {initialValues.map((field, index) => {
        const defaultTitle = getStringFromField(field.label)
        const onChangeHandler = value => {
          onChange({
            ...stateValues,
            [field.name]: value
          })
        }
        const getSelectedItem = value => {
          if (field.props.subItems && value) {
            return field.props.subItems.find(item => item.id === value)
          }
        }
        switch (field.props.type) {
          case 'text':
            return (
              <TextInputControl
                key={index}
                theme="dark"
                title={defaultTitle}
                value={stateValues[field.name] ?? field.value}
                onChange={onChangeHandler}
                {...field.props}
              />
            )
          case 'primary':
            return (
              <DropdownControl
                key={index}
                title={defaultTitle}
                isBusy={false}
                type="primary"
                theme="dark"
                selectedItem={getSelectedItem(stateValues[field.name] ?? field.value)}
                onClick={value => onChangeHandler(value.id)}
                {...field.props}
              />
            )
        }
      })}
    </>
  )
}
