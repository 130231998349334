import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setMainState } from '../../actions/main'
import { deleteLearner, getLearners } from '../../asyncActions/learners'
import ButtonControl from '../../controls/button/button'
import TableContentWrapper from '../../controls/tableContentWrapper/tableContentWrapper'
import { selectPaginateState } from '../../selectors/selectors'
import { selectNotificationState } from '../notifications/selectors'
import AddUserComponent from '../user/components/addUser'
import { selectLearners, selectLearnersLoaded, selectLearnersState } from './selectors'
import { MyTableControl } from '../../controls/myTableControl/myTableControl'
import ButtonComponent from '../customComponents/button'
import { faEye, faPen, faRedo, faTrash } from '@fortawesome/pro-light-svg-icons'
import { use } from 'echarts'
import ConfirmationModal from '../customComponents/confirmationModal'
import Table from '../induction/components/table'
import TableComponent from '../UIComponents/components/tables/table'
import { on } from 'events'
import { icon } from '@fortawesome/fontawesome-svg-core'
import { set } from 'date-fns'

const LearnerComponent = () => {
  const history = useHistory()
  const userType = 'LEARNER-ACC'
  const [showAddRecruitment, setShowAddRecruitment] = useState(false)
  const dispatch = useDispatch()
  const learnerState = useSelector(selectLearnersState)
  const learnersLoaded = useSelector(selectLearnersLoaded)
  const learners = useSelector(selectLearners)
  const [selectedUser, setSelectedUser] = useState({})
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    dispatch(getLearners())
  }, [])

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-4">
        <TableComponent
          search={true}
          title="Learners"
          displayKeys={[
            { value: 'user_name', label: 'Name' },
            { value: 'user_surname', label: 'Surname' },
            { value: 'user_cell_number', label: 'Phone Number' },
            { value: 'user_email', label: 'Email Address' },
            {
              value: 'status_code',
              label: 'Status',
              isChip: true,
              expression: [
                { value: 'ACTIVE', color: 'green' },
                { value: 'INACTIVE', color: 'red' }
              ]
            }
          ]}
          data={learners}
          onRowClick={item => {
            dispatch(
              setMainState({
                selectedUser: item
              })
            )
            history.push(`learner-info/${item._id}/${userType}`)
          }}
          actions={[
            {
              label: 'Delete',
              hide: item => item.status_code === 'INACTIVE',
              action: item => {
                setSelectedUser(item)
                setIsModalOpen(true)
              },
              icon: faTrash
            }
          ]}
        />
      </div>

      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={v => setIsModalOpen(false)}
        onConfirm={v => {
          dispatch(deleteLearner(selectedUser._id))
          setIsModalOpen(false)
        }}
        title="Confirmation"
        description="Are you sure you want to remove this learner?"
        confirmationText="CONFIRM"
        confirmButtonText="Confirm"
        cancelButtonText="Cancel"
      />
    </div>
  )
}

export default LearnerComponent
