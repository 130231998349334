import { createBrowserHistory } from 'history'

import { setGreenlightState } from '../actions/greenlight'
import {
  delete_greenlight_survey,
  get_all_greenlight_surveys,
  get_greenlight,
  save_greenlight_survey
} from '../api/calls'
import { resetNotification, setNotification } from './notification'

const browserHistory = createBrowserHistory()

export const getAllGreenLightSurveys = () => {
  return dispatch => {
    get_all_greenlight_surveys()
      .then(res => {
        if (res.data.success) {
          dispatch(
            setGreenlightState({
              greenlightSurveysLoaded: true,
              greenlightSurveys: res.data.content
            })
          )
        }
      })
      .catch(error => {})
  }
}

export const getGreenlightSurvey = greenlightSurvey => {
  return dispatch => {
    get_greenlight(`id=${greenlightSurvey._id}`)
      .then(res => {
        if (res.data.success) {
          dispatch(
            setGreenlightState({
              greenlightSurveys: res.data.content
            })
          )
        }
      })
      .catch(error => {})
  }
}

export const saveGreenlightSurvey = greenlight => {
  return dispatch => {
    save_greenlight_survey(greenlight)
      .then(res => {
        if (res.data.success) {
          dispatch(
            setNotification({
              title: 'Success',
              messages: ['You have successfully saved a survey.'],
              onConfirm: () => {
                dispatch(resetNotification())
                browserHistory.goBack()
              }
            })
          )
        } else {
          dispatch(
            setNotification({
              title: 'Failure',
              messages: ['Unable to save survey.']
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          setNotification({
            title: 'Failure',
            messages: [error.message]
          })
        )
      })
  }
}

export const deleteGreenlightSurvey = greenlight => {
  return dispatch => {
    delete_greenlight_survey(`id=${greenlight._id}`)
      .then(res => {
        if (res.data.success) {
          dispatch(
            setGreenlightState({
              greenlight: greenlight
            })
          )
        }
      })
      .catch(error => {})
  }
}
