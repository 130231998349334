export const content = [
  {
    name: 'ABC Gym',
    date: '29 August 2023',
    number_of_registers_taken: 13,
    download: {}
  },
  {
    name: 'ABC Gym',
    date: '29 August 2023',
    number_of_registers_taken: 13,
    download: {}
  }
]
