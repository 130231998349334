import React from 'react'

import { faBinoculars, faReceipt } from '@fortawesome/free-solid-svg-icons'
import {
  faBell,
  faBookReader,
  faHome,
  faStar,
  faTasks,
  faUser,
  faTally,
  faPersonBooth
} from '@fortawesome/pro-solid-svg-icons'
import { Redirect, Route, Switch } from 'react-router-dom'

import AttendanceRegisterComponent from './components/attendanceRegister/attendanceRegister'
import RemedialAction from './components/attendanceRegister/remedialAction'
import VolunteerDays from './components/attendanceRegister/volunteerDays'
import { CalendarCreation } from './components/calendarCreation'
import ActivityLogComponent from './components/dashboard/components/activityLog'
import HomeComponent from './components/dashboard/components/home'
import TaskManagerComponent from './components/events/events'
import FacilitatorDetails from './components/facilitators/components/details'
import FacilitatorComponent from './components/facilitators/facilitator'
import GreenlightSurveys from './components/greenlightSurvey/greenlightSurveys'
import AddGreenlightSurvey from './components/greenlightSurvey/addGreenlightSurvey'
import StudentApplications from './components/studentApplication/studentApplications'
import GroupsComponent from './components/groups/groups'
import InterviewComponent from './components/interview/interview'
import LearnerInfoComponent from './components/learner/components/learnerInfo'
import LearnerComponent from './components/learner/learner'
import NotificationsComponent from './components/notifications/notifications'
import OrderForm from './components/orderForm/orderForm'
import TaxInvoice from './components/orderForm/taxInvoice/taxInvoice'
import PerformanceTracker from './components/performanceTracker/performanceTracker'
import ProgrammeCurriculumComponent from './components/programmeCurriculum/programmeCurriculum'
import ProgramDetailsComponent from './components/programs/components/details'
import ProgramsComponent from './components/programs/programs'
import RecruitmentDetails from './components/recruitment/components/details'
import RecruitmentComponent from './components/recruitment/recruitment'
import StoplightGraph from './components/stoplightResults/stoplightGraph/stoplightGraph'
import StoplightResults from './components/stoplightResults/stoplightResults'
import ThankYouComponent from './components/studentApplication/thankYou'
import VenueDetails from './components/venues/components/venueDetails'
import Venues from './components/venues/venues'
import GroupDetailsComponent from './components/groups/components/details'
import EntitiesComponent from './components/entities/entities'
import EntityDetails from './components/entities/components/details'
import ReportsComponent from './components/reports/reports'
import GreenlightSurveyInfo from './components/greenlightSurvey/greenlightSurveyInfo'
import DownloadableRegister from './components/attendanceRegister/components/downloadableRegister'
import {
  faBook,
  faClipboardList,
  faGraduationCap,
  faScrewdriver
} from '@fortawesome/pro-light-svg-icons'
import AbsenteesComponent from './components/absentees/absentees'
import ConfigurationsComponent from './components/configurations/configurations'
import InductionComponent from './components/induction/induction'
import RecruitmentSummary from './components/recruitment summary/recruitment_summary'
import RecruitmentHistory from './components/recruitment/recruitment_history'
import GroupDashboard2 from './components/groupDashboard2/groupDashboard2'

const AppRoutes = ({ path }) => {
  return (
    <Switch>
      <Redirect exact from={path} to={`${path}/home`} />
      <Route path={`${path}/home`} render={() => <HomeComponent />} />
      <Route path={`${path}/performance-tracker`} render={() => <PerformanceTracker />} />
      <Route path={`${path}/recruitments`} render={() => <RecruitmentComponent />} />
      <Route path={`${path}/recruitment-summary`} render={() => <RecruitmentSummary />} />
      <Route path={`${path}/recruitment-history`} render={() => <RecruitmentHistory />} />
      <Route path={`${path}/learners`} render={() => <LearnerComponent />} />
      <Route path={`${path}/induction`} render={() => <InductionComponent />} />
      <Route path={`${path}/facilitators`} render={() => <FacilitatorComponent />} />
      <Route path={`${path}/groups`} render={() => <GroupsComponent />} />
      <Route path={`${path}/group-dashboard`} render={() => <GroupDashboard2 />} />
      <Route path={`${path}/programs`} render={() => <ProgramsComponent />} />
      <Route path={`${path}/program-details/:id`} render={() => <ProgramDetailsComponent />} />
      <Route path={`${path}/group-details/:id`} render={() => <GroupDetailsComponent />} />
      <Route path={`${path}/facilitator-details/:id`} render={() => <FacilitatorDetails />} />
      <Route path={`${path}/events`} render={() => <TaskManagerComponent />} />
      <Route path={`${path}/activity-log`} render={() => <ActivityLogComponent />} />
      <Route path={`${path}/notifications`} render={() => <NotificationsComponent />} />
      <Route path={`${path}/learner-info/:user_id/:type`} render={() => <LearnerInfoComponent />} />
      <Route path={`${path}/greenlight-surveys`} render={() => <GreenlightSurveys />} />
      <Route path={`${path}/application-portal`} render={() => <StudentApplications />} />
      <Route path={`${path}/create-greenlight-survey`} render={() => <AddGreenlightSurvey />} />
      <Route path={`${path}/greenlight-survey-info/:id`} render={() => <GreenlightSurveyInfo />} />
      <Route path={`${path}/stoplight-results`} render={() => <StoplightResults />} />
      <Route path={`${path}/venues`} render={() => <Venues />} />
      <Route path={`${path}/venue-details/:id`} render={() => <VenueDetails />} />
      <Route path={`${path}/stoplight-graph`} render={() => <StoplightGraph />} />
      <Route path={`${path}/entities`} render={() => <EntitiesComponent />} />
      <Route path={`${path}/entity-details/:id`} render={() => <EntityDetails />} />
      <Route path={`${path}/configurations`} render={() => <ConfigurationsComponent />} />
      <Route
        path={`${path}/register-download/:attendance_id`}
        render={() => <DownloadableRegister />}
      />
      <Route
        path={`${path}/calendar-creation/:curriculum_id`}
        render={() => <CalendarCreation />}
      />
      <Route path={`${path}/interview/:user_id`} render={() => <InterviewComponent />} />
      <Route path={`${path}/order-form`} render={() => <OrderForm />} />
      <Route path={`${path}/tax-invoice`} render={() => <TaxInvoice />} />
      <Route path={`${path}/recruitment-status/:user_id`} render={() => <RecruitmentDetails />} />
      <Route
        path={`${path}/attendance/:program_id`}
        render={() => <AttendanceRegisterComponent />}
      />{' '}
      <Route path={`${path}/remedial-action/:program_id`} render={() => <RemedialAction />} />
      <Route path={`${path}/volunteer-days/:program_id`} render={() => <VolunteerDays />} />
      <Route
        path={`${path}/upload-programme-curriculum/:program_id/:curriculum_id?`}
        render={() => <ProgrammeCurriculumComponent />}
      />
      <Route path={`${path}/reports`} render={() => <ReportsComponent />} />
      <Route path={`${path}/absentees`} render={() => <AbsenteesComponent />} />
    </Switch>
  )
}

export default AppRoutes
