import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import Paper from '@mui/material/Paper'
import {
  InductionTableCell,
  InductionTableHeader,
  InductionTableRow,
  tableColumns
} from '../constants'
import { connect, useDispatch } from 'react-redux'
import { getPrograms } from '../../../asyncActions'
import DropdownButtonComponent from '../../customComponents/dropdownButton'
import DatePickerComponent from '../../customComponents/datepicker'
import { get_induction_report } from '../../../api/calls/induction'

const InductionTable = ({ programsState }) => {
  const dispatch = useDispatch()

  const [selectedProgram, setSelectedProgram] = React.useState(null)
  const [selectedDate, setSelectedDate] = React.useState(null)
  const [loading, setLoading] = React.useState(true)
  const [data, setData] = React.useState([])

  React.useEffect(() => {
    console.log('programs', programsState.programs)
    if (programsState.programs.length == 0) {
      dispatch(getPrograms())
      setLoading(false)
    }
  }, [])

  React.useEffect(() => {
    //fetch data for the selected program
    if (selectedProgram || selectedDate) {
      get_induction_report(selectedDate, selectedProgram?._id).then(response => {
        if (response.data.success) {
          setData(response.data.content.report)
          setLoading(false)
        }
      })
    }
  }, [selectedProgram, selectedDate])

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2 w-80">
        <DropdownButtonComponent
          placeholder="Select Programme"
          isBusy={false}
          type="primary"
          theme="dark"
          displayKey="program_name"
          dropdownItems={programsState?.programs}
          selectedItem={
            selectedProgram?.program_name
              ? programsState?.programs?.length > 0
                ? programsState?.programs?.find(
                    x => x.program_name == selectedProgram?.program_name
                  )?.program_name
                : 'No programs available'
              : 'Select Programme'
          }
          onClick={item => {
            setSelectedProgram({
              _id: item._id,
              program_name: item.program_name
            })
          }}
        />
        <DatePickerComponent
          title="Date"
          date={selectedDate}
          showYearOnly={true}
          onChange={date => {
            const d = new Date(date)
            setSelectedDate(d.getFullYear())
          }}
        />
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <InductionTableHeader>
              {tableColumns.map((column, idx) => (
                <InductionTableCell align="left">{column.display}</InductionTableCell>
              ))}
            </InductionTableHeader>
          </TableHead>
          <TableBody>
            {data.length == 0 && (
              <InductionTableRow>
                <InductionTableCell align="left" colSpan={tableColumns.length}>
                  No data available
                </InductionTableCell>
              </InductionTableRow>
            )}
            {data.map(row => (
              <InductionTableRow>
                {tableColumns.map(column => (
                  <InductionTableCell align="left">
                    {Array.isArray(row[column.key])
                      ? row[column.key].map((item, idx) => (
                          <div key={idx}>
                            {item.quarter}: {item.attendance}
                          </div>
                        ))
                      : row[column.key]}
                  </InductionTableCell>
                ))}
              </InductionTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
const mapStateToProps = state => {
  return {
    programsState: state.programsState
  }
}

export default connect(mapStateToProps)(InductionTable)
  