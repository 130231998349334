import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import PopupControl from '../../controls/popup/popup'
import DropdownButtonComponent from '../customComponents/dropdownButton'
import ButtonComponent from '../customComponents/button'
import { faPen } from '@fortawesome/pro-light-svg-icons'

const eventOptions = [
  {
    key: 'open_day',
    value: 'Open Day'
  },
  {
    key: 'oi_workshop',
    value: 'Occupational Intelligence Workshop'
  },
  {
    key: 'interview',
    value: 'Occupational Intelligence Interviews'
  }
]

const EventCategories = ({ open, show, onClick }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [option, setOption] = useState()

  useEffect(() => {
    if (option) {
      onClick(option)
      show(false)
    }
  }, [option])

  return (
    <div>
      <PopupControl
        title="Event Options"
        isOpen={open}
        close={{
          showClose: true,
          onClose: () => {
            show(false)
          }
        }}
      >
        <div className="flex flex-col gap-2">
          <ButtonComponent
            title="Create Event (Custom)"
            icon={faPen}
            onClick={() => {
              setOption({ key: 'custom', value: 'Custom' })
              // setCreateCustomEvent(true)
              //   setShowCreateEventComponent(true)
            }}
          />
          {/* <DropdownButtonComponent
            placeholder={'Create Event'}
            dropdownItems={eventOptions}
            displayKey="value"
            onClick={v => {
              setOption(v)
              //   setShowCreateEventComponent(true)
            }}
          /> */}
          {/* <ButtonComponent
            title="Add category"
            icon={faPen}
            onClick={v => {
              setShowAddCategory(true)
            }}
          /> */}
        </div>
      </PopupControl>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    mainState: state.mainState,
    groupState: state.groupState,
    programsState: state.programsState
  }
}

export default connect(mapStateToProps)(EventCategories)
