import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'

const AccordionControl = ({ content = [] }) => {
  const [open, setOpen] = useState(-1)
  const handleOpen = value => setOpen(open === value ? -1 : value)

  return (
    <div className="w-full">
      <div className="flex flex-col bg-main rounded-md overflow-auto gap-1">
        {content.map((item, index) => {
          return (
            <div className="flex flex-col">
              <div
                className={`flex justify-between ${open==index? 'bg-buttonColor': 'bg-activeMenu'} hover:bg-buttonColor p-4 font-extrabold font-sans cursor-pointer`}
                onClick={() => handleOpen(index)}
              >
                <div>
                  <p className="text-white">{item.title}</p>
                  {item.subTitle && (
                    <p className="text-white font-normal text-sm">{item.subTitle}</p>
                  )}
                </div>
                <FontAwesomeIcon
                  icon={open == index ? faChevronUp : faChevronDown}
                  size="lg"
                  color="white"
                />
              </div>
              <div
                className={`bg-main-light ${
                  open == index ? 'p-4' : 'overflow-hidden h-0'
                } transition-all duration-700`}
              >
                {item.content}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default AccordionControl
