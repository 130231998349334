import React, { useEffect, useState } from 'react'

import { connect, useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { getGroups } from '../../asyncActions'
import { getFacilitators } from '../../asyncActions/facilitators'
import DropdownControl from '../../controls/dropdown/dropdown'
import { selectFacilitatorsLoaded } from '../facilitators'
import { selectGroups, selectGroupsLoaded } from '../groups'
import Questions from './components/questions'
import { get_interview } from '../../api/calls/get_interview'
import InputComponent from '../customComponents/input'
import DropdownButtonComponent from '../customComponents/dropdownButton'
import TableContentWrapper from '../../controls/tableContentWrapper/tableContentWrapper'

const InterviewComponent = ({ facilitatorState, userState, mainState }) => {
  const history = useHistory()
  const [formValues, setFormValues] = useState()
  const [content, setContent] = useState()
  const [questions, setQuestions] = useState([])
  const [isGroupSelected, setIsGroupSelected] = useState(false)
  const dispatch = useDispatch()
  const { user_id } = useParams()
  const facilitatorsLoaded = useSelector(selectFacilitatorsLoaded)
  const groupsLoaded = useSelector(selectGroupsLoaded)
  const selectedGroups = useSelector(selectGroups)
  const groups = selectedGroups.map(group => ({
    ...group,
    id: group._id
  }))

  useEffect(() => {
    get_interview(user_id).then(response => {
      if (response.data.success) {
        setContent(response.data.content)
      }
    })
  }, [])

  useEffect(() => {
    if (!facilitatorsLoaded) {
      dispatch(getFacilitators())
    }
    if (!groupsLoaded) {
      dispatch(getGroups())
    }

    setFormValues({ ...formValues, facilitator_id: userState.id, learner_id: user_id })
  }, [facilitatorsLoaded, groupsLoaded, userState, facilitatorState])

  const _onGroupSelection = value => {
    setFormValues({ ...formValues, ...value })
  }

  return formValues != null ? (
    <TableContentWrapper title={'Interview'}>
      <div className="flex flex-col w-full">
        <div className="grid grid-cols-6">
          <div className="text-3xl px-2 font-extrabold italic col-span-5">
            {`${userState.user_name} ${userState.user_surname} (Interviewer)`}
          </div>
        </div>

        {isGroupSelected == true ? (
          <Questions
            interviewDetails={formValues}
            facilitatorState={facilitatorState}
            userState={userState}
            mainState={mainState}
          />
        ) : (
          <div className="h-screen flex items-center justify-center flex-col gap-4">
            <div className="text-sm">
              {'Hello, '}
              <span className="font-extrabold font-sans">{userState.user_name}.</span>
              {' To begin the interview, please select a group.'}
            </div>
            <div className="flex justify-center items-center">
              <DropdownButtonComponent
                // theme={'form'}
                placeholder={'Select Group'}
                type="primary"
                displayKey="name"
                dropdownItems={groups}
                selectedItem={groups?.find(x => x.id === formValues?.group?._id)?.name}
                onClick={group => {
                  _onGroupSelection({ group: { _id: group?.id, name: group.name } })
                  setIsGroupSelected(true)
                }}
              />
            </div>
          </div>
        )}
      </div>
    </TableContentWrapper>
  ) : (
    <div></div>
  )
}

const mapStateToProps = state => {
  return {
    mainState: state.mainState,
    userState: state.userState,
    programsState: state.programsState,
    facilitatorState: state.facilitatorState
  }
}

export default connect(mapStateToProps)(InterviewComponent)
