//TODO: component not in use - remove this
import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { setNotification } from '../../../asyncActions'
import { VALIDATION_FAILED_MSG } from '../../../constants/constants'
import ButtonControl from '../../../controls/button/button'
import DropdownControl from '../../../controls/dropdown/dropdown'
import ModalFormWrapper from '../../../controls/modalFormWrapper/modalFormWrapper'
import PopupControl from '../../../controls/popup/popup'
import TextInputControl from '../../../controls/textinput/textinput'
import { useFormActionHook } from '../../../hooks/useFormActionHook'
import { selectNotificationState } from '../../notifications/selectors'
import { ratePerItems, rateValidationConfig } from '../../user/constants'

const AddRateModal = ({ initialValue, open = false, setShow, onSuccess }) => {
  const dispatch = useDispatch()
  const notificationData = useSelector(selectNotificationState)
  const [formValues, setFormValues] = useState(initialValue)
  const { handleSubmit } = useFormActionHook(formValues, rateValidationConfig)

  const _onInputChange = value => {
    setFormValues({ ...formValues, ...value })
  }

  useEffect(() => {
    setFormValues(initialValue)
  }, [initialValue])

  const _getSelectedItem = value => {
    const item = ratePerItems.find(item => item.id === value)
    return item
  }

  const _onSubmitHandler = () =>
    handleSubmit({
      formAction: () => {
        onSuccess(formValues)
        setShow(false)
      },
      onFailedValidation: errors =>
        dispatch(
          setNotification({
            title: VALIDATION_FAILED_MSG,
            messages: [...errors]
          })
        )
    })

  return (
    <div>
      <PopupControl
        title="Add Rate"
        isOpen={open}
        close={{
          showClose: true,
          onClose: () => {
            setShow(false)
          }
        }}
      >
        <ModalFormWrapper notificationData={notificationData}>
          <div className="w-72 flex flex-col gap-4">
            <TextInputControl
              theme="dark"
              title="Rate"
              type="number"
              value={formValues.rate}
              onChange={rate => _onInputChange({ rate })}
            />

            <DropdownControl
              title="Per"
              theme="dark"
              type="primary"
              displayKey="name"
              subItems={ratePerItems}
              selectedItem={_getSelectedItem(formValues.per)}
              onClick={_ => _onInputChange({ per: _.id })}
            />
          </div>
          <div className="flex justify-center">
            <div className="w-full mt-8">
              <ButtonControl
                placeholder="Save"
                isBusy={false}
                type="primary"
                onClick={_onSubmitHandler}
              />
            </div>
          </div>
        </ModalFormWrapper>
      </PopupControl>
    </div>
  )
}

export default AddRateModal
