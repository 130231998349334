import { SET_APPLICATION_STATE } from '../../constants/redux_types'

const INITIAL_STATE = { applications: [], applicationsLoaded: false }

export function applicationStateReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_APPLICATION_STATE:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
