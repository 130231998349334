// @ts-nocheck
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { generateTableHeaders } from '../functions'
import PerformanceRecord from './performanceRecord'
import TextInputControl from '../../../controls/textinput/textinput'
import InputComponent from '../../customComponents/input'
import { faSearch } from '@fortawesome/pro-light-svg-icons'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'

const PerformanceTable = ({ content, filterBySearchCallback }) => {
  const [headers, setHeaders] = useState([])
  const [searchItem, setSearchItem] = useState()

  useEffect(() => {
    setHeaders(generateTableHeaders(content))
  }, [content])

  return (
    <div className="flex flex-col w-full gap-8">
      <InputComponent
        type="search"
        theme={'form'}
        placeholder="Search"
        value={searchItem}
        onChange={v => {
          setSearchItem(v)
          filterBySearchCallback(v)
        }}
      />
      <Table className="flex min-w-full">
        <TableHead className="bg-main text-lg">
          <TableRow>
            <TableCell className="text-gray-600 text-sm leading-normal">#</TableCell>
            <TableCell className="text-gray-600 text-sm leading-normal">Name</TableCell>
            <TableCell className="text-gray-600 text-sm leading-normal">Surname</TableCell>
            <TableCell className="text-gray-600 text-sm leading-normal">Avg Human OI</TableCell>
            {/* <TableCell className="text-gray-600 text-sm leading-normal">Avg Business OI</TableCell> */}
            <TableCell className="text-gray-600 text-sm leading-normal">Avg Technical OI</TableCell>
            {headers.map(value => (
              <TableCell className="text-gray-600 text-sm leading-normal">{value.code}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className="text-gray-600 text-sm font-normal">
          {content.map((learner, i) => (
            <PerformanceRecord
              learner={learner}
              index={i}
              headers={headers}
              searchItem={searchItem}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default PerformanceTable
