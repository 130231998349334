import { editLearnerState } from '../actions/learner'
import { get_user_programs } from '../api/calls/users/get_user_programs'

export const getUserPrograms = user => {
  return dispatch => {
    get_user_programs(`id=${user._id}`)
      .then(res => {
        if (res.data.success) {
          dispatch(
            editLearnerState({
              ...user,
              programs: res.data.content
            })
          )
        }
      })
      .catch(error => {})
  }
}
