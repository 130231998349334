import { faPlus, faUser } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const AvatarComponent = props => {
  const { src, onClick } = props
  return src ? (
    <img className="w-40 h-40 rounded-full bg-contain bg-center" src={src} alt="Image" />
  ) : (
    <div className="absolute z-40 bottom-0 right-0 bg-white shadow-md p-2 h-10 w-10  rounded-full">
      <div className="flex justify-center items-center ">
        <FontAwesomeIcon className="text-dark" icon={faPlus} />
      </div>
    </div>
  )
}

{
}
export default AvatarComponent
