import { setVenuesState } from '../actions'
import { get_venues, save_venue } from '../api/calls/venues'
import { delete_venue } from '../api/calls/venues/delete_venue'
import { setNotification } from './notification'

export const getVenues = () => {
  return dispatch => {
    get_venues()
      .then(res => {
        if (res.data.success) {
          dispatch(
            setVenuesState({
              venues: res.data.content,
              venuesLoaded: true
            })
          )
        }
      })
      .catch(error => {})
  }
}

export const saveVenue = venue => {
  return dispatch => {
    save_venue(venue).then(res => {
      if (res.data.success) {
        dispatch(
          setNotification({
            title: 'Success',
            messages: ['You have successfully saved the venue.']
          })
        )
      }
    })
  }
}
export const deleteVenue = venue => {
  return dispatch => {
    delete_venue(`id=${venue._id}`).then(res => {
      if (res.data.success) {
        dispatch(
          setNotification({
            title: 'Success',
            messages: ['You have successfully deleted the venue.']
          })
        )
      }
    })
  }
}
