import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { getAllTasks } from '../../asyncActions'
import TableContentWrapper from '../../controls/tableContentWrapper/tableContentWrapper'
import CalendarComponent from './calendar/calendar'

const EventsComponent = ({ taskState }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (!taskState.tasksLoaded) {
      dispatch(getAllTasks())
    }
  }, [taskState])

  return (
    <TableContentWrapper title="Calendar">
      <CalendarComponent events={taskState.tasks} />
    </TableContentWrapper>
  )
}

const mapStateToProps = state => {
  return {
    taskState: state.taskState
  }
}

export default connect(mapStateToProps)(EventsComponent)
