export const learnerAttendanceInfoSkeleton = {
  attendance_percentage: '',
  human_oi: '',
  business_oi: '',
  programs: [],
  recruitment: {}
}

export const catchUpDayTableColumn = [
  { key: 'date', date: true, display: 'Date Absent' },
  { key: 'interaction_code', display: 'Interaction' },
  { key: 'program_name', display: 'Programme' },
  { key: 'catch_up_status', display: 'Catch Up' }
]