import React, { useEffect } from 'react'

import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'

import { getVenues } from '../../../asyncActions'
import TitleCardControl from '../../../controls/cards/titlecard'
import { selectCurriculumsLoaded } from '../../programmeCurriculum/selectors'
import { selectVenueById } from '../selectors'
import VenueDetailsBlock from './detailsBlockWidget'

const VenueDetailsComponent = props => {
  const { id } = useParams()
  const history = useHistory()
  const venuesLoaded = useSelector(selectCurriculumsLoaded)
  const venue = useSelector(selectVenueById)(id)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!venuesLoaded) {
      dispatch(getVenues())
    }
  }, [venuesLoaded])

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="">
          <Link
            to="/dashboard/venues"
            className="mt-4 mb-4 text-green hover:underline focus:outline-none cursor-pointer"
          >
            <FontAwesomeIcon className="text-sm" icon={faArrowLeft} /> Back to all venues
          </Link>
        </div>
        <div className="flex flex-row">
          <div className="flex-auto text-3xl font-bold">{venue?.name}</div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="bg-white shadow-md p-4">
            <div className="flex gap-4">
              <div className="flex-grow" style={{ width: '80%' }}>
                <TitleCardControl css={{ height: '150px' }}>
                  <div className="flex text-sm">
                    <div className="" style={{ width: '30%' }}>
                      <img
                        src="https://i.picsum.photos/id/518/500/300.jpg?hmac=VTTNnAHwQvTv-P-iGF2ZPNvv8akuzKWkYLtTXDNtisg"
                        style={{ width: '100%' }}
                      />
                    </div>
                    <div className="mx-5">
                      <p className="font-bold">{venue?.name}</p>
                      <p className="text-gray my-2">{venue?.description}</p>
                    </div>
                  </div>
                </TitleCardControl>
              </div>
              <VenueDetailsBlock venue={venue} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default VenueDetailsComponent
