import { editRecruiterState, setRecruiterState } from '../actions/recruiter'
import { get_diligence, save_diligence } from '../api/calls'
import { get_all_users } from '../api/calls/get_all_users'
import { enroll_recruitee } from '../api/calls/recruitment/enroll_recruitee'
import { initialPageNumber, itemsPerPage } from '../constants/constants'
import { setAlert } from './alert'
import { setNotification } from './notification'

export const getRecruiters = (pageNumber = initialPageNumber, search = '') => {
  //   const filters = `type_code=RECRUITER-ACC&page=${pageNumber}&limit=${itemsPerPage}&search=${search}`
  const filters = `type_code=RECRUITER-ACC&search=${search}`

  return dispatch => {
    get_all_users(`${filters}`)
      .then(response => {
        if (response.data.success) {
          dispatch(
            setRecruiterState({
              recruitersLoaded: true,
              recruiters: response.data.content,
              paginate: response.data.paginate
            })
          )
        } else {
        }
      })
      .catch(error => {})
  }
}
export const getDiligence = user => {
  return dispatch => {
    get_diligence(`user_id=${user._id}`)
      .then(response => {
        if (response.data.success) {
          dispatch(
            editRecruiterState({
              ...user,
              diligence: response.data.content
            })
          )
        } else {
        }
      })
      .catch(error => {})
  }
}

export const saveDiligence = data => {
  return dispatch => {
    save_diligence(data)
      .then(response => {
        if (response.data.success) {
          dispatch(
            setNotification({
              title: 'Success',
              messages: ['You have saved diligence successfully.']
            })
          )
        } else {
        }
      })
      .catch(error => {})
  }
}
export const enrollRecruitee = data => {
  return dispatch => {
    enroll_recruitee(data)
      .then(response => {
        if (response.data.success) {
          dispatch(
            setAlert({
              show: true,
              success: true,
              message: 'Enrollment successful.'
            })
          )
        } else {
        }
      })
      .catch(error => {})
  }
}
