import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import {
  getAllCurriculums,
  getFacilitators,
  getGroups,
  getPrograms,
  getProgramUsers,
  getVenues,
  saveCalendar,
  setNotification
} from '../../asyncActions'
import ButtonControl from '../../controls/button/button'
import TableContentWrapper from '../../controls/tableContentWrapper/tableContentWrapper'
import { selectFacilitatorsLoaded, selectFacilitatorsWithIds } from '../facilitators/selectors'
import { selectGroupsLoaded, selectGroupsWithIds } from '../groups/selectors'
import { selectNotificationState } from '../notifications/selectors'
import {
  selectCurriculumById,
  selectCurriculumInteractionsWithIds,
  selectCurriculumsLoaded
} from '../programmeCurriculum/selectors'
import { selectProgramById, selectProgramsLoaded } from '../programs/selectors'
import { selectVenuesLoaded, selectVenuesWithIds } from '../venues/selectors'
import { EventCalendarTab, FullCalendarTab } from './components'
import { extractCalendarViewItems } from './utils/utils'
import ButtonComponent from '../customComponents/button'
import { faSave } from '@fortawesome/pro-light-svg-icons'

const CalendarCreation = props => {
  const { curriculum_id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const [calendarItems, setCalendarItemsState] = useState([])
  const notificationData = useSelector(selectNotificationState)
  const calendarViewItems = extractCalendarViewItems(calendarItems)

  const curriculumsLoaded = useSelector(selectCurriculumsLoaded)
  const facilitatorsLoaded = useSelector(selectFacilitatorsLoaded)
  const groupsLoaded = useSelector(selectGroupsLoaded)
  const programsLoaded = useSelector(selectProgramsLoaded)
  const venuesLoaded = useSelector(selectVenuesLoaded)

  const curriculum = useSelector(selectCurriculumById)(curriculum_id)
  const interactions = useSelector(selectCurriculumInteractionsWithIds)(curriculum_id)
  const program = useSelector(selectProgramById)(curriculum?.program_id)

  const facilitators = useSelector(selectFacilitatorsWithIds)
  const groups = useSelector(selectGroupsWithIds)
  const venues = useSelector(selectVenuesWithIds)

  const title = 'Calendar Creation - ' + (curriculum?.name || 'Untitled')

  useEffect(() => {
    if (!curriculum_id) history.goBack()
  }, [curriculum_id])

  useEffect(() => {
    if (!facilitatorsLoaded) dispatch(getFacilitators())
    if (!groupsLoaded) dispatch(getGroups())
    if (!venuesLoaded) dispatch(getVenues())
    if (!programsLoaded) dispatch(getPrograms())
    if (!curriculumsLoaded) dispatch(getAllCurriculums())
    if (program && !program?.learners) dispatch(getProgramUsers(program))
  }, [facilitatorsLoaded, groupsLoaded, curriculumsLoaded, programsLoaded, venuesLoaded, program])

  const onAddToCalendar = calendarItem => {
    setCalendarItemsState([...calendarItems, calendarItem])
    dispatch(
      setNotification({
        title: 'Success',
        messages: ['Curriculum added to calendar']
      })
    )
  }

  const _onGenerateCalendar = () => {
    if (calendarItems.length === 0) {
      return dispatch(
        setNotification({
          title: 'Validation Failed',
          messages: ['calendar fields empty']
        })
      )
    }
    dispatch(
      saveCalendar({
        curriculum_id,
        name: curriculum?.name,
        description: curriculum?.name,
        interactions: calendarItems
      })
    )
  }

  return (
    <TableContentWrapper title={title} notificationData={notificationData}>
      <div className="flex flex-row gap-2">
        <div className="w-84">
          <EventCalendarTab
            curriculum={curriculum}
            learners={program?.learners}
            facilitators={facilitators}
            interactions={interactions}
            venues={venues}
            groups={groups}
            onAddToCalendar={onAddToCalendar}
          />
        </div>
        <div className="w-4/5">
          <FullCalendarTab calendarEvents={calendarViewItems} />
        </div>
      </div>
      {/* -------- generate button */}
      <div className="m-5 flex items-center justify-center">
        <ButtonComponent
          title="Generate"
          icon={faSave}
          type="primary"
          onClick={_onGenerateCalendar}
        />
      </div>
    </TableContentWrapper>
  )
}

export default CalendarCreation
