import React from 'react'

import LoaderControl from '../loader/loader'
import NotificationControl from '../notification/notification'

const ModalFormWrapper = ({
  children,
  loaderIsActive = false,
  notificationData = { title: '', messages: [], type: 0 }
}) => {
  return (
    <div className="flex flex-col gap-8">
          {children}
      <LoaderControl show={loaderIsActive} />
      {notificationData && <NotificationControl notificationData={notificationData} />}
    </div>
  )
}

export default ModalFormWrapper
