import React, { useEffect, useState } from 'react'

import { faUser } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import {
  getFacilitatorsPrograms,
  getFacilitatorTeachingDays,
  getFacilitatorTransactions,
  saveBilling
} from '../../../asyncActions'
import { getModules } from '../../../asyncActions/modules'
import BreadCrumb from '../../../controls/breadcrumb/breadcrumb'
import ButtonControl from '../../../controls/button/button'
import TitleCardControl from '../../../controls/cards/titlecard'
import EntityTable from '../../../controls/entityTable/entityTable'
import TableContentWrapper from '../../../controls/tableContentWrapper/tableContentWrapper'
import { selectSelectedUser } from '../../../selectors/selectors'
import { extractById } from '../../../utils/entityUtils'
import { selectModules, selectModulesLoaded } from '../../modules/selectors'
import { selectNotificationState } from '../../notifications/selectors'
import AddPaymentComponent from '../../user/components/addPayment'
import AddTransactionComponent from '../../user/components/addTransactions'
import DetailsBlock from '../../user/components/detailsBlock'
import AddDocumentComponent from '../../user/components/UploadDocuments'
import ValueExchange from '../../user/components/valueExchange'
import {
  initialRateValues,
  programColumn,
  teachingDaysColumn,
  transactionsColumn
} from '../../user/constants'
import AddRateModal from '../modals/addRate'
import { selectFacilitators } from '../selectors'
import { extractWithModules, getTransactionTableData } from '../utils'
import { MyTableControl } from '../../../controls/myTableControl/myTableControl'
import ButtonComponent from '../../customComponents/button'

const entityName = 'Facilitator'

const FacilitatorDetails = props => {
  const { id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const notificationData = useSelector(selectNotificationState)
  const [showAddPayment, setShowAddPayment] = useState(false)
  const [showAddRate, setShowAddRate] = useState(false)
  const [showAddDocument, setShowAddDocument] = useState(false)
  const [showAddTransaction, setShowAddTransaction] = useState(false)
  const [selectedVebProgram, setSelectedVebProgram] = useState()

  const selectedUser = useSelector(selectSelectedUser)

  const facilitators = useSelector(selectFacilitators)
  const facilitator = extractById(facilitators)(selectedUser._id)

  const modulesLoaded = useSelector(selectModulesLoaded)
  const modules = useSelector(selectModules)

  const teachingDays = facilitator?.teachingDays || []
  const teachingDayModules = extractWithModules(teachingDays, modules)

  const programs = facilitator?.programs || []
  const transactionsTableData = getTransactionTableData(facilitator?.transactions)

  const [formValues] = useState({
    ...initialRateValues,
    user_id: id
  })

  useEffect(() => {
    if (!modulesLoaded) dispatch(getModules())
  }, [modulesLoaded])

  useEffect(() => {
    if (selectedUser?._id) {
      if (!facilitator?.programs) {
        dispatch(getFacilitatorsPrograms(facilitator))
      }
      if (facilitator?.programs && !facilitator?.transactions) {
        dispatch(getFacilitatorTransactions(facilitator))
      }
      if (facilitator?.transactions && !facilitator?.teachingDays) {
        dispatch(getFacilitatorTeachingDays(facilitator))
      }
    } else {
      history.goBack()
    }
  }, [facilitator, selectedUser])

  const _onViewProgram = item => {
    history.push(`/dashboard/program-details/${item._id}`)
  }

  const _onEditRate = () => {
    setShowAddRate(!showAddRate)
  }

  const _onViewTeachingDays = () => {}

  const _onSubmitHandler = values => {
    facilitator['billingInfo'] = values
    dispatch(saveBilling(values))
  }

  const _onSelectVebProgram = item => {
    setSelectedVebProgram(item)
    dispatch(getFacilitatorTransactions(facilitator, item))
  }

  return (
    <TableContentWrapper
      title={`${entityName} - ${facilitator?.user_name} ${facilitator?.user_surname}`}
      notificationData={notificationData}
      actionButtons={[]}
    >
      <div className="flex flex-col gap-4">
        <BreadCrumb name={entityName} />
        <div className="flex flex-col gap-4">
          <div className="bg-white shadow-md p-4">
            <div className="flex gap-4">
              <div className="flex-grow">
                <TitleCardControl css={{ height: '150px' }}>
                  <div className="grid grid-cols-2 gap-8">
                    <div className="flex flex-row space-x-8">
                      <div
                        className="p-4 flex bg-indigo rounded-full justify-center"
                        style={{ height: '100px', width: '100px' }}
                      >
                        <FontAwesomeIcon
                          className="fa-4x text-white ml-8 mr-8 align-baseline"
                          icon={faUser}
                        />
                      </div>
                      <div className="mx-10 items-start">
                        <p>{`${facilitator?.user_name} ${facilitator?.user_surname}`}</p>
                        <p>{facilitator?.user_email}</p>
                      </div>
                    </div>
                  </div>
                </TitleCardControl>
                <MyTableControl
                  title={'Programs'}
                  search={{ show: true, search_by: '' }}
                  showPagination={true}
                  data={programs ?? []}
                  displayKeys={programColumn}
                  actionButton={{
                    side: 'right',
                    title: 'View',
                    isDropdown: true,
                    onClick: _onViewProgram
                  }}
                />
                <MyTableControl
                  title={'Teaching Days'}
                  search={{ show: true, search_by: '' }}
                  showPagination={true}
                  data={teachingDayModules ?? []}
                  displayKeys={teachingDaysColumn}
                  actionButton={{
                    side: 'right',
                    title: 'View',
                    isDropdown: true,
                    onClick: _onViewTeachingDays
                  }}
                />

                <div className="py-10">
                  <ValueExchange
                    items={programs}
                    selected={selectedVebProgram}
                    onSelect={_onSelectVebProgram}
                  />

                  <MyTableControl
                    title={'Transactions'}
                    search={{ show: true, search_by: '' }}
                    showPagination={true}
                    data={transactionsTableData ?? []}
                    displayKeys={transactionsColumn}
                    // actionButton={{
                    //   side: 'right',
                    //   title: 'View',
                    //   isButton: true,
                    //   onClick: _onViewTeachingDays
                    // }}
                    mainButton={
                      <ButtonComponent
                        title="Add Transaction"
                        isBusy={false}
                        type="primary"
                        onClick={v => {
                          setShowAddTransaction(true)
                        }}
                      />
                    }
                  />
                </div>
              </div>
              {/* {facilitator && (
                <DetailsBlock details={facilitator} addRate={_onEditRate} userType={undefined} />
              )} */}
            </div>
          </div>
        </div>
        <AddPaymentComponent open={showAddPayment} setShow={setShowAddPayment} user={facilitator} />
        <AddTransactionComponent
          open={showAddTransaction}
          setShow={setShowAddTransaction}
          user={facilitator}
        />
        <AddRateModal
          initialValue={facilitator?.billingInfo || formValues}
          open={showAddRate}
          setShow={setShowAddRate}
          onSuccess={_onSubmitHandler}
        />
        <AddDocumentComponent
          open={showAddDocument}
          setShow={setShowAddDocument}
          user={facilitator}
        />
      </div>
    </TableContentWrapper>
  )
}

export default FacilitatorDetails
