import axios from 'axios'

export const get_open_weather = async (lat, lon) => {
  return await axios({
    method: 'GET',
    url: `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=f4c5c3bd078a73102f0007e6791f691a`
  })
    .then(response => {
      return response.data
    })
    .catch(error => {
      return extractErrorMessage(error)
    })
}
