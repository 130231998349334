import {
  SAVE_CURRICULUM_STATE,
  SET_CURRICULUM_STATE,
  RESET_CURRICULUM_STATE
} from '../constants/redux_types'

export const setCurriculumState = payload => {
  return {
    type: SET_CURRICULUM_STATE,
    payload: payload
  }
}

export const saveCurriculumState = payload => {
  return {
    type: SAVE_CURRICULUM_STATE,
    payload: payload
  }
}

export const resetCurriculumState = payload => {
  return {
    type: RESET_CURRICULUM_STATE,
    payload: payload
  }
}
