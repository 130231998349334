import Papa from 'papaparse'

import { validateFormValues } from './formUtils'
import { objectKeysAsField } from './utils'

export const getBlobPath = file => file && URL.createObjectURL(file)

export const csvUpload = ({ file, fieldsConfig, onSuccess, onError }) => {
  Papa.parse(file, {
    header: true,
    skipEmptyLines: true,
    complete: results => {
      try {
        const _results = transformCSVArray(results.data)
        const _values = _results.map(data => {
          const values = objectKeysAsField(data)

          const [validValues, errors] = validateFormValues({
            formValues: values,
            validationsConfig: fieldsConfig,
            parseOnly: true
          })

          if (validValues) return validValues
          else onError(errors)
        })
        onSuccess(_values)
      } catch (error) {
        onError()
      }
    }
  })
}

const transformCSVArray = items =>
  items.map(item => {
    let _results = {}
    let _arrayKey, _childKey, _listKey
    for (var key in item) {
      const _arrayLookup = '/0/'
      const _isArray = key.includes(_arrayLookup)
      const _headers = key.split(_arrayLookup)
      if (_isArray) {
        _arrayKey = _headers[0]
        _childKey = _headers[1]
        _results[_arrayKey] = { ..._results[_arrayKey], [_childKey]: item[key] }
      } else if (key !== '__parsed_extra') {
        _listKey = key
        _results[_listKey] = item[_listKey]
      }
    }
    return _arrayKey ? { ..._results, [_arrayKey]: [_results[_arrayKey]] } : _results
  })
