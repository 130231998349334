import {
  SAVE_STOPLIGHT_STATE,
  SET_STOPLIGHT_STATE,
  DELETE_STOPLIGHT_STATE
} from '../constants/redux_types'

export const setStoplightState = payload => {
  return {
    type: SET_STOPLIGHT_STATE,
    payload: payload
  }
}

export const saveStoplightState = payload => {
  return {
    type: SAVE_STOPLIGHT_STATE,
    payload: payload
  }
}

export const deleteStoplightState = payload => {
  return {
    type: DELETE_STOPLIGHT_STATE,
    payload: payload
  }
}
