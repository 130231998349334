import React, { useEffect, useState } from 'react'

import { connect, useDispatch } from 'react-redux'
import { useParams } from 'react-router'

import { get_program_users, get_venues } from '../../api/calls'
import { get_program_interactions } from '../../api/calls/programs/get_program_interactions'
import { getLearners, getPrograms } from '../../asyncActions'
import { saveRegister } from '../../asyncActions/attendance'
import { setNotification } from '../../asyncActions/notification'
import CheckBoxControl from '../../controls/checkbox/checkbox'
import DropdownControl from '../../controls/dropdown/dropdown'
import TableContentWrapper from '../../controls/tableContentWrapper/tableContentWrapper'
import TextInputControl from '../../controls/textinput/textinput'
import { useFormActionHook } from '../../hooks/useFormActionHook'
import AttendanceTable from './components/attendanceTable'
import { attendanceValidationConfig, initialAttendanceData } from './components/contants'
import Form from './components/form'
import ButtonComponent from '../customComponents/button'

const AttendanceRegisterComponent = ({
  venueState,
  learnerState,
  notificationState: notificationData,
  programsState,
  facilitatorState,
  userState
}) => {
  const dispatch = useDispatch()
  const [searchItem, setSearchItem] = useState('')
  const [formValues, setFormValues] = useState(initialAttendanceData)
  const [time, setTime] = useState('')
  const [init, setInit] = useState(true)
  const [users, setUsers] = useState([])
  const [venues, setVenues] = useState([])
  const [learners, setLearners] = useState()
  const { handleSubmit } = useFormActionHook(formValues, attendanceValidationConfig)
  const { program_id } = useParams()
  const [selectedProgram, setSelectedProgram] = useState({})
  const [attendanceForm, setAttendanceForm] = useState({})
  const [showAttendanceForm, setShowAttendanceForm] = useState(false)
  const [show, setShow] = useState(false)
  const [interactions, setInteractions] = useState([])

  const _saveRegister = () => {
    handleSubmit({
      formAction: () => {
        dispatch(saveRegister(formValues))
      },
      onFailedValidation: errors => {
        dispatch(
          setNotification({
            title: 'Validation Failed',
            messages: errors
          })
        )
      }
    })
  }

  useEffect(() => {
    if (program_id)
      get_program_interactions(program_id)
        .then(response => {
          if (response.data.success) {
            setInteractions(response.data.content)
          }
        })
        .catch(error => {})
  }, [program_id])

  useEffect(() => {
    if (Object.keys(attendanceForm).length > 0) {
      _onInputChange({
        attendance_form: {
          name: '',
          url: attendanceForm.attendance_form.location,
          etag: attendanceForm.attendance_form.etag
        }
      })
    }
  }, [attendanceForm])

  const handleAttendanceForm = form => {
    setAttendanceForm(form)
  }

  const _onInputChange = value => {
    setFormValues({ ...formValues, ...value })
  }

  const handleLearners = value => {
    setLearners(value)
  }

  useEffect(() => {
    _onInputChange({ learners })
  }, [learners])

  useEffect(() => {
    if (!programsState.programsLoaded) {
      dispatch(getPrograms())
    }
    if (init && learnerState.learners.length == 0) {
      dispatch(getLearners())
      setInit(false)
    }

    let _program = programsState?.programs?.find(x => x._id === program_id)
    if (_program) {
      setSelectedProgram(_program)
    }

    // dispatch(getVenues()) - REMEMBER TO COME BACK TO THIS!

    get_venues()
      .then(response => {
        if (response.data.success) {
          setVenues(response.data.content)
        }
      })
      .catch(error => {})
    _onInputChange({ program_id: selectedProgram._id, facilitator_id: userState.id })
  }, [learnerState, programsState, selectedProgram, venueState, userState])

  useEffect(() => {
    if (Object.keys(selectedProgram).length > 0) {
      get_program_users(`id=${selectedProgram._id}`).then(res => {
        if (res.data.success) {
          setUsers(res.data.content)
        }
      })
    }
  }, [selectedProgram])

  return (
    <TableContentWrapper title="" notificationData={notificationData}>
      <div className="flex flex-col gap-4">
        <div className="flex flex-row">
          <div className="flex-auto text-3xl font-bold">Attendance Register</div>
        </div>
        <div className="py-4 px-4 bg-white ">
          <div className="grid grid-row-3 space-y-4">
            <div className="grid grid-rows-4 space-y-4">
              <div className="grid grid-cols-2 space-x-2 px-4">
                <TextInputControl
                  title="Please indicate the number of registers you have used"
                  value={formValues.number_of_registers}
                  onChange={number_of_registers => {
                    _onInputChange({ number_of_registers })
                  }}
                />
                <div className="w-full justify-center flex">
                  <div className="flex flex-col gap-2">
                    <p>Select OI</p>
                    <div className="flex flex-row gap-8">
                      <div className="flex flex-row gap-2">
                        <CheckBoxControl
                          theme="light"
                          name="level_type"
                          checked={formValues.level_type == 'Human'}
                          type="radio"
                          value="Human"
                          onChange={e => {
                            _onInputChange({ level_type: e.target.value })
                          }}
                        />
                        <p>Human OI</p>
                      </div>
                      {/* <div className="flex flex-row gap-2">
                        <CheckBoxControl
                          theme="light"
                          name="level_type"
                          checked={formValues.level_type == 'Business'}
                          type="radio"
                          value="Business"
                          onChange={e => {
                            _onInputChange({ level_type: e.target.value })
                          }}
                        />
                        <p>Business OI</p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-2 space-x-2 px-4">
                <DropdownControl
                  title="Interaction Code"
                  placeholder={'-Select Interaction Code-'}
                  isBusy={false}
                  type="primary"
                  theme="light"
                  displayKey="interaction_name"
                  selector="_id"
                  subItems={interactions}
                  selectedItem={interactions?.find(x => x._id == formValues.interaction_id)}
                  onClick={item => {
                    _onInputChange({ interaction_id: item?._id })

                    if (interactions.length < 1) {
                      dispatch(
                        setNotification({
                          title: 'Oops',
                          messages: ['There are no interactions to select from.']
                        })
                      )
                    }
                  }}
                />
                <div className="grid grid-cols-2 space-x-2">
                  <TextInputControl
                    type="date"
                    title="Date:"
                    value={formValues.date}
                    onChange={date => {
                      _onInputChange({ date })
                    }}
                  />
                  <DropdownControl
                    title="Venue"
                    placeholder={'-Select Venue-'}
                    isBusy={false}
                    type="primary"
                    theme="light"
                    selector="_id"
                    displayKey="name"
                    subItems={venues}
                    selectedItem={venues?.find(x => x._id == formValues.venue_id)}
                    onClick={item => {
                      if (venues.length < 1) {
                        dispatch(
                          setNotification({
                            title: 'Oops',
                            messages: ['There are no venues to select from.']
                          })
                        )
                      }
                      _onInputChange({ venue_id: item._id })
                    }}
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 space-x-2 px-4">
                <TextInputControl
                  title="Name"
                  value={formValues.name}
                  onChange={name => {
                    _onInputChange({ name })
                  }}
                />
                <TextInputControl
                  type="time"
                  title="Time:"
                  value={time}
                  onChange={time => {
                    setTime(time)
                  }}
                />
              </div>

              <div className="grid grid-cols-1 items-center px-4">
                <TextInputControl
                  type="search"
                  placeholder="Search"
                  value={searchItem}
                  onChange={v => {
                    setSearchItem(v)
                  }}
                />
              </div>
            </div>
            <AttendanceTable
              users={users}
              payloadCallBack={handleLearners}
              formValues={formValues}
              program={selectedProgram}
            ></AttendanceTable>

            <div className="grid grid-rows-2 justify-center w-30 space-y-2">
              <ButtonComponent
                style="bg-buttonColor"
                placeholder="Upload Attendance Form"
                type="secondary"
                onClick={v => {
                  setShowAttendanceForm(true)
                }}
              />

              <ButtonComponent
                style="bg-buttonColor"
                placeholder="Save"
                // isBusy='false'
                type="secondary"
                onClick={_saveRegister}
              />
            </div>
          </div>
        </div>
      </div>
      <Form
        open={showAttendanceForm}
        setShow={setShowAttendanceForm}
        onClick={handleAttendanceForm}
      />
    </TableContentWrapper>
  )
}

const mapStateToProps = state => {
  return {
    learnerState: state.learnerState,
    venueState: state.venueState,
    notificationState: state.notificationState,
    programsState: state.programsState,
    facilitatorState: state.facilitatorState,
    userState: state.userState
  }
}

export default connect(mapStateToProps)(AttendanceRegisterComponent)
