import {
  RESET_CURRICULUM_STATE,
  SAVE_CURRICULUM_STATE,
  SET_CURRICULUM_STATE
} from '../../constants/redux_types'

export function curriculumStateReducer(
  state = {
    curriculumsLoaded: false,
    curriculums: []
  },
  action
) {
  switch (action.type) {
    case SET_CURRICULUM_STATE:
      return {
        ...state,
        ...action.payload
      }
    case SAVE_CURRICULUM_STATE:
      return {
        ...state
      }
    case RESET_CURRICULUM_STATE:
      return state
    default:
      return state
  }
}
