import React, { useEffect, useState } from 'react'

import { connect, shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { acceptApplication, allApplications, rejectApplication } from '../../asyncActions'
import TableContentWrapper from '../../controls/tableContentWrapper/tableContentWrapper'
import { selectPaginateState } from '../../selectors/selectors'
import { selectNotificationState } from '../notifications/selectors'
import { applicationTableColumns } from './constants'
import { selectApplications, selectApplicationsLoaded, selectApplicationsState } from './selectors'
import { MyTableControl } from '../../controls/myTableControl/myTableControl'
import EditApplicationComponent from './editApplication'

const StudentApplications = ({ alertState, groupState, taskState }) => {
  const dispatch = useDispatch()
  const applicationsLoaded = useSelector(selectApplicationsLoaded)
  const applications = useSelector(selectApplications, shallowEqual)
  const notificationData = useSelector(selectNotificationState)
  const [editApplication, setEditApplication] = useState(false)
  const [applicationData, setApplicationData] = useState({})

  useEffect(() => {
    dispatch(allApplications())
  }, [alertState])

  useEffect(() => {
    if (!applicationsLoaded) dispatch(allApplications())
  }, [applicationsLoaded])

  const _onClickActionButton = (item, _) => {
    if (_.id == 1) {
      setApplicationData(item)

      //check if email is already added, if yes then accept the application, else open the edit application popup
      if (item.email != undefined && item.email != '') {
        dispatch(acceptApplication(item))
      } else {
        setApplicationData(item)
        setEditApplication(true)
      }
    }
    if (_.id == 2) {
      dispatch(rejectApplication(item))
    }
  }

  return (
    <TableContentWrapper notificationData={notificationData} actionButtons={[]}>
      {/* ----------Table */}
      <MyTableControl
        title={'Application Portal'}
        search={{ show: true, search_by: '' }}
        showPagination={true}
        data={applications}
        displayKeys={applicationTableColumns}
        actionButton={{
          title: 'Details',
          isDropdown: 'true',
          side: 'right',
          items: [
            {
              name: 'Accept',
              id: 1
            },
            {
              name: 'Reject',
              id: 2
            }
          ],
          onClick: _onClickActionButton
        }}
      />
      <EditApplicationComponent
        data={applicationData}
        open={editApplication}
        setShow={setEditApplication}
      />
    </TableContentWrapper>
  )
}

const mapStateToProps = state => {
  return {
    userState: state.userState,
    taskState: state.taskState,
    groupState: state.groupState,
    permissionsState: state.permissionsState,
    alertState: state.alertState
  }
}

export default connect(mapStateToProps)(StudentApplications) 
