import React, { useEffect, useState } from 'react'
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import { getLearners } from '../../../asyncActions/learners'
import TitleCardControl from '../../../controls/cards/titlecard'
import NotificationControl from '../../../controls/notification/notification'
import TableControl from '../../../controls/table/table'
import { selectNotificationState } from '../../notifications/selectors'
import { getGroups, removeLearnerFromGroup } from '../../../asyncActions/groups'
import { getFacilitators } from '../../../asyncActions/facilitators'
import { formatDate } from '../../../utils'
import TableContentWrapper from '../../../controls/tableContentWrapper/tableContentWrapper'
import ButtonControl from '../../../controls/button/button'
import AddLearnerToGroupComponent from './addLearnerToGroup'
import { initialPageNumber } from '../../../constants/constants'
import { selectPaginateState } from '../../../selectors/selectors'
import { actionButtonItems, facilitatorsColumn } from '../../user/constants'
import { setMainState } from '../../../actions'
import { selectLearners, selectLearnersLoaded } from '../../learner/selectors'
import { add_learner } from '../../../api/calls/add_learner'
import { setNotification } from '../../../asyncActions/notification'
import { MyTableControl } from '../../../controls/myTableControl/myTableControl'
import ButtonComponent from '../../customComponents/button'
import InformationBlock from './informationBlock'

const GroupDetailsComponent = ({ learnerState, groupState, facilitatorState }) => {
  const history = useHistory()

  const notificationData = useSelector(selectNotificationState)
  //   const curriculumsLoaded = useSelector(selectCurriculumsLoaded)
  const [showAddLearner, setShowAddLearner] = useState(false)
  const [init, setInit] = useState(true)
  const [learnerInit, setLearnerInit] = useState(true)
  const [selectedGroup, setSelectedGroup] = useState({})
  //   const curriculums = useSelector(selectCurriculumByProgramId)(selectedGroup?._id)
  const [learnerIDs, setLearnerIDs] = useState([])
  const [learnersInGroup, setLearnersInGroup] = useState([])
  const [facilitatorIDs, setFacilitatorIDs] = useState([])
  const [facilitators, setFacilitators] = useState([])
  const [learners, setLearners] = useState([])
  const [selectedLearners, setSelectedLearners] = useState([])
  const [createdBy, setCreatedBy] = useState({})
  const { id } = useParams()
  const dispatch = useDispatch()
  const paginate = selectPaginateState(facilitatorState)
  const [selectedUser, setSelectedUser] = useState({})
  const [addLearners, setAddLearners] = useState(false)
  const [learnersPayload, setLearnersPayload] = useState({
    group_id: '',
    learner_ids: []
  })
  const learnersLoaded = useSelector(selectLearnersLoaded)
  const learnersState = useSelector(selectLearners).map(_ => ({
    ..._,
    fullName: `${_?.user_name} ${_?.user_surname}`
  }))

  useEffect(() => {
    if (!groupState.groupsLoaded) {
      dispatch(getGroups())
    }

    if (!facilitatorState.facilitatorsLoaded) {
      dispatch(getFacilitators())
    }

    if (init && learnerState.learners.length == 0) {
      dispatch(getLearners())
      setInit(false)
    }

    let _group = groupState.groups.find(x => x._id === id)
    if (_group) {
      setSelectedGroup(_group)
      if (_group.learner_ids) {
        setLearnerIDs(_group.learner_ids)
      }
      if (_group.facilitator_ids) {
        setFacilitatorIDs(_group.facilitator_ids)
      }
    }

    if (
      learnerInit &&
      selectedGroup._id &&
      (!selectedGroup.learners || selectedGroup.learners.length == 0)
    ) {
      dispatch(getGroups())
      setLearnerInit(false)
    }
  }, [learnerState.learners, groupState.groups, facilitatorState.facilitators])

  useEffect(() => {
    let learnersInGroup = []
    let facilitatorInGroup = []
    let creator = {}

    for (const learner of learnerState.learners) {
      learnerIDs.find(id => {
        if (id == learner._id) {
          learnersInGroup.push(learner)
        }
      })
    }
    setLearnersInGroup(learnersInGroup)

    for (const facilitator of facilitatorState.facilitators) {
      facilitatorIDs.find(id => {
        if (id === facilitator._id) {
          facilitatorInGroup.push(facilitator)
        }
      })
    }

    for (const facilitator of facilitatorState.facilitators) {
      facilitatorIDs.find(id => {
        if (id === facilitator?.created_by) {
          //   console.log('found creator!', facilitator)
          creator = facilitator
        }
      })
    }
    setFacilitators(facilitatorInGroup)
    setCreatedBy(creator)
    setLearnersPayload({ program_id: selectedGroup._id })
  }, [selectedGroup, facilitatorIDs, learnerIDs])

  const _addNewLearners = () => {
    add_learner(learnersPayload).then(response => {
      if (response.data.success) {
        dispatch(
          setNotification({
            title: 'Success',
            messages: ['New learners successfully added to the group.']
          })
        )
      } else {
        dispatch(
          setNotification({
            title: 'Failure',
            messages: ['Failed to add learners to group.']
          })
        )
      }
    })
  }

  const _onFacilitatorDropdown = (item, x) => {
    setSelectedUser(item)

    if (x.id == 1) {
      dispatch(
        setMainState({
          selectedUser: item
        })
      )
      history.push(`/dashboard/facilitator-details/${item._id}`)
    }
  }

  const _onLearnerDropdown = (item, x) => {
    setSelectedUser(item)
    const userType = 'LEARNER-ACC'

    if (x.id == 1) {
      dispatch(
        setMainState({
          selectedUser: item
        })
      )
      history.replace(`/dashboard/learner-info/${item._id}/${userType}`)
    }

    if (x.id == 2) {
      const payload = {
        group_id: selectedGroup._id,
        learner_ids: [item._id]
      }
      dispatch(removeLearnerFromGroup(payload))
    }
  }

  useEffect(() => {
    const learnersList = []
    if (learnerState.learners.length > 0) {
      for (const learner of learnerState.learners) {
        learnersList.push({ name: `${learner.user_name} ${learner.user_surname}`, id: learner._id })
      }
    }

    setLearners(learnersList)
  }, [learnerState.learners])

  useEffect(() => {
    console.log('selectedGroup', selectedGroup)
  }, [selectedGroup])

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="">
          <Link
            to="/dashboard/groups"
            className="mt-4 mb-4 text-green hover:underline focus:outline-none cursor-pointer"
          >
            <FontAwesomeIcon className="text-sm" icon={faArrowLeft} /> Back to all groups
          </Link>
        </div>
        <div className="flex flex-row">
          <div className="flex-auto text-3xl font-bold">Group - {selectedGroup.name}</div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="bg-white shadow-md p-4">
            <div className="flex gap-4">
              <div className="flex-grow" style={{ width: '80%' }}>
                <div>
                  <div className="flex text-sm">
                    <div className="overflow-hidden w-60 h-58">
                      <img
                        src={
                          selectedGroup?.image?.url ||
                          'https://via.placeholder.com/400x400?text=no+image'
                        }
                        style={{ width: '100%' }}
                      />
                    </div>
                    <div className="mx-5">
                      <p className="font-bold">{selectedGroup.name}</p>
                      <p className="text-gray my-2">{selectedGroup.allocation_code}</p>
                    </div>
                  </div>
                </div>
                <br />
                <div className="bg-white shadow-mdf flex flex-col">
                  <div className="w-full flex justify-end">
                    <div className="w-48 flex flex-col gap-4">
                      {/* {learners.length > 0 ? (
                        <MultiDropDownButtonComponent
                          name="Add Learners"
                          backgroundColor="bg-dark"
                          textColor="text-white"
                          subItems={learners}
                          displayKey={'name'}
                          selected={selectedLearners}
                          onSubmit={learner_ids => {
                            setSelectedLearners(learner_ids)
                            setLearnersPayload({
                              group_id: selectedGroup._id,
                              learner_ids: learner_ids.map(e => e.id)
                            })
                          }}
                        />
                      ) : null} */}

                      {/* {learnersPayload?.learner_ids?.length > 0 ? (
                        <ButtonComponent
                          title="Add New Learners"
                          isBusy={false}
                          type="primary"
                          onClick={_addNewLearners}
                        />
                      ) : null} */}
                    </div>
                  </div>
                  <br />
                  <MyTableControl
                    title={'Learners'}
                    search={{ show: true, search_by: '' }}
                    showPagination={true}
                    data={learnersInGroup ?? []}
                    displayKeys={[
                      { key: 'user_name', display: 'Name' },
                      { key: 'user_surname', display: 'Surname' }
                    ]}
                    actionButton={{
                      side: 'right',
                      title: 'View',
                      items: actionButtonItems,
                      isDropdown: true,
                      hasFilter: false,
                      filterConfig: {
                        lookup: 'name'
                      },
                      onClick: _onLearnerDropdown
                    }}
                    // mainButton={
                    //   <ButtonComponent
                    //     title="Add New Learners"
                    //     isBusy={false}
                    //     type="primary"
                    //     onClick={_ => {
                    //       setShowAddLearner(true)
                    //     }}
                    //   />
                    // }
                  />
                </div>
                {/* -------curriculums */}

                <br />

                <div className="bg-white shadow-md">
                  <MyTableControl
                    title={'Facilitators'}
                    search={{ show: true, search_by: '' }}
                    showPagination={true}
                    data={facilitators ?? []}
                    displayKeys={[
                      { key: 'user_name', display: 'Name' },
                      { key: 'user_surname', display: 'Surname' }
                    ]}
                    actionButton={{
                      side: 'right',
                      title: 'View',
                      items: actionButtonItems,
                      isDropdown: true,
                      hasFilter: false,
                      filterConfig: {
                        lookup: 'name'
                      },
                      onClick: _onFacilitatorDropdown
                    }}
                  />
                </div>
              </div>
              <div className="flex min-w-min max-h-full">
                <InformationBlock data={selectedGroup} />
              </div>
            </div>
          </div>
        </div>

        <AddLearnerToGroupComponent
          learners={learnersState}
          learnersInGroup={learnersInGroup}
          open={showAddLearner}
          setShow={setShowAddLearner}
          group={selectedGroup}
        />

        <NotificationControl notificationData={notificationData} />
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    learnerState: state.learnerState,
    mainState: state.mainState,
    userState: state.userState,
    groupState: state.groupState,
    facilitatorState: state.facilitatorState
  }
}

export default connect(mapStateToProps)(GroupDetailsComponent)
