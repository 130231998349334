import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { connect, useDispatch, useSelector } from 'react-redux'
import { editProgramState } from '../../../actions/programs'
import LoaderControl from '../../../controls/loader/loader'
import PopupControl from '../../../controls/popup/popup'
import { DATE_FORMAT } from '../../../constants/constants'
import { add_learner_program } from '../../../api/calls'
import DropdownButtonComponent from '../../customComponents/dropdownButton'
import { faSave } from '@fortawesome/pro-light-svg-icons'
import ButtonComponent from '../../customComponents/button'
import { assign_recruit_to_group } from '../../../api/calls/recruitment/assign_to_group'
import { setAlert } from '../../../asyncActions/alert'

const AddRecruitToGroupComponent = ({ groupState, open = false, setShow, learner, onSuccess }) => {
  const dispatch = useDispatch()

  const [isBusy, setIsBusy] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [group, setGroup] = useState({})
  const [programLearner, setProgramLearner] = useState([])

  useEffect(() => {
    dispatch(
      setAlert({
        show: false,
        success: false
      })
    )
  }, [])

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  return (
    <div>
      <PopupControl
        title="Adding Recruit to Group"
        isOpen={open}
        close={{
          showClose: true,
          onClose: () => {
            setShow(false)
          }
        }}
      >
        <div className="flex flex-col gap-8">
          <div className="flex flex-row gap-4">
            <div className="w-72 flex flex-col gap-4">
              <DropdownButtonComponent
                title={'Groups'}
                placeholder={'Please select group'}
                theme="dark"
                dropdownItems={groupState?.groups.filter(
                  x => !programLearner.map(x => x._id).includes(x._id)
                )}
                displayKey="name"
                selectedItem={groupState?.groups.find(x => x._id == group._id)?.name}
                onClick={item => {
                  setGroup(item)
                }}
              />

              <ButtonComponent
                title="Save"
                icon={faSave}
                onClick={v => {
                  const payload = {
                    id: learner.learner_id,
                    group_id: group?._id
                  }

                  assign_recruit_to_group(payload).then(e => {
                    if (e.data.success) {
                      dispatch(
                        setAlert({
                          show: true,
                          success: true,
                          message: 'Successfully assigned to group.'
                        })
                      )
                      onSuccess()
                      setShow(false)
                    } else {
                      dispatch(
                        setAlert({
                          show: true,
                          success: false,
                          message: 'Failed to assign recruit to a group.'
                        })
                      )
                    }
                  })
                }}
              />
            </div>
          </div>
        </div>
      </PopupControl>
      <LoaderControl show={isBusy} />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    learnerState: state.learnerState,
    mainState: state.mainState,
    userState: state.userState,
    groupState: state.groupState
  }
}

export default connect(mapStateToProps)(AddRecruitToGroupComponent)
