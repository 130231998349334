import React from 'react';
import ReactECharts from 'echarts-for-react'


export const DoughnutChart = ({data, title, onClick}) => {
    const chartData = [
        { name: 'Yes', value: data?.yes?.total },
        { name: 'No', value: data?.no?.total },
        { name: 'No Status', value: data?.no_status?.total },
    ];

    const onChartClick = (params) => {
        onClick(params)
      };
    
      const onEvents = {
        click: onChartClick
      };
    
    

    const option = {
        title: {
            text: title,
            left: 'center'
        },
        tooltip: {
            trigger: 'item'
        },
        legend: {
            top: '12%',
            left: 'center'
        },
        series: [
            {
              name: title,
              type: 'pie',
              avoidLabelOverlap: false,
              padAngle: 2,
              itemStyle: {
                borderRadius: 5,
              },
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 12,
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: false
              },
              data: chartData,
              color: [
                '#00FF00',
                '#FF0000',
                '#FFA500',
              ]
            }
        ]
    }

    return <ReactECharts option={option} onEvents={onEvents} />;
}