import React, { useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import TableContentWrapper from '../tableContentWrapper/tableContentWrapper'

export const AttendancePerQuarterTable = props => {
  const {
    title,
    data,
  } = props

  return (
    <TableContentWrapper>
      <div className="flex flex-col gap-4">
        <div className="flex justify-between">
          <div className="flex-auto text-xl font-bold">{title}</div>
        </div>
        <Table className="flex min-w-full">
          <TableHead className="bg-main text-lg">
            <TableRow>
            <TableCell className="text-gray-600 text-xs leading-normal font-light">Programme Name</TableCell>
            <TableCell className="text-gray-600 text-xs leading-normal font-light">Year</TableCell>
            <TableCell className="text-gray-600 text-xs leading-normal font-light">Quarter (Defined by Date on Programme Creation)</TableCell>
            <TableCell className="text-gray-600 text-xs leading-normal font-light">Avg Human OI</TableCell>
            {/* <TableCell className="text-gray-600 text-xs leading-normal font-light">Avg Business OI</TableCell> */}
            <TableCell className="text-gray-600 text-xs leading-normal font-light">Avg Technical OI</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="text-gray-600 text-sm font-normal">
            {data.length > 0 ? (
              data.map((dataItem, idx) => {
                  return (
                    <TableRow key={idx} className={`border-b border-gray-200 hover:bg-gray-100 border-dark font-light h-8`}>
                        <TableCell className="text-gray-600 text-xs leading-normal font-light">{dataItem.program_name}</TableCell>
                        <TableCell className="text-gray-600 text-xs leading-normal font-light">{dataItem.program_year}</TableCell>
                        <TableCell className="text-gray-600 text-xs leading-normal font-light">
                            {
                                dataItem.program_quarters.map((quarter, i)=>{
                                    return <div>
                                        {quarter.quarter}
                                    </div>
                                })
                            }
                        </TableCell>
                        <TableCell className="text-gray-600 text-xs leading-normal font-light">
                            {
                                dataItem.program_quarters.map((quarter, i)=>{
                                    return <div>
                                        {quarter.quarter_human_oi}
                                    </div>
                                })
                            }
                        </TableCell>
                        <TableCell className="text-gray-600 text-xs leading-normal font-light">
                            {
                                dataItem.program_quarters.map((quarter, i)=>{
                                    return <div>
                                        {quarter.quarter_business_oi}
                                    </div>
                                })
                            }
                        </TableCell>
                    </TableRow>
                  )
                })
            ) : (
                <TableCell>This table is empty</TableCell>
            )}
          </TableBody>
        </Table>
      </div>
    </TableContentWrapper>
  )
}
