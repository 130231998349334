import { faCalendar, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isMoment } from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from 'react-date-picker';
import DateTimePicker from 'react-datetime-picker';
import { getISODate, isoToDate } from "../../utils/utils";
// import DatePicker from 'react-date-picker/dist/entry.nostyle';

const DateTimeControl = props => {
    const { title, type, theme, value, onChange, } = props;

    const [selectedValue, setSelectedValue] = useState();

    //Did mount...
    useEffect(() => {
        // setSelectedValue(value);
        if (isMoment(value)) {
            setSelectedValue(value);
        } else {
            setSelectedValue(value);
        }
    }, [value]);

    //Will unmount...
    useEffect(() => {
        return () => {
            // console.log("Cleaning up...");
        }
    }, []);

    const onChanged = (v) => {
        // setSelectedValue(v);
        if (onChange) {
            onChange(getISODate(v));
            // onChange(v);
        }
    }

    const options = {
        light: {
            title: "text-white",
            placeholder: "text-white",
            buttonBg: "bg-green",
        },
        dark: {
            title: "text-white",
            placeholder: "text-gray",
            buttonBg: "bg-textinput-dark",
        }
    };

    return (
        <div className="controls-datetime flex-1">
            {title && 
            <div className={`${options[theme].title} text-lg`}>
                {title}
            </div>}
            {(() => {
                switch (type) {
                    case "date": 
                        // console.log("selectedValue", selectedValue);
                        return (
                            <DatePicker
                                className={`h-8 ${options[theme].buttonBg} text-l ${options[theme].placeholder} rounded-full first:border-none px-2 w-full`}
                                onChange={(v) => onChanged(v)}
                                value={selectedValue ? isoToDate(selectedValue) : undefined}
                                clearIcon={<FontAwesomeIcon className={`text-l ${options[theme].placeholder}`} icon={faTimes} />}
                                calendarIcon={<FontAwesomeIcon className={`text-l ${options[theme].placeholder}`} icon={faCalendar} />}
                                showLeadingZeros={true}
                            />
                        )
                    case "datetime": 
                        return (
                            <DateTimePicker
                                onChange={(v) => onChanged(v)}
                                value={value}
                                disableClock={true}
                            />
                        )
                }
            })()}
        </div>
    )
}

export default DateTimeControl;