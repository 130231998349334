import {
  faUser,
  faEnvelope,
  faMoneyBill,
  faNoteMedical,
  faPen,
  faTrash,
  faNotes
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import {
  get_all_users,
  get_learner_attendance_info,
  get_recruitee,
  get_user_programs,
  all_transactions,
  all_documents,
  get_absenteeism
} from '../../../api/calls'
import { getUserTransactions } from '../../../asyncActions'
import { resetAlert, setAlert } from '../../../asyncActions/alert'
import BreadCrumb from '../../../controls/breadcrumb/breadcrumb'
import TitleCardControl from '../../../controls/cards/titlecard'
import { MyTableControl } from '../../../controls/myTableControl/myTableControl'
import TableContentWrapper from '../../../controls/tableContentWrapper/tableContentWrapper'
import { selectNotificationState } from '../../notifications/selectors'
import DetailsBlock from '../../user/components/detailsBlock'
import ValueExchange from '../../user/components/valueExchange'
import {
  actionButtonItems,
  attendanceColumn,
  programColumn,
  paymentColumn,
  documentsColumn,
  transactionsColumn
} from '../../user/constants'
import { catchUpDayTableColumn } from '../constants'
import IssueDoctorsNote from './issueDoctorsNote'
import IssueFine from './issueFine'
import IssueVedDays from './issueVedDays'
import Pills from './pills'
import SendSMSDialog from './sendSMS'
import IssueCatchUpDay from './issueCatchUpDay'
import AddPaymentComponent from '../../user/components/addPayment'
import AddTransactionComponent from '../../user/components/addTransactions'
import AddDocumentComponent from '../../user/components/UploadDocuments'
import { toTitleCase } from '../../../utils'
import { extractById } from '../../../utils/entityUtils'
import TableComponent from '../../UIComponents/components/tables/table'
import DatePickerComponent from '../../UIComponents/components/calendar/date'
import AttendancePerQuarterTable from '../../../controls/myTableControl/tableForAttendancePerQuarter'
import ButtonComponentOutlined from '../../UIComponents/components/buttons/buttonOutlined'
import SingleSelectComponent from '../../UIComponents/components/select/singleSelect'

const LearnerInfoComponent = ({ programsState, alertState }) => {
  const { type, user_id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const notificationData = useSelector(selectNotificationState)
  const [showAddCatchUpDay, setShowAddCatchUpDay] = useState(false)
  const [showIssueFine, setShowIssueFine] = useState(false)
  const [showAddPayment, setShowAddPayment] = useState(false)
  const [showAddDocument, setShowAddDocument] = useState(false)
  const [showAddTransaction, setShowAddTransaction] = useState(false)
  const [showCatchUpDayAlert, setShowCatchUpDayAlert] = useState(false)
  const [showIssueFineAlert, setShowIssueFineAlert] = useState(false)
  const [showAddVedDayAlert, setShowAddVedDayAlert] = useState(false)
  const [showAddDoctorsNoteAlert, setShowAddDoctorsNoteAlert] = useState(false)
  const [selectedVebProgram, setSelectedVebProgram] = useState()
  const userType = type === 'RECRUITER-ACC' ? 'RECRUITER-ACC' : 'LEARNER-ACC'
  const entityName = type === 'RECRUITER-ACC' ? 'Recruitment' : 'Learner'
  const [users, setUsers] = useState([])
  const [user, setUser] = useState()
  const [data, setData] = useState({})
  const [attendanceInformation, setAttendanceInformation] = useState([])
  const [programs, setPrograms] = useState([])
  const [docs, setDocs] = useState([])
  const [payments, setPayments] = useState([])
  const [transactions, setTransactions] = useState([])
  const [selectedAbsenteeism, setSelectedAbsenteeism] = useState({})
  const [recruitment, setRecruitment] = useState({})
  const [learnerAttendance, setLearnerAttendance] = useState({})
  const [showSmsDialog, setShowSmsDialog] = useState(false)
  const [showIssueVedDay, setShowIssueVedDay] = useState(false)
  const [showIssueDoctorsNote, setShowIssueDoctorsNote] = useState(false)

  useEffect(() => {
    init()
  }, [])

  const init = () => {
    _getAllUsers()
    _getLearnerAttendanceInformation()
    _fetchAbsenteeimInformation({ user_id })
    _getRecruiteeInformation()
    dispatch(resetAlert())
  }

  const _getRecruiteeInformation = () => {
    get_recruitee(user_id).then(res => {
      if (res.data.success) {
        setRecruitment(res.data.content)
      }
    })
  }

  const _getAllUsers = () => {
    const filters = `type_code=${type}`

    get_all_users(filters)
      .then(response => {
        if (response.data.success) {
          setUsers([...response.data.content])
        }
      })
      .catch(error => {})
  }

  const _getLearnerAttendanceInformation = () => {
    get_learner_attendance_info(user_id)
      .then(response => {
        if (response.data.success) {
          setLearnerAttendance(response.data.content)
        }
      })
      .catch(error => {})
  }

  const _onAttendanceDateSelection = e => {
    const date = moment(e).format('YYYY-MM-DD')
    _fetchAbsenteeimInformation({ date, user_id })
  }

  const _fetchAbsenteeimInformation = data => {
    get_absenteeism(data).then(e => {
      if (e.data.success) {
        const data = e.data.content.map(e => ({
          _id: e._id,
          attendance_date: moment(e.attendance.attendance_date).format('LL'),
          program_name: e.program.program_name,
          interaction_name: e.register.interaction.interaction_name
            ? toTitleCase(e.register.interaction.interaction_name)
            : '-',
          ...e
        }))
        setAttendanceInformation(data)
      }
    })
  }

  useEffect(() => {
    if (users.length > 0) {
      setUser(extractById(users)(user_id))
    }
  }, [users])

  useEffect(() => {
    if (user != null) {
      _onGetPrograms(user)
      _onGetPayments(user)
      _onGetDocuments(user)
      _onGetTransactions(user)
    }
  }, [user])

  const _onSelectVebProgram = program => {
    setSelectedVebProgram(program)
    dispatch(getUserTransactions(user, program))
  }

  const _onGetPrograms = user => {
    get_user_programs(`id=${user._id}`)
      .then(res => {
        if (res.data.success) {
          setPrograms(res.data.content)
        }
      })
      .catch(error => {})
  }

  const _onGetPayments = user => {
    all_transactions(`user_id=${user._id}&transaction_type=LOAN_REPAYMENT`)
      .then(res => {
        if (res.data.success) {
          setPayments(res.data.content)
        }
      })
      .catch(error => {})
  }

  const _onGetDocuments = user => {
    all_documents(`user=${user._id}`)
      .then(res => {
        if (res.data.success) {
          setDocs(res.data.content)
        }
      })
      .catch(error => {})
  }

  const _onGetTransactions = user => {
    all_transactions(`user=${user._id}`)
      .then(res => {
        if (res.data.success) {
          const lisOfTransactions = res.data.content.map(item => {
            return {
              ...item,
              date: moment(item.date).format('LL'),
              transaction_type: item.transaction_type,
              payment_type: item.payment_type,
              amount: `R${item.value.amount ?? 0}`,
              _id: item._id,
              user_id: item.user_id,
              type: item.type,
              recurring: item.recurring
            }
          })
          setTransactions(lisOfTransactions)
        }
      })
      .catch(error => {})
  }

  useEffect(() => {
    if (showCatchUpDayAlert) {
      dispatch(
        setAlert({
          show: true,
          success: true,
          message: 'Learner successfully added to catch-up day.'
        })
      )
    }
    if (showIssueFineAlert) {
      dispatch(
        setAlert({ show: true, success: true, message: 'Fine successfully issued to learner.' })
      )
    }
    if (showAddVedDayAlert) {
      dispatch(
        setAlert({ show: true, success: true, message: 'VED Days successfully added to learner.' })
      )
    }
    if (showAddDoctorsNoteAlert) {
      dispatch(
        setAlert({
          show: true,
          success: true,
          message: 'Doctors Note successfully issued to learner.'
        })
      )
    }

    setShowCatchUpDayAlert(false)
    setShowIssueFineAlert(false)
    setShowAddVedDayAlert(false)
    setShowAddDoctorsNoteAlert(false)

    _onGetTransactions({ _id: user_id })
    _fetchAbsenteeimInformation({ user_id })
  }, [showCatchUpDayAlert, showIssueFineAlert, showAddVedDayAlert])

  return (
    <TableContentWrapper
      // title={`${entityName} - ${user?.user_name} ${user?.user_surname}`}
      notificationData={notificationData}
      description={undefined}
    >
      <div className="flex flex-col gap-4">
        <BreadCrumb name={entityName} />
        <div className="flex flex-col gap-4">
          <div className="flex gap-4">
            <div className="flex flex-1 flex-col gap-2">
              <TitleCardControl css={{ height: '150px' }}>
                <div className="grid grid-cols-2 gap-8">
                  <div className="flex flex-col gap-4 justify-between">
                    <div className="flex flex-row space-x-8">
                      <div
                        className="p-4 flex bg-indigo rounded-full justify-center"
                        style={{ height: '100px', width: '100px' }}
                      >
                        <FontAwesomeIcon
                          className="fa-4x text-white ml-8 mr-8 align-baseline"
                          icon={faUser}
                        />
                      </div>
                      <div className="mx-10 items-start flex flex-col justify-between">
                        <div className="font-extrabold text-lg font-sans">{`${
                          user?.user_name ?? ''
                        } ${user?.user_surname ?? ''}`}</div>
                        <div className="text-sm text-gray">{user?.user_email}</div>

                        {user?.user_name && (
                          <div
                            className="flex flex-row gap-2 hover:text-indigo items-center justify-start cursor-pointer"
                            onClick={() => {
                              setShowSmsDialog(true)
                            }}
                          >
                            <FontAwesomeIcon icon={faEnvelope} size="2xl" color="blue" />
                            <p className="font-extrabold font-sans">
                              Send {`${user?.user_name}`} a text.
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end">
                    <Pills data={user?.account} />
                  </div>
                </div>
              </TitleCardControl>

              <TableComponent
                displayKeys={[
                  { value: 'attendance_date', date: true, label: 'Date Absent' },
                  { value: 'interaction_name', label: 'Interaction' },
                  { value: 'program_name', label: 'Programme' }
                ]}
                data={attendanceInformation}
                title="Attendance Information"
                actions={[
                  {
                    label: 'Fine',
                    action: item => {
                      setSelectedAbsenteeism(item)
                      setShowIssueFine(true)
                    },
                    icon: faMoneyBill
                  },
                  {
                    label: 'VED Day',
                    action: item => {
                      setSelectedAbsenteeism(item)
                      setShowIssueVedDay(true)
                    },
                    icon: faPen
                  },
                  {
                    label: "Dr's note",
                    action: item => {
                      setSelectedAbsenteeism(item)
                      setShowIssueDoctorsNote(true)
                    },
                    icon: faNoteMedical
                  }
                ]}
              >
                <div className="flex flex-col gap-2">
                  <div className="flex flex-row gap-2 items-center">
                    <div className="font-bold text-md">Days Absent</div>
                    <div className="border-1 border-black rounded-full border-solid px-2 py-1 text-white font-extrabold font-sans bg-green">
                      {/* <div className="text-white font-extrabold font-sans"> */}
                      {attendanceInformation.length}
                      {/* </div> */}
                    </div>
                  </div>
                  <DatePickerComponent
                    title="Select Date"
                    disablePast={false}
                    onChange={_onAttendanceDateSelection}
                    value={undefined}
                    required={undefined}
                  />
                </div>
              </TableComponent>

              {userType === 'LEARNER-ACC' && (
                <TableComponent
                  title="Transaction History"
                  data={transactions}
                  displayKeys={[
                    { value: 'date', date: true, label: 'Date' },
                    { value: 'transaction_type', label: 'Transaction type' },
                    { value: 'payment_type', label: 'Payment type' },
                    { value: 'amount', label: 'Amount' }
                  ]}
                >
                  <div className="flex flex-row gap-2">
                    <ButtonComponentOutlined
                      title="Add Transaction"
                      disabled={programs.length === 0}
                      onClick={v => {
                        setShowAddTransaction(true)
                      }}
                      startIcon={undefined}
                      endIcon={undefined}
                      children={undefined}
                    />
                    {/* {userType !== 'RECRUITER-ACC' ? (
                        <SingleSelectComponent
                          title="Select Value Exchange Days"
                          displayKey="program_name"
                          data={programs}
                          disabled={programs.length === 0}
                          onClick={v => {
                            _onSelectVebProgram
                          }}
                        />
                      ) : (
                        <div></div>
                      )} */}
                  </div>
                </TableComponent>
              )}

              {userType === 'LEARNER-ACC' && (
                <AttendancePerQuarterTable
                  data={data?.programs ?? []}
                  title="Attendance Per Quarter"
                />
              )}
              {userType === 'LEARNER-ACC' && (
                <TableComponent
                  title="Programmes Enrolled"
                  data={programs}
                  displayKeys={programColumn}
                />
              )}

              <TableComponent
                title="Loan Repayment History"
                data={
                  payments?.map(payment => ({
                    ...payment,
                    amount: `R${payment?.value.amount ?? 0}`,
                    status: payment?.value.status,
                    date: moment(payment?.date).format('LL'),
                    proof: payment?.proof[0]
                  })) ?? []
                }
                displayKeys={[
                  { value: 'date', label: 'Date' },
                  { value: 'transaction_type', label: 'Transacation Type' },
                  { value: 'status', label: 'Status' },
                  { value: 'amount', label: 'Amount' },
                  { value: 'proof', isLink: true, label: 'Document' }
                ]}
              />

              <TableComponent
                title="Documents Attached"
                data={docs}
                displayKeys={[
                  { value: 'name', label: 'Name' },
                  { value: 'description', label: 'Description' },
                  { value: 'document', isLink: true, label: 'Document' }
                ]}
              >
                <div className="flex flex-row gap-2">
                  <ButtonComponentOutlined
                    title="Add Document"
                    onClick={v => {
                      setShowAddDocument(true)
                    }}
                    startIcon={faNotes}
                    endIcon={undefined}
                    children={undefined}
                  />
                </div>
              </TableComponent>
            </div>
            {user && (
              <DetailsBlock
                data={{
                  ...user,
                  ...learnerAttendance,
                  recruitment: recruitment,
                  ...programs[0]
                }}
                userType={userType}
                addRate={undefined}
              />
            )}
          </div>
        </div>
        <AddPaymentComponent open={showAddPayment} setShow={setShowAddPayment} user={user} />
        <AddTransactionComponent
          user={user}
          open={showAddTransaction}
          setShow={setShowAddTransaction}
          programs={programs}
        />

        <AddDocumentComponent
          open={showAddDocument}
          setShow={setShowAddDocument}
          user={user}
          onSuccess={() => {
            _onGetDocuments(user)
          }}
        />
        <IssueCatchUpDay
          show={showAddCatchUpDay}
          setShow={setShowAddCatchUpDay}
          data={selectedAbsenteeism}
          showAlert={setShowCatchUpDayAlert}
          onSuccess={() => {
            _fetchAbsenteeimInformation({ user_id })
          }}
        />
        <IssueFine
          show={showIssueFine}
          setShow={setShowIssueFine}
          data={selectedAbsenteeism}
          showAlert={setShowIssueFineAlert}
          onSuccess={() => {
            _fetchAbsenteeimInformation({ user_id })
          }}
        />
        <IssueVedDays
          show={showIssueVedDay}
          setShow={setShowIssueVedDay}
          data={selectedAbsenteeism}
          showAlert={setShowAddVedDayAlert}
          onSuccess={() => {
            _fetchAbsenteeimInformation({ user_id })
            _getLearnerAttendanceInformation()
          }}
        />
        <IssueDoctorsNote
          show={showIssueDoctorsNote}
          setShow={setShowIssueDoctorsNote}
          data={selectedAbsenteeism}
          showAlert={setShowAddVedDayAlert}
          onSuccess={() => {
            _fetchAbsenteeimInformation({ user_id })
          }}
        />
      </div>
      <SendSMSDialog show={showSmsDialog} setShow={setShowSmsDialog} userData={user} />
    </TableContentWrapper>
  )
}

const mapStateToProps = state => ({
  programsState: state.programsState,
  alertState: state.alertState
})

export default connect(mapStateToProps)(LearnerInfoComponent)
