import React, { useEffect } from 'react'

const Pills = ({ data }) => {
  const pillStyle = 'flex w-28 py-1 px-4 rounded-full justify-center'
  return (
    <div className="flex flex-col space-y-1 items-center">
      {data ? (
        data['status_code']?.toLowerCase() == 'active' ? (
          <div className={`${pillStyle} bg-green`}>Active</div>
        ) : data['status_code']?.toLowerCase() == 'inactive' ? (
          <div className={`${pillStyle} bg-red-600`}>Inactive</div>
        ) : (
          <div className={`${pillStyle} bg-orange`}>In Review</div>
        )
      ) : (
        <div></div>
      )}
    </div>
  )
}

export default Pills
