import React from 'react'

const VenueDetailsBlock = ({ venue }) => {
  return (
    <div className="w-2/6 py-4">
      <div className="shadow-xl p-4 rounded-xl">
        <div className="grid grid-cols-2 py-1 space-x-8 mb-4">
          <p className="text-sm font-bold">Venue Information:</p>
        </div>
        <div className="grid grid-cols-2 py-1 space-x-8">
          <p className="text-sm font-bold">City:</p>
          <p className="text-sm">{venue?.city}</p>
        </div>
        <div className="grid grid-cols-2 py-1 space-x-8">
          <p className="text-sm font-bold">Address 1:</p>
          <p className="text-sm">{venue?.address_line_1}</p>
        </div>
        <div className="grid grid-cols-2 py-1 space-x-8">
          <p className="text-sm font-bold">Address 2:</p>
          <p className="text-sm">{venue?.address_line_2}</p>
        </div>
        <div className="grid grid-cols-2 py-1 space-x-8">
          <p className="text-sm font-bold">Area Code:</p>
          <p className="text-sm">{venue?.area_code}</p>
        </div>
        <div className="grid grid-cols-2 py-1 space-x-8">
          <p className="text-sm font-bold">Building:</p>
          <p className="text-sm">{venue?.building}</p>
        </div>
        <div className="grid grid-cols-2 py-1 space-x-8">
          <p className="text-sm font-bold">Room Number:</p>
          <p className="text-sm">{venue?.room_number}</p>
        </div>
      </div>
    </div>
  )
}

export default VenueDetailsBlock
