import React, { useEffect, useState } from 'react'
import { faUser } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { getUserTransactions } from '../../../asyncActions'
import BreadCrumb from '../../../controls/breadcrumb/breadcrumb'
import TitleCardControl from '../../../controls/cards/titlecard'
import TableContentWrapper from '../../../controls/tableContentWrapper/tableContentWrapper'
import { extractById } from '../../../utils/entityUtils'
import { selectNotificationState } from '../../notifications/selectors'
import AddPaymentComponent from '../../user/components/addPayment'
import AddTransactionComponent from '../../user/components/addTransactions'
import DetailsBlock from '../../user/components/detailsBlock'
import AddDocumentComponent from '../../user/components/UploadDocuments'
import ValueExchange from '../../user/components/valueExchange'
import {
  attendanceColumn,
  documentsColumn,
  paymentColumn,
  programColumn,
  transactionsColumn
} from '../../user/constants'
import Pills from './pills'
import { MyTableControl } from '../../../controls/myTableControl/myTableControl'
import ButtonComponent from '../../customComponents/button'
import { get_all_users } from '../../../api/calls/get_all_users'
import {
  all_documents,
  all_transactions,
  get_absenteeism,
  get_learner_attendance_info,
  get_recruitee,
  get_user_programs
} from '../../../api/calls'
import { catchUpDayTableColumn, learnerAttendanceInfoSkeleton } from '../constants'
import { AttendancePerQuarterTable } from '../../../controls/myTableControl/tableForAttendancePerQuarter'
import DatePickerComponent from '../../customComponents/datepicker'
import AddCatchUpDayPopUp from './addCatchUpDay'
import IssueFinePopUp from './issueFine'
import { resetAlert, setAlert } from '../../../asyncActions/alert'
import moment from 'moment'
import { content } from '../../programs/components/constants'

const recruiterAccText = 'RECRUITER-ACC'
const learnerAccText = 'LEARNER-ACC'

export const actionButtonItems = [
  { name: 'Add catch up day', id: 1 },
  { name: 'Issue a fine', id: 2 }
]

const LearnerInfoComponent = ({ programsState, alertState }) => {
  const { type, user_id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const notificationData = useSelector(selectNotificationState)
  const [showAddCatchUpDay, setShowAddCatchUpDay] = useState(false)
  const [showIssueFine, setShowIssueFine] = useState(false)
  const [showAddPayment, setShowAddPayment] = useState(false)
  const [showAddDocument, setShowAddDocument] = useState(false)
  const [showAddTransaction, setShowAddTransaction] = useState(false)
  const [showCatchUpDayAlert, setShowCatchUpDayAlert] = useState(false)
  const [showIssueFineAlert, setShowIssueFineAlert] = useState(false)
  const [selectedVebProgram, setSelectedVebProgram] = useState()
  const userType = type === recruiterAccText ? recruiterAccText : learnerAccText
  const entityName = type === recruiterAccText ? 'Recruitment' : 'Learner'
  const [users, setUsers] = useState([])
  const [user, setUser] = useState()
  const [data, setData] = useState(learnerAttendanceInfoSkeleton)
  const [attendanceInformation, setAttendanceInformation] = useState([])
  const [programs, setPrograms] = useState([])
  const [docs, setDocs] = useState([])
  const [payments, setPayments] = useState([])
  const [transactions, setTransactions] = useState([])
  const [selectedAbsenteeism, setSelectedAbsenteeism] = useState({})
  const [recruitment, setRecruitment] = useState({})
  const [learnerInfo, setLearnerInfo] = useState({})
  const [learnerAttendance, setLearnerAttendance] = useState({})

  useEffect(() => {
    const filters = `type_code=${type}`
    get_all_users(`${filters}`)
      .then(response => {
        if (response.data.success) {
          setUsers([...response.data.content])
        }
      })
      .catch(error => {})

    get_learner_attendance_info(user_id)
      .then(response => {
        if (response.data.success) {
          setLearnerAttendance(response.data.content)
        }
      })
      .catch(error => {})

    _fetchAbsenteeimInformation({ user_id })
    dispatch(resetAlert())

    get_recruitee(user_id).then(res => {
      if (res.data.success) {
        setRecruitment(res.data.content)
      }
    })
  }, [])

  useEffect(() => {
    if (users.length > 0) {
      setUser(extractById(users)(user_id))
    }
  }, [users])

  useEffect(() => {
    if (user != null) {
      _onGetPrograms(user)
      _onGetPayments(user)
      _onGetDocuments(user)
      _onGetTransactions(user)
    }
  }, [user])

  const _onSelectVebProgram = program => {
    setSelectedVebProgram(program)
    dispatch(getUserTransactions(user, program))
  }

  const _onGetPrograms = user => {
    get_user_programs(`id=${user._id}`)
      .then(res => {
        if (res.data.success) {
          setPrograms(res.data.content)
        }
      })
      .catch(error => {})
  }

  const _onGetPayments = user => {
    all_transactions(`user_id=${user._id}&transaction_type=LOAN_REPAYMENT`)
      .then(res => {
        if (res.data.success) {
          setPayments(res.data.content)
        }
      })
      .catch(error => {})
  }

  const _onGetDocuments = user => {
    all_documents(`user=${user._id}`)
      .then(res => {
        if (res.data.success) {
          setDocs(res.data.content)
        }
      })
      .catch(error => {})
  }

  const _onGetTransactions = user => {
    all_transactions(`user=${user._id}`)
      .then(res => {
        if (res.data.success) {
          let lisOfTransactions = []

          //map out the data so that it is accepted by the table
          for (const item of res.data.content) {
            item.map(e => {
              lisOfTransactions.push({
                _id: e._id,
                user_id: e.user_id,
                transaction_type: e.transaction_type,
                date: e.date,
                type: e.type,
                recurring: e.recurring,
                payment_type: e.payment_type,
                amount: e.value.amount
              })
            }) || []
          }

          setTransactions(lisOfTransactions)
        }
      })
      .catch(error => {})
  }

  const _onAttendanceDateSelection = e => {
    //write method procedure here
    const date = moment(e).format('YYYY-MM-DD')
    _fetchAbsenteeimInformation({ date, user_id })
  }

  const _fetchAbsenteeimInformation = data => {
    //write fetch operation here...
    get_absenteeism(data).then(e => {
      if (e.data.success) {
        const data = []
        e.data.content.map(attendance => {
          data.push({
            _id: attendance._id,
            date: attendance.date_absent,
            interaction_code: attendance.interaction.codes,
            interaction_id: attendance.interaction._id,
            program_id: attendance.program._id,
            program_name: attendance.program.program_name,
            catch_up_status: attendance.catch_up_status
          })
        })
        setAttendanceInformation(data)
      }
    })
  }

  const _onClickActionButton = (item, e) => {
    if (e.id == 1) {
      setSelectedAbsenteeism(item)
      setShowAddCatchUpDay(true)
    }

    if (e.id == 2) {
      setSelectedAbsenteeism(item)
      setShowIssueFine(true)
    }
  }

  //show alert once catch up day is saved
  useEffect(() => {
    if (showCatchUpDayAlert) {
      dispatch(
        setAlert({
          show: true,
          success: true,
          message: 'Learner successfully added to catch-up day.'
        })
      )
      _fetchAbsenteeimInformation({ user_id })
    } else if (showIssueFineAlert) {
      dispatch(
        setAlert({
          show: true,
          success: true,
          message: 'Fine successfully issued to learner.'
        })
      )
      _onGetTransactions({ _id: user_id })
    } else {
      dispatch(resetAlert())
    }
  }, [showCatchUpDayAlert, showIssueFineAlert])

  return (
    <TableContentWrapper
      title={`${entityName} - ${user?.user_name} ${user?.user_surname}`}
      notificationData={notificationData}
    >
      <div className="flex flex-col gap-4">
        <BreadCrumb name={entityName} />
        <div className="flex flex-col gap-4">
          <div className="bg-white p-4">
            <div className="flex gap-4">
              <div className="flex-grow gap-4">
                <TitleCardControl css={{ height: '150px' }}>
                  <div className="grid grid-cols-2 gap-8">
                    <div className="flex flex-row space-x-8">
                      <div
                        className="p-4 flex bg-indigo rounded-full justify-center"
                        style={{ height: '100px', width: '100px' }}
                      >
                        <FontAwesomeIcon
                          className="fa-4x text-white ml-8 mr-8 align-baseline"
                          icon={faUser}
                        />
                      </div>
                      <div className="mx-10 items-start">
                        <p>{`${user?.user_name} ${user?.user_surname}`}</p>
                        <p>{user?.user_email}</p>
                      </div>
                    </div>

                    <div className="flex justify-end">
                      <Pills data={user?.account} />
                    </div>
                  </div>
                </TitleCardControl>
                {/* <br />
                <MyTableControl
                  title={'Recruitment History'}
                  search={{ show: true, search_by: 'interaction_code' }}
                  showPagination={true}
                  data={attendanceInformation}
                  displayKeys={catchUpDayTableColumn}
                  actionButton={{
                    side: 'right',
                    title: 'Actions',
                    items: actionButtonItems,
                    isDropdown: true,
                    onClick: _onClickActionButton
                  }}
                /> */}

                <div className="flex-auto text-xl font-bold py-2">Attendance Information</div>
                <div className="flex flex-col">
                  <div className="flex flex-row gap-4 items-center">
                    <p>Days Absent</p>
                    <div className="border-2 border-black border-solid px-2 py-1">
                      {attendanceInformation.length}
                    </div>
                  </div>
                  <div className="w-72">
                    <DatePickerComponent
                      title="Select Date"
                      disablePast={false}
                      isDateOnly={true}
                      onChange={_onAttendanceDateSelection}
                    />
                  </div>
                </div>

                <MyTableControl
                  title={'Attendance Information'}
                  search={{ show: true, search_by: 'interaction_code' }}
                  showPagination={true}
                  data={attendanceInformation}
                  displayKeys={catchUpDayTableColumn}
                  actionButton={{
                    side: 'right',
                    title: 'Actions',
                    items: actionButtonItems,
                    isDropdown: true,
                    onClick: _onClickActionButton
                  }}
                />
                <br />
                <hr />
                {userType === 'LEARNER-ACC' && (
                  <AttendancePerQuarterTable
                    title={'Attendance Per Programmes Per OI Levels'}
                    data={data?.programs ?? []}
                    displayKeys={attendanceColumn}
                  />
                )}
                <br />
                {userType === 'LEARNER-ACC' && (
                  <MyTableControl
                    title={'Programmes Enrolled'}
                    search={{ show: true, search_by: 'program_name' }}
                    showPagination={true}
                    data={programs ?? []}
                    displayKeys={programColumn}
                  />
                )}
                <br />
                <MyTableControl
                  title={'Loan Repayment History'}
                  search={{ show: true, search_by: '' }}
                  showPagination={true}
                  data={
                    payments?.map(payment => ({
                      ...payment,
                      amount: payment?.value?.amount
                    })) ?? []
                  }
                  displayKeys={paymentColumn}
                  mainButton={
                    <ButtonComponent
                      title="Add Payment"
                      isBusy={false}
                      type="primary"
                      onClick={v => {
                        setShowAddPayment(true)
                      }}
                    />
                  }
                />
                <br />
                <MyTableControl
                  title={'Documents Attached'}
                  search={{ show: true, search_by: '' }}
                  showPagination={true}
                  data={docs ?? []}
                  displayKeys={documentsColumn}
                  mainButton={
                    <ButtonComponent
                      title="Add Documents"
                      isBusy={false}
                      type="primary"
                      onClick={v => {
                        setShowAddDocument(true)
                      }}
                    />
                  }
                />
                <br />
                {userType !== 'RECRUITER-ACC' ? (
                  <ValueExchange
                    items={programs ?? []}
                    selected={selectedVebProgram}
                    onSelect={_onSelectVebProgram}
                  />
                ) : (
                  <div></div>
                )}
                {userType === 'LEARNER-ACC' && (
                  <MyTableControl
                    title={'Transaction History'}
                    search={{ show: true, search_by: '' }}
                    showPagination={true}
                    data={transactions ?? []}
                    displayKeys={transactionsColumn}
                    mainButton={
                      <ButtonComponent
                        title="Add Transaction"
                        isBusy={false}
                        type="primary"
                        onClick={v => {
                          setShowAddTransaction(true)
                        }}
                      />
                    }
                  />
                )}
              </div>
              {user && (
                <DetailsBlock
                  data={{
                    ...user,
                    ...learnerAttendance,
                    recruitment: recruitment
                  }}
                  userType={userType}
                  addRate={undefined}
                />
              )}
            </div>
          </div>
        </div>
        <AddPaymentComponent open={showAddPayment} setShow={setShowAddPayment} user={user} />
        <AddTransactionComponent
          user={user}
          open={showAddTransaction}
          setShow={setShowAddTransaction}
        />

        <AddDocumentComponent open={showAddDocument} setShow={setShowAddDocument} user={user} />
        <AddCatchUpDayPopUp
          show={showAddCatchUpDay}
          setShow={setShowAddCatchUpDay}
          data={{
            user_id: user_id,
            program_id: selectedAbsenteeism?.program_id,
            interaction_id: selectedAbsenteeism?.interaction_id,
            attendance_id: selectedAbsenteeism?._id
          }}
          showAlert={setShowCatchUpDayAlert}
        />
        <IssueFinePopUp
          show={showIssueFine}
          setShow={setShowIssueFine}
          data={{
            user_id: user_id,
            program_id: selectedAbsenteeism?.program_id,
            interaction_id: selectedAbsenteeism?.interaction_id,
            attendance_id: selectedAbsenteeism?._id
          }}
          showAlert={setShowIssueFineAlert}
        />
      </div>
    </TableContentWrapper>
  )
}

const mapStateToProps = state => {
  return {
    programsState: state.programsState,
    alertState: state.alertState
  }
}

export default connect(mapStateToProps)(LearnerInfoComponent)
