import React, { useEffect } from 'react'

import { faEnvelope, faPhone } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import TitleCardControl from '../../../controls/cards/titlecard'
import MapControl from '../../../controls/map/map'
import { CURRENCY } from '../../../constants/constants'
import Attendance from '../../attendanceRegister/components/attendance'
import CardControl from '../../learner/components/card'
import { formatDate } from '../../../utils'

const DetailsBlock = ({ data, addRate, userType }) => {
  useEffect(() => {
    console.log('data', data)
  }, [data])
  return (
    <div className="flex flex-col gap-1" style={{ width: '30%' }}>
      {/* <CardControl
        style="rounded-2xl shadow-xs  border border-homeBorder "
        title={'RECRUITMENT INFORMATION'}
      >
        <div className="flex">
          <div className="items-center w-full">
            <div className="flex flex-col">
              <div className="flex py-2 flex-col">
                <div className="font-extrabold font-sans text-xs italic">Open Day</div>
                <div className="font-extrabold text-sm">
                  {data?.recruitment?.open_day?.status?.toString().toUpperCase() ?? '-'}
                </div>
                <div className="font-extrabold text-sm">
                  {formatDate(data?.recruitment?.open_day?.date)}
                </div>
              </div>
            </div>

            <div className="flex flex-col">
              <div className="flex py-2 flex-col">
                <div className="font-extrabold font-sans text-xs italic">OI Workshop</div>
                <div className="font-extrabold text-sm">
                  {data?.recruitment?.oi_workshop?.status?.toString().toUpperCase() ?? '-'}
                </div>
                <div className="font-extrabold text-sm">
                  {formatDate(data?.recruitment?.oi_workshop?.date)}
                </div>
              </div>
            </div>

            <div className="flex flex-col">
              <div className="flex py-2 flex-col">
                <div className="font-extrabold font-sans text-xs italic">Interview</div>
                <div className="font-extrabold text-sm">
                  {data?.recruitment?.interview?.status?.toString().toUpperCase() ?? '-'}
                </div>
                <div className="font-extrabold text-sm">
                  {formatDate(data?.recruitment?.interview?.date)}
                </div>
              </div>
            </div>

            <div className="flex flex-col">
              <div className="flex py-2 flex-col">
                <div className="font-extrabold font-sans text-xs italic">Tech Interview</div>
                <div className="font-extrabold text-sm">
                  {data?.recruitment?.tech_interview?.status?.toString().toUpperCase() ?? '-'}
                </div>
                <div className="font-extrabold text-sm">
                  {formatDate(data?.recruitment?.tech_interview?.date)}
                </div>
              </div>
            </div>

            <div className="flex flex-col">
              <div className="flex py-2 flex-col">
                <div className="font-extrabold font-sans text-xs italic">Green Light Survey</div>
                <div className="font-extrabold text-sm">
                  {data?.recruitment?.green_light_survey?.status?.toString().toUpperCase() ?? '-'}
                </div>
                <div className="font-extrabold text-sm">
                  {formatDate(data?.recruitment?.green_light_survey?.date)}
                </div>
              </div>
            </div>

            <div className="flex flex-col">
              <div className="flex py-2 flex-col">
                <div className="font-extrabold font-sans text-xs italic">Shadow Week</div>
                <div className="font-extrabold text-sm">
                  {data?.recruitment?.shadow_week?.status?.toString().toUpperCase() ?? '-'}
                </div>
                <div className="font-extrabold text-sm">
                  {formatDate(data?.recruitment?.shadow_week?.date)}
                </div>
              </div>
            </div>

            <div className="flex flex-col">
              <div className="flex py-2 flex-col">
                <div className="font-extrabold font-sans text-xs italic">Due Diligence</div>
                <div className="font-extrabold text-sm">
                  {data?.recruitment?.due_diligence?.status?.toString().toUpperCase() ?? '-'}
                </div>
                <div className="font-extrabold text-sm">
                  {formatDate(data?.recruitment?.due_diligence?.date)}
                </div>
              </div>
            </div>

            <div className="flex flex-col">
              <div className="flex py-2 flex-col">
                <div className="font-extrabold font-sans text-xs italic">Successful</div>
                <div className="font-extrabold text-sm">
                  {data?.recruitment?.successful?.status?.toString().toUpperCase() ?? '-'}
                </div>
                <div className="font-extrabold text-sm">
                  {formatDate(data?.recruitment?.successful?.date)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </CardControl> */}
      <CardControl
        style="rounded-2xl shadow-xs  border border-homeBorder "
        title={'GENERAL INFORMATION'}
      >
        <div className="flex">
          <div className="items-center w-full">
            <div className="flex flex-col">
              <div className="flex py-2 flex-col">
                <div className="font-extrabold font-sans text-xs italic">Mentor</div>
                <div className="font-extrabold text-sm">{data?.mentor ?? '-'}</div>
              </div>
              <div className="flex pb-2 flex-col">
                <div className="font-extrabold font-sans text-xs italic">Group</div>
                <div className="font-extrabold text-sm">{data?.group_name ?? '-'}</div>
              </div>
              <div className="flex pb-2 flex-col">
                <div className="font-extrabold font-sans text-xs italic">Value Exchange Days</div>
                <div className="font-extrabold text-sm">{data?.outstanding_ved_days ?? '-'}</div>
              </div>
              <div className="flex pb-2 flex-col">
                <div className="font-extrabold font-sans text-xs italic">Attendance</div>
                <div className="font-extrabold text-sm">{data?.attendance_percentage ?? '-'}%</div>
              </div>
              {/* <div className="flex pb-2 flex-col">
                <div className="font-extrabold font-sans text-xs italic">Business OI Level</div>
                <div className="font-extrabold text-sm">
                  {data?.business_oi?.length > 0 ? data?.business_oi : '-'}
                </div>
              </div> */}
              <div className="flex pb-2 flex-col">
                <div className="font-extrabold font-sans text-xs italic">Human OI Level</div>
                <div className="font-extrabold text-sm">{data?.human_oi ?? '-'}</div>
              </div>
              <div className="flex pb-2 flex-col">
                <div className="font-extrabold font-sans text-xs italic">Technical OI Level</div>
                <div className="font-extrabold text-sm">{data?.technical_oi ?? '-'}</div>
              </div>
              {/* <div className="flex pb-2 flex-col">
                <div className="font-extrabold font-sans text-xs italic">Induction OI Level</div>
                <div className="font-extrabold text-sm">{data?.induction_oi ?? '-'}</div>
              </div> */}
              <div className="flex pb-2 flex-col">
                <div className="font-extrabold font-sans text-xs italic">Loan Amount</div>
                <div className="font-extrabold text-sm">{data?.loan_amount ?? '-'}</div>
              </div>
            </div>
          </div>
        </div>
      </CardControl>

      <CardControl
        style="rounded-2xl shadow-xs  border border-homeBorder "
        title={'CONTACT INFORMATION'}
      >
        <div className="flex flex-col">
          <div className="flex py-2 flex-col">
            <div className="font-extrabold font-sans text-xs italic">
              <div>Phone</div>
            </div>
            <div className="text-sm font-extrabold">{data?.user_cell_number ?? '-'}</div>
          </div>
          <div className="flex pb-2 flex-col">
            <div className="font-extrabold font-sans text-xs italic">
              <div>Email</div>
            </div>
            <div className="text-sm font-extrabold">{data?.user_email ?? '-'}</div>
          </div>
        </div>
      </CardControl>

      <CardControl style="rounded-2xl shadow-xs  border border-homeBorder " title={'ADDRESS'}>
        <div className="flex flex-col py-2">
          <div
            className="mr-10"
            style={{
              width: '100px',
              height: '100px',
              borderRadius: '4px'
            }}
          >
            <MapControl
              markers={[
                {
                  longitude: data?.address?.longitude ?? 0,
                  latitude: data?.address?.latitude ?? 0
                }
              ]}
            />
          </div>
          <div className="">
            <div>{data?.address?.address ?? '-'}</div>
            <p className="py-2 text-green hover:underline focus:outline-none cursor-pointer text-sm">
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${
                  data?.address?.latitude ?? 0
                },${data?.address?.longitude ?? 0}`}
                target="_blank"
              >
                View on maps
              </a>
            </p>
          </div>
        </div>
      </CardControl>

      <CardControl
        style="rounded-2xl shadow-xs  border border-homeBorder "
        title={'BANK INFORMATION'}
      >
        <div className="flex flex-col">
          <div className="flex py-2 flex-col">
            <div className="font-extrabold font-sans text-xs italic">
              <div>Bank</div>
            </div>
            <div className="text-sm font-extrabold">{data?.bankAccount?.bank_name ?? '-'}</div>
          </div>
          <div className="flex pb-2 flex-col">
            <div className="font-extrabold font-sans text-xs italic">
              <div>Account Number</div>
            </div>
            <div className="text-sm font-extrabold">{data?.bankAccount?.account_number ?? '-'}</div>
          </div>
          <div className="flex pb-2 flex-col">
            <div className="font-extrabold font-sans text-xs italic">
              <div>Account Type</div>
            </div>
            <div className="text-sm font-extrabold">{data?.bankAccount?.type ?? '-'}</div>
          </div>
          <div className="flex pb-2 flex-col">
            <div className="font-extrabold font-sans text-xs italic">
              <div>Branch Code</div>
            </div>
            <div className="text-sm font-extrabold">{data?.bankAccount?.branch ?? '-'}</div>
          </div>
        </div>
      </CardControl>

      {userType == 'LEARNER-ACC' && (
        <CardControl
          style="rounded-2xl shadow-xs  border border-homeBorder "
          title={'BILLING INFORMATION'}
        >
          <div className="flex flex-col">
            <div className="text-sm text-blue-600" onClick={addRate}>
              Edit
            </div>
            <hr />
            <div className="flex py-2 flex-col">
              <div className="font-extrabold font-sans text-xs italic">
                <div>Rate per {data?.billingInfo?.per}</div>
              </div>
              <div className="text-sm font-extrabold">
                {CURRENCY + (data?.billingInfo?.rate ?? '-')}
              </div>
            </div>
          </div>
        </CardControl>
      )}
    </div>
  )
}

export default DetailsBlock
