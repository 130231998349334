import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { differenceInMinutes } from 'date-fns'

// Initialize toast notifications
toast.configure()

const notify = data => {
  toast.info(`You have an event in the next ${data.remaining_time}.`, {
    type: 'info',
    className: 'notification',
    position: toast.POSITION.TOP_RIGHT,
    hideProgressBar: true,
    closeButton: true,
    closeOnClick: true,
    pauseOnHover: true,
    autoClose: data.close_notification ? 5000 : false,
    draggable: true,
    onClick: () => {
      console.log('Notification clicked!')
    }
  })
}

export const checkForUpcomingEvents = (events = [], callback) => {
  console.log('Checking for upcoming events...')
  console.log('Events:', events)
  const now = new Date()

  let closeNotification = false

  events.forEach(event => {
    const eventDate = new Date(event.start_date)
    console.log('Event date:', eventDate)
    console.log('Now:', now)

    const minutesToEvent = differenceInMinutes(eventDate, now)

    //check how many hours are remaining to the event
    const hoursToEvent = parseInt((differenceInMinutes(eventDate, now) / 60).toFixed(0))

    console.log('Minutes to event:', minutesToEvent)

    //if hours to event are less than 24 hours, notify the user
    if ((hoursToEvent < 24 && hoursToEvent > 0) || (minutesToEvent < 60 && minutesToEvent > 0)) {
      closeNotification = true
      const eventData = {
        title: event.title,
        remaining_time: hoursToEvent < 1 ? `${minutesToEvent} minutes` : `${hoursToEvent} hours`,
        close_notification: closeNotification
      }
      notify(eventData)
    }
  })
}
