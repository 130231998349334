import React, { useState, useEffect } from "react";
import Gif from "../../assets/loaders/animation_500_klm80ad8.gif";

const LoaderControl = props => {
    const [ visibility, setVisibility ] = useState(false);
    const { show, inline } = props;

    //Did mount...
    useEffect(() => {
        setVisibility(show);
    }, [show]);

    return visibility ? (
        <div className={`${inline ? "relative" : "fixed w-full h-full top-0 left-0 z-50 "}`}>
            <div className={`${inline ? "" : "bg-overlay"} h-full flex justify-center items-center`}>
                <img src={Gif} className="w-20 rounded-full" />
            </div>
        </div>
        ) : (
        <div className="hidden" />
    )
    
}

export default LoaderControl;