export const documentPayloadMock = {
  documents: [
    {
      url: 'test_url',
      etag: 'test_etag',
      name: 'test_doc'
    }
  ]
}

export const interactionsPayloadMock = {
  banner: 'H0',
  stream: 'Better Together',
  num_interactions: 1,
  cost: 1,
  codes: 'BT1',
  interaction_name: 'OPEN DAY',
  ...documentPayloadMock
}

export const curriculumPayloadMock = {
  name: 'curriculum name',
  program_id: '62a1e6c3d9b608ec60257c01',
  start_date: '2020-3-3',
  end_date: '2021-3-3',
  interactions: [interactionsPayloadMock],
  ...documentPayloadMock
}

export const curriculumTableColumns = [
  { key: 'banner', display: 'Banner' },
  { key: 'stream', display: 'Stream' },
  { key: 'num_interactions', display: 'Num Interactions' },
  { key: 'cost', display: 'Cost' },
  { key: 'codes', display: 'Codes' },
  { key: 'interaction_name', display: 'Interaction Name' },
  { key: 'start_date', date: true, display: 'Start Date' },
  { key: 'end_date', date: true, display: 'End Date' }
]

export const eventsTableColumns = [
  { key: 'event_name', display: 'Name of Event' },
  { key: 'start_date', display: 'Start Date' },
  { key: 'end_date', display: 'End Date' }
]

export const interactionsResponseMock = [interactionsPayloadMock]
export const curriculumResponseMock = [curriculumPayloadMock]

export const initialInteractionsValues = {
  banner: '',
  stream: '',
  num_interactions: '',
  cost: '',
  codes: '',
  interaction_name: '',
  documents: []
}

export const initialCurriculumValues = {
  name: '',
  program_id: '',
  start_date: '',
  end_date: '',
  interactions: [],
  documents: []
}

export const initialDocumentValues = { documents: [] }
export const initialProgrammeDetailsValues = {
  program_id: '',
  program_name: ''
}

export const initialProgrammeCurriculumValues = {
  name: '',
  start_date: '',
  end_date: '',
  interactions: []
}

export const curriculumValidationConfig = [
  { field: 'name', types: ['required'] },
  { field: 'program_id', types: ['required'] },
  { field: 'start_date', types: ['required', 'date'] },
  { field: 'end_date', types: ['required', 'date'] },
  { field: 'interactions', types: ['required'] }
]

export const interactionsValidationConfig = [
  { field: 'banner', types: ['required'] },
  { field: 'stream', types: ['required'] },
  { field: 'num_interactions', types: ['required', 'number'] },
  { field: 'cost', types: ['required', 'number'] },
  { field: 'codes', types: ['required'] },
  { field: 'interaction_name', types: ['required'] }
]

export const programDetailsCurriculumValidationConfig = [
  { field: 'program_id', types: ['required'] },
  { field: 'program_name', types: ['required'] },
  { field: 'start_date', types: ['required'] },
  { field: 'end_date', types: ['required'] }
]

export const programCurriculumValidationConfig = [
  { field: 'program_name', types: ['required'] },
  { field: 'program_code', types: ['required'] }
]

export const documentValidationConfig = [{ field: 'documents', types: ['required'] }]

export const actionButtonItems = [{ name: 'Add to Event', id: 1 }]