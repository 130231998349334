import {
  EDIT_PROGRAM_STATE,
  SET_PROGRAM_STATE,
  UPDATE_PROGRAM_STATE
} from '../../constants/redux_types'

export function programsStateReducer(
  state = {
    programs: [],
    programsLoaded: false
  },
  action
) {
  switch (action.type) {
    case SET_PROGRAM_STATE:
      return {
        ...state,
        ...action.payload
      }
    case EDIT_PROGRAM_STATE:
      const _programs = state.programs.map(x => {
        if (x._id === action.payload._id) {
          return action.payload
        } else {
          return x
        }
      })

      return {
        ...state,
        programs: _programs
      }
    case UPDATE_PROGRAM_STATE:
      return {
        ...state,
        programs: [action.payload, ...state.programs]
      }
    default:
      return state
  }
}
