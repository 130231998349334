import React, { useEffect, useState } from "react";

const ButtonTabControl = props => {
    const { titles, pages } = props;

    const [visiblePageIndex, setVisiblePageIndex] = useState(0);
    const [tabTitles, setTabTitles] = useState(titles);

    useEffect(() => {
        if (!(props.children && titles) && !(props.children.length == titles.length)) {
            throw new Error("The children's length does not match the titles's length.");
        }
    }, []);

    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-row gap-4">
                {tabTitles.map((tabTitle, index) => {
                    return (
                        <div key={index} className={`px-4 py-2 rounded-xl cursor-pointer ${index == visiblePageIndex ? "bg-green text-white": "bg-white"} shadow-md font-extrabold`} onClick={() => {
                                setVisiblePageIndex(index);
                            }}>
                            {tabTitle}
                        </div>
                    )
                })}
            </div>

            {props.children.map(
                (child, i) => {
                    if (visiblePageIndex == i) {
                        return child;
                    }
                }
            )}
        </div>
    )

}

export default ButtonTabControl;