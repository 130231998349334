import React, { useEffect, useState } from 'react'
import PopupControl from '../../../controls/popup/popup'
import ButtonComponent from '../../customComponents/button'
import InputComponent from '../../customComponents/input'
import LoaderControl from '../../../controls/loader/loader'
import { useDispatch } from 'react-redux'
import { getVenues, saveVenue } from '../../../asyncActions/venues'
import { setVenuesState } from '../../../actions'

const AddEditVenueComponent = ({ isEdit = false, setShow, open = false, formData, venues }) => {
  const [formValues, setFormValues] = useState({})
  const dispatch = useDispatch()
  const [isBusy, setIsBusy] = useState(false)

  const onFormValueChange = value => {
    setFormValues({ ...formValues, ...value })
  }

  useEffect(() => {
    if (formData) {
      setFormValues(formData)
    }
  }, [formData])

  const _onSave = () => {
    if (isEdit) {
      const id = formValues._id
      delete formValues._id
      dispatch(saveVenue({ ...formValues, id }))
      dispatch(getVenues())
    } else {
      dispatch(saveVenue(formValues))
      dispatch(getVenues())
    }
    setIsBusy(false)
    setShow(false)
  }

  return (
    <div>
      <PopupControl
        title={`${isEdit ? 'Update' : 'Add'} Venue`}
        isOpen={open}
        close={{
          showClose: true,
          onClose: () => {
            setShow(false)
          }
        }}
      >
        <div className="flex flex-col w-72">
          <InputComponent
            theme="dark"
            title="Name"
            value={formValues.name}
            onChange={v =>
              onFormValueChange({
                name: v
              })
            } //
          />

          <InputComponent
            theme="dark"
            size="lg"
            title="Description"
            value={formValues.description}
            onChange={v =>
              onFormValueChange({
                description: v
              })
            }
          />

          <InputComponent
            theme="dark"
            title="City"
            value={formValues.city}
            onChange={v =>
              onFormValueChange({
                city: v
              })
            }
          />
          <InputComponent
            theme="dark"
            title="Address Line 1"
            value={formValues.address_line_1}
            onChange={v =>
              onFormValueChange({
                address_line_1: v
              })
            }
          />
          <InputComponent
            theme="dark"
            title="Address Line 2"
            value={formValues.address_line_2}
            onChange={v =>
              onFormValueChange({
                address_line_2: v
              })
            }
          />
          <InputComponent
            theme="dark"
            title="Area Code"
            value={formValues.area_code}
            onChange={v =>
              onFormValueChange({
                area_code: v
              })
            }
          />
          <InputComponent
            theme="dark"
            title="Building"
            value={formValues.building}
            onChange={v =>
              onFormValueChange({
                building: v
              })
            }
          />
          <InputComponent
            theme="dark"
            title="Room Number"
            value={formValues.room_number}
            onChange={v =>
              onFormValueChange({
                room_number: v
              })
            }
          />
          <div className="flex justify-center">
            <div className="w-full pt-8 flex justify-center items-center">
              <ButtonComponent
                title={`${isEdit ? 'Update' : 'Add'} Venue`}
                isLoading={false}
                onClick={() => {
                  setIsBusy(true)
                  _onSave()
                }}
              />
            </div>
          </div>
        </div>
      </PopupControl>
      <LoaderControl show={isBusy} />
    </div>
  )
}

export default AddEditVenueComponent
