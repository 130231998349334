import { faFarm, faHandHoldingSeedling, faWheat } from '@fortawesome/pro-solid-svg-icons'
import React, { useEffect, useState } from 'react'

import ArcPercentageControl from '../../controls/arcpercentage/arcpercentage'
import BarGraphControl from '../../controls/bargraph/bargraph'
import ButtonControl from '../../controls/button/button'
import CardArcDetailControl from '../../controls/cards/arcdetailcard'
import TitleCardControl from '../../controls/cards/titlecard'
import DropdownControl from '../../controls/dropdown/dropdown'
import LoaderControl from '../../controls/loader/loader'
import NotificationControl from '../../controls/notification/notification'
import PopupControl from '../../controls/popup/popup'
import RadioButtonGroupControl from '../../controls/radiobuttongroup/radiobuttongroup'
import TableControl from '../../controls/table/table'
import TextInputControl from '../../controls/textinput/textinput'
import TreeControl from '../../controls/tree/tree'
import { getISODate } from '../../utils/utils'

const SampleUsageContainer = props => {
  const [isBusy, setIsBusy] = useState()
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [selectedItem, setSelectedItem] = useState()
  const [selectedItems, setSelectedItems] = useState()
  const [popupVisibility, setPopupVisibility] = useState()
  const [subItems, setSubItems] = useState([
    { farm_name: 'Komatipoort', id: '123-1234-12345-123456' },
    { farm_name: 'Estancia', id: '223-1234-12345-123456' }
  ])
  const [ellipsisItems, setEllipsisItems] = useState([
    { name: 'Edit', id: 'edit' },
    { name: 'Details', id: 'details' }
  ])
  const [activityType, setActivityType] = useState()
  const [notificationData, setNotificationData] = useState({
    title: '',
    messages: [],
    type: 'ok' | 'confirm'
  })
  //Did mount...
  useEffect(() => {}, [])

  //Will unmount...
  useEffect(() => {
    return () => {
      console.log('Cleaning up...')
    }
  }, [])

    let onActionClick = item => {}

  return (
    <div className="containers-sampleusage bg-main w-full">
      <div className="h-60 w-60">
        <TitleCardControl title="Orders Per Day">
          <BarGraphControl
            items={[
              { title: 'Mon', percentage: 0.1 },
              { title: 'Tue', percentage: 0.2 },
              { title: 'Wed', percentage: 0.3 },
              { title: 'Thu', percentage: 1.0 },
              { title: 'Fri', percentage: 0.8 },
              { title: 'Sat', percentage: 0.9 },
              { title: 'Sun', percentage: 0.5 }
            ]}
          />
          <div className="h-4"></div>
          <ButtonControl placeholder="See More " isBusy={false} type="primary" onClick={v => {}} />
        </TitleCardControl>
      </div>
      <div className="w-60 bg-dark">
        <DropdownControl
          placeholder={'Farm'}
          isBusy={false}
          type="main"
          displayKey="farm_name"
          subItems={subItems}
          selectedItem={selectedItem}
          allItem={{ farm_name: 'All Farms', id: 'ALL' }}
          onClick={item => {
            setSelectedItem(item)
          }}
        />
      </div>
      <div className="w-60 ">
        <DropdownControl
          type="account"
          placeholder={`User Name`}
          displayKey="name"
          subItems={[{ name: 'Log Out', value: 'logOut' }]}
          onClick={item => {
            if (item.value == 'logOut') {
              // logOut();
            }
          }}
        />
      </div>
      <div className="w-20 bg-white">
        <DropdownControl
          type="ellipsis"
          displayKey="name"
          subItems={ellipsisItems}
          onClick={menuItem => {}}
        />
      </div>

      <div className="p-5 w-80">
        <TextInputControl
          title="Email"
          value={email}
          placeholder="Email"
          onChange={v => setEmail(v)}
        />
        <TextInputControl
          title="Password"
          value={password}
          type="password"
          placeholder="Password"
          onChange={v => setPassword(v)}
        />
      </div>

      <div className="p-5 w-80">
        <ButtonControl placeholder="SIGN IN" isBusy={false} type="primary" onClick={v => {}} />
        <ButtonControl
          placeholder="Forgot Your Password?"
          isBusy={false}
          type="link"
          onClick={v => {}}
        />
      </div>

      <div className="p-5 w-80 flex flex-col gap-4">
        <ButtonControl
          placeholder="Show Loader"
          isBusy={false}
          type="primary"
          theme="dark"
          onClick={v => {
            setIsBusy(true)
            setTimeout(() => {
              setIsBusy(false)
            }, 5000)
          }}
        />
        <ButtonControl
          placeholder="Show Notification"
          isBusy={false}
          type="primary"
          theme="dark"
          onClick={v => {
            setNotificationData({
              title: 'Notification',
              messages: ['Message one', 'Message two'],
              type: 'ok'
            })
          }}
        />
        <ButtonControl
          placeholder="Show Popup"
          isBusy={false}
          type="primary"
          theme="dark"
          onClick={v => {
            setPopupVisibility(true)
          }}
        />
      </div>

      <div className="w-96 flex items-end justify-center bg-dark">
        <RadioButtonGroupControl
          group="activity-type"
          value={activityType}
          values={[
            { title: 'Running Activity', value: 'running' },
            { title: 'Event', value: 'event' }
          ]}
          onChange={v => setActivityType(v)}
        />
      </div>

      <div className="w-80 bg-dark p-5">
        <DropdownControl
          title="SOP"
          isBusy={false}
          type="primary"
          theme="light"
          displayKey="farm_name"
          subItems={subItems}
          selectedItem={selectedItem}
          onClick={items => {
            setSelectedItem(items)
          }}
        />

        <DropdownControl
          title="SOP's"
          multiple={true}
          isBusy={false}
          type="primary"
          theme="light"
          displayKey="farm_name"
          subItems={subItems}
          selectedItems={selectedItems}
          onClick={items => {
            setSelectedItems(items)
          }}
        />
      </div>

      <div className="flex flex-row gap-4 p-5">
        <CardArcDetailControl
          title="Farms"
          icon={faFarm}
          percentage="27"
          details={[{ name: 'Active', value: 1 }]}
        />
        <CardArcDetailControl
          title="Harvests"
          icon={faWheat}
          percentage="9"
          details={[{ name: 'Active', value: 2 }]}
        />
        <CardArcDetailControl
          title="SOP's"
          icon={faHandHoldingSeedling}
          percentage="47"
          details={[
            { name: 'Complete', value: 2 },
            { name: 'In-Progress', value: 13 },
            { name: "Haven't Started", value: 3 },
            { name: 'Delayed', value: 1 }
          ]}
        />

        <TitleCardControl title="Small number with percentage">
          <ArcPercentageControl percentage={20} display="1" smallDisplay="99" />
        </TitleCardControl>
      </div>

      <div className="flex flex-row gap-4 h-full p-5">
        <div className="w-3/5 flex flex-col flex-none gap-4">
          <TitleCardControl title="Farm Locations"></TitleCardControl>
        </div>
        <div className="flex flex-auto flex-col gap-4">
          <div className="h-1/2">
            <TitleCardControl></TitleCardControl>
          </div>
          <div className="h-1/2">
            <TitleCardControl title="SOP's"></TitleCardControl>
          </div>
        </div>
      </div>

      <div className="p-5 h-50">
        <div className="p-5 bg-white">
          <TableControl
            title="Sample title"
            search={true}
            actionButton={{
              side: 'right',
              title: 'Action',
              isDropdown: true,
              onClick: onActionClick,
              items: [
                { name: 'One', value: 1 },
                { name: 'Two', value: 2 },
                { name: 'Three', value: 3 }
              ]
            }}
            columns={[
              { key: 'subject', display: 'Subject' },
              { key: 'updatedAt', display: 'Updated At' },
              { key: 'createdAt', display: 'Created At' }
            ]}
            data={[
              {
                subject: 'One',
                updatedAt: getISODate(),
                createdAt: getISODate()
              },
              {
                subject: 'Two',
                updatedAt: getISODate(),
                createdAt: getISODate()
              },
              {
                subject: 'Three',
                updatedAt: getISODate(),
                createdAt: getISODate()
              },
              {
                subject: 'Four',
                updatedAt: getISODate(),
                createdAt: getISODate()
              },
              {
                subject: 'Five',
                updatedAt: getISODate(),
                createdAt: getISODate()
              },
              {
                subject: 'Six',
                updatedAt: getISODate(),
                createdAt: getISODate()
              },
              {
                subject: 'Seven',
                updatedAt: getISODate(),
                createdAt: getISODate()
              },
              {
                subject: 'Eight',
                updatedAt: getISODate(),
                createdAt: getISODate()
              },
              {
                subject: 'Nine',
                updatedAt: getISODate(),
                createdAt: getISODate()
              },
              {
                subject: 'Ten',
                updatedAt: getISODate(),
                createdAt: getISODate()
              }
            ]}
            rowClicked={id => {
              // console.log(id);
              //history.push(`blogs/${id}`);
            }}
          />
        </div>
      </div>

      <div className="p-5">
        <div className="p-5 bg-white">
          <TreeControl
            title="Farm"
            search={false}
            columns={['Start Date', 'Finish Date']}
            items={[
              {
                name: 'Medley Farm - Komatipoort (FARM)',
                expanded: true,
                columns: ['1 March 2021', '1 Sep 2021'],
                items: [
                  {
                    name: 'Yellow Peas (CROP)',
                    expanded: true,
                    columns: ['1 April 2021', '1 Sep 2021'],
                    items: [
                      {
                        name: 'Harvesting (SOP)',
                        expanded: true,
                        columns: ['1 Activity', '0% Complete', '1 Aug 2021', '1 Sep 2021'],
                        items: [
                          {
                            name: 'Combine Inspection (Activity)',
                            expanded: true,
                            // items: [
                            //     { name: "Activity Details", },
                            //     { name: "1.1.1.1.2", },
                            // ]
                            details: {
                              fields: [
                                {
                                  Details:
                                    'Blah... blah... blah blah... blah... Blah... blah... blah blah... blah... Blah... blah... blah blah... blah...'
                                },
                                { Name: '' },
                                { Surname: '' },
                                { Email: '' }
                              ],
                              map: {
                                latitude: 0,
                                longitude: 0
                              }
                            },
                            actions: [
                              <DropdownControl
                                placeholder="Action"
                                type="primary"
                                theme="light"
                                displayKey="name"
                                subItems={(() => {
                                  return [
                                    {
                                      name: 'Add Planned Start Date',
                                      value: 'planned_start'
                                    },
                                    {
                                      name: 'Add Planned Finish Date',
                                      value: 'planned_finish'
                                    },
                                    {
                                      name: 'Add Actual Start Date',
                                      value: 'actual_start'
                                    },
                                    {
                                      name: 'Add Actual Finish Date',
                                      value: 'actual_finish'
                                    }
                                  ]
                                })()}
                                onClick={item => {}}
                              />
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    name: '1.2',
                    expanded: false,
                    items: [{ name: '1.2.1' }, { name: '1.2.2' }]
                  },
                  {
                    name: '1.3',
                    expanded: false,
                    items: [{ name: '1.3.1' }]
                  }
                ]
              },
              {
                name: '2',
                expanded: false,
                items: [
                  {
                    name: '2.1',
                    expanded: false,
                    items: [{ name: '2.1.1' }]
                  },
                  {
                    name: '2.2',
                    expanded: false,
                    items: [{ name: '2.2.1' }, { name: '2.2.2' }]
                  }
                ]
              }
            ]}
          />
        </div>
      </div>

      <LoaderControl show={isBusy} />

      <NotificationControl notificationData={notificationData} />

      <PopupControl
        title={'Update'}
        isOpen={popupVisibility}
        close={{
          showClose: true,
          onClose: () => {
            setPopupVisibility(false)
          }
        }}
      >
        <div className="flex flex-col gap-4">
          <div className="flex flex-row gap-4">
            <div className="w-56 flex flex-col gap-4">
              <TextInputControl
                theme="dark"
                title="Crop Name"
                value={email}
                onChange={v => setEmail(v)}
              />
            </div>
            <div className="w-56 flex flex-col gap-4">
              <DropdownControl
                title="Farm"
                isBusy={false}
                type="primary"
                theme="dark"
                displayKey="farm_name"
                subItems={subItems}
                selectedItem={selectedItem}
                onClick={item => {
                  setSelectedItem(item)
                }}
              />
            </div>
          </div>
          <div className="flex justify-center">
            <div className="w-40">
              <ButtonControl
                placeholder="Update"
                isBusy={false}
                type="primary"
                onClick={v => {
                  setPopupVisibility(false)
                }}
              />
            </div>
          </div>
        </div>
      </PopupControl>
    </div>
  )
}

export default SampleUsageContainer
