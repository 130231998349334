import React, { useEffect, useRef, useState } from 'react'
import { faBars, faBell, faSignOut, faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { connect, useDispatch } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { _initializeData } from '../../api/calls/_iniitializeData'
import logo from '../../assets/images/black_logo.png'
import DropdownControl from '../../controls/dropdown/dropdown'
import NotificationControl from '../../controls/notification/notification'
import NotificationPanelControl from '../../controls/notificationPanel/notificationPanel'
import AppRoutes from '../../routes'
import { setUserState } from '../../actions'
import {
  adminNavigationItems,
  entityNavigationItems,
  facilitatorNavigationItems,
  learnerNavigationItems,
  navigationItems
} from '../../constants'
import routes from '../../routes'
import { get_me } from '../../api/calls/me'
import AlertComponent from '../customComponents/alert'
import ButtonComponent from '../customComponents/button'
import { get_all_tasks } from '../../api/calls'
import { checkForUpcomingEvents } from '../../utils/eventNotification'

// @ts-ignore

const DashboardComponent = ({ permissionsState, userState, alertState }) => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false)
  const [accountType, setAccountType] = useState('')
  const history = useHistory()
  let { path, url } = useRouteMatch()
  let timeout = null
  const cookies = new Cookies()
  const [notificationData, setNotificationData] = useState({
    title: '',
    messages: [],
    // @ts-ignore
    type: 'ok' | 'confirm'
  })
  const [showNotifications, setShowNotifications] = useState(false)
  const dispatch = useDispatch()
  const [navItems, setNavItems] = useState([])
  const [notifications, setNotifications] = useState([
    {
      id: 1,
      title: 'First',
      message: 'First notification',
      time: '10 seconds ago'
    },
    {
      id: 2,
      title: 'Second',
      message: 'Second notification',
      time: '20 minutes ago'
    },
    {
      id: 3,
      title: 'Third',
      message: 'Third notification',
      time: '40 minutes ago'
    }
  ])

  //   const _navigationItems = userState?.permissions?.filter(item => item.name) ?? []

  //create delay variable that will be used to fetch events after every 1 hour
  const delay = 1000 * 60 * 60
  const savedCallback = useRef()

  //method to fetch events
  const getEvents = () => {
    console.log('Fetching events...')
    get_all_tasks().then(res => {
      console.log('Events fetched:', res)
      if (res.data.success) {
        dispatch(setUserState({ tasks: res.data.content }))
        let events = []
        events = res.data.content ?? []
        checkForUpcomingEvents(events)
      }
    })
  }

  useEffect(() => {
    savedCallback.current = getEvents
  }, [getEvents])

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])

  useEffect(() => {
    const cookieJSON = cookies.get('veb-user-cookie')

    get_me().then(res => {
      if (res.data.success) {
        setAccountType(res.data.content.account.type_code)
      }
    })

    if (!cookieJSON) {
      history.push('/') //Log out.
    } else {
      let cookieExpiryTime = moment(cookieJSON.expiry_time)
      let timeDifference = cookieExpiryTime.diff(moment(), 'milliseconds')

      timeout = setTimeout(() => {
        setNotificationData({
          title: 'Session expired',
          close: false,
          // @ts-ignore
          messages: ['You are being logged out because your secure session has expired.'],
          onConfirm: () => {
            history.push('/')
          }
        })
      }, timeDifference)
      _initializeData()
    }
  }, [])

  useEffect(() => {
    if (accountType.length > 0) {
      switch (accountType) {
        case 'ADMIN-ACC':
          handleUserNavigationItems(adminNavigationItems)
          break
        case 'FACILITATOR-ACC':
          handleUserNavigationItems(facilitatorNavigationItems)
          break
        case 'ENTITY-ACC':
          handleUserNavigationItems(entityNavigationItems)
          break
        default:
          break
      }
    }
  }, [accountType])

  const handleUserNavigationItems = tabs => {
    let permittedTabs = []
    let items = []

    for (const tab of tabs) {
      navigationItems.find(item => {
        if (tab.path === item.path) {
          permittedTabs.push(item)
        }
      })
    }

    items = permittedTabs.filter(item => item.name)
    setNavItems(items)
  }

  const logOut = () => {
    cookies.remove('veb-user-cookie')
    history.push('/')
  }

  return (
    <div className="w-screen h-screen flex flex-row">
      <div
        className={`flex-none ${
          isSidebarCollapsed ? 'w-0' : 'w-64'
        } flex flex-col gap-8 justify-between bg-navigation overflow-y-auto`}
      >
        <div className="flex flex-col items-center">
          <img src={logo} className="object-contain h-20 pb-4 pt-4" />
        </div>
        <div className="flex-grow space-y-4">
          {navItems.map((item, index) => {
            let isSelected = history.location.pathname == `${path}/${item.path}`
            return (
              <div
                key={index}
                className={`border-l-8 mr-4 rounded-r-lg h-8 flex flex-row items-center  cursor-pointer hover:bg-activeMenu hover:border-white text-black hover:text-white transition-colors
                  ${isSelected ? 'border-white' : 'border-transparent'}
                  ${isSelected ? 'bg-activeMenu text-white' : 'bg-transparent text-black'}`}
                onClick={() => history.push(`${path}/${item.path}`)}
              >
                <div className="ml-4 w-6 flex justify-center">
                  <FontAwesomeIcon className="text-sm " icon={item.icon} />
                </div>
                <div className="pl-4 text-sm">{item.name}</div>
              </div>
            )
          })}
        </div>
        <div
          className={`border-l-8 border-transparent mr-4 rounded-r-lg h-8 flex flex-none flex-row items-center
                      cursor-pointer hover:bg-activeMenu hover:border-white text-black Hover:text-white transition-colors mb-4`}
          onClick={() => logOut()}
        >
          <div className="ml-4 w-6 flex justify-center">
            <FontAwesomeIcon className="text-xl " icon={faSignOut} />
          </div>
          <div className="pl-4">Log Out</div>
        </div>
      </div>
      <div className="flex flex-col flex-auto bg-main-light relative">
        <AlertComponent
          className={'absolute z-10 top-10 right-10'}
          show={alertState.show}
          message={alertState.message}
          success={alertState.success}
        />

        <div className="flex justify-between flex-none items-center">
          <div
            className="bg-green m-3 w-10 h-10 rounded-lg flex justify-center items-center cursor-pointer"
            onClick={() => {
              setIsSidebarCollapsed(!isSidebarCollapsed)
            }}
          >
            {isSidebarCollapsed ? (
              <FontAwesomeIcon className="text-2xl text-white" icon={faBars} />
            ) : (
              <FontAwesomeIcon className="text-2xl text-white" icon={faTimes} />
            )}
          </div>
          <div className="flex">
            <div
              className="cursor-pointer mx-2 pt-2"
              onClick={() => setShowNotifications(!showNotifications)}
            >
              {/* <div className="rounded-full text-white bg-green w-4 ml-3 text-center absolute">
                  <span className="text-xs">3</span>
                </div>
                <FontAwesomeIcon className=" text-2xl text-black mr-3 " icon={faBell} /> */}
            </div>
            <NotificationPanelControl showNotifications={showNotifications} data={notifications} />
            <div>
              <DropdownControl
                type="account"
                placeholder={`${userState.user_name} ${userState.user_surname}`}
                displayKey="name"
                subItems={[{ name: 'Log Out', value: 'logOut' }]}
                onClick={item => {
                  if (item.value == 'logOut') {
                    logOut()
                  }
                }}
              />
            </div>
          </div>
        </div>

        <div className="flex-grow p-4 pt-0 overflow-auto">
          <AppRoutes path={path} />
        </div>
      </div>
      <NotificationControl notificationData={notificationData} />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    userState: state.userState,
    permissionsState: state.permissionsState,
    alertState: state.alertState
  }
}

export default connect(mapStateToProps)(DashboardComponent)
