import { resetNotificationState, setNotificationState } from '../actions'

export const setNotification = payload => {
  return dispatch => {
    dispatch(setNotificationState({ onConfirm: () => dispatch(resetNotification()), ...payload }))
  }
}

export const resetNotification = () => {
  return dispatch => {
    dispatch(resetNotificationState())
    window.location.reload()
  }
}
