import React from 'react'

import { stoplightSurveyIndicators } from '../../../constants/constants'
import { CircleIconText } from '../../../controls/circleIconText/circleIconText'

const AveragesWidget = ({ averages }) => {
  return Object.keys(averages).length > 0 ? (
    <table className={'text-left flex flex-col w-full'}>
      <tbody className="bg-white flex flex-col items-center justify-between w-full">
        <tr className="flex w-full">
          {/* --------- Using td to align position accurately with parent */}
          <td className="px-4 py-2 w-full"></td>
          <td className="px-4 py-2 w-full"></td>
          <td className="px-4 py-2 w-full"></td>

          {/* --------- Averages */}
          <td className="px-4 py-2 w-full">Average</td>
          {stoplightSurveyIndicators.map((item, i) => (
            <td key={i} className="px-4 py-2 w-full">
              <CircleIconText text={averages[item.id]} className={item.color} />
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  ) : null
}

export default AveragesWidget
