import { createBrowserHistory } from 'history'

import { setCurriculumState } from '../actions'
import { save_calendar, save_curriculum } from '../api/calls'
import { get_all_curriculums } from '../api/calls/curriculums/get_all_curriculums'
import { get_curriculum } from '../api/calls/curriculums/get_curriculum'
import { resetNotification, setNotification } from './notification'

const browserHistory = createBrowserHistory()

export const getAllCurriculums = () => {
  return dispatch => {
    get_all_curriculums()
      .then(res => {
        if (res.data.success) {
          dispatch(
            setCurriculumState({
              curriculums: res.data.content,
              curriculumsLoaded: true
            })
          )
        }
      })
      .catch(error => {})
  }
}
export const saveCalendar = calendar => {
  return dispatch => {
    save_calendar(calendar)
      .then(res => {
        if (res.data.success) {
          dispatch(
            setNotification({
              title: 'Success',
              messages: ['You have successfully saved the calendar.']
            })
          )
        }
      })
      .catch(error => {})
  }
}

export const getCurriculum = curriculums => {
  return dispatch => {
    get_curriculum(`id=${curriculums._id}`)
      .then(res => {
        if (res.data.success) {
          dispatch(
            setCurriculumState({
              curriculums: res.data.content
            })
          )
        }
      })
      .catch(error => {})
  }
}

export const saveCurriculum = curriculums => {
  return dispatch => {
    save_curriculum(curriculums)
      .then(res => {
        if (res.data.success) {
          dispatch(
            setNotification({
              title: 'Success',
              messages: ['You have successfully saved a curriculum.'],
              onConfirm: () => {
                dispatch(resetNotification())
                browserHistory.goBack()
              }
            })
          )
          dispatch(getAllCurriculums())
        }
      })
      .catch(error => {})
  }
}
