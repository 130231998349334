import { validateFormValues } from '../utils'

export function useFormActionHook(formValues, validationsConfig) {
  const handleSubmit = ({ formAction, onFailedValidation }) => {
    const [isValid, errors] = validateFormValues({ formValues, validationsConfig })
    if (isValid) {
      return formAction(isValid)
    }

    return onFailedValidation(errors)
  }
  return { handleSubmit }
}
