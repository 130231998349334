import React from 'react'

import { useHistory, useRouteMatch } from 'react-router-dom'

import logo from './../../assets/images/black_logo.png'
import ButtonControl from '../../controls/button/button'

const AddStudentApplication = () => {
  const history = useHistory()
  let { path } = useRouteMatch()
  let isSelected = history.location.pathname == `${path}/'`

  return (
    <div className="m-auto flex flex-col bg-white h-full justify-center content-center">
      <img className="object-contain h-60 pb-8 pt-4" src={logo} />
      <h1 className="text-center text-4xl font-bold">Welcome to The Go Group</h1>
      <div className="text-center py-4">Please proceed to the application process</div>
      <div className="grid grid-cols-3">
        <div></div>
        <div>
          <ButtonControl
            placeholder="Apply Now"
            isBusy={false}
            type="primary"
            onClick={() => history.push(`application-form`)}
          />
        </div>
        <div></div>
      </div>
    </div>
  )
}

export default AddStudentApplication
