export const surveyIndicators = [
  {
    name: 'Not Stuck',
    id: 'Not Stuck',
    color: 'text-green',
    iconColor: 'bg-main-green',
    iconText: '3'
  },
  {
    name: 'Struggling',
    id: 'Struggling',
    color: 'text-yellow',
    iconColor: 'bg-yellow-600',
    iconText: '2'
  },
  { name: 'Stuck', id: 'Stuck', color: 'text-red-600', iconColor: 'bg-red-600', iconText: '1' }
]
export const stoplightSurveyIndicators = [
  { name: 'Doing Well', id: 'doing_well', color: 'text-green' },
  { name: 'Struggle', id: 'struggle', color: 'text-yellow' },
  { name: 'Stuck', id: 'stuck', color: 'text-red-600' }
]

export const actionButtonItems = [
  {
    name: 'View',
    id: 1
  },
  {
    name: 'Edit',
    id: 2
  },
  {
    name: 'Delete',
    id: 3
  }
]

export const CURRENCY = 'R'
export const DATE_FORMAT = 'YYYY-MM-DD'
export const EMPTY_TEXT = 'N/A'
export const VALIDATION_FAILED_MSG = 'Validation Failed'
export const CONFIRM_DELETE_MSG = 'Are you sure you want to delete - '

export const itemsPerPage = 20
export const initialPageNumber = 1
