import { SET_ALERT, RESET_ALERT } from '../../constants/redux_types'

export function alertStateReducer(
  state = { title: '', messages: '', show: false, success: false, type: 0 },
  action
) {
  switch (action.type) {
    case SET_ALERT:
      return {
        ...action.payload
      }
    case RESET_ALERT:
      return { title: '', messages: '', show: false, success: false, type: 0 }
    default:
      return state
  }
}
