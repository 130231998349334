import React, { useEffect, useState } from 'react'

import { connect, useDispatch } from 'react-redux'
import { useParams } from 'react-router'

import { get_program_users, get_venues } from '../../api/calls'
import { get_program_interactions } from '../../api/calls/programs/get_program_interactions'
import { getLearners, getPrograms } from '../../asyncActions'
import { saveRegister } from '../../asyncActions/attendance'
import { setNotification } from '../../asyncActions/notification'
import { CircleIconText } from '../../controls/circleIconText/circleIconText'
import TableContentWrapper from '../../controls/tableContentWrapper/tableContentWrapper'
import { useFormActionHook } from '../../hooks/useFormActionHook'
import AttendanceTable from './components/attendanceTable'
import { attendanceValidationConfig, initialAttendanceData } from './components/contants'
import Form from './components/form'
import InputComponent from '../customComponents/input'
import DatePickerComponent from '../customComponents/datepicker'
import ButtonComponent from '../customComponents/button'
import { faSave } from '@fortawesome/pro-light-svg-icons'
import DropdownButtonComponent from '../customComponents/dropdownButton'

const VolunteerDays = ({
  venueState,
  learnerState,
  notificationState: notificationData,
  programsState,
  facilitatorState,
  userState
}) => {
  const dispatch = useDispatch()
  const [searchItem, setSearchItem] = useState('')
  const [formValues, setFormValues] = useState(initialAttendanceData)
  const [time, setTime] = useState('')
  const [init, setInit] = useState(true)
  const [users, setUsers] = useState([])
  const [venues, setVenues] = useState([])
  const [learners, setLearners] = useState()
  const { handleSubmit } = useFormActionHook(formValues, attendanceValidationConfig)
  const { program_id } = useParams()
  const [selectedProgram, setSelectedProgram] = useState({})
  const [attendanceForm, setAttendanceForm] = useState({})
  const [showAttendanceForm, setShowAttendanceForm] = useState(false)
  const [show, setShow] = useState(false)
  const [interactions, setInteractions] = useState([])

  const _saveRegister = () => {
    handleSubmit({
      formAction: () => {
        dispatch(saveRegister(formValues))
      },
      onFailedValidation: errors => {
        dispatch(
          setNotification({
            title: 'Validation Failed',
            messages: errors
          })
        )
      }
    })
  }

  useEffect(() => {
    if (program_id)
      get_program_interactions(program_id)
        .then(response => {
          if (response.data.success) {
            setInteractions(response.data.content)
          }
        })
        .catch(error => {})
  }, [program_id])

  useEffect(() => {
    if (Object.keys(attendanceForm).length > 0) {
      _onInputChange({
        attendance_form: {
          name: '',
          url: attendanceForm.attendance_form.location,
          etag: attendanceForm.attendance_form.etag
        }
      })
    }
  }, [attendanceForm])

  const handleAttendanceForm = form => {
    setAttendanceForm(form)
  }

  const _onInputChange = value => {
    setFormValues({ ...formValues, ...value })
  }

  const handleLearners = value => {
    setLearners(value)
  }

  useEffect(() => {
    _onInputChange({ learners })
  }, [learners])

  useEffect(() => {
    if (!programsState.programsLoaded) {
      dispatch(getPrograms())
    }
    if (init && learnerState.learners.length == 0) {
      dispatch(getLearners())
      setInit(false)
    }

    let _program = programsState.programs.find(x => x._id === program_id)
    if (_program) {
      setSelectedProgram(_program)
    }

    // dispatch(getVenues()) - REMEMBER TO COME BACK TO THIS!

    get_venues()
      .then(response => {
        if (response.data.success) {
          setVenues(response.data.content)
        }
      })
      .catch(error => {})
    _onInputChange({ program_id: selectedProgram._id, facilitator_id: userState.id })
  }, [learnerState, programsState, selectedProgram, venueState, userState])

  useEffect(() => {
    if (Object.keys(selectedProgram).length > 0) {
      get_program_users(`id=${selectedProgram._id}`).then(res => {
        if (res.data.success) {
          setUsers(res.data.content)
        }
      })
    }
  }, [selectedProgram])

  return (
    <TableContentWrapper
      title="Value Exchange Days / Catch Up days"
      notificationData={notificationData}
    >
      <div className="w-full ">
        <div className="flex w-full flex-col items-center justify-center gap-4">
          <div className="flex flex-col w-full gap-4 px-12">
            <div className="flex flex-row gap-4 justify-center pb-6">
              <CircleIconText text={'A1 Level'} iconWithText={true} iconColor={'bg-red-600'} />
              <CircleIconText text={'A2 Level'} iconWithText={true} iconColor={'bg-yellow-400'} />
              <CircleIconText text={'A3 Level'} iconWithText={true} iconColor={'bg-main-green'} />
            </div>
            <div className="flex justify-between w-full gap-4">
              <div className="flex flex-col gap-2 w-full ">
                <InputComponent
                  theme="form"
                  title="Please indicate the number of registers you have used"
                  value={formValues.number_of_registers}
                  onChange={number_of_registers => {
                    _onInputChange({ number_of_registers })
                  }}
                />
                <InputComponent
                  title="Name"
                  theme="form"
                  value={formValues.office}
                  onChange={name => {
                    _onInputChange({ name })
                  }}
                />

                <DatePickerComponent
                  title="Date"
                  date={formValues.date}
                  onChange={date => _onInputChange({ date })}
                />
              </div>
              <div className="w-full flex flex-col gap-2">
                <DropdownButtonComponent
                  theme={'form'}
                  title="Interaction Code"
                  placeholder={'Select Interaction Code'}
                  isBusy={false}
                  selector="_id"
                  displayKey="interaction_name"
                  dropdownItems={interactions}
                  selectedItem={
                    interactions.find(x => x._id == formValues.interaction_id)?.interaction_name
                  }
                  onClick={item => {
                    _onInputChange({ interaction_id: item._id })
                  }}
                />
                <DropdownButtonComponent
                  theme={'form'}
                  isBusy={false}
                  title="Venue"
                  placeholder="Select Venue"
                  selector="_id"
                  displayKey="name"
                  dropdownItems={venues}
                  selectedItem={venues.find(x => x._id == formValues.venue_id)?.name}
                  onClick={item => {
                    _onInputChange({ venue_id: item._id })
                  }}
                />
              </div>
            </div>
            <hr className="text-gray" />

            <div className="w-full flex-col gap-2">
              <InputComponent
                size="lg"
                theme="form"
                title="General notes"
                value={formValues.general_notes} //TODO: Chris to advise warning_letter || general_notes
                onChange={general_notes => _onInputChange({ general_notes })}
              />
              <div className="flex flex-row justify-between gap-2 py-4">
                <div className="w-full rounded-lg">
                  <div className="bg-gray flex justify-center p-2">Attendance Consequences</div>
                  <div className="bg-card1 flex flex-col justify-start items-start p-2 gap-2">
                    <CircleIconText
                      text="Not Present - Gets a dismissed after 3 mistakes (reason)"
                      className="text-red-600"
                    />
                    <CircleIconText
                      text="Late - Gets a warning for reason"
                      className="text-yellow"
                    />
                    <CircleIconText text="Present - No consequences" className="text-green" />
                  </div>
                </div>
                <div className="w-full">
                  <div className="bg-gray flex justify-center p-2">KPIs to be met</div>
                  <div className="bg-card1 flex flex-col justify-start items-start p-2 gap-2">
                    <CircleIconText text="0-49 %" className="text-red-600" />
                    <CircleIconText text="50-69% orange" className="text-orange" />
                    <CircleIconText text="70-100% green" className="text-green" />
                  </div>
                </div>
              </div>
            </div>
            <hr className="text-gray" />

            <AttendanceTable
              users={users}
              payloadCallBack={handleLearners}
              formValues={formValues}
              program={selectedProgram}
            ></AttendanceTable>

            <div className="flex flex-row justify-center items-center w-full gap-4">
              <ButtonComponent
                title="Save"
                icon={faSave}
                onClick={_saveRegister}
                isBusy={false}
                type="primary"
              />
            </div>
          </div>
        </div>
      </div>
      <Form
        open={showAttendanceForm}
        setShow={setShowAttendanceForm}
        onClick={handleAttendanceForm}
      />
    </TableContentWrapper>
  )
}

const mapStateToProps = state => {
  return {
    learnerState: state.learnerState,
    venueState: state.venueState,
    notificationState: state.notificationState,
    programsState: state.programsState,
    facilitatorState: state.facilitatorState,
    userState: state.userState
  }
}

export default connect(mapStateToProps)(VolunteerDays)
