import { faCheck, faCross, faTimes } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import './alert.scss'
import { Transition } from 'react-transition-group'
import { useRef } from 'react'

const duration = 500

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0
}

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 }
}

const AlertComponent = props => {
  const { success = false, message, show = false, className } = props
  const nodeRef = useRef(null)
  const [showAlert, setShowAlert] = useState(false)

  useEffect(() => {
    setShowAlert(show)
  }, [show])

  useEffect(() => {
    if (showAlert) {
      const timeout = setTimeout(() => {
        setShowAlert(false)
      }, 3000)

      return () => clearTimeout(timeout)
    }
  }, [showAlert])

  return (
    <div className={className}>
      <Transition in={showAlert} timeout={300} unmountOnExit ref={nodeRef}>
        {state => (
          <div
            ref={nodeRef}
            style={{
              ...defaultStyle,
              ...transitionStyles[state]
            }}
          >
            <div className="flex w-96 shadow-lg rounded-lg">
              <div
                className={`${
                  success ? 'bg-main-green' : 'bg-red-600'
                } py-4 px-6 rounded-l-lg flex items-center`}
              >
                <FontAwesomeIcon
                  icon={success ? faCheck : faTimes}
                  color="white"
                  size="lg"
                ></FontAwesomeIcon>
              </div>
              <div className="px-4 py-6 bg-white flex border-gray rounded-r-lg justify-between items-center w-full border-l-transparent">
                <div className="text-sm">{message}</div>
                <button>
                  <FontAwesomeIcon icon={faTimes} size="lg"></FontAwesomeIcon>
                </button>
              </div>
            </div>
          </div>
        )}
      </Transition>
    </div>
  )
}

export default AlertComponent
