export const SET_MAIN_STATE = 'SET_MAIN_STATE'
export const SET_IMAGES_STATE = 'SET_IMAGES_STATE'

export const SET_USERS_STATE = 'SET_USERS_STATE'
export const SET_DEVICES_STATE = 'SET_DEVICES_STATE'
export const SET_CROPS_STATE = 'SET_CROPS_STATE'
export const SET_CROPS_TYPE_STATE = 'SET_CROP_TYPES_STATE'
export const SET_PROJECTS_STATE = 'SET_PROJECTS_STATE'
export const SET_ACTIVITIES_STATE = 'SET_ACTIVITIES_STATE'
export const SET_FARMS_STATE = 'SET_FARMS_STATE'
export const UPDATE_FARM_STATE = 'UPDATE_FARM_STATE'

export const SET_PROJECT_STATE = 'SET_PROJECT_STATE'
export const SET_USER_STATE = 'SET_USER_STATE'
export const SET_LEARNER_STATE = 'SET_LEARNER_STATE'
export const SET_FACILITATOR_STATE = 'SET_FACILITATOR_STATE'
export const SET_RECRUITOR_STATE = 'SET_RECRUITOR_STATE'
export const EDIT_LEARNER_STATE = 'EDIT_LEARNER_STATE'
export const EDIT_RECRUITOR_STATE = 'EDIT_RECRUITOR_STATE'
export const EDIT_FACILITATOR_STATE = 'EDIT_FACILITATOR_STATE'
export const UPDATE_FACILITATOR_STATE = 'UPDATE_FACILITATOR_STATE'
export const UPDATE_LEARNER_STATE = 'UPDATE_LEARNER_STATE'
export const UPDATE_RECRUITOR_STATE = 'UPDATE_RECRUITOR_STATE'

export const SET_BLOGS_STATE = 'SET_BLOGS_STATE'
export const SET_TASK_STATE = 'SET_TASK_STATE'
export const EDIT_TASK_STATE = 'EDIT_TASK_STATE'
export const UPDATE_TASK_STATE = 'UPDATE_TASK_STATE'
export const SET_CATEGORIES_STATE = 'SET_CATEGORIES_STATE'
export const UPDATE_CATEGORIES_STATE = 'UPDATE_CATEGORIES_STATE'

export const SET_PROGRAM_STATE = 'SET_PROGRAM_STATE'
export const EDIT_PROGRAM_STATE = 'EDIT_PROGRAM_STATE'
export const CREATE_PROGRAM_STATE = 'CREATE_PROGRAM_STATE'
export const UPDATE_PROGRAM_STATE = 'UPDATE_PROGRAM_STATE'

export const SET_STOPLIGHT_STATE = 'SET_STOPLIGHT_STATE'
export const SAVE_STOPLIGHT_STATE = 'SAVE_STOPLIGHT_STATE'
export const DELETE_STOPLIGHT_STATE = 'DELETE_STOPLIGHT_STATE'
export const RESET_STOPLIGHT_STATE = 'RESET_STOPLIGHT_STATE'

export const SET_GREENLIGHT_STATE = 'SET_GREENLIGHT_STATE'
export const SAVE_GREENLIGHT_STATE = 'SAVE_GREENLIGHT_STATE'
export const DELETE_GREENLIGHT_STATE = 'DELETE_GREENLIGHT_STATE'
export const RESET_GREENLIGHT_STATE = 'RESET_GREENLIGHT_STATE'

export const SET_VENUES_STATE = 'SET_VENUES_STATE'
export const SAVE_VENUES_STATE = 'SAVE_VENUES_STATE'

export const SET_APPLICATION_STATE = 'SET_APPLICATION_STATE'
export const RESET_APPLICATION_STATE = 'RESET_APPLICATION_STATE'

export const SET_MODULES_STATE = 'SET_MODULES_STATE'

export const SET_CURRICULUM_STATE = 'SET_CURRICULUM_STATE'
export const SAVE_CURRICULUM_STATE = 'SAVE_CURRICULUM_STATE'
export const RESET_CURRICULUM_STATE = 'RESET_CURRICULUM_STATE'

export const SET_GROUP_STATE = 'SET_GROUP_STATE'
export const EDIT_GROUP_STATE = 'EDIT_GROUP_STATE'
export const UPDATE_GROUP_STATE = 'UPDATE_GROUP_STATE'

export const SET_FILE_UPLOAD_STATE = 'SET_FILE_UPLOAD_STATE'
export const RESET_FILE_UPLOAD_STATE = 'RESET_FILE_UPLOAD_STATE'

export const SET_NOTIFICATION = 'SET_NOTIFICATION'
export const RESET_NOTIFICATION = 'RESET_NOTIFICATION'

export const SET_VENUE_STATE = 'SET_VENUE_STATE'


export const EDIT_ENTITY_STATE = 'EDIT_ENTITY_STATE'
export const SET_ENTITY_STATE = 'SET_ENTITY_STATE'
export const UPDATE_ENTITY_STATE = 'UPDATE_ENTITY_STATE'

export const SET_ALERT = 'SET_ALERT'
export const RESET_ALERT = 'RESET_ALERT'
