import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LabelControl from "../label/label";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import TextInputControl from "../textinput/textinput";
import { faFilePdf } from "@fortawesome/pro-light-svg-icons";

const FileInputControl = (props) => {

  // console.log("1",props);
  const {
    title,
    placeholder = "Upload a file",
    onChange,
    style,
    showCheck,
    theme = "dark",
  } = props;

  //Did mount...
  useEffect(() => {}, []);

  const [isSelected, setIsSelected] = useState(false)
  const [fileName, setFileName] = useState('')

  //Will unmount...
  useEffect(() => {
    return () => {
      console.log("Cleaning up...");
    };
  }, []);

  const randomNum = Math.floor(Math.random() * 100) + 1;


  return (
    <div className="flex-1">
      {
        isSelected? 
        <TextInputControl
            title="File Attached"
            disabled={true}
            value={fileName}
            icon={faFilePdf}
        />
        : 
        <div>
            <LabelControl title={title} theme={theme} />
            <input
                id={`file-upload-${randomNum}`}
                name="file-upload"
                type="file"
                // className="sr-only"
                onChange={(e) => {
                    setIsSelected(true)
                    onChange(e.target.files[0]);
                    setFileName(e.target.files[0].name)
                }}
            />
        </div> 
      }
      </div>
      );
    };
    export default FileInputControl;
