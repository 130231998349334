import {
  EDIT_LEARNER_STATE,
  SET_LEARNER_STATE,
  UPDATE_LEARNER_STATE
} from '../../constants/redux_types'

export function learnerStateReducer(
  state = {
    learnersLoaded: false,
    learners: []
  },
  action
) {
  switch (action.type) {
    case SET_LEARNER_STATE:
      return {
        ...state,
        ...action.payload
      }

    case EDIT_LEARNER_STATE:
      const _learners = state.learners.map(x => {
        if (x._id === action.payload._id) {
          return action.payload
        } else {
          return x
        }
      })

      return {
        ...state,
        learners: _learners
      }
    case UPDATE_LEARNER_STATE:
      return {
        ...state,
        learners: [action.payload, ...state.learners]
      }
    default:
      return state
  }
}
