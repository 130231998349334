import { setApplicationState } from '../actions/application'
import {
  accept_application,
  reject_application,
  all_applications,
  save_application
} from '../api/calls'
import { initialPageNumber, itemsPerPage } from '../constants/constants'
import { resetAlert, setAlert } from './alert'

export const saveApplication = data => {
  return dispatch => {
    save_application(data)
      .then(res => {
        if (res.data.success) {
          dispatch(
            setAlert({
              show: true,
              success: true,
              message: 'You have applied successfully.'
            })
          )
        } else {
          dispatch(
            setAlert({
              show: true,
              success: false,
              message: 'The application was unsuccessful.'
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          setAlert({
            show: true,
            success: false,
            message: 'Fatal Error. Something went wrong.'
          })
        )
      })
    dispatch(resetAlert())
  }
}

export const allApplications = (pageNumber = initialPageNumber, search = '') => {
  //   const filters = `page=${pageNumber}&limit=${itemsPerPage}&search=${search}`
  const filters = `page=${pageNumber}&search=${search}`

  return dispatch => {
    all_applications(filters)
      .then(res => {
        if (res.data.success) {
          dispatch(
            setApplicationState({
              applications: res.data.content,
              applicationsLoaded: true
            })
          )
        }
      })
      .catch(error => {})
  }
}

export const acceptApplication = application => {
  return dispatch => {
    accept_application(application._id)
      .then(res => {
        if (res.data.success) {
          dispatch(
            setAlert({
              show: true,
              success: true,
              message: 'The application was successfully accepted.'
            })
          )
        } else {
          dispatch(
            setAlert({
              show: true,
              success: false,
              message: 'The application could not be accepted.'
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          setAlert({
            show: true,
            success: false,
            message: error.message
          })
        )
      })

    dispatch(resetAlert())
  }
}

export const rejectApplication = application => {
  return dispatch => {
    reject_application(application._id)
      .then(res => {
        if (res.data.success) {
          dispatch(
            setAlert({
              show: true,
              success: true,
              message: 'The application was successfully rejected.'
            })
          )
        } else {
          dispatch(
            setAlert({
              show: true,
              success: false,
              message: 'The application could not be rejected.'
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          setAlert({
            show: true,
            success: false,
            message: error.message
          })
        )
      })
    dispatch(resetAlert())
  }
}
