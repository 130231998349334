import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { setGreenlightState } from '../../actions'
import { deleteGreenlightSurvey, getAllGreenLightSurveys } from '../../asyncActions'
import { actionButtonItems, CONFIRM_DELETE_MSG } from '../../constants/constants'
import ButtonControl from '../../controls/button/button'
import TableControl from '../../controls/table/table'
import TableContentWrapper from '../../controls/tableContentWrapper/tableContentWrapper'
import { selectNotificationState } from '../notifications/selectors'
import { greenlightSurveyListColumns } from './constants'
import { selectGreenlightSurveys, selectGreenlightSurveysLoaded } from './selectors'
import { MyTableControl } from '../../controls/myTableControl/myTableControl'
import ButtonComponent from '../customComponents/button'
import { faPen } from '@fortawesome/pro-light-svg-icons'


const GreenlightSurveys = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [selectedGreenlightSurvey, setSelectedGreenlightSurvey] = useState(false)
  const greenlightSurveysLoaded = useSelector(selectGreenlightSurveysLoaded)
  const greenlightSurveys = useSelector(selectGreenlightSurveys)
  const notificationData = useSelector(selectNotificationState)

  useEffect(() => {
    if (!greenlightSurveysLoaded) dispatch(getAllGreenLightSurveys())
  }, [greenlightSurveysLoaded])

  const _toggleAddGreenlightSurveys = () => {
    history.push('/dashboard/create-greenlight-survey')
  }

  const _onClickActionButton = (item, _) => {
    setSelectedGreenlightSurvey(item)

    if (_.id == 1) {
      history.push(`/dashboard/greenlight-survey-info/${item._id}`)
    }

    if (_.id == 2 && confirm(CONFIRM_DELETE_MSG + item.name)) {
      dispatch(deleteGreenlightSurvey(item))
      dispatch(
        setGreenlightState({
          greenlightSurveys: greenlightSurveys.filter(
            greenlightSurvey => greenlightSurvey._id !== item._id
          )
        })
      )
    }
  }

  return (
    <TableContentWrapper
      notificationData={notificationData}
      actionButtons={[
        <ButtonComponent
          title="Add a New Greenlight Survey"
          icon={faPen}
          isBusy={false}
          type="primary"
          onClick={_toggleAddGreenlightSurveys}
        />
      ]}
    >
      {/* ----------Table */}
      <MyTableControl
        title={'Greenlight Surveys'}
        search={{ show: true, search_by: 'name' }}
        showPagination={true}
        data={greenlightSurveys}
        displayKeys={greenlightSurveyListColumns}
        actionButton={{
          side: 'right',
          title: 'Details',
          items: [
            {
              name: 'View/Edit',
              id: 1
            },
            {
              name: 'Delete',
              id: 2
            }
          ],
          isDropdown: true,
          onClick: _onClickActionButton
        }}
      />
    </TableContentWrapper>
  )
}

export default GreenlightSurveys
