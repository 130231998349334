import React, { useState, useEffect, useRef, useCallback } from "react";

const LabelControl = props => {
    const { 
        title,
        onClick,
        theme = "dark",
        type = "normal",
    } = props;
    
    //Did mount...
    useEffect(() => {
        
    }, []);
    
    const labelType = {
        normal: {
            textSize: "text-md",
        },
        title: {
            textSize: "text-4xl",
        },
    };

    return (
        <div className={` ${onClick ? "text-blue-400-500 cursor-pointer hover:underline" : `${theme == "light"? "text-white": "text-grey"}`}
            ${labelType[type].textSize} 
            `}
            onClick={onClick}>
            {title}
        </div>
    )
}

export default LabelControl;