import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { getFilteredGroupDashboard } from '../../asyncActions'
import { selectGroupDashboard } from '../groups'
import { OILevels, averageGroupAttendanceColumns, performanceColumns } from './constants'
import DropdownButtonComponent from '../customComponents/dropdownButton'
import { faFilter, faTimes } from '@fortawesome/pro-light-svg-icons'
import { MyTableControl } from '../../controls/myTableControl/myTableControl'
import NotificationControl from '../../controls/notification/notification'
import CheckBoxControl from '../../controls/checkbox/checkbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { get_all_groups, get_all_programs } from '../../api/calls'
import { MultiSelectDropdown } from '../customComponents/multiselect/multiselect'
import TableContentWrapper from '../../controls/tableContentWrapper/tableContentWrapper'
import { GroupSummaryTable } from './components/groupSummaryTable'
import { LearnerSummaryTable } from './components/learnerSummaryTable'

const GroupDashboard2 = () => {
  const dispatch = useDispatch()
  const [notificationData, setNotificationData] = useState({
    title: '',
    messages: []
  })
  const [formValues, setFormValues] = useState()
  const [selectedOILevel, setSelectedOILevel] = useState()
  const groupDashboard = useSelector(selectGroupDashboard)
  const [groups, setGroups] = useState([])
  const [programs, setPrograms] = useState([])

  const performanceTableData =
    groupDashboard?.learners?.map(item => ({
      ...item.performance,
      learner: item?.learner,
      group: item?.group,
      attendance: item?.attendance_percentage,
      status: item?.status,
      program: groupDashboard?.program?.program_name,
      ...item.progress
    })) || []

  useEffect(() => {
    get_all_programs().then(res => {
      if (res.data.success) {
        setPrograms(res.data.content)
      }
    })

    get_all_groups().then(res => {
      if (res.data.success) {
        setGroups(res.data.content)
      }
    })
  }, [])

  const _onInputChange = value => {
    setFormValues({ ...formValues, ...value })
  }

  const _filterGroups = () => dispatch(getFilteredGroupDashboard(formValues))

  useEffect(() => {
    const groups = formValues?.group_ids ?? []
    console.log('formValues', formValues)

    if (!formValues?.group_ids) {
      setNotificationData({
        title: 'Warning',
        messages: ['At least one group must be selected first']
      })
      return
    }

    if (groups.length > 0) {
      _filterGroups()
      return
    }

    if (groups.length > 0 && formValues?.program_id != null) {
      _filterGroups()
      return
    }
  }, [formValues])

  //validate if group is selected
  const _validate = () => {
    if (!formValues?.group_ids) {
      setNotificationData({
        title: 'Warning',
        messages: ['At least one group must be selected first']
      })
      return false
    }
    return true
  }


  const clearFilters = () => {
    delete formValues?.program_id
    delete formValues?.oi_level
    delete formValues?.learner_status
    delete formValues?.attendance_range

    _filterGroups()
  }

  return (
    <TableContentWrapper title={'Group Dashboard'}>
      <div className="flex flex-row gap-4">
        <div className="flex flex-col gap-8 items-end">
          <div className=" flex-col flex justify-between w-80 h-full rounded-md shadow-sm bg-indigo p-2 gap-8">
            <div className="flex flex-col gap-2">
              <div className="flex flex-row gap-1">
                <span>
                  <FontAwesomeIcon size="sm" icon={faFilter} color="white" />
                  <div className="text-white">Filter</div>
                </span>
              </div>
              <DropdownButtonComponent
                placeholder={'Select Programme'}
                theme="light"
                displayKey="program_name"
                dropdownItems={programs ?? []}
                selectedItem={programs.find(x => x._id == formValues?.program_id)?.program_name}
                onClick={item => {
                  if (!_validate()) return
                  _onInputChange({ program_id: item._id })
                }}
              />

              <MultiSelectDropdown
                displayKey={'name'}
                title={'Please select groups'}
                options={groups}
                theme={'light'}
                onSelect={e => {
                  console.log(e)
                  if (e.length > 0) {
                    _onInputChange({
                      group_ids: e.map(e => {
                        return e._id
                      })
                    })
                  } else {
                    delete formValues?.group_ids
                    _onInputChange({ ...formValues })
                  }
                }}
              />

              <DropdownButtonComponent
                placeholder={'Select OI Level'}
                theme="light"
                displayKey="display"
                dropdownItems={OILevels}
                selectedItem={selectedOILevel}
                onClick={item => {
                  if (!_validate()) return
                  _onInputChange({ oi_level: item.display })
                  //   setSelectedOILevel(item.display)
                }}
              />

              <div className="flex flex-col w-full gap-4">
                <div className="flex flex-col gap-1">
                  <div className="font-extrabold text-white text-md">Learner Status</div>
                  <div className="flex flex-col w-full">
                    <CheckBoxControl
                      label="ACTIVE"
                      theme="dark"
                      name="learner_status"
                      checked={formValues?.learner_status == 'ACTIVE'}
                      value="ACTIVE"
                      onChange={e => {
                        if (!_validate()) return
                        if (formValues?.learner_status == 'ACTIVE') {
                          delete formValues.learner_status
                          _onInputChange({ ...formValues })
                        } else {
                          _onInputChange({ learner_status: e.target.value })
                        }
                      }}
                    />
                    <CheckBoxControl
                      label="INACTIVE"
                      theme="dark"
                      name="learner_status"
                      checked={formValues?.learner_status == 'INACTIVE'}
                      value="INACTIVE"
                      onChange={e => {
                        if (!_validate()) return
                        if (formValues?.learner_status == 'INACTIVE') {
                          delete formValues.learner_status
                          _onInputChange({ ...formValues })
                        } else {
                          _onInputChange({ learner_status: e.target.value })
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="flex flex-col gap-1">
                  <div className="font-extrabold text-white text-md">Attendance Ranges</div>
                  <div className="flex flex-col w-full">
                    <CheckBoxControl
                      label="50-80%"
                      theme="dark"
                      name="attendance_range"
                      checked={formValues?.attendance_range == '50-80'}
                      value="50-80"
                      onChange={e => {
                        if (!_validate()) return
                        if (formValues?.attendance_range == '50-80') {
                          delete formValues.attendance_range
                          _onInputChange({ ...formValues })
                        } else {
                          _onInputChange({ attendance_range: e.target.value })
                        }
                      }}
                    />
                    <CheckBoxControl
                      label="80-100%"
                      theme="dark"
                      name="attendance_range"
                      checked={formValues?.attendance_range == '80-100'}
                      value="80-100"
                      onChange={e => {
                        if (!_validate()) return
                        if (formValues?.attendance_range == '80-100') {
                          delete formValues.attendance_range
                          _onInputChange({ ...formValues })
                        } else {
                          _onInputChange({ attendance_range: e.target.value })
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {formValues?.group_ids && (
              <div
                className="h-30 flex justify-center items-center p-2 bg-red-500 rounded-full gap-3 cursor-pointer hover:bg-red-300"
                onClick={clearFilters}
              >
                <FontAwesomeIcon size="sm" icon={faTimes} color="white" />
                <div className="text-white">Clear</div>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-4 w-full">
          <GroupSummaryTable title="Group Summary" data={groupDashboard?.group_summary ?? []} />
          <LearnerSummaryTable
            title="Learner Attendance & Performance"
            data={groupDashboard?.learner_summary ?? []}
          />
        </div>
      </div>
      <NotificationControl notificationData={notificationData} />
    </TableContentWrapper>
  )
}

const mapStateToProps = state => {
  return {
    groupState: state.groupState,
    programsState: state.programsState
  }
}

export default GroupDashboard2
