import {
  EDIT_RECRUITOR_STATE,
  SET_RECRUITOR_STATE,
  UPDATE_RECRUITOR_STATE
} from '../constants/redux_types'

export const setRecruiterState = payload => {
  return {
    type: SET_RECRUITOR_STATE,
    payload: payload
  }
}

export const editRecruiterState = payload => {
  return {
    type: EDIT_RECRUITOR_STATE,
    payload: payload
  }
}

export const updateRecruiterState = payload => {
  return {
    type: UPDATE_RECRUITOR_STATE,
    payload: payload
  }
}
