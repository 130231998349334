import React, { useEffect, useState } from 'react';

import ReactPaginate from 'react-paginate';
import { itemsPerPage } from '../../constants/constants';
import { formatDate } from '../../utils/utils';
import DropdownControl from '../dropdown/dropdown';
import TableContentWrapper from '../tableContentWrapper/tableContentWrapper';
import TextInputControl from '../textinput/textinput';


const MIN_TEXT_LENGTH = 3;

const TableControl = props => {
  const { data, columns, actionButton, width, paginate, onPaginate, onSearchChange } = props 
  const [searchValue, setSearchValue] = useState('') 
  const [searchItem, setSearchItem] = useState('') 
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [cols, setCols] = useState(columns)
  const [filteredData, setFilteredData] = useState([])
  const [searchByItemValue, setSearchByItemValue] = useState({})

  const _onSearchChange = (query) => {
    setSearchItem(query)
  }

  const init = ()=>{
    if (data.length > 0) {
        setSearchByItemValue(columns[0])
        const endOffset = itemOffset + itemsPerPage;
        //   console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        setCurrentItems(data.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(data.length / itemsPerPage));
    }
  }

  useEffect(() => { 
    init()
  }, [itemOffset, itemsPerPage, data, paginate]);

  useEffect(()=>{
    setFilteredData(currentItems);
  },[currentItems, pageCount])
  
  useEffect(() => {
    if (actionButton) {
      setCols([...cols, ''])
    }
  }, [actionButton])

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
    onPaginate(event.selected + 1)
  };

  const actionButtonColumn = idx => {
    return (
      <td className="w-40 py-2">
        <DropdownControl
          isBusy={false}
          placeholder={actionButton.title}
          subItems={actionButton.items}
          hasFilter={actionButton.hasFilter}
          filterConfig={actionButton.filterConfig}
          type="primary"
          theme="light"
          displayKey="name"
          onClick={item => actionButton.onClick(currentItems[idx], item)}
        />
      </td>
    )
  }

  const rowStyle = `px-4 py-2 ${width == 'full' ? 'w-full' : 'w-1/' + (width || columns.length)}`

  useEffect(() => {
    filteredData.splice(0, filteredData.length)
    let list = [];

    if(searchItem.length > 0){
        if (data.length > 0) {
          if (Object.keys(searchItem).length != 0) {
            for (const item of data) {
                if (
                    item[`${searchByItemValue['key']}`].toString().toLowerCase().includes(searchItem.toLowerCase()) 
                        // e.user_surname.toString().toLowerCase().includes(searchItem.toLowerCase())
                      ) {
                        list.push(item)
                      }
            }
          } else {
            filteredData.splice(0, filteredData.length)
            setFilteredData([])
          }
        }
        setFilteredData(list)
    }else{
        init();
        setFilteredData([...currentItems]);
    }
  }, [searchItem])

  return (
    <div className="control-table">
        <div className="flex flex-row space-x-20 items-center">
          {props.title && <div className="pb-4 text-lg font-bold">{props.title}</div>}
          {props.search && (
            <div className="pb-4 flex-grow">
              <TextInputControl
                value={searchItem}
                placeholder={`Search by ${searchByItemValue['display']}`}
                // onChange={_onSearchChange}
                onChange={_onSearchChange}
                rounded="rounded-sm"
              />
              <div className='text-gray text-xs leading-normal'>To search by column, simply click on the column header.</div>
            </div>
          )}
        </div>
        <div className="bg-white shadow-md rounded my-6">
          <table className="min-w-max w-full table-auto">
            <thead className=" bg-main text-lg">
              <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                {cols.map((key, index) => (
                  <th onClick={()=>{
                    if(cols[index]){
                        setSearchByItemValue(cols[index])
                    }else{
                        setSearchByItemValue(columns[0])
                    }
                    }} key={index} className="py-3 px-6 text-left">
                    {key.display}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-light">
            {filteredData && filteredData.length > 0 ? (
                filteredData.map((row, index) => {
                  return (
                    <tr
                      key={index}
                      className="border-b border-gray-200 hover:bg-gray-100 border-dark"
                    >
                      {actionButton && actionButton.side == 'left' && actionButtonColumn(index)}
                      {columns &&
                        columns.map((column, idx) => {
                          return (
                            <td key={idx} className=" py-3 px-6 text-left">
                              {column.date ? (
                                formatDate(row[column['key']], column.unix, column.toLocal)
                              ) : column['link'] ? (
                                <a href={row[column['key']]} target="_blank" className="text-green">
                                  {column['link']}
                                </a>
                              ) : Array.isArray(row[column['key']]) ? (
                                row[column['key']].map((item, idx) => {
                                  return <div key={idx}>{item}</div>
                                })
                              ) : (
                                row[column['key']]
                              )}
                            </td>
                          )
                        })}
                      {actionButton && actionButton.side == 'right' && actionButtonColumn(index)}
                    </tr>
                  )
                })
              ) : (
                <tr className="flex w-full border-b border-dark p-4">This table is empty.</tr>
              )}
            </tbody>
          </table>
        </div>

        <ReactPaginate
          breakLabel="..."
          nextLabel="Next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="< Previous"
          activeClassName={'active'}
          containerClassName="pagination justify-content-center"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          renderOnZeroPageCount={null}
        />
    </div>
  )
}

export default TableControl