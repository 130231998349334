import { apiGet } from '../../api'

export const get_group_dashboard = () => {
  return apiGet({
    endpoint: `groups/dashboard`
  })
}


export const get_filtered_group_dashboard = (query, sort) => {
  const program_id_query = query?.program_id != null ? `&program_id=${query.program_id}` : ''
  const learner_status_query =
    query?.learner_status != null ? `&learner_status=${query.learner_status}` : ''
  const oi_level_query = query?.oi_level != null ? `&oi_level=${query.oi_level}` : ''
  const attendance_range =
    query?.attendance_range != null ? `&attendance_range=${query.attendance_range}` : ''

  return apiGet({
    endpoint: `groups/dashboard?group_id=${query?.group_ids?.join(
      ','
    )}${`${program_id_query}${learner_status_query}${oi_level_query}${attendance_range}`}`
  })
}
