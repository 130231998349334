import React, { useEffect, useState } from 'react'

import { connect, useDispatch } from 'react-redux'
import LoaderControl from '../../../controls/loader/loader'
import PopupControl from '../../../controls/popup/popup'
import { add_learner } from '../../../api/calls/add_learner'
import { setNotification } from '../../../asyncActions/notification'
import ButtonComponent from '../../customComponents/button'
import { faSave } from '@fortawesome/pro-light-svg-icons'
import MultiDropDownButtonComponent from '../../customComponents/multiDropdownButton/multiDropdownButton'

const AddLearnerToGroupComponent = ({
  learnerState,
  open = false,
  setShow,
  group,
  learners,
  learnersInGroup
}) => {
  const dispatch = useDispatch()

  const [isBusy, setIsBusy] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedLearners, setSelectedLearners] = useState([])
  const [formValues, setFormValues] = useState({})
  const [learnersList, setLearnersList] = useState([])

  //   console.log('learnersList', learnersList)

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  useEffect(() => {
    const list = []
    for (const a of learners) {
      for (const b of learnersInGroup) {
        if (a._id == b._id) {
          list.push(a)
        }
      }
    }

    const sanitizedList = learners.filter(e => !list.includes(e))
    setLearnersList(sanitizedList)
  }, [learners, learnersInGroup])

  return (
    <div>
      <PopupControl
        title="Adding A Learner"
        isOpen={open}
        close={{
          showClose: true,
          onClose: () => {
            setShow(false)
          }
        }}
      >
        <div className="flex flex-col gap-8">
          <div className="flex flex-row gap-4">
            <div className="w-72 flex flex-col gap-4">
              {learners.length > 0 ? (
                <MultiDropDownButtonComponent
                  title="Select Learners"
                  backgroundColor="bg-dark"
                  textColor="text-white"
                  subItems={learnersList}
                  displayKey={'fullName'}
                  selected={selectedLearners}
                  onSubmit={learner_ids => {
                    setSelectedLearners(learner_ids)
                    setFormValues({
                      group_id: group._id,
                      learner_ids: learner_ids.map(e => e._id)
                    })
                  }}
                />
              ) : // <MultiDropDownButtonComponent
              //   label={'Learners'}
              //   placeholder={'Select Learners'}
              //   theme={'dark'}
              //   dropdownItems={learnersList}
              //   displayKey={'user_name'}
              // />
              null}
              <div className="justify-center flex items-center">
                <ButtonComponent
                  title={'Save'}
                  icon={faSave}
                  isBusy={false}
                  type="primary"
                  onClick={v => {
                    add_learner({
                      group_id: formValues.group_id,
                      learner_ids: formValues.learner_ids
                    }).then(res => {
                      if (res.data.success) {
                        dispatch(
                          setNotification({
                            title: 'Success',
                            messages: ['Learner successfully added to group.']
                          })
                        )
                        setShow(false)
                      }
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </PopupControl>
      <LoaderControl show={isBusy} />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    learnerState: state.learnerState,
    mainState: state.mainState,
    userState: state.userState
  }
}

export default connect(mapStateToProps)(AddLearnerToGroupComponent)
