import {
  faHome,
  faUser,
  faBookReader,
  faStar,
  faBook,
  faTasks,
  faClipboardList,
  faBell,
  faBinoculars,
  faTally,
  faReceipt,
  faPersonBooth,
  faScrewdriver
} from '@fortawesome/pro-light-svg-icons'

export const navigationItems = [
  { icon: faHome, name: 'Home', path: 'home' },
  { icon: faUser, name: 'Learners', path: 'learners' },
  { icon: faBookReader, name: 'Programmes', path: 'programs' },
  { icon: faBookReader, name: 'Groups', path: 'groups' },
  { icon: faStar, name: 'Recruitment', path: 'recruitments' },
  { icon: faBook, name: 'Recruitment Summary', path: 'recruitment-summary' },
  { icon: faBook, name: 'Recruitment History', path: 'recruitment-history' },
  { icon: faStar, name: 'Facilitators', path: 'facilitators' },
  { icon: faTasks, name: 'Calendar', path: 'events' },
  { icon: faTasks, name: 'Application Portal', path: 'application-portal' },
  { icon: faClipboardList, name: 'Absentees', path: 'absentees' },
  //   { icon: faGraduationCap, name: 'Induction', path: 'induction' },

  //   { icon: faBell, name: 'Notifications', path: 'notifications' },
  { icon: faBell, name: 'Venues', path: 'venues' },
  { icon: faTasks, name: 'Greenlight Survey', path: 'greenlight-surveys' },
  { icon: faTasks, name: 'Group Dashboard', path: 'group-dashboard' },
  { icon: faTasks, name: 'Stoplight Results', path: 'stoplight-results' },
  { icon: faBinoculars, name: 'Performance Tracker', path: 'performance-tracker' },
  { icon: faTally, name: 'Entities', path: 'entities' },
  { icon: faReceipt, name: 'Reports', path: 'reports' },
  //   { icon: faPersonBooth, name: 'Profile', path: 'profile' },
  { icon: faScrewdriver, name: 'Configurations', path: 'configurations' }
]

export const adminNavigationItems = [
  { path: 'home' },
  { path: 'learners' },
  { path: 'programs' },
  { path: 'groups' },
  { path: 'facilitators' },
  { path: 'recruitments' },
  { path: 'recruitment-summary' },
  { path: 'recruitment-history' },
  { path: 'events' },
  { path: 'application-portal' },
  { path: 'absentees' },
  { path: 'induction' },
  { path: 'notifications' },
  { path: 'venues' },
  { path: 'greenlight-surveys' },
  { path: 'group-dashboard' },
  { path: 'stoplight-results' },
  { path: 'performance-tracker' },
  { path: 'entities' },
  { path: 'reports' },
  { path: 'configurations' }
]

export const facilitatorNavigationItems = [
  { path: 'home' },
  { path: 'learners' },
  { path: 'programs' },
  { path: 'groups' },
  { path: 'recruitments' },
  { path: 'recruitment-summary' },
  { path: 'recruitment-history' },
  { path: 'events' },
  { path: 'application-portal' },
  { path: 'absentees' },
  { path: 'induction' },
  { path: 'notifications' },
  { path: 'venues' },
  { path: 'group-dashboard' },
  { path: 'performance-tracker' },
  //   { path: 'profile' },
  { path: 'configurations' }
]

export const entityNavigationItems = [{ path: 'home' }, { path: 'profile' }]

export const learnerNavigationItems = [{ path: 'home' }, { path: 'entities' }]
