import {
  EDIT_FACILITATOR_STATE,
  SET_FACILITATOR_STATE,
  UPDATE_FACILITATOR_STATE
} from '../../constants/redux_types'

export function facilitatorStateReducer(
  state = {
    facilitatorsLoaded: false,
    facilitators: []
  },
  action
) {
  switch (action.type) {
    case SET_FACILITATOR_STATE:
      return {
        ...state,
        ...action.payload
      }

    case EDIT_FACILITATOR_STATE:
      const _facilitators = state.facilitators.map(x => {
        if (x._id === action.payload._id) {
          return action.payload
        } else {
          return x
        }
      })

      return {
        ...state,
        facilitators: _facilitators
      }

    case UPDATE_FACILITATOR_STATE:
      return {
        ...state,
        facilitators: [action.payload, ...state.facilitators]
      }
    default:
      return state
  }
}
