import moment from 'moment'
import React, { useState, useEffect } from 'react'
import Calendar from 'react-calendar'
import PopupControl from '../../../controls/popup/popup'
import { faSave } from '@fortawesome/pro-light-svg-icons'
import ButtonComponent from '../../customComponents/button'
import { setAlert } from '../../../asyncActions/alert'
import { useDispatch } from 'react-redux'

const SelectDate = ({ open, setShow, onClick }) => {
  const [date, setDate] = useState()
  const [formattedDate, setFormattedDate] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    if (open) {
      //set the date to null everytime this window is opened.
      setDate(null)
    }
  }, [open])

  useEffect(() => {
    if (date) {
      setFormattedDate(moment(date).format('YYYY-MM-DD'))
    }
  }, [date])

  return (
    <div>
      <PopupControl
        title="Select Date"
        isOpen={open}
        close={{
          showClose: true,
          onClose: () => {
            setShow(false)
          }
        }}
      >
        <div className="flex flex-col gap-8">
          <div className="flex justify-center">
            <div className="flex flex-col gap-12">
              <div className="px-2 flex flex-col">
                <Calendar
                  value={date}
                  onChange={v => {
                    setDate(v)
                  }}
                />
              </div>
              <div className="flex flex-row justify-center items-center">
                <ButtonComponent
                  title="Save"
                  icon={faSave}
                  isBusy={false}
                  type="primary"
                  onClick={v => {
                    if (formattedDate.length > 0) {
                      onClick(formattedDate)
                      setShow(false)
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </PopupControl>
    </div>
  )
}

export default SelectDate
