import {
  EDIT_FACILITATOR_STATE,
  SET_FACILITATOR_STATE,
  UPDATE_FACILITATOR_STATE
} from '../constants/redux_types'

export const setFacilitatorState = payload => {
  return {
    type: SET_FACILITATOR_STATE,
    payload: payload
  }
}

export const editFacilitatorState = payload => {
  return {
    type: EDIT_FACILITATOR_STATE,
    payload: payload
  }
}

export const updateFacilitatorState = payload => {
  return {
    type: UPDATE_FACILITATOR_STATE,
    payload: payload
  }
}
