import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { setNotification } from '../../../asyncActions'
import ButtonControl from '../../../controls/button/button'
import FileInputControl from '../../../controls/fileinput/fileinput'
import ModalFormWrapper from '../../../controls/modalFormWrapper/modalFormWrapper'
import PopupControl from '../../../controls/popup/popup'
import { csvUpload } from '../../../utils'
import { selectNotificationState } from '../../notifications/selectors'
import { dimensionsValidationConfig } from '../constants'
import FilesComponent from '../../customComponents/files'
import ButtonComponent from '../../customComponents/button'
import { faSave } from '@fortawesome/pro-light-svg-icons'

const ImportCsvModal = ({ open = false, setShow, onSuccess }) => {
  const dispatch = useDispatch()
  const notificationData = useSelector(selectNotificationState)
  const [uploadedFile, setUploadedFile] = useState('')
  const [formValues, setFormValues] = useState([])

  const _handleCsvUpload = file => {
    setUploadedFile(file)
    csvUpload({
      file,
      fieldsConfig: dimensionsValidationConfig,
      onSuccess: values => setFormValues(values),
      onError: () =>
        dispatch(
          setNotification({
            title: 'Validation Failed',
            messages: ['Invalid CSV file']
          })
        )
    })
  }

  const _onSubmitHandler = () => {
    onSuccess(formValues)
    setUploadedFile('')
    setShow(false)
  }

  return (
    <div>
      <PopupControl
        title="Import Excel Data"
        isOpen={open}
        close={{
          showClose: true,
          onClose: () => {
            setShow(false)
          }
        }}
      >
        <ModalFormWrapper notificationData={notificationData}>
          <div className="flex flex-col gap-4">
            <FilesComponent title="Attach Document" theme="light" onChange={_handleCsvUpload} />
          </div>
          <div className="flex justify-center items-center">
            <ButtonComponent
              title="Save"
              icon={faSave}
              isLoading={false}
              type="primary"
              onClick={_onSubmitHandler}
            />
          </div>
        </ModalFormWrapper>
      </PopupControl>
    </div>
  )
}

export default ImportCsvModal
