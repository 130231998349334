export const selectLearnersLoaded = ({ learnerState }) => {
  return learnerState.learnersLoaded
}
export const selectLearners = ({ learnerState }) => {
  return learnerState.learners
}
export const selectLearnersState = ({ learnerState }) => {
  return learnerState
}
export const selectLearnersWithIds = ({ learnerState }) => {
  return learnerState.learners.map(learners => ({
    id: learners.id,
    name: learners.user_name
  }))
}
