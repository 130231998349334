import React, { useEffect, useState } from 'react'

import moment from 'moment'
import { connect, useDispatch, useSelector } from 'react-redux'

import { editLearnerState } from '../../../actions/learner'
import { upload_file } from '../../../api/calls/upload_file'
import { saveTransaction, setNotification } from '../../../asyncActions'
import { DATE_FORMAT } from '../../../constants/constants'
import ButtonControl from '../../../controls/button/button'
import DateTimeControl from '../../../controls/datetime/datetime'
import FileInputControl from '../../../controls/fileinput/fileinput'
import ModalFormWrapper from '../../../controls/modalFormWrapper/modalFormWrapper'
import PopupControl from '../../../controls/popup/popup'
import TextInputControl from '../../../controls/textinput/textinput'
import { useFormActionHook } from '../../../hooks/useFormActionHook'
import { selectNotificationState } from '../../notifications/selectors'
import {
  initialTransactionValues,
  paymentTransactionTypes,
  paymentTypes,
  recurringTypes,
  transactionTypes,
  transactionValidationConfig
} from '../constants'
import DropdownButtonComponent from '../../customComponents/dropdownButton'
import FilesComponent from '../../customComponents/files'
import ButtonComponent from '../../customComponents/button'
import { faSave } from '@fortawesome/pro-light-svg-icons'
import InputComponent from '../../customComponents/input'
import DatePickerComponent from '../../customComponents/datepicker'

const AddTransactionComponent = ({ mainState, open = false, setShow, user }) => {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const notificationData = useSelector(selectNotificationState)
  const [formValues, setFormValues] = useState(initialTransactionValues)
  const { handleSubmit } = useFormActionHook(formValues, transactionValidationConfig)

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  useEffect(() => {
    if (user?._id) _onChangeFields({ user_id: user._id })
  }, [user])

  const _onChangeFields = value => {
    setFormValues({ ...formValues, ...value })
  }

  const _onSubmit = () =>
    handleSubmit({
      formAction: () => {
        user.transactions = [formValues, ...(user?.transactions || [])]
        dispatch(saveTransaction(formValues))
        dispatch(editLearnerState({ ...user }))
        // setShow(false)

        setFormValues(initialTransactionValues)
      },
      onFailedValidation: errors =>
        dispatch(
          setNotification({
            title: 'Validation Failed',
            messages: errors
          })
        )
    })

  return (
    <div>
      <PopupControl
        title="Add Transaction"
        isOpen={open}
        close={{
          showClose: true,
          onClose: () => {
            setShow(false)
          }
        }}
      >
        <ModalFormWrapper notificationData={notificationData}>
          <div className="w-72 flex flex-col gap-4 justify-center items-center">
            <DropdownButtonComponent
              title="Transaction Type"
              placeholder="Select Transaction Type"
              isLoading={false}
              type="primary"
              theme="dark"
              displayKey="name"
              dropdownItems={transactionTypes}
              selectedItem={
                transactionTypes.find(
                  x => x.id.toLowerCase() === formValues.transaction_type?.toLowerCase()
                )?.name
              }
              onClick={item => {
                _onChangeFields({
                  transaction_type: item.id
                })
              }}
            />
            <DropdownButtonComponent
              title="Type"
              placeholder="Select Type"
              isLoading={false}
              type="primary"
              theme="dark"
              displayKey="name"
              dropdownItems={paymentTransactionTypes}
              selectedItem={
                paymentTransactionTypes.find(
                  x => x.id.toLowerCase() === formValues.type?.toLowerCase()
                )?.name
              }
              onClick={item => {
                _onChangeFields({
                  type: item.id
                })
              }}
            />

            <InputComponent
              title="Amount"
              theme="dark"
              value={formValues?.value?.amount}
              onChange={amount =>
                _onChangeFields({
                  value: { amount }
                })
              }
            />

            <DropdownButtonComponent
              placeholder="Select Payment Type"
              title="Payment Type"
              isLoading={false}
              type="primary"
              theme="dark"
              displayKey="name"
              dropdownItems={paymentTypes}
              selectedItem={
                paymentTypes.find(
                  x => x.id.toLowerCase() === formValues.payment_type?.toLowerCase()
                )?.name
              }
              onClick={item => {
                _onChangeFields({
                  payment_type: item.id
                })
              }}
            />
            <DropdownButtonComponent
              placeholder="Select Recurring Type"
              title="Recurring Type"
              isLoading={false}
              type="primary"
              theme="dark"
              displayKey="name"
              dropdownItems={recurringTypes}
              selectedItem={
                recurringTypes.find(x => x.id.toLowerCase() === formValues.recurring?.toLowerCase())
                  ?.name
              }
              onClick={item => {
                _onChangeFields({
                  recurring: item.id
                })
              }}
            />

            <DropdownButtonComponent
              placeholder="Select Program"
              title="Program"
              isLoading={false}
              type="primary"
              theme="dark"
              displayKey="program_name"
              dropdownItems={user?.programs}
              selectedItem={
                user?.programs?.find(x => x?._id === formValues?.program_id)?.program_name
              }
              onClick={program => {
                _onChangeFields({
                  program_id: program._id
                })
              }}
            />

            <FilesComponent
              label="Proof of transaction"
              //   theme="light"
              //   placeholder={formValues.proof ? formValues.proof : 'Upload a file'}
              onChange={v => {
                upload_file({ file: v, name: v.name }).then(res => {
                  if (res.data.success) {
                    _onChangeFields({
                      proof: res.data.content.location,
                      etag: res.data.content.etag
                    })
                  }
                })
              }}
            />

            <DatePickerComponent
              // theme="dark"
              // type="date"
              title="Recurring Date"
              date={formValues.recurring_start}
              onChange={date => {
                _onChangeFields({
                  recurring_start: moment(date).format(DATE_FORMAT)
                })
              }}
            />

            <DatePickerComponent
              //   theme="dark"
              //   type="date"
              title="Date"
              date={formValues.date}
              onChange={date => {
                _onChangeFields({
                  date: moment(date).format(DATE_FORMAT)
                })
              }}
            />

            <div className="w-72 justify-center items-center flex">
              <ButtonComponent
                title={'Save'}
                icon={faSave}
                isLoading={false}
                type="primary"
                onClick={_onSubmit}
              />
            </div>
          </div>
        </ModalFormWrapper>
      </PopupControl>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    mainState: state.mainState,
    assetState: state.assetState
  }
}

export default connect(mapStateToProps)(AddTransactionComponent)
