import { combineReducers } from 'redux'
import {
  mainStateReducer,
  blogsStateReducer,
  userStateReducer,
  projectStateReducer,
  taskStateReducer,
  groupStateReducer,
  programsStateReducer,
  venueStateReducer,
  fileUploadStateReducer,
  facilitatorStateReducer,
  stoplightStateReducer,
  greenlightStateReducer,
  moduleStateReducer,
  curriculumStateReducer,
  learnerStateReducer,
  recruiterStateReducer,
  notificationStateReducer,
  alertStateReducer,
  setCategoriesStateReducer
} from './states'
import { applicationStateReducer } from './states/application'
import { entityStateReducer } from './states/entity'

export const reducers = combineReducers({
  mainState: mainStateReducer,
  blogsState: blogsStateReducer,
  userState: userStateReducer,
  projectState: projectStateReducer,
  taskState: taskStateReducer,
  groupState: groupStateReducer,
  programsState: programsStateReducer,
  venuesState: venueStateReducer,
  fileState: fileUploadStateReducer,
  facilitatorState: facilitatorStateReducer,
  stoplightState: stoplightStateReducer,
  greenlightState: greenlightStateReducer,
  applicationState: applicationStateReducer,
  moduleState: moduleStateReducer,
  venueState: venueStateReducer,
  curriculumState: curriculumStateReducer,
  learnerState: learnerStateReducer,
  recruiterState: recruiterStateReducer,
  notificationState: notificationStateReducer,
  alertState: alertStateReducer,
  entityState: entityStateReducer,
  categoriesState: setCategoriesStateReducer
})
