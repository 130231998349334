export const actionButtonItems = [
  { name: 'View', id: 1 },
  { name: 'Remove', id: 2 }
  //   { name: 'Delete', id: 3 }
]
export const absenteesColumns = [
  { key: 'name', display: 'Name' },
  { key: 'surname', display: 'Surname' },
  { key: 'cell_number', display: 'Cell Number' },
  { key: 'group', display: 'Group' },
  { key: 'interaction', display: 'Interaction' },
  { key: 'date', date: true, display: 'Date' },
  { key: 'oi_type', display: 'OI Type' }
]


export const dateValues = ['Day', 'Month', 'Week', 'Year']
