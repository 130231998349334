export const selectFacilitatorsState = ({ facilitatorState }) => {
  return facilitatorState
}

export const selectFacilitatorsLoaded = ({ facilitatorState }) => {
  return facilitatorState.facilitatorsLoaded
}

export const selectFacilitators = ({ facilitatorState }) => {
  return facilitatorState.facilitators
}

export const selectFacilitatorById =
  ({ facilitatorState }) =>
  id => {
    return facilitatorState.facilitators.find(facilitator => facilitator._id === id)
  }

export const selectFacilitatorsWithIds = ({ facilitatorState }) => {
  return facilitatorState.facilitators.map(facilitators => ({
    id: facilitators._id,
    name: facilitators.user_name
  }))
}
