import { faSave } from '@fortawesome/pro-light-svg-icons'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import {
  get_all_users,
  get_all_programs,
  get_categories,
  get_venues,
  save_event
} from '../../../api/calls'
import { get_task } from '../../../api/calls/tasks/get_task'
import LoaderControl from '../../../controls/loader/loader'
import NotificationControl from '../../../controls/notification/notification'
import PopupControl from '../../../controls/popup/popup'
import ButtonComponent from '../../customComponents/button'
import DropdownButtonComponent from '../../customComponents/dropdownButton'
import DatePickerComponent from '../../customComponents/datepicker'
import ViewComponent from './viewComponent'
import MultiDropDownButtonComponent from '../../customComponents/multiDropdownButton/multiDropdownButton'
import InputComponent from '../../customComponents/input'
import { useDispatch } from 'react-redux'
import { getAllTasks } from '../../../asyncActions'
import ConfirmDeletePopUp from './confirmDelete'

const EditEventComponent = ({ open, data, show, isEdit }) => {
  const [formValues, setFormValues] = useState({})
  const [notificationData, setNotificationData] = useState({})
  const [isBusy, setIsBusy] = useState(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [owners, setOwners] = useState([])
  const [categories, setCategories] = useState([])
  const [programs, setPrograms] = useState([])
  const [venues, setVenues] = useState([])
  const [owner, setOwner] = useState('')
  const [startDate, setStartDate] = useState()
  const [startTime, setStartTime] = useState()
  const [endDate, setEndDate] = useState()
  const [endTime, setEndTime] = useState()
  const [loading, setLoading] = useState(false)
  const [facilitators, setFacilitators] = useState([])
  const [admins, setAdmins] = useState([])
  const [selectedPrograms, setSelectedPrograms] = useState([])
  const [enableEdit, setEnableEdit] = useState(isEdit)
  const dispatch = useDispatch()

  //use effects
  useEffect(() => {
    if (data && open) {
      get_task(data._id).then(response => {
        if (response.data.success) {
          setFormValues({ ...response.data.content, id: response.data.content._id })
        } else {
          setNotificationData({
            title: 'Failed',
            messages: ['Failed to load task.']
          })
        }
      })
    }
    convertDateTimeFromISO(data.start_date, 'start')
    convertDateTimeFromISO(data.end_date, 'end')
    setFormValues(data)
    getSelectedProgrammes(data)
  }, [data])

  useEffect(() => {
    getFacilitators()
    getAdmins()
    getPrograms()
    getCategories()
    getVenues()
  }, [])

  useEffect(() => {
    setOwners([...facilitators, ...admins])
  }, [facilitators, admins])

  useEffect(() => {
    let dateString = startDate
    let timeString = startTime

    if (startDate && startTime) {
      let isoDateTimeString = new Date(dateString + 'T' + timeString + ':00').toISOString()

      setFormValues({
        ...formValues,
        start_date: isoDateTimeString
      })
    }
  }, [startDate, startTime])

  useEffect(() => {
    let dateString = endDate
    let timeString = endTime

    if (endTime && endDate) {
      let isoDateTimeString = new Date(dateString + 'T' + timeString + ':00').toISOString()

      setFormValues({
        ...formValues,
        end_date: isoDateTimeString
      })
    }
  }, [endDate, endTime])

  useEffect(() => {
    if (formValues.owner_id) {
      let user = owners.find(e => e._id == formValues.owner_id)
      if (user) {
        setOwner(`${user?.user_name} ${user?.user_surname}`)
      } else {
        setOwner('Please select user')
      }
    } else if (formValues.owner_ids) {
      const users = formValues?.owner_ids?.map(
        v =>
          `${facilitators.find(e => e._id == v)?.user_name} ${
            facilitators.find(e => e._id == v)?.user_surname
          }`
      )

      setOwner(users)
    }
  }, [formValues.owner_id, formValues.owner_ids])

  //methods
  const onFormValuesChange = value => {
    setFormValues({ ...formValues, ...value })
  }

  const getSelectedProgrammes = v => {
    let selected = []

    if (v.program_ids != null && v.program_ids.length > 0) {
      for (const program_id of v.program_ids) {
        let found = programs.find(e => e._id == program_id)
        selected.push(found)
      }
    }
    setSelectedPrograms(selected)
  }

  const getFacilitators = async () => {
    await get_all_users(`type_code=FACILITATOR-ACC`)
      .then(response => {
        if (response.data.success) {
          setFacilitators(response.data.content)
        }
      })
      .catch(error => {})
  }

  const getAdmins = async () => {
    await get_all_users(`type_code=ADMIN-ACC`)
      .then(response => {
        if (response.data.success) {
          //   setAdmins(response.data.content)
          setAdmins(response.data.content)
        }
      })
      .catch(error => {})
  }

  const getPrograms = async () => {
    await get_all_programs()
      .then(response => {
        if (response.data.success) {
          setPrograms(response.data.content)
        }
      })
      .catch(error => {})
  }

  const getCategories = async () => {
    await get_categories()
      .then(response => {
        if (response.data.success) {
          setCategories(response.data.content)
        }
      })
      .catch(error => {})
  }

  const getVenues = async () => {
    await get_venues()
      .then(response => {
        if (response.data.success) {
          setVenues(response.data.content)
        }
      })
      .catch(error => {})
  }

  const handleSaveEvent = payload => {
    const valid = _onValidateDateSelections()

    if (valid) {
      save_event(payload).then(response => {
        if (response.data.success) {
          setNotificationData({
            title: 'Success',
            messages: ['Event successfully edited.']
          })
          dispatch(getAllTasks())
          show(false)
        } else {
          setNotificationData({
            title: 'Problem',
            messages: [response.data.message]
          })
        }
        setLoading(false)
      })
    }
  }

  const convertDateTimeFromISO = (datetime, type) => {
    let dt
    let date
    let time
    if (type == 'start') {
      dt = moment(datetime)
      date = dt.format('YYYY-MM-DD')
      time = dt.format('HH:mm')
      setStartDate(date)
      setStartTime(time)
    }

    if (type == 'end') {
      dt = moment(datetime)
      date = dt.format('YYYY-MM-DD')
      time = dt.format('HH:mm')
      setEndDate(date)
      setEndTime(time)
    }
  }

  const _onStartDateTimeSelection = e => {
    onFormValuesChange({ start_date: e })
  }

  const _onEndDateTimeSelection = e => {
    onFormValuesChange({ end_date: e })
  }

  const _formatDateToDayTime = v => {
    return moment(v).format('LLLL')
  }

  const _onValidateDateSelections = () => {
    if (formValues.start_date || formValues.end_date) {
      if (formValues.start_date > formValues.end_date) {
        setNotificationData({
          title: 'Problem',
          messages: ['Start date cannot be greater than end date.']
        })
        return false
      } else {
        setLoading(true)
        return true
      }
    }
  }

  useEffect(() => {
    if (formValues.event_category_id) {
      let selectedCategory = categories.find(x => x._id == formValues.event_category_id)
      if (selectedCategory) {
        onFormValuesChange({
          event_category_id: selectedCategory._id,
          type: selectedCategory.category_name
        })
      }
    } else {
      onFormValuesChange({
        type: 'Custom'
      })
    }
  }, [formValues.event_category_id])

  return (
    <div>
      <PopupControl
        title={enableEdit ? 'Edit' : 'View'}
        isOpen={open}
        close={{
          showClose: open,
          onClose: () => {
            show(false)
          }
        }}
        showEdit={true}
        onEdit={() => {
          if (enableEdit) {
            setEnableEdit(false)
          } else {
            setEnableEdit(true)
          }
        }}
        showDelete={true}
        onDelete={() => {
          setShowConfirmDelete(true)
        }}
      >
        {enableEdit == false ? (
          <div className="flex flex-col gap-4 w-72 justify-center items-start">
            <ViewComponent title={'Event Name'} data={formValues.event_name} />
            <ViewComponent title={'Event Description'} data={formValues.event_description} />
            <ViewComponent title={'User(s) In Charge of This Event'} data={`${owner}`} />
            <ViewComponent
              title={'Name of Category'}
              data={categories.find(x => x._id == formValues.event_category_id)?.category_name}
            />
            <ViewComponent
              title={'Name(s) of Program'}
              data={formValues?.program_ids?.map(
                v =>
                  `${programs.find(e => e._id == v)?.program_name}${
                    programs.length >= 1 ? ', ' : ''
                  }`
              )}
            />
            <ViewComponent
              title={'Name of Venue'}
              data={venues?.find(x => x._id == formValues.venue_id)?.name}
            />
            <ViewComponent
              title={'Start Date and Time'}
              data={_formatDateToDayTime(formValues?.start_date)}
            />
            <ViewComponent
              title={'End Date and Time'}
              data={_formatDateToDayTime(formValues?.end_date)}
            />
          </div>
        ) : (
          <div className="flex flex-col gap-4 w-full justify-center items-start">
            <InputComponent
              theme="dark"
              title={'Event Name'}
              placeholder={'Please enter event name'}
              value={formValues?.event_name}
              rounded="lg"
              onChange={v =>
                onFormValuesChange({
                  event_name: v
                })
              }
            />
            <div className="flex w-full flex-col gap-1.5">
              <InputComponent
                theme="dark"
                title={'Event Description'}
                placeholder={'Please enter event description'}
                value={formValues?.event_description}
                rounded="lg"
                onChange={v =>
                  onFormValuesChange({
                    event_description: v
                  })
                }
              />
              <div className="italic text-sm text-white">{formValues.event_description}</div>
            </div>
            <DropdownButtonComponent
              title={'User In Charge of This Event'}
              placeholder={'Select User'}
              theme="dark"
              dropdownItems={owners}
              displayKey="user_name"
              selectedItem={owner}
              onClick={item => {
                setOwner(`${item?.user_name} ${item?.user_surname}`)
                onFormValuesChange({ owner_id: item._id })
              }}
            />

            <DropdownButtonComponent
              title={'Category'}
              placeholder={'Select Name of Category'}
              theme="dark"
              dropdownItems={categories}
              displayKey="category_name"
              selectedItem={
                categories.find(x => x._id == formValues.event_category_id)?.category_name
              }
              onClick={item => {
                onFormValuesChange({
                  event_category_id: item._id
                })
              }}
            />

            <div className="w-full">
              <MultiDropDownButtonComponent
                title="Programmes"
                theme={'dark'}
                subItems={programs}
                displayKey={'program_name'}
                selected={selectedPrograms}
                onSubmit={programs => {
                  setSelectedPrograms(programs)
                  onFormValuesChange({
                    program_ids: programs.map(e => {
                      return e.id
                    })
                  })
                }}
              />
            </div>

            <DropdownButtonComponent
              title={'Venue'}
              placeholder={'Select Venue'}
              theme="dark"
              dropdownItems={venues}
              displayKey="name"
              selectedItem={venues?.find(x => x._id == formValues.venue_id)?.name}
              onClick={item => {
                onFormValuesChange({ venue_id: item._id })
              }}
            />

            <DatePickerComponent
              title={'Select Start Date & Time'}
              date={formValues?.start_date}
              onChange={_onStartDateTimeSelection}
              isDateOnly={false}
            />
            <DatePickerComponent
              title={'Select End Date & Time'}
              date={formValues?.end_date}
              onChange={_onEndDateTimeSelection}
              isDateOnly={false}
            />
          </div>
        )}

        {enableEdit && (
          <div className="flex justify-center flex-col gap-2 w-full">
            <ButtonComponent
              title={'Save'}
              isLoading={loading}
              icon={faSave}
              onClick={() => {
                delete formValues._id
                handleSaveEvent(formValues)
              }}
            />
          </div>
        )}
      </PopupControl>
      <LoaderControl show={isBusy} />
      <NotificationControl notificationData={notificationData} />
      <ConfirmDeletePopUp
        show={showConfirmDelete}
        close={setShowConfirmDelete}
        event={data}
        onDeleteSuccess={e => {
          if (e) {
            dispatch(getAllTasks())
            show(false)
          }
        }}
      />
    </div>
  )
}

export default EditEventComponent
