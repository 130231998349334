import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setMainState } from '../../actions/main'
import { deleteLearner, getLearners } from '../../asyncActions/learners'
import ButtonControl from '../../controls/button/button'
import TableContentWrapper from '../../controls/tableContentWrapper/tableContentWrapper'
import { selectPaginateState } from '../../selectors/selectors'
import { selectNotificationState } from '../notifications/selectors'
import AddUserComponent from '../user/components/addUser'
import { selectLearners, selectLearnersLoaded, selectLearnersState } from './selectors'
import { MyTableControl } from '../../controls/myTableControl/myTableControl'
import ButtonComponent from '../customComponents/button'
import { faPen, faRedo } from '@fortawesome/pro-light-svg-icons'
import { use } from 'echarts'

const LearnerComponent = () => {
  const history = useHistory()
  const userType = 'LEARNER-ACC'
  const notificationData = useSelector(selectNotificationState)
  const [showAddRecruitment, setShowAddRecruitment] = useState(false)
  const dispatch = useDispatch()
  const learnerState = useSelector(selectLearnersState)
  const paginate = selectPaginateState(learnerState)

  const learnersLoaded = useSelector(selectLearnersLoaded)
  const learners = useSelector(selectLearners)
  const [selectedUser, setSelectedUser] = useState({})

  useEffect(() => {
    if (!learnersLoaded) {
      dispatch(getLearners())
    }
  }, [learnersLoaded])

  useEffect(() => {
    console.log('learners', learners)
  }, [learners])

  return (
    <TableContentWrapper
      notificationData={notificationData}
      actionButtons={[
        // <div className="flex-none mr-4">
        //   <ButtonComponent
        //     title="Manually Add User"
        //     icon={faPen}
        //     onClick={v => {
        //       setSelectedUser({})
        //       setShowAddRecruitment(true)
        //     }}
        //   />
        // </div>,
        <div className="flex-none w-40 gap-4">
          <ButtonComponent
            title="Refresh"
            icon={faRedo}
            onClick={v => {
              dispatch(getLearners())
            }}
          />
        </div>
      ]}
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-4">
          <MyTableControl
            title={'Learners'}
            search={{ show: true, search_by: 'user_name' }}
            showPagination={true}
            data={learners}
            displayKeys={[
              { key: 'user_name', display: 'Name' },
              { key: 'user_surname', display: 'Surname' },
              { key: 'user_cell_number', display: 'Phone Number' },
              { key: 'user_email', display: 'Email Address' },
              { key: 'status_code', display: 'Status' }
            ]}
            actionButton={{
              title: 'Details',
              isDropdown: 'true',
              side: 'right',
              items: [
                {
                  name: 'View',
                  id: 1
                },
                {
                  name: 'Edit',
                  id: 2
                },
                {
                  name: 'Delete',
                  id: 3
                }
              ],
              onClick: (item, x) => {
                setSelectedUser(item)
                if (x.id == 1) {
                  dispatch(
                    setMainState({
                      selectedUser: item
                    })
                  )
                  history.push(`learner-info/${item._id}/${userType}`)
                }

                if (x.id == 2) {
                  setShowAddRecruitment(true)
                }

                if (x.id == 3) {
                  dispatch(deleteLearner(item._id))
                }
              }
            }}
          />
        </div>

        <AddUserComponent
          open={showAddRecruitment}
          setShow={setShowAddRecruitment}
          type={userType}
          user={selectedUser}
        />
      </div>
    </TableContentWrapper>
  )
}

export default LearnerComponent
