import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import React, { useState } from 'react'
import TableContentWrapper from '../../../controls/tableContentWrapper/tableContentWrapper'
import ButtonComponent from '../../customComponents/button'
import DropdownButtonComponent from '../../customComponents/dropdownButton'
import { formatDate } from '../../../utils'

const ITEMS_PER_PAGE = 10
const defaultSearchValue = {
  show: false,
  search_by: ''
}
const groupColumns = [
  { key: 'name', display: 'Group Name' },
  { key: 'allocation_code', display: 'Allocation Code' },
  { key: 'facilitators', display: 'Facilitators' },
  { key: 'total_applicants', display: 'Learner Applicants (Amount)' },
  { key: 'createdAt', display: 'Created' }
]

export const GroupsTable = props => {
  const { title, data, actionButton = {}, mainButton, facilitators = [] } = props

  const [currentPage, setCurrentPage] = useState(1)

  const action = (idx, title) => {
    return (
      <>
        {actionButton?.isDropdown ? (
          <DropdownButtonComponent
            placeholder={title}
            dropdownItems={actionButton.items}
            displayKey="name"
            onClick={item => actionButton.onClick(data[idx], item)}
          />
        ) : actionButton?.isButton ? (
          <ButtonComponent
            title={actionButton?.title}
            placeholder={actionButton?.title}
            onClick={item => actionButton.onClick(data[idx], item)}
          />
        ) : null}
      </>
    )
  }

  const handleChangePage = page => {
    setCurrentPage(page)
  }

  return (
    <TableContentWrapper>
      <div className="flex flex-col gap-4">
        <div className="flex justify-between">
          <div className="flex-auto text-xl font-bold">{title}</div>
          <div className="flex justify-end items-end">
            <div className="flex min-w-min">{mainButton ? mainButton : null}</div>
          </div>
        </div>

        <Table className="flex min-w-full">
          <TableHead className="bg-main text-lg">
            <TableRow>
              {groupColumns.map((title, i) => (
                <TableCell key={i} className="text-gray-600 uppercase text-sm leading-normal">
                  {title.display}
                </TableCell>
              ))}
              <TableCell className="text-gray-600 uppercase text-sm leading-normal">
                Details
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="text-gray-600 text-sm font-normal">
            {data.length > 0 ? (
              data.map((item, idx) => {
                return (
                  <TableRow
                    key={(currentPage - 1) * ITEMS_PER_PAGE + item._id}
                    className={`border-b border-gray-200 hover:bg-gray-100 border-dark font-light h-8`}
                  >
                    <TableCell className="text-gray-600 uppercase text-xs leading-normal font-light">
                      {item.name}
                    </TableCell>
                    <TableCell className="text-gray-600 uppercase text-xs leading-normal font-light">
                      {item.allocation_code}
                    </TableCell>
                    <TableCell className="text-gray-600 uppercase text-xs leading-normal font-light flex flex-col">
                      {item?.facilitator_ids?.map(e => {
                        return <div>{facilitators.find(f => f._id === e)?.full_name}</div>
                      })}
                    </TableCell>
                    <TableCell className="text-gray-600 uppercase text-xs leading-normal font-light flex flex-col">
                      {item.total_applicants}
                    </TableCell>
                    <TableCell className="text-gray-600 uppercase text-xs leading-normal font-light flex flex-col">
                      {formatDate(item.createdAt)}
                    </TableCell>

                    <TableCell className="text-gray-600 w-60 uppercase text-xs leading-normal font-light">
                      {action((currentPage - 1) * ITEMS_PER_PAGE + idx, actionButton.title)}
                    </TableCell>
                  </TableRow>
                )
              })
            ) : (
              <TableRow>
                <TableCell>This table is empty</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </TableContentWrapper>
  )
}
