import React, { useState, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getFacilitators, getGroups } from '../../asyncActions'
import TableContentWrapper from '../../controls/tableContentWrapper/tableContentWrapper'
import { getArrayByObjKey } from '../../utils'
import { selectNotificationState } from '../notifications/selectors'
import { GroupsTable } from './components/groupsTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { get_unenrolled_recruits_for_group } from '../../api/calls/recruitment/get_unenrolled_recruits_for_group'
import BreadCrumbs from './components/breadCrumbs'
import { MyTableControl } from '../../controls/myTableControl/myTableControl'
import RecruitmentStatusTable from './components/recruitment_table'
import AddRecruitToGroupComponent from './components/addToGroup'

const RecruitmentComponent2 = ({ facilitatorState, groupState }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const notificationData = useSelector(selectNotificationState)
  const [showUnassignedRecruits, setShowUnassignedLearners] = useState(false)
  const [showAddToGroupPopup, setShowAddToGroupPopup] = useState(false)
  const [recruit, setRecruit] = useState({})
  const [buttonIndex, setButtonIndex] = useState(0)
  const [unenrolledRecruits, setUnenrolledRecruits] = useState([])

  const facilitators = facilitatorState?.facilitators?.map(e => {
    return {
      ...e,
      full_name: `${e?.user_name} ${e?.user_surname}`
    }
  })
  const groups = groupState?.groups

  useEffect(() => {
    if (!facilitatorState.facilitatorsLoaded) {
      dispatch(getFacilitators())
    }

    if (!groupState.groupsLoaded) {
      dispatch(getGroups())
    }
  }, [])

  const tableData =
    groups != null
      ? groups.map(group => ({
          ...group,
          facilitators: getArrayByObjKey(group.facilitators, 'full_name'),
          learners: getArrayByObjKey(group.learners, 'full_name')
        }))
      : {}

  const _onButtonIndexChange = index => {
    setButtonIndex(index)
  }

  //   const _getUnassignedRecruits = () => {
  //     get_unassigned_recruites().then(e => {
  //       if (e.data.success) {
  //         const recruits = e.data.content?.map(e => {
  //           return {
  //             ...e,
  //             ...e.user,
  //             // ...e.account,
  //             group: 'Unassigned',
  //             full_name: `${e?.user.user_name} ${e?.user.user_surname}`
  //           }
  //         })
  //         setRecruits(recruits)
  //         setShowUnassignedLearners(true)
  //         _onButtonIndexChange(1)
  //       }
  //     })
  //   }

  const _filterRecruitsOnSuccess = () => {
    const filteredRecruits = unenrolledRecruits.filter(e => e._id !== recruit._id)
    setUnenrolledRecruits(filteredRecruits)
  }

  return (
    // <div></div>
    <TableContentWrapper
      notificationData={notificationData}
      actionButtons={[
        <div className="flex flex-row gap-2">
          <div className="flex flex-row gap-2 items-center">
            <FontAwesomeIcon icon={faPen} />
            <div
              className={'text-black cursor-pointer hover:text-blue-400 hover:underline'}
              onClick={e => {
                history.push(`/student-application`)
              }}
            >
              {'Add New Recruit'}
            </div>
          </div>
          {/* |
          <div className="flex flex-row gap-2 items-center">
            <FontAwesomeIcon icon={faRedo} />
            <div
              className={'text-black cursor-pointer hover:text-blue-400 hover:underline'}
              onClick={e => {
                dispatch(getGroups())
              }}
            >
              {'Refresh'}
            </div>
          </div> */}
        </div>
      ]}
    >
      {/* ----------Table */}
      <div className="flex flex-row justify-between">
        <div className="flex flex-row gap-2">
          <BreadCrumbs
            title={'Back to Groups'}
            onClick={() => {
              setShowUnassignedLearners(false)
              _onButtonIndexChange(0)
              //   dispatch(getGroups())
            }}
            disabled={buttonIndex < 1 ? true : false}
          />
          <BreadCrumbs
            title={'Back to Learners'}
            onClick={() => {
              _onButtonIndexChange(1)
            }}
            disabled={buttonIndex <= 1 ? true : false}
          />
        </div>
        {/* <div
          className="text-blue-600 underline cursor-pointer hover:text-blue-400"
          onClick={_getUnassignedRecruits}
        >
          See Unassigned Learners
        </div> */}
      </div>
      {buttonIndex == 0 && (
        <GroupsTable
          title={'Recruitment Groups'}
          type={'groups'}
          facilitators={facilitators ?? []}
          data={tableData}
          actionButton={{
            title: 'Details',
            isDropdown: 'true',
            side: 'right',
            items: [
              {
                name: 'View',
                id: 1
              }
            ],
            onClick: (item, option) => {
              if (option.id == 1) {
                get_unenrolled_recruits_for_group(item._id).then(e => {
                  if (e.data.success) {
                    setUnenrolledRecruits(e.data.content ?? [])
                    setButtonIndex(1)
                  }
                })
              }
            }
          }}
        />
      )}
      {buttonIndex == 1 && (
        <MyTableControl
          title={`${
            unenrolledRecruits.length > 0 ? `${unenrolledRecruits[0].group_name} Recruits` : ''
          }`}
          search={{ show: true, search_by: '' }}
          showPagination={true}
          data={unenrolledRecruits.map(recruit => ({
            ...recruit,
            user_name: recruit.user?.user_name,
            user_surname: recruit.user?.user_surname,
            group: recruit.group_name,
            user_cell_number: recruit.user?.user_cell_number,
            user_email: recruit.user?.user_email
            // status_code: recruit.status_code
          }))}
          displayKeys={[
            { key: 'user_name', display: 'Name' },
            { key: 'user_surname', display: 'Surname' },
            { key: 'group', display: 'Group' },
            { key: 'user_cell_number', display: 'Phone Number' },
            { key: 'user_email', display: 'Email Address' }
            // { key: 'status_code', display: 'Status' }
          ]}
          actionButton={{
            side: 'right',
            title: `${showUnassignedRecruits ? 'Assign to Group' : 'View'}`,
            isButton: true,
            onClick: recruit => {
              setRecruit(recruit)

              if (showUnassignedRecruits) {
                setShowAddToGroupPopup(true)
              } else {
                setButtonIndex(2)
              }
            }
          }}
        />
      )}
      {buttonIndex == 2 && (
        <RecruitmentStatusTable
          tableTitle={'Recruitment'}
          learner={recruit}
          recruits={unenrolledRecruits}
          onFinished={() => {
            _onButtonIndexChange(0)
            dispatch(getGroups())
          }}
          onSuccess={recruit => {
            const filteredRecruits = unenrolledRecruits.filter(e => e._id !== recruit._id)
            setUnenrolledRecruits(filteredRecruits)
          }}
        />
      )}
      {showAddToGroupPopup && (
        <AddRecruitToGroupComponent
          open={showAddToGroupPopup}
          setShow={setShowAddToGroupPopup}
          learner={recruit}
          onSuccess={() => {
            _filterRecruitsOnSuccess()
          }}
        />
      )}
    </TableContentWrapper>
  )
}
const mapStateToProps = state => {
  return {
    facilitatorState: state.facilitatorState,
    mainState: state.mainState,
    recruiterState: state.recruiterState,
    learnerState: state.learnerState,
    paginateState: state.paginateState,
    groupState: state.groupState
  }
}

export default connect(mapStateToProps)(RecruitmentComponent2)
