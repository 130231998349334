import React from 'react'

import FullCalenderControl from '../../../controls/fullCalendar/fullCalendar'

const FullCalendarTab = ({ calendarEvents }) => {
  return (
    <div className="rounded-xl p-4 h-fit w-auto">
      <FullCalenderControl events={calendarEvents} />
    </div>
  )
}

export default FullCalendarTab
