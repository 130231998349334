import { save_billing } from '../api/calls/kyc/save_rate'
import { resetNotification, setNotification } from './notification'

export const saveBilling = billing => {
  return dispatch => {
    save_billing(billing)
      .then(res => {
        if (res.data.success) {
          dispatch(
            setNotification({
              title: 'Success',
              messages: ['You have successfully saved billing information.'],
              onConfirm: () => {
                dispatch(resetNotification())
              }
            })
          )
        }
      })
      .catch(error => {})
  }
}
