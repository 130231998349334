import React, { useState } from 'react'
import { connect } from 'react-redux'

import TitleCardControl from '../../controls/cards/titlecard'
import MapControl from '../../controls/map/map'
import TreeControl from '../../controls/tree/tree'

const NotificationsComponent = () => {
  const [markers, setMarkers] = useState([])
  const [notifications, setNotifications] = useState([
    {
      id: 1,
      day: 'Monday',
      notification_list: [
        {
          id: 1,
          title: 'First',
          message: 'First notification',
          time: '10 seconds ago'
        },
        {
          id: 2,
          title: 'Second',
          message: 'Second notification',
          time: '20 minutes ago'
        },
        {
          id: 3,
          title: 'Third',
          message: 'Third notification',
          time: '40 minutes ago'
        }
      ]
    },

    {
      id: 2,
      day: 'Tuesday',
      notification_list: [
        {
          id: 1,
          title: 'First',
          message: 'First notification',
          time: '10 seconds ago'
        },
        {
          id: 2,
          title: 'Second',
          message: 'Second notification',
          time: '20 minutes ago'
        },
        {
          id: 3,
          title: 'Third',
          message: 'Third notification',
          time: '40 minutes ago'
        }
      ]
    }
  ])

  const notificationBody = notification => {
    return (
      <div className="flex gap-2">
        <div className="mx-2">
          <div className="bg-green w-2 h-2 rounded-full relative top-1/2"></div>
        </div>
        <div className="">
          <div className="flex gap-4">
            <span className="text-blue-400 text-sm">{notification.title}</span>
            <span className="text-xs text-gray">{notification.time}</span>
          </div>
          <div className="text-sm">{notification.message}</div>
        </div>
      </div>
    )
  }

  const generateTreeStructure = () => {
    let data = []

    for (let notification of notifications) {
      let notificationBranch = {
        id: notification.id,
        name: `${notification.day}`,
        items: [],
        expanded: false
      }

      for (let item of notification.notification_list) {
        let info = {
          id: item.id,
          name: notificationBody(item),
          items: [],
          expanded: false,
          html: <p>{item.message}</p>
        }
        notificationBranch.items.push(info)
      }

      data.push(notificationBranch)
    }

    return data
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row">
        <div className="flex-auto text-3xl font-bold">Notification</div>
      </div>

      <div className="h-72 w-100">
        <MapControl markers={markers} />
      </div>
      <div className="">
        <TitleCardControl>
          <TreeControl
            title="Notifications"
            items={notifications.length > 0 ? generateTreeStructure() : []}
          />
        </TitleCardControl>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    mainState: state.mainState
  }
}

export default connect(mapStateToProps)(NotificationsComponent)
