import { RESET_NOTIFICATION, SET_NOTIFICATION } from '../constants/redux_types'

export const setNotificationState = payload => {
  return {
    type: SET_NOTIFICATION,
    payload: payload
  }
}

export const resetNotificationState = payload => {
  return {
    type: RESET_NOTIFICATION,
    payload: payload
  }
}
