import React from 'react'

import { CircleIconText } from '../controls/circleIconText/circleIconText'

//get averages
export const getIndicatorAverages = ({ data, lookup = '' }) => {
  const keys = lookup.split('.')
  const averages = []
  const newObj = {}
  for (var i = 0; i < data.length; i++) {
    keys.map(key => {
      newObj[key] = (newObj[key] || 0) + parseFloat(data[i][key])
    })
  }
  for (let key in newObj) {
    averages[key] = (newObj[key] / data.length).toFixed(1)
  }
  return averages
}

// find lookup values and wrap with element
export const getDataWithIndicatorsIcons = ({ data, iconConfig, lookup = '' }) => {
  const keys = lookup.split('.')

  const getIcon = (key, value) => {
    const configObj = iconConfig.find(icon => icon.id === key)
    return configObj ? (
      <CircleIconText text={value ?? configObj.name} className={configObj.color} />
    ) : (
      value
    )
  }

  return data.map(value => {
    const newObj = {}
    keys.map(key => {
      if (Array.isArray(value[key]))
        newObj[key] = value[key].map(_value => {
          const _key = keys[keys.length - 1]
          return { ..._value, [_key]: getIcon(_value[_key]) }
        })
      else if (value[key]) {
        newObj[key] = getIcon(key, value[key])
      } else {
        newObj[key] = getIcon(key)
      }
    })
    return { ...value, ...newObj }
  })
}

// https://rusty.atlassian.net/browse/VEB1-58
// finds all objects in array with lookup key
// create a new array of object with the lookup key
// repeat the same with other keys on the object with count prefixed
export const getQuestionsAndIndicators = ({ data, lookup }) => {
  let dataWithIndicators = []
  let count = 1
  for (let i = 0; i < data.length; i++) {
    let newObj = {}
    Object.keys(data[i]).forEach(key => {
      if (Array.isArray(data[i][key])) {
        for (let j = 0; j < data[i][key].length; j++) {
          Object.keys(data[i][key][j]).forEach(_key => {
            if (lookup == _key) {
              newObj[_key] = [...(newObj[_key] ?? []), data[i][key][j][_key]]
            } else {
              newObj[key] = [...(newObj[key] ?? []), `${count++}. ${data[i][key][j][_key]}`]
            }
          })
        }
      }
    })
    dataWithIndicators.push({ ...data[i], ...newObj })
  }
  return dataWithIndicators
}
