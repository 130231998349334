import { apiGet } from '../api'

export const get_catch_up_days = data => {
  console.log('get_catch_up_days', data)
  const { program_id, interaction_id, group_id } = data
  let query = ''

  if (program_id && interaction_id) {
    query = `?program_id=${program_id}&interaction_id=${interaction_id}`
    if (group_id) {
      query += `&group_id=${group_id}`
    }
  }

  return apiGet({
    endpoint: `attendance/get_catch_up_days${query}`
  })
}
