import { createBrowserHistory } from 'history'

import { editFacilitatorState } from '../actions'
import { editLearnerState } from '../actions/learner'
import { save_transaction } from '../api/calls'
import { all_transactions } from '../api/calls/documents/all_transactions'
import { setNotification } from './notification'

const browserHistory = createBrowserHistory()

export const getUserTransactions = (user, program) => {
  const programQuery = program?._id ? `&program_id=${program._id}` : ''

  return dispatch => {
    all_transactions(`user=${user._id}${programQuery}`)
      .then(res => {
        if (res.data.success) {
          dispatch(
            editLearnerState({
              ...user,
              transactions: res.data.content
            })
          )
        }
      })
      .catch(error => {})
  }
}
export const getFacilitatorTransactions = (user, program) => {
  const programQuery = program?._id ? `&program_id=${program._id}` : ''

  return dispatch => {
    all_transactions(`user=${user._id}${programQuery}`)
      .then(res => {
        if (res.data.success) {
          dispatch(
            editFacilitatorState({
              ...user,
              transactions: res.data.content
            })
          )
        }
      })
      .catch(error => {})
  }
}
export const saveTransaction = transaction => {
  return dispatch => {
    save_transaction(transaction)
      .then(res => {
        if (res.data.success) {
          dispatch(
            setNotification({
              title: 'Success',
              messages: ['You have successfully saved a transaction.']
            })
          )
        }
      })
      .catch(error => {})
  }
}
