import moment from 'moment'

// --- gets list of events for specific month
// --- extract start date and last date
const COMPARE_BY = 'month'

export const extractRangeFromEvents = (eventsData, selectedDate) => {
  const _initialValue = eventsData[0]
  const listOfEvents = eventsData.filter(date =>
    moment(date.start_date).isSame(selectedDate, COMPARE_BY)
  )

  const startDate = listOfEvents.reduce((a, b) => {
    return moment(b.start_date).isAfter(a.start_date) ? a : b
  }, _initialValue)

  const endDate = listOfEvents.reduce((a, b) => {
    return moment(b.end_date).isAfter(a.end_date) ? b : a
  }, _initialValue)

  return [new Date(startDate.start_date), new Date(endDate.end_date)]
}

export const extractCalendarViewItems = data => {
  const items = []
  data.map(item => {
    let id = 1
    items.push({
      id: id++,
      title: 'Calendar Event',
      start: item.start_date,
      end: item.end_date
    })
  })
  return items
}

// Get time interval 00:00 - *
export const getTimeList = () => {
  let time = []
  var hours, minutes
  for (var i = 0; i <= 1380; i += 60) {
    hours = Math.floor(i / 60)
    minutes = i % 60
    if (minutes < 10) {
      minutes = '0' + minutes // adding leading zero to minutes portion
    }
    time.push(hours + ':' + minutes)
  }
  return time
}
