import React, { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/pro-light-svg-icons'

const FilesComponent = props => {
  const ref = useRef(null)
  // 1. add state for tracking the selected files
  const [selectedFile, setSelectedFile] = useState()
  const { onChange, label, theme = 'light' } = props

  // 2. pass the click event to the hidden input element to trigger the file selection.
  const handleClick = () => {
    ref.current?.click()
  }

  // 3. convert FileList to File[]
  const handleChange = e => {
    const file = e.currentTarget.files[0]
    setSelectedFile(file)
    onChange(file)
  }

  const options = {
    light: { textColor: 'text-white' },
    dark: { textColor: 'text-black', theme: 'border-transparent' }
  }

  return (
    <div className={`flex flex-col w-full  ${options[theme]} overflow-ellipsis `}>
      <div className={`text-sm py-0 ${options[theme].textColor} overflow-ellipsis`}>{label}</div>
      <div
        onClick={handleClick}
        className={`flex flex-row w-full items-center gap-2 bg-theme text-white rounded-lg border border-white hover:bg-blue-400 cursor-pointer overflow-ellipsis`}
      >
        <div className="flex w-20 flex-col justify-center items-center border-r border-white rounded-l-lg p-2 bg-blue-400">
          <FontAwesomeIcon icon={faUpload} color="indigo" />
          <div className={`text-sm text-white`}>Pick File</div>
        </div>
        <input type="file" ref={ref} className="hidden" onChange={handleChange} />
        <div className="p-2 mt-2 bg-violet-50 text-xs text-white overflow-hidden whitespace-nowrap">
          {selectedFile != null ? selectedFile?.name : 'No File Selected.'}
        </div>
      </div>
    </div>
  )
}

export default FilesComponent
