import React, { useState } from 'react'
import ButtonControl from '../../../controls/button/button'
import PopupControl from '../../../controls/popup/popup'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import ButtonComponent from '../../customComponents/button'
import { faSave } from '@fortawesome/pro-light-svg-icons'

const CommentComponent = ({ open, setShow, onClick, data }) => {
  const [comment, setComment] = useState(data)
  const history = useHistory()

  return (
    <div>
      <PopupControl
        title="Add Comment"
        isOpen={open}
        close={{
          showClose: true,
          onClose: () => {
            setShow(false)
          }
        }}
      >
        <div className="flex flex-col gap-8">
          <div className="flex justify-center">
            <div className="grid grid-rows-2 space-y-10">
              <div className="px-2 flex flex-col">
                <p className="text-white text-sm">Comment</p>
                <textarea
                  id="comment"
                  value={comment}
                  rows={3}
                  className="border-2 rounded-lg text-white bg-gray p-2"
                  onChange={v => setComment(v.target.value)}
                ></textarea>
              </div>
              <div className="flex flex-row justify-center items-center">
                <ButtonComponent
                  title="Save"
                  icon={faSave}
                  isLoading={false}
                  type="primary"
                  onClick={v => {
                    onClick(comment)
                    setShow(false)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </PopupControl>
    </div>
  )
}

export default CommentComponent
