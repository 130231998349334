export const selectEntitiesLoaded = ({ entityState }) => {
  return entityState.entitiesLoaded
}
export const selectEntities = ({ entityState }) => {
  return entityState.entities
}
export const selectEntitiesState = ({ entityState }) => {
  return entityState
}

export const selectEntitiesWithIds = ({ entityState }) => {
  return entityState.entities.map(entities => ({
    id: entities.id,
    name: entities.user_name
  }))
}

export const selectEntityById =
  ({ entityState }) =>
  id => {
    return entityState.entities.find(e => e._id === id)
  }
