import React, { useEffect, useState } from 'react'

import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { setMainState } from '../../actions/main'
import { delete_facilitator } from '../../api/calls/users/delete_facilitator'
import { getFacilitators } from '../../asyncActions/facilitators'
import { initialPageNumber } from '../../constants/constants'
import LoaderControl from '../../controls/loader/loader'
import NotificationControl from '../../controls/notification/notification'
import TableControl from '../../controls/table/table'
import { selectPaginateState } from '../../selectors/selectors'
import { selectNotificationState } from '../notifications/selectors'
import AddUserComponent from '../user/components/addUser'
import { actionButtonItems, facilitatorsColumn } from '../user/constants'
import { selectFacilitators, selectFacilitatorsLoaded, selectFacilitatorsState } from './selectors'
import { setNotification } from '../../asyncActions/notification'
import { MyTableControl } from '../../controls/myTableControl/myTableControl'
import ButtonComponent from '../customComponents/button'
import { faPen, faRedo, faRepeat } from '@fortawesome/pro-light-svg-icons'

const FacilitatorComponent = ({ mainState }) => {
  const history = useHistory()
  const userType = 'FACILITATOR-ACC'

  const [showAddFacilitator, setShowAddFacilitator] = useState(false)

  const facilitatorState = useSelector(selectFacilitatorsState)
  const facilitators = useSelector(selectFacilitators)
  const facilitatorsLoaded = useSelector(selectFacilitatorsLoaded)
  const paginate = selectPaginateState(facilitatorState)

  const notificationData = useSelector(selectNotificationState)
  const [isBusy, setIsBusy] = useState(false)
  const dispatch = useDispatch()
  const [selectedUser, setSelectedUser] = useState({})

  useEffect(() => {
    if (!facilitatorsLoaded) dispatch(getFacilitators())
  }, [facilitatorsLoaded])

  const _onChangeDropdown = (item, x) => {
    setSelectedUser(item)

    if (x.id == 1) {
      dispatch(
        setMainState({
          selectedUser: item
        })
      )
      history.push(`facilitator-details/${item._id}`)
    }

    // if (x.id == 2) {
    //   setShowAddFacilitator(true)
    // }

    if (x.id == 2) {
      delete_facilitator(item._id).then(response => {
        if (response.data.success) {
          dispatch(
            setNotification({
              title: 'Success',
              messages: ['Facilitator Successfully Disabled.']
            })
          )
        }
      })
    }
  }

  const _onRefresh = _ => {
    dispatch(getFacilitators())
  }

  const _handleAddFacilitator = _ => {
    setSelectedUser({})
    setShowAddFacilitator(true)
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-end items-end">
        <div className="flex-none mr-4">
          <ButtonComponent
            title="Add New Facilitator"
            isBusy={false}
            type="primary"
            icon={faPen}
            onClick={_handleAddFacilitator}
          />
        </div>
        <div className="flex-none w-40 gap-4">
          <ButtonComponent
            title="Refresh"
            isBusy={false}
            type="primary"
            onClick={_onRefresh}
            icon={faRedo}
          />
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="bg-white shadow-md p-4">
          <MyTableControl
            title={'Facilitators'}
            search={{ show: true, search_by: '' }}
            showPagination={true}
            data={facilitators}
            displayKeys={facilitatorsColumn}
            actionButton={{
              title: 'Details',
              isDropdown: 'true',
              side: 'right',
              items: actionButtonItems,
              onClick: _onChangeDropdown
            }}
          />
        </div>
      </div>

      <LoaderControl show={isBusy} />

      <AddUserComponent
        open={showAddFacilitator}
        setShow={setShowAddFacilitator}
        type={userType}
        user={selectedUser}
      />
      <NotificationControl notificationData={notificationData} />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    mainState: state.mainState,
    facilitatorState: state.facilitatorState
  }
}

export default connect(mapStateToProps)(FacilitatorComponent)
