import { EDIT_GROUP_STATE, SET_GROUP_STATE, UPDATE_GROUP_STATE } from '../../constants/redux_types'

export function groupStateReducer(
  state = {
    groupsLoaded: false,
    groups: []
  },
  action
) {
  switch (action.type) {
    case SET_GROUP_STATE:
      return {
        ...state,
        ...action.payload
      }
    case EDIT_GROUP_STATE:
      const _groups = state.groups.map(x => {
        if (x._id === action.payload._id) {
          return action.payload
        } else {
          return x
        }
      })

      return {
        ...state,
        groups: _groups
      }
    case UPDATE_GROUP_STATE:
      return {
        ...state,
        groups: [action.payload, ...state.groups]
      }
    default:
      return state
  }
}
