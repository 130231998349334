import React from 'react'
import TitleCardControl from '../../../controls/cards/titlecard'
import MapControl from '../../../controls/map/map'
import { CURRENCY } from '../../../constants/constants'
import { formatDate } from '../../../utils/utils'
import CardControl from '../../learner/components/card'

const InformationBlock = ({ data }) => {
  return (
    <div className="flex flex-col gap-2">
      <CardControl title="Group Information">
        <div className="flex flex-col">
          <div className="flex pb-2 flex-col">
            <div className="font-extrabold font-sans text-xs italic">Group</div>
            <div className="font-extrabold text-sm">{data?.name ?? '-'}</div>
          </div>
          <div className="flex pb-2 flex-col">
            <div className="font-extrabold font-sans text-xs italic">Location</div>
            <div className="font-extrabold text-sm">{data?.location ?? '-'}</div>
          </div>
          <div className="flex pb-2 flex-col">
            <div className="font-extrabold font-sans text-xs italic">Description</div>
            <div className="font-extrabold text-sm">{data?.description ?? '-'}</div>
          </div>
          <div className="flex pb-2 flex-col">
            <div className="font-extrabold font-sans text-xs italic">Date Added</div>
            <div className="font-extrabold text-sm">{formatDate(data.createdAt) ?? '-'}</div>
          </div>
          <div className="flex pb-2 flex-col">
            <div className="font-extrabold font-sans text-xs italic">Added By</div>
            <div className="font-extrabold text-sm">{data?.user_name ?? '-'}</div>
          </div>
        </div>
      </CardControl>
      <CardControl title="Group Attendance Information">
        <div className="flex flex-col">
          <div className="flex py-2 flex-col">
            <div className="font-extrabold font-sans text-xs italic">
              <div>Average Attendace</div>
            </div>
            <div className="text-sm font-extrabold">
              {data?.attendance?.average_percentage != null
                ? `${data?.attendance?.average_percentage}%`
                : '-'}
            </div>
          </div>
          <div className="flex pb-2 flex-col">
            <div className="font-extrabold font-sans text-xs italic">
              <div>Human OI Level</div>
            </div>
            <div className="text-sm font-extrabold">{data?.attendance?.human_oi ?? '-'}</div>
          </div>
          {/* <div className="flex pb-2 flex-col">
            <div className="font-extrabold font-sans text-xs italic">
              <div>Business OI Level</div>
            </div>
            <div className="text-sm font-extrabold">{data?.attendance?.business_oi ?? '-'}</div>
          </div> */}
        </div>
      </CardControl>
    </div>
  )
}

export default InformationBlock
