export const selectModules = ({ moduleState }) => {
  return moduleState.modules
}

export const selectModulesLoaded = ({ moduleState }) => {
  return moduleState.modulesLoaded
}
export const selectModuleById =
  ({ moduleState }) =>
  id =>
    moduleState.modules.find(module => id === module._id)

export const selectModuleByProgramId =
  ({ moduleState }) =>
  programId =>
    moduleState.modules.filter(module => module.program_id === programId)
