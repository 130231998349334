import { getObjectsByIdName } from '../../utils'

export const extractByIds = data => ids => {
  if (!data) return []
  return getObjectsByIdName(data, ids)
}

export const extractProgramsSelection = programs => {
  return programs.map(program => ({
    value: program._id,
    name: program.program_name
  }))
}

export const extractLearnersIdsByGroupIds = (groups, lookup) => ids => {
  const items = []
  groups.map(_ => {
    if (ids.find(el => el == _._id)) {
      _[lookup].forEach(__ => items.push(__))
    }
  })
  return items
}
