import React from 'react'

import DropdownControl from '../../controls/dropdown/dropdown'
import TextInputControl from '../../controls/textinput/textinput'
import { getStringFromField } from '../../utils/utils'
import InputComponent from '../customComponents/input'

//TODO: refactor this
export const CreateControlFormFields = ({ initialValues, stateValues, onChange }) => {
  return (
    <>
      {initialValues.map((field, index) => {
        const defaultTitle = getStringFromField(field.label)
        const onChangeHandler = value => {
          onChange({
            ...stateValues,
            [field.name]: value
          })
        }
        const getSelectedItem = value => {
          if (field.props.subItems && value) {
            return field.props.subItems.find(item => item.id === value)
          }
        }
        switch (field.props.type) {
          case 'text':
            return (
              <InputComponent
                key={index}
                theme="dark"
                title={defaultTitle}
                placeholder={defaultTitle}
                value={stateValues[field.name] ?? field.value}
                onChange={onChangeHandler}
                {...field.props}
              />
            )
          case 'primary':
            return (
              <DropdownControl
                key={index}
                title={defaultTitle}
                isBusy={false}
                type="primary"
                theme="dark"
                selectedItem={getSelectedItem(stateValues[field.name] ?? field.value)}
                onClick={value => onChangeHandler(value.id)}
                {...field.props}
              />
            )
        }
      })}
    </>
  )
}
