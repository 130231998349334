import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { save_update_configurations } from '../../api/calls/configurations/save_update_configurations'
import TableContentWrapper from '../../controls/tableContentWrapper/tableContentWrapper'
import InputComponent from '../customComponents/input'
import ButtonComponent from '../customComponents/button'
import { faSave } from '@fortawesome/pro-light-svg-icons'
import { get_me } from '../../api/calls/me'
import { get_configurations } from '../../api/calls/configurations/get_configurations'
import CreateSms from './components/createSms'
import CreateEventCategory from './components/createEventCategory'
import AccordionControl from '../../controls/accordion/accordion'

const ConfigurationsComponent = () => {
  return (
    <TableContentWrapper>
      <div className="w-full ">
        <div className="flex w-full flex-col gap-8">
          <AccordionControl
            content={[
              {
                title: 'Recruitment SMS Configurations',
                subTitle:
                  'Configure the SMS that is sent to learners when they are accepted or rejected from a recruitment',
                content: <CreateSms />
              },
              {
                title: 'Event Category Configurations',
                subTitle: 'Configure the categories for events',
                content: <CreateEventCategory />
              }
            ]}
          />
        </div>
      </div>
    </TableContentWrapper>
  )
}

export default ConfigurationsComponent
