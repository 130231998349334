import {
  SET_CATEGORIES_STATE,
  UPDATE_CATEGORIES_STATE,
  EDIT_TASK_STATE,
  SET_TASK_STATE,
  UPDATE_TASK_STATE
} from '../../constants/redux_types'

export function taskStateReducer(
  state = {
    tasks: [],
    tasksLoaded: false,
    categoriesLoaded: false,
    setupEventOption: {},
    filterTasks: [],
    statuses: [
      {
        id: 1,
        status: 'To Do'
      },
      {
        id: 2,
        status: 'In Progress'
      },
      {
        id: 3,
        status: 'Complete'
      }
    ],
    categories: []
  },
  action
) {
  switch (action.type) {
    case SET_TASK_STATE:
      return {
        ...state,
        ...action.payload
      }
    case EDIT_TASK_STATE:
      const _tasks = state.tasks.map(x => {
        if (x._id === action.payload._id) {
          return action.payload
        } else {
          return x
        }
      })

      return {
        ...state,
        tasks: _tasks
      }
    case UPDATE_TASK_STATE:
      return {
        ...state,
        tasks: [action.payload, ...state.tasks]
      }

    case SET_CATEGORIES_STATE:
      return {
        ...state,
        ...action.payload
      }
    case UPDATE_CATEGORIES_STATE:
      return {
        ...state,
        categories: [action.payload, ...state.categories]
      }
    default:
      return state
  }
}

export function setCategoriesStateReducer(
  state = {
    categoriesLoaded: false,
    categories: []
  },
  action
) {
  switch (action.type) {
    case SET_CATEGORIES_STATE:
      return {
        ...state,
        ...action.payload
      }

    case UPDATE_CATEGORIES_STATE:
      return {
        ...state,
        categories: [action.payload, ...state.entities]
      }
    default:
      return state
  }
}
