export const venueColumns = [
  { key: 'name', display: 'Name' },
  { key: 'description', display: 'Description' },
  { key: 'city', display: 'City' }
]

export const venueValidationConfig = [
  { field: 'name', types: ['required'] },
  { field: 'description', types: ['required'] },
  { field: 'city', types: ['required'] },
  { field: 'address_line_1', types: ['required'] },
  { field: 'address_line_2', types: ['required'] },
  { field: 'area_code', types: ['required'] },
  { field: 'building', types: ['required'] },
  { field: 'room_number', types: ['required'] }
]

export const initialVenueValues = {
  name: '',
  description: '',
  city: '',
  address_line_1: '',
  address_line_2: '',
  area_code: '',
  building: '',
  room_number: ''
}

export const mockData = {
  ...initialVenueValues,
  results: [initialVenueValues, initialVenueValues]
}

export const actionButtonItems = [
  {
    name: 'View',
    id: 1
  },
  {
    name: 'Edit',
    id: 2
  },
  {
    name: 'Delete',
    id: 3
  }
]
