import React, { useEffect } from 'react'

import { faPlus } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const AddMultipleInputControl = ({ values, onChange, children }) => { 
  const onInputChange = (value, index ) => {
    const field = Object.keys(value)[0]
    const list = [...values]
    list[index][field] = value[field]
    onChange(list)
  }

  const onRemove = index => {
    const list = [...values]
    list.splice(index, 1)
    onChange(list)
  }

  const onAdd = () => {
    onChange([...values, {}])
  }

  useEffect(() => {
    if(values.length === 0) {
      onAdd()
    }
  }, [])
  
  return (
      <div className="">
        {values.map((x, i) => {
          return (
            <div key={i} className="">
              <div className={`${i == 1 ? 'pt-6' : ''}`}>
                {children(onInputChange, i)}
                {values.length !== 1  && i !== 0 && (
                   <div
                      onClick={_ => {
                           onRemove(i)
                      }}
                      className="shadow-md p-1 flex justify-end"
                    >
                       <div className="text-red-400 text-sm">Remove</div>
                  </div> 
                )}
              </div>
              <div className="flex justify-start">
                {values.length - 1 === i && ( 
                    <div
                      onClick={_ => {
                        onAdd()
                      }}
                      className="bg-card1 inline-block shadow-md p-3 mt-2 rounded-full"
                    >
                      <FontAwesomeIcon className="text-white" icon={faPlus} /> 
                  </div>
                )}
              </div>
            </div>
          )
        })}
    </div>
  )
}
export default AddMultipleInputControl
