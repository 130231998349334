import { createBrowserHistory } from 'history'

import { setStoplightState } from '../actions/stoplights'
import { delete_survey, get_all_surveys, get_stoplight, save_survey } from '../api/calls/stoplight'
import { resetNotification, setNotification } from './notification'

const browserHistory = createBrowserHistory()

export const getAllStopLightSurveys = () => {
  return dispatch => {
    get_all_surveys()
      .then(res => {
        if (res.data.success) {
          dispatch(
            setStoplightState({
              stoplights: res.data.content
            })
          )
        }
      })
      .catch(error => {})
  }
}

export const getStoplightSurvey = stoplight => {
  return dispatch => {
    get_stoplight(`id=${stoplight._id}`)
      .then(res => {
        if (res.data.success) {
          dispatch(
            setStoplightState({
              stoplight: res.data.content
            })
          )
        }
      })
      .catch(error => {})
  }
}

export const saveStoplightSurvey = stoplight => {
  return dispatch => {
    save_survey(stoplight)
      .then(res => {
        if (res.data.success) {
          dispatch(
            setNotification({
              title: 'Success',
              messages: ['You have successfully saved a survey.'],
              onConfirm: () => {
                dispatch(resetNotification())
                browserHistory.goBack()
              }
            })
          )
        } else {
          dispatch(
            setNotification({
              title: 'Failure',
              messages: ['Unable to save survey.']
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          setNotification({
            title: 'Failure',
            messages: [error.message]
          })
        )
      })
  }
}

export const deleteStoplightSurvey = stoplight => {
  return dispatch => {
    delete_survey(`id=${stoplight._id}`)
      .then(res => {
        if (res.data.success) {
          dispatch(
            setStoplightState({
              stoplight: stoplight
            })
          )
        }
      })
      .catch(error => {})
  }
}
