import React, { useEffect, useRef, useState } from 'react'
import { LoadScript } from '@react-google-maps/api'
import { Redirect, Route, Switch } from 'react-router-dom'
import LoaderGlobalControl from '../controls/loaderGlobal/loaderGlobal'
import NotificationGlobalControl from '../controls/notificationGlobal/notification'
import DashboardComponent from './dashboard/dashboard'
import LoginComponent from './login/login'
// import RegisterComponent from './register/register'
import SampleUsageContainer from './sampleusage/sampleusage'
import AddStudentApplication from './studentApplication/addStudentApplication'
import ApplicationFormComponent from './studentApplication/applicationForm'
import ResetPasswordComponent from './login/forgot/reset/reset'
import ThankYouComponent from './studentApplication/thankYou'

const MainContainer = props => {
  const mapsAPIKey = process.env.GOOGLE_MAPS_API_KEY

  //Did mount...
  useEffect(() => {}, [])

  //Will unmount...
  useEffect(() => {
    return () => {
      console.log('Cleaning up...')
    }
  }, [])

  return (
    <div className="containers-main flex h-screen">
      <NotificationGlobalControl />
      <LoaderGlobalControl />
      <LoadScript googleMapsApiKey={mapsAPIKey} language="en">
        <Switch>
          <Redirect exact from="/" to="/login" />

          <Route path="/login" render={() => <LoginComponent />} />

          <Route path="/reset/:token" render={() => <ResetPasswordComponent />} />

          {/* <Route path="/register" render={() => <RegisterComponent />} /> */}

          <Route path="/dashboard" render={() => <DashboardComponent />} />

          <Route path="/student-application" render={() => <AddStudentApplication />} />

          <Route path={`/thank-you`} render={() => <ThankYouComponent />} />

          <Route path={'/application-form'} render={() => <ApplicationFormComponent />} />

          <Route exact path="/controls" render={() => <SampleUsageContainer />} />
        </Switch>
      </LoadScript>
    </div>
  )
}

export default MainContainer
