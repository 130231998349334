import { RESET_FILE_UPLOAD_STATE, SET_FILE_UPLOAD_STATE } from '../../constants/redux_types'

const INITIAL_STATE = {}

export function fileUploadStateReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_FILE_UPLOAD_STATE:
      return {
        ...state,
        ...action.payload
      }
    case RESET_FILE_UPLOAD_STATE:
      return INITIAL_STATE
    default:
      return state
  }
}
