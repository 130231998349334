import React, { useState } from 'react'

import { connect } from 'react-redux'

import ButtonControl from '../../../controls/button/button'
import ModalFormWrapper from '../../../controls/modalFormWrapper/modalFormWrapper'
import PopupControl from '../../../controls/popup/popup'
import { useFormActionHook } from '../../../hooks/useFormActionHook'
import { INITIAL_STATE as initialNotificationData } from '../../../reducers/states/notification'
import { prepareFormFields } from '../../../utils'
import { initialResultValues, surveyResultsValidationConfig } from '../constants'
import { CreateControlFormFields } from '../utils'
import ButtonComponent from '../../customComponents/button'
import { faSave } from '@fortawesome/pro-light-svg-icons'

const AddResultsModal = ({ open = false, setShow, onSuccess }) => {
  const [formValues, setFormValues] = useState(initialResultValues)
  const { handleSubmit } = useFormActionHook(formValues, surveyResultsValidationConfig)
  const [notificationData, setNotificationData] = useState(initialNotificationData)
  const _formFields = prepareFormFields({
    values: formValues,
    overrides: []
  })

  const _onSubmitHandler = () =>
    handleSubmit({
      formAction: () => {
        onSuccess(formValues)
        setShow(false)
        setFormValues(initialResultValues)
      },
      onFailedValidation: errors =>
        setNotificationData({
          title: 'Validation Failed',
          messages: errors,
          onConfirm: () => setNotificationData(initialNotificationData)
        })
    })

  return (
    <div>
      <PopupControl
        title="Adding Results"
        isOpen={open}
        close={{
          showClose: true,
          onClose: () => {
            setShow(false)
          }
        }}
      >
        <ModalFormWrapper notificationData={notificationData}>
          <div>
            {/* -------form fields */}
            <div className="flex flex-row gap-4">
              <div className="w-72 flex flex-col gap-4">
                {/* -------form fields */}
                <CreateControlFormFields
                  initialValues={_formFields}
                  stateValues={formValues}
                  onChange={setFormValues}
                />
              </div>
            </div>
            <div className="flex justify-center items-center pt-8">
              {/* -------button */}
              <ButtonComponent
                title="Save Results"
                icon={faSave}
                isBusy={false}
                type="primary"
                onClick={_onSubmitHandler}
              />
            </div>
          </div>
        </ModalFormWrapper>
      </PopupControl>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    notificationState: state.notificationState
  }
}

export default connect(mapStateToProps)(AddResultsModal)
