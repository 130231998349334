import React, { useEffect, useState } from 'react'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'

import Img from '../../../assets/images/Signin-bg-image.png'
import logo from '../../../assets/images/black_logo.png'
import ButtonControl from '../../../controls/button/button'
import TextInputControl from '../../../controls/textinput/textinput'
import SentContainer from './sent/sent'
import { forgot_password } from '../../../api/calls/forgot_password'

const ForgotPasswordComponent = props => {
  const [emailAddress, setemailAddress] = useState('')

  const history = useHistory()
  let { path, url } = useRouteMatch()

  //Did mount...
  useEffect(() => {
    console.log('it works')
    return () => {
      console.log('Cleaning up...')
    }
  }, [])

  //Will unmount...
  useEffect(() => {
    return () => {
      console.log('Cleaning up...')
    }
  }, [])

  return (
    <div className="flex flex-col items-center justify-center">
      <Switch>
        <Route
          exact
          path={`${path}`}
          render={() => (
            <div className="grid gap-y-10 w-full max-w-lg px-4">
              <img className="object-contain max-w-lg px-20 pb-20 pt-10" src={logo} />
              <div>
                <p>Please Fill In Your Email Address Below To Recieve Password Retrievel Steps</p>
              </div>
              <TextInputControl
                title="Email Address"
                value={emailAddress}
                placeholder="Email Address"
                onChange={v => setemailAddress(v)}
              />
              <ButtonControl
                style="bg-buttonColor"
                placeholder="SEND"
                isBusy={false}
                type="primary"
                onClick={async v => {
                  await forgot_password(emailAddress).then(response => {
                    if (response.data.success) {
                      history.push(`${path}/sent`)
                    }
                  })
                }}
              />
            </div>
          )}
        />

        <Route path={`${path}/sent`} render={() => <SentContainer />} />
      </Switch>
    </div>
  )
}

export default ForgotPasswordComponent
