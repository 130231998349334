import React from 'react'
import DropdownButtonComponent from '../../../customComponents/dropdownButton'
import InputComponent from '../../../customComponents/input'

const VebDayForm = ({ formValues, onChange, groups, programs }) => {
  return (
    <div className="flex flex-col gap-2">
      <InputComponent
        theme="dark"
        title="Catch Up Days Used"
        value={formValues?.value_exchange_days}
        onChange={v =>
          onChange({
            value_exchange_days: v
          })
        }
      />
      <DropdownButtonComponent
        title={'Group'}
        placeholder={'Select Group'}
        isBusy={false}
        type="primary"
        theme="dark"
        selector="_id"
        displayKey="name"
        dropdownItems={groups}
        selectedItem={groups.find(x => formValues?.group_id == x._id)?.name}
        onClick={v => {
          onChange({
            group_id: v._id
          })
        }}
      />
      <br />
    </div>
  )
}

export default VebDayForm
