import { setFileUploadState } from '../actions'
import { upload_file } from '../api/calls'
import { resetAlert, setAlert } from './alert'

export const uploadFile = (file, fileUploadCallback = arg => {}) => {
  return dispatch => {
    upload_file({ file, name: file.name })
      .then(res => {
        if (res.data.success) {
          if (fileUploadCallback) return fileUploadCallback(res.data)
          //TODO: remove this
          dispatch(setFileUploadState(res.data))
          dispatch(
            setAlert({
              title: 'Success',
              show: true,
              message: 'Upload successful.'
            })
          )
        } else {
          dispatch(
            setAlert({
              title: 'Failure',
              show: false,
              message: 'Upload failed.'
            })
          )
        }
      })
      .catch(error => {
        dispatch(
          setAlert({
            title: 'Failure',
            show: false,
            message: error.message
          })
        )
      })
    dispatch(resetAlert())
  }
}
