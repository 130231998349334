export const initialGroupValues = {
  name: '',
  description: '',
  location: '',
  facilitator_ids: [],
  learner_ids: [],
  allocation_code: '',
  graduation_date: ''
}
export const groupValidationConfig = {}
export const groupColumns = [
  { key: 'name', display: 'Group Name' },
  { key: 'allocation_code', display: 'Allocation Code' },
  { key: 'facilitators', display: 'Facilitators' }
]
