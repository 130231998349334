import React, { useEffect, useState } from 'react'

import { faCircle, faSave } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from 'react-router-dom'
import { save_interviews } from '../../../api/calls/save_interviews'
import ButtonControl from '../../../controls/button/button'
import CheckBoxControl from '../../../controls/checkbox/checkbox'
import NotificationControl from '../../../controls/notification/notification'
import { connect, useDispatch } from 'react-redux'
import InputComponent from '../../customComponents/input'
import ButtonComponent from '../../customComponents/button'
import { setMainState } from '../../../actions/main'

const initFormValues = {
  facilitator_id: '',
  learner_id: '',
  group_id: '',
  questions: [],
  status: '',
  comment: ''
}

function Questions(props) {
  //   const [formValue, setFormValue] = useState()
  //   const [answers, setAnswers] = useState([])
  // const [questions, setQuestions] = useState([])
  //   const [showAddQuestion, setShowAddQuestion] = useState(false)
  //   const [showComment, setShowComment] = useState(false)
  //   const [module, setModule] = useState({})
  //   const [comment, setComment] = useState('')
  const [notificationData, setNotificationData] = useState({
    title: '',
    messages: [],
    type: 'ok' | 'confirm'
  })
  const { interviewDetails, mainState, facilitatorState, userState } = props
  const dispatch = useDispatch()
  const [risk, setRisk] = useState('')
  const [formValues, setFormValues] = useState(initFormValues)
  const [factors, setFactors] = useState('')
  const [questions, setQuestions] = useState('')
  const [answers, setAnswers] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const history = useHistory()

  const _validate = () => {
    if (risk.length > 0 && factors.length > 0 && questions.length > 0 && answers.length > 0) {
      return true
    } else {
      return false
    }
  }

  const _onSaveAndAddAnother = () => {
    setRisk('')
    setFactors('')
    setQuestions('')
    setAnswers('')
  }

  useEffect(() => {
    setIsValid(_validate())
  }, [risk, factors, questions, answers])

  useEffect(() => {
    _onFormValueChange({
      group_id: (mainState?.interview?.group_id ?? '') || (interviewDetails?.group?._id ?? ''),
      facilitator_id: (mainState?.interview?.facilitator_id ?? '') || (userState?.id ?? ''),
      learner_id: (mainState?.selectedUser?._id ?? '') || (interviewDetails.learner_id ?? ''),
      questions: (mainState?.interview?.questions ?? '') || (interviewDetails?.questions ?? []),
      status: (mainState?.interview?.status ?? '') || (interviewDetails?.status ?? ''),
      comment: (mainState?.interview?.comment ?? '') || (interviewDetails?.comment ?? '')
    })
  }, [interviewDetails, mainState, facilitatorState, userState])

  const _onFormValueChange = v => {
    setFormValues({ ...formValues, ...v })
  }

  const _onSave = async () => {
    await save_interviews(formValues).then(res => {
      if (res.data.success) {
        dispatch(
          setMainState({
            interview: { content: res.data.content },
            ...mainState
          })
        )
        setNotificationData({
          title: 'Successful',
          messages: ['Interview successfully added.']
        })
        history.push(`dashboard/interview/${mainState?.user_id}`)
      }
    })
    setIsLoading(false)
  }

  return (
    <div>
      <div className="py-4 flex flex-col gap-4 w-full">
        <div className="w-full flex gap-4">
          <div className="w-full bg-green shadow">
            <div className="w-full flex-col">
              <div className="w-full bg-theme text-white flex justify-center p-4">Risk Profile</div>
              <div className="p-4 bg-white">
                <InputComponent
                  theme="form"
                  size="lg"
                  title="Please indicate risk"
                  placeholder="High/Medium/Low - Please give reason why you are putting this beneficiary through if risk is high or medium"
                  value=""
                  onChange={v => {
                    // _onFormValueChange({})
                  }}
                />
              </div>
            </div>
          </div>
          <div className="w-full bg-green shadow">
            <div className="w-full flex-col">
              <div className="w-full bg-theme text-white flex justify-center p-4">
                Status and Comment
              </div>
              <div className="p-4 bg-white flex gap-8">
                <div className="flex flex-col gap-1 w-1/2 ">
                  <div className="text-sm font-extrabold">Select Status</div>
                  <div className="flex flex-col justify-center w-full gap-4">
                    <CheckBoxControl
                      label="Yes"
                      theme="light"
                      name="status"
                      checked={formValues.status == 'Yes' ? true : false}
                      value="Yes"
                      onChange={e => {
                        _onFormValueChange({ status: e.target.value })
                      }}
                    />
                    <CheckBoxControl
                      label="Maybe"
                      theme="light"
                      name="status"
                      checked={formValues.status == 'Maybe' ? true : false}
                      value="Maybe"
                      onChange={e => {
                        _onFormValueChange({ status: e.target.value })
                      }}
                    />
                    <CheckBoxControl
                      label="No"
                      theme="light"
                      name="status"
                      checked={formValues.status == 'No' ? true : false}
                      value="No"
                      onChange={e => {
                        _onFormValueChange({ status: e.target.value })
                      }}
                    />
                  </div>
                </div>
                <div className="w-full">
                  <InputComponent
                    theme="form"
                    size="lg"
                    title="Comment"
                    placeholder="Add your comment here..."
                    value={formValues.comment}
                    onChange={v => {
                      _onFormValueChange({ comment: v })
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col pt-8">
          <div className="flex justify-start items-center text-2xl font-extrabold ">Questions</div>
          <hr className="text-gray" />
        </div>

        <div className="flex justify-between gap-4">
          <div className="w-full shadow">
            <div className="w-full flex-col">
              <div className="w-full bg-theme text-white flex justify-center p-4">Risk</div>
              <div className="p-4 bg-white">
                <InputComponent
                  theme="form"
                  title="Indicate risk"
                  value={risk}
                  size="lg"
                  showErrorMessage={risk.length < 1 ? '* Required' : ''}
                  onChange={v => {
                    setRisk(v)
                  }}
                />
              </div>
            </div>
          </div>
          <div className="w-full shadow">
            <div className="w-full flex-col">
              <div className="w-full bg-theme text-white flex justify-center p-4">
                Identifying factors
              </div>
              <div className="p-4 bg-white">
                <InputComponent
                  theme="form"
                  title="Indicate identifying factors"
                  size="lg"
                  value={factors}
                  showErrorMessage={factors.length < 1 ? '* Required' : ''}
                  onChange={v => {
                    setFactors(v)
                  }}
                />
              </div>
            </div>
          </div>
          <div className="w-full shadow">
            <div className="w-full flex-col">
              <div className="w-full bg-theme text-white flex justify-center p-4">
                Possible Questions
              </div>
              <div className="p-4 bg-white">
                <InputComponent
                  theme="form"
                  size="lg"
                  title="Add possible questions"
                  value={questions}
                  showErrorMessage={questions.length < 1 ? '* Required' : ''}
                  onChange={v => {
                    setQuestions(v)
                  }}
                />
              </div>
            </div>
          </div>
          <div className="w-full  shadow">
            <div className="w-full flex-col">
              <div className="w-full bg-theme text-white flex justify-center p-4">
                Learner Answers
              </div>
              <div className="p-4 bg-white">
                <InputComponent
                  theme="form"
                  size="lg"
                  title="Provide answers below"
                  value={answers}
                  showErrorMessage={answers.length < 1 ? '* Required' : ''}
                  onChange={v => {
                    setAnswers(v)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-start items-center">
        <ButtonComponent
          title={'Save and Add Another'}
          disabled={!isValid}
          onClick={() => {
            if (_validate()) {
              _onFormValueChange({
                questions: [
                  ...formValues.questions,
                  {
                    risk: risk,
                    identifying_factors: factors,
                    possible_questions: questions,
                    answer: answers
                  }
                ]
              })
              _onSaveAndAddAnother()
            } 
          }}
          icon={faSave}
        />
      </div>

      {formValues?.questions.length > 0 ? (
        <div className="py-16 flex flex-col gap-4 w-full">
          <div className="w-ful flex flex-col">
            <div className="flex justify-start items-center text-2xl font-extrabold ">Answers</div>
            <hr className="text-gray" />
          </div>

          <div className="grid grid-cols-3 space-y-2">
            {formValues?.questions?.map((e, index) => (
              <div key={index} className="w-full flex flex-row shadow-sm">
                <div className="p-4 italic text-xl font-sans flex justify-center items-center w-8 bg-main">
                  {index + 1}
                </div>
                <div className="py-4 px-2 flex justify-center items-start flex-col gap-1">
                  <div className="flex fle-row justify-start items-end gap-2 text-sm">
                    Risk:
                    <div className="text-sm italic font-extralight font-sans">{e.risk}</div>
                  </div>
                  <div className="flex fle-row justify-start items-end gap-2 text-sm">
                    Identifying factors:
                    <div className="text-sm italic font-extralight font-sans">
                      {e.identifying_factors}
                    </div>
                  </div>
                  <div className="flex fle-row justify-start items-end gap-2 text-sm">
                    Possible questions:
                    <div className="text-sm italic font-extralight font-sans">
                      {e.possible_questions}
                    </div>
                  </div>
                  <div className="flex fle-row justify-start items-end gap-2 text-sm">
                    Learner's answer(s):
                    <div className="text-sm italic font-extralight font-sans">{e.answer}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-start items-center">
            <ButtonComponent
              title={'Save Interview'}
              isLoading={isLoading}
              onClick={() => {
                setIsLoading(true)
                _onSave()
              }}
              icon={faSave}
            />
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <NotificationControl notificationData={notificationData} />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    mainState: state.mainState,
    userState: state.userState,
    programsState: state.programsState,
    facilitatorState: state.facilitatorState
  }
}

export default connect(mapStateToProps)(Questions)
