export const groupDashboardColumns = [
  { key: 'status', display: 'Status' },
  { key: 'learner', display: 'Learner' },
  { key: 'group', display: 'Group Name' },
  { key: 'attendance_percentage', display: 'Attendance' }
]

export const OILevels = [
  { key: 'A0', display: 'A0' },
  { key: 'A1', display: 'A1' },
  { key: 'A2', display: 'A2' },
  { key: 'B0', display: 'B0' },
  { key: 'B1', display: 'B1' },
  { key: 'B2', display: 'B2' }
]

export const performanceColumns = [
  { key: 'group', display: 'Group', filter: true },
  { key: 'learner', display: 'Learner', filter: true },
  { key: 'attendance', display: 'Attendance', filter: true },
  { key: 'status', display: 'Status' },
  { key: 'sales_in_units', display: 'Sales In Units', filter: true },
  { key: 'sales_in_rand', display: 'Sales (R)', filter: true },
  { key: 'veb_days', display: 'Value Exchange Days', filter: true },
  { key: 'veb_days_outstanding', display: 'Value Exchange Days Outstanding', filter: true },
  { key: 'purchase', display: 'Purchase', filter: true },
  { key: 'loan_percentage', display: 'Loan %', filter: true },
  { key: 'human_oi', display: 'Human OI', filter: true },
  //   { key: 'business_oi', display: 'Business OI', filter: true },
  { key: 'technical_oi', display: 'Technical OI', filter: true },
  { key: 'progress_percentage', display: 'Progress %' },
  { key: 'documents', display: 'Documents' }
]

// export const averageGroupAttendanceColumns = [
//   { key: 'group', display: 'Group' },
//   { key: 'attendance_percentage', display: 'Attendance %' },
//   { key: 'human_oi', display: 'Human OI' },
//   { key: 'business_oi', display: 'Business OI' },
//   { key: 'technical_oi', display: 'Technical OI' },
//   { key: 'ved_oi', display: 'VED OI' },
//   //   { key: 'average_technical_oi', display: 'Avg Technical OI' },
//   { key: 'sales_in_units', display: 'Sales In Units' },
//   { key: 'sales_in_rand', display: 'Sales (R)' },
//   { key: 'purchase', display: 'Purchase' },
//   { key: 'veb_days', display: 'Value Exchange Days' },
//   { key: 'veb_days_outstanding', display: 'Value Exchange Days Outstanding' },
//   { key: 'loan_percentage', display: 'Loan % Paid' }
// ]

export const averageGroupAttendanceColumns = [
  { key: 'group_name', display: 'Group' },
  { key: 'attendance_percentage', display: 'Attendance %' },
  { key: 'human_oi', display: 'Human OI' },
  //   { key: 'business_oi', display: 'Business OI' },
  { key: 'technical_oi', display: 'Technical OI' },
  { key: 'ved_oi', display: 'VED OI' },
  //   { key: 'average_technical_oi', display: 'Avg Technical OI' },
  { key: 'sales_in_units', display: 'Sales In Units' },
  { key: 'sales_in_rand', display: 'Sales (R)' },
  { key: 'purchase', display: 'Purchase' },
  { key: 'veb_days', display: 'Value Exchange Days' },
  { key: 'veb_days_outstanding', display: 'Value Exchange Days Outstanding' },
  { key: 'loan_percentage', display: 'Loan % Paid' }
]

export const progressTableColumns = [
  { key: 'progress_percentage', display: 'Progress Percentage' },
  { key: 'documents', display: 'Documents' }
]

export const groupDashboardValidationConfig = [
  { field: 'program_id', types: ['required'] },
  { field: 'group_ids', types: ['required'] }
]

export const initialGroupDashboardValues = {}

export const statusIndicators = {
  Active: { color: 'text-green' },
  'Performance Review': { color: 'text-yellow' },
  Inactive: { color: 'text-red-600' }
}

export const programSkeleton = {
  id: '',
  name: ''
}

const learner_info = {
  days_absent: '',
  attendance_information: [
    {
      date_absent: '',
      program_name: '',
      interaction_code: ''
    }
  ]
}
