import { apiPost } from '../api'

export const upload_file = async ({
  file = '',
  name = '',
  description = '-',
  type = 'DOCUMENT',
  category = 'veb_document'
}) => {

    let formData = new FormData()
    formData.append('file', file)
    formData.append('name', name)
    formData.append('description', description)
    formData.append('type', type)
    formData.append('category', category)

    return apiPost({
      endpoint: 'files/upload',
      path: 'upload',
      type: 'media',
      data: formData
    })
}
