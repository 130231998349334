import React from 'react'

import { faCalendar, faPencil } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const DateRangeWidget = props => {
  return (
    <div className="flex">
      <div className="px-2 flex border text-xs text-cardIcon4">
        <FontAwesomeIcon className="text-l pt-1 text-dark" icon={faCalendar} />
        <div className="px-2 pt-1">Add date</div>
        <FontAwesomeIcon className="text-l pt-1 flex text-dark" icon={faPencil} />
      </div>
      <div className="px-1 py-1 bg-cardIcon4 text-xs text-white">06</div>
      <div className="px-1 py-1 bg-cardIcon4 text-xs text-white border-gray-400 border-l-2">
        September
      </div>
      <div className="px-1 py-1 bg-cardIcon4 text-xs text-white border-gray-400 border-l-2">
        2022
      </div>
      <div className="px-4 py-1 text-xs">To</div>
      <div className="px-1 py-1 bg-cardIcon4 text-xs text-white">06</div>
      <div className="px-1 py-1 bg-cardIcon4 text-xs text-white border-gray-400 border-l-2">
        September
      </div>
      <div className="px-1 py-1 bg-cardIcon4 text-xs text-white border-gray-400 border-l-2">
        2022
      </div>
    </div>
  )
}

export default DateRangeWidget
