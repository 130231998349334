import moment from 'moment'
import Attendance from './attendance'
export const tableHeader = [
  'Name',
  'Surname',
  'ID',
  'Program',
  'Contact',
  'Attendance',
  'Comment',
  'OI Rating'
]

export const OILevel = [
  { id: 1, level: 'A0' },
  { id: 2, level: 'A1' },
  { id: 3, level: 'A2' },
  { id: 4, level: 'A3' },
  { id: 5, level: 'B1' },
  { id: 6, level: 'B2' },
  { id: 7, level: 'B3' }
]

export const dayTypes = [
  { id: 'Volunteer', name: 'Volunteer' },
  { id: 'Standard', name: 'Standard' },
  { id: 'Catch Up', name: 'Catch Up' },
  { id: 'Technical', name: 'Technical' },
  { id: 'Induction', name: 'Induction' },
  { id: 'VED', name: 'VED' }
]
export const AttendanceTypes = [
  { id: 'Present', name: 'Present' },
  { id: 'Late', name: 'Late' },
  { id: 'Absent', name: 'Absent' }
]

export const attendanceValidationConfig = [
  { field: 'number_of_registers', types: ['required'] },
  { field: 'interaction_id', types: ['required'] },
  { field: 'level_type', types: ['required'] },
  { field: 'day_type', types: [''] },
  { field: 'name', types: ['required'] },
  { field: 'date', types: ['required'] },
  { field: 'venue_id', types: ['required', 'isNumber'] }
]

export const initialAttendanceData = {
  draft: false,
  number_of_registers: '',
  interaction_id: '',
  group_id: '',
  level_type: 'Human',
  name: '',
  date: moment(Date.now()).format('YYYY-MM-DD'),
  venue_id: '',
  learners: []
}

export const mockInteractionValues = [
  { id: '99049xxxxxxxxxxxxxxxxxxxx' },
  { id: '77849xxxxxxxxxxxxxxxxxxxx' },
  { id: '78399xxxxxxxxxxxxxxxxxxxx' },
  { id: '02112xxxxxxxxxxxxxxxxxxxx' },
  { id: '64553xxxxxxxxxxxxxxxxxxxx' }
]

export const initialAttendanceValues = {
  user_id: '',
  program_id: '',
  venue_id: '',
  attendance: {
    user_id: '',
    attendance_status: '',
    day_type: '',
    oi_level: '',
    attendance_date: ''
  }
}

export const attendanceTableDisplayKeys = [
  {
    key: '#',
    display: '#'
  },
  {
    key: 'id',
    display: 'ID'
  },
  {
    key: 'user_name',
    display: 'Name'
  },
  {
    key: 'user_cell_number',
    display: 'Contact'
  },
  {
    key: 'program_name',
    display: 'Program'
  },
  {
    key: 'day_type',
    display: 'Day Type'
  },
  {
    key: 'attendance',
    display: 'Attendance'
  },
  {
    key: 'comment',
    display: 'Comment'
  },
  {
    key: 'oi_rating',
    display: 'OI Rating'
  }
]
