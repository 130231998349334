import React, { useEffect } from 'react'
import { useState } from 'react'
import { interactionHandler } from '../functions'
import { TableCell, TableRow } from '@material-ui/core'

const PerformanceRecord = ({ learner, index, headers }) => {
  const [trackerData, setTrackerData] = useState([])
  useEffect(() => {
    setTrackerData(interactionHandler(learner, headers))
  }, [learner, headers])

  return (
    <TableRow
      key={index}
      className={'border-b border-gray-200 hover:bg-gray-100 border-dark font-light h-8'}
    >
      <TableCell className="text-gray-600 text-xs leading-normal font-light">
        <div className="flex flex-col">{index + 1}</div>
      </TableCell>
      <TableCell className="text-gray-600 text-xs leading-normal font-light">
        <div className="flex flex-col">{learner.user_name}</div>
      </TableCell>
      <TableCell className="text-gray-600 text-xs leading-normal font-light">
        <div className="flex flex-col">{learner.user_surname}</div>
      </TableCell>
      <TableCell className="text-gray-600 text-xs leading-normal font-light">
        <div className="flex flex-col">{learner?.oi_levels?.human_oi ?? '-'}</div>
      </TableCell>
      <TableCell className="text-gray-600 text-xs leading-normal font-light">
        <div className="flex flex-col">{learner?.oi_levels?.business_oi ?? '-'}</div>
      </TableCell>
      {trackerData.map((item, i) => (
        <TableCell className="text-gray-600 text-xs leading-normal font-light">
          <div className="flex flex-col">
            {item?.attendance?.length > 0 ? item?.attendance[0]['oi_level'] : '-'}
          </div>
        </TableCell>
      ))}
    </TableRow>
  )
}

export default PerformanceRecord
