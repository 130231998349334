import React, { useEffect, useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow, Input } from '@material-ui/core'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TableContentWrapper from '../../../controls/tableContentWrapper/tableContentWrapper'

const ITEMS_PER_PAGE = 10
const defaultSearchValue = {
  show: false,
  search_by: ''
}

//Abbriviate where necessary
const headers = [
  'Group',
  'Att. %',
  'Human OI',
  //   'Business OI',
  'Technical OI',
  'VED OI',
  'Sales In Units',
  'Sales (R)',
  //   'Purchase',
  'VED Outstanding'
  //   'Loan %'
]

export const GroupSummaryTable = props => {
  const { title, data = [] } = props

  console.log('data', data)

  const [currentPage, setCurrentPage] = useState(1)
  let filteredData = data

  const totalPages = Math.ceil(filteredData.length / ITEMS_PER_PAGE)

  const handleChangePage = page => {
    setCurrentPage(page)
  }

  return (
    <TableContentWrapper>
      <div className="flex flex-col gap-4">
        <div className="flex justify-between">
          <div className="flex-auto text-xl font-bold">{title}</div>
        </div>

        <Table className="flex min-w-full">
          <TableHead className="bg-main text-lg">
            <TableRow>
              {headers.map((e, idx) => (
                <TableCell key={idx} className="text-gray-600 text-sm font-bold leading-normal">
                  <div className="flex flex-row gap-2 items-center">{e}</div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="text-gray-600 text-sm font-normal">
            {data.length > 0 ? (
              filteredData
                .slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE)
                .map((item, idx) => {
                  console.log('item', item)
                  return (
                    <TableRow
                      key={(currentPage - 1) * ITEMS_PER_PAGE + item._id}
                      className={`border-b border-gray-200 hover:bg-gray-100 border-dark font-light h-8`}
                    >
                      <TableCell className="text-gray-600 text-xs leading-normal font-light">
                        {item.group_name ?? '-'}
                      </TableCell>
                      <TableCell className="text-gray-600 text-xs leading-normal font-light">
                        {item.attendance_percentage ?? '-'}
                      </TableCell>
                      <TableCell className="text-gray-600 text-xs leading-normal font-light">
                        <div className="flex flex-col">
                          {item.average_oi.human_level != null
                            ? item.average_oi?.human_level.map(e => {
                                return <div>{e}</div>
                              })
                            : '-'}
                        </div>
                      </TableCell>
                      <TableCell className="text-gray-600 text-xs leading-normal font-light">
                        <div className="flex flex-col">
                          {item.average_oi.business_level != null
                            ? item.average_oi?.business_level.map(e => {
                                return <div>{e}</div>
                              })
                            : '-'}
                        </div>
                      </TableCell>
                      <TableCell className="text-gray-600 text-xs leading-normal font-light">
                        <div className="flex flex-col">
                          {item.average_oi.technical_level != null
                            ? item.average_oi?.technical_level.map(e => {
                                return <div>{e}</div>
                              })
                            : '-'}
                        </div>
                      </TableCell>
                      <TableCell className="text-gray-600 text-xs leading-normal font-light">
                        <div className="flex flex-col">
                          {item.average_oi.ved_level != null
                            ? item.average_oi?.ved_level.map(e => {
                                return <div>{e}</div>
                              })
                            : '-'}
                        </div>
                      </TableCell>
                      <TableCell className="text-gray-600 text-xs leading-normal font-light">
                        <div className="flex flex-col">
                          {item.transactions != null ? item.transactions.unit_sales : '-'}
                        </div>
                      </TableCell>
                      <TableCell className="text-gray-600 text-xs leading-normal font-light">
                        <div className="flex flex-col">
                          {item.transactions != null
                            ? item.transactions?.amount
                              ? `R${item.transactions?.amount}`
                              : 'R0'
                            : '-'}
                        </div>
                      </TableCell>
                      <TableCell className="text-gray-600 text-xs leading-normal font-light">
                        <div className="flex flex-col">{item.ved_outstanding ?? '-'}</div>
                      </TableCell>
                      <TableCell className="text-gray-600 text-xs leading-normal font-light">
                        <div className="flex flex-col">{item.loan_percentage ?? '-'}</div>
                      </TableCell>
                    </TableRow>
                  )
                })
            ) : (
              <TableRow>
                <TableCell>This table is empty</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        {data && (
          <div className="flex justify-center mt-5">
            <button
              onClick={() => handleChangePage(currentPage - 1)}
              disabled={currentPage === 1}
              className={`rounded-l-lg px-3 py-1 ${
                currentPage === 1
                  ? 'bg-gray-200 cursor-default'
                  : 'bg-main hover:bg-blue-700 cursor-pointer'
              }`}
            >
              <FontAwesomeIcon icon={faArrowLeft} className="w-5 h-5 text-black" />
            </button>
            <div className="flex items-center bg-gray-200 px-3 py-1">
              {currentPage} of {totalPages}
            </div>
            <button
              onClick={() => handleChangePage(currentPage + 1)}
              disabled={currentPage === totalPages}
              className={` rounded-r-lg px-3 py-1 ${
                currentPage === totalPages
                  ? 'bg-gray-200 cursor-default'
                  : 'bg-main hover:bg-blue-700 cursor-pointer'
              }`}
            >
              <FontAwesomeIcon icon={faArrowRight} className="w-5 h-5 text-black" />
            </button>
          </div>
        )}
      </div>
    </TableContentWrapper>
  )
}
