import { EDIT_GROUP_STATE, SET_GROUP_STATE, UPDATE_GROUP_STATE } from '../constants/redux_types'

export const setGroupState = payload => {
  return {
    type: SET_GROUP_STATE,
    payload: payload
  }
}

export const editGroupState = payload => {
  return {
    type: EDIT_GROUP_STATE,
    payload: payload
  }
}

export const updateGroupState = payload => {
  return {
    type: UPDATE_GROUP_STATE,
    payload: payload
  }
}
