import { faPen, faRedo } from '@fortawesome/pro-light-svg-icons'
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getLearners, deleteLearner } from '../../asyncActions'
import TableContentWrapper from '../../controls/tableContentWrapper/tableContentWrapper'
import { selectPaginateState } from '../../selectors/selectors'
import ButtonComponent from '../customComponents/button'
import { selectLearnersState, selectLearnersLoaded, selectLearners } from '../learner/selectors'
import { selectNotificationState } from '../notifications/selectors'
import { useHistory } from 'react-router-dom'
import InductionTable from './components/table'

const InductionComponent = () => {
  const history = useHistory()
  const userType = 'LEARNER-ACC'
  const notificationData = useSelector(selectNotificationState)
  const [showAddRecruitment, setShowAddRecruitment] = useState(false)
  const dispatch = useDispatch()
  const learnerState = useSelector(selectLearnersState)
  const paginate = selectPaginateState(learnerState)

  const learnersLoaded = useSelector(selectLearnersLoaded)
  const learners = useSelector(selectLearners)
  const [selectedUser, setSelectedUser] = useState({})

  useEffect(() => {
    if (!learnersLoaded) {
      dispatch(getLearners())
    }
  }, [learnersLoaded])

  return (
    <TableContentWrapper
      notificationData={notificationData}
      title="Induction"
      actionButtons={[
        <div className="flex-none w-40 gap-4">
          <ButtonComponent
            title="Refresh"
            icon={faRedo}
            onClick={v => {
              dispatch(getLearners())
            }}
          />
        </div>
      ]}
    >
      <div className="flex flex-col gap-4">
        <InductionTable />
      </div>
    </TableContentWrapper>
  )
}

export default InductionComponent
