import React from 'react'

const ViewComponent = ({ title, data }) => {
  return (
    <div className="w-full flex flex-col">
      <div className="text-sm text-white italic">{title}</div>
      <div className="text-white font-sans font-extrabold italic">{data ?? '-'}</div>
    </div>
  )
}

export default ViewComponent
