import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ArcPercentageControl = (props) => {
  const { percentage, icon, display, smallDisplay } = props

  const circleColor = '#171E2F'
  const progressColor = '#FE7060'
  return (
    <div className="">
      <div className="flex justify-center items-center relative">
        <svg viewBox="0 0 36 36" className="h-24">
          <path
            className=""
            d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
            fill="none"
            stroke={circleColor}
            strokeWidth="4"
            strokeLinecap="round"
            strokeDasharray="100, 100"
          />
          <path
            className=""
            d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
            fill="none"
            stroke={progressColor}
            strokeWidth="4"
            strokeLinecap="round"
            strokeDasharray={`${percentage}, 100`}
          />
        </svg>
        <div className="absolute">
          {icon && (
            <FontAwesomeIcon className="text-4xl text-dark" icon={icon} />
          )}
          {display && <span className="text-4xl text-dark">{display}</span>}
        </div>
        {smallDisplay && (
          <div
            className="absolute bottom-0 -right-6 rounded-full w-9 h-9 flex items-center justify-center"
            style={{ backgroundColor: circleColor }}
          >
            <div
              className="flex rounded-full w-6 h-6 items-center justify-center text-sm"
              style={{ backgroundColor: progressColor }}
            >
              {smallDisplay}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ArcPercentageControl
