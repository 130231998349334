import { setModulesState } from '../actions'
import { get_modules } from '../api/calls/modules'

export const getModules = () => {
  return dispatch => {
    get_modules()
      .then(res => {
        if (res.data.success) {
          dispatch(
            setModulesState({
              modules: res.data.content,
              modulesLoaded: true
            })
          )
        }
      })
      .catch(error => {})
  }
}
