import {
  DELETE_STOPLIGHT_STATE,
  SAVE_STOPLIGHT_STATE,
  SET_STOPLIGHT_STATE,
  RESET_STOPLIGHT_STATE
} from '../../constants/redux_types'

export function stoplightStateReducer(
  state = {
    hasLoaded: false,
    stoplights: []
  },
  action
) {
  switch (action.type) {
    case SET_STOPLIGHT_STATE:
      return {
        ...state,
        ...action.payload,
        hasLoaded: true
      }
    case SAVE_STOPLIGHT_STATE:
      return {
        ...state,
        hasLoaded: true
      }
    case DELETE_STOPLIGHT_STATE:
      return {
        ...state,
        stoplights: state.stoplights.map(x => {
          if (x._id !== action.payload._id) {
            return action.payload
          } else {
            return x
          }
        })
      }
    case RESET_STOPLIGHT_STATE:
      return state
    default:
      return state
  }
}
