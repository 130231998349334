export const stoplightColumns = [
  { key: 'name_of_facilitator', display: 'Name of Facilitator' },
  { key: 'name_of_client', display: 'Name of Client' },
  { key: 'survey_timeline', display: 'Survey timeline' },
  { key: 'identity_card', display: 'Identity Card' },
  { key: 'doing_well', display: 'Doing Well' },
  { key: 'struggle', display: 'Struggle' },
  { key: 'stuck', display: 'Stuck' }
]

export const stoplightValidationConfig = [
  { field: 'date', types: ['required', 'notEmpty'] },
  { field: 'survey_name', types: ['required', 'notEmpty'] },
  { field: 'office', types: ['required', 'notEmpty'] }
]

export const surveyResultsValidationConfig = [
  { field: 'name_of_facilitator', types: ['required', 'notEmpty'] },
  { field: 'name_of_client', types: ['required', 'notEmpty'] },
  { field: 'survey_timeline', types: ['required', 'notEmpty'] },
  { field: 'identity_card', types: ['required', 'notEmpty'] },
  { field: 'doing_well', types: ['required', 'notEmpty'] },
  { field: 'struggle', types: ['required', 'notEmpty'] },
  { field: 'stuck', types: ['required', 'notEmpty'] }
]

export const initialStoplightValues = {
  date: '',
  survey_name: '',
  office: '',
  results: [] // initialResultValues[] | undefined
}

export const initialResultValues = {
  name_of_facilitator: '',
  name_of_client: '',
  survey_timeline: '',
  identity_card: '',
  doing_well: '',
  struggle: '',
  stuck: ''
}

export const mockData = {
  ...initialStoplightValues,
  results: [initialResultValues, initialResultValues]
}
