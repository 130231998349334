import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { setNotification } from '../../../asyncActions/notification'
import ButtonControl from '../../../controls/button/button'
import DateTimeControl from '../../../controls/datetime/datetime'
import FileInputControl from '../../../controls/fileinput/fileinput'
import ModalFormWrapper from '../../../controls/modalFormWrapper/modalFormWrapper'
import PopupControl from '../../../controls/popup/popup'
import TextInputControl from '../../../controls/textinput/textinput'
import { csvUpload } from '../../../utils'
import { selectNotificationState } from '../../notifications/selectors'
import { initialProgrammeCurriculumValues, interactionsValidationConfig } from '../constants'
import InputComponent from '../../customComponents/input'
import ButtonComponent from '../../customComponents/button'
import { faSave } from '@fortawesome/pro-light-svg-icons'
import FilesComponent from '../../customComponents/files'
import DatePickerComponent from '../../customComponents/datepicker'

const UploadCurriculum = ({ open = false, setShow, csvValues }) => {
  const dispatch = useDispatch()
  const notificationData = useSelector(selectNotificationState)
  const [uploadedFile, setUploadedFile] = useState('')
  const [formValues, setFormValues] = useState(initialProgrammeCurriculumValues)
  const [showDateErrorMessage, setShowDateErrorMessage] = useState(false)
  const [showSaveButton, setShowSaveButton] = useState(false)
  const [dateErrorMessage, setDateErrorMessage] = useState('')

  const _onInputChange = value => {
    setFormValues({ ...formValues, ...value })
  }

  const _onCSVUpload = interactions => {
    setFormValues({ ...formValues, interactions })
  }

  const _uploadCSV = file => {
    csvUpload({
      file,
      fieldsConfig: interactionsValidationConfig,
      onSuccess: result => {
        _onCSVUpload(result)
      },
      onError: errors =>
        dispatch(
          setNotification({
            title: 'Validation Failed',
            messages: errors || ['Invalid CSV file']
          })
        )
    })
  }

  const _onSubmitHandler = () => {
    csvValues(formValues)
    setShow(false)
  }

  const _onShowSaveButton = () => {
    setShowSaveButton(true)
  }

  useEffect(() => {
    if (formValues?.name && formValues?.start_date && formValues?.end_date && !showSaveButton) {
      _onShowSaveButton()
    }
  }, [formValues, showDateErrorMessage])

  return (
    <div>
      <PopupControl
        title="Upload Curriculum"
        isOpen={open}
        close={{
          showClose: true,
          onClose: () => {
            setShow(false)
          }
        }}
      >
        <ModalFormWrapper notificationData={notificationData}>
          <div className="flex flex-row gap-8 justify-center items-center">
            <div className="w-72 flex flex-col gap-2">
              <InputComponent
                theme="dark"
                title="Curriculum name"
                value={formValues.name}
                onChange={name => _onInputChange({ name })}
              />
              <div className="flex flex-col gap-2">
                <DatePickerComponent
                  title="Start Date"
                  //   type="date"
                  //   theme="dark"
                  value={formValues.start_date}
                  disablePast={true}
                  onChange={start_date => _onInputChange({ start_date })}
                />
                {formValues?.start_date && (
                  <DatePickerComponent
                    title="End Date"
                    //   type="date"
                    //   theme="dark"
                    value={showDateErrorMessage ? '' : formValues.end_date}
                    onChange={end_date => {
                      //check if start and end date is greater than 3 months
                      const startDate = new Date(formValues?.start_date)
                      const endDate = new Date(end_date)
                      const diffTime = Math.abs(endDate - startDate)
                      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
                      if (diffDays > 90) {
                        setShowDateErrorMessage(true)
                        setDateErrorMessage('Start and end date must not be greater than 3 months')
                      } else {
                        setShowDateErrorMessage(false)
                        setDateErrorMessage('')
                        _onInputChange({ end_date })
                      }
                    }}
                    showErrorMessage={showDateErrorMessage}
                    errorMessage={dateErrorMessage}
                  />
                )}
              </div>
              <FilesComponent
                label="Upload CSV (Interactions)"
                // theme="light"
                // placeholder={uploadedFile.name ?? 'Choose file'}
                onChange={_uploadCSV}
              />
            </div>
          </div>
          {showSaveButton && (
            <div className="flex justify-center items-center">
              <ButtonComponent
                title="Save"
                icon={faSave}
                isBusy={false}
                type="primary"
                onClick={_onSubmitHandler}
              />
            </div>
          )}
        </ModalFormWrapper>
      </PopupControl>
    </div>
  )
}

export default UploadCurriculum
