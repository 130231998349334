import { SET_MODULES_STATE } from '../../constants/redux_types'

const INITIAL_STATE = { modules: [], modulesLoaded: false }

export function moduleStateReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_MODULES_STATE:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
