import { faChevronDown } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useRef, useEffect } from 'react'
import { getJoinedNamesFromObj } from '../../../utils'
import SelectedItemsComponent from './selectedItems'
import SubItemsComponent from './subItems'

const MultiDropDownButtonComponent = props => {
  const {
    title,
    theme = 'light',
    isMultiple = true,
    subItems = [],
    selected = [],
    displayKey,
    onSubmit,
    position = 'b'
  } = props
  const initialSelectedItems = isMultiple ? selected : [selected]
  const [isOpen, setOpen] = useState(false)
  const dropdownRef = useRef()
  const [selectedItems, setSelectedItems] = useState(selected)
  const [subItemsList, setSubItemsList] = useState((subItems || selected) ?? [])
  const [searchQuery, setSearchQuery] = useState('')
  //   console.log('subItems', subItems)

  const options = {
    light: {
      titleColor: 'text-white',
      placeholder: 'text-white',
      color: 'bg-theme text-white',
      hover: 'hover:bg-blue-400'
    },
    dark: {
      titleColor: 'text-white',
      placeholder: 'text-gray',
      color: 'bg-textinput-dark border border-gray text-white',
      hover: 'hover:bg-blue-400'
    }
  }

  //use effects
  useEffect(() => {
    const _handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false)
      }
    }

    document.addEventListener('mousedown', _handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', _handleClickOutside)
    }
  }, [])

  useEffect(() => {
    _onSubmit(selectedItems)
  }, [selectedItems])

  useEffect(() => {
    if (selected.length > 0) {
      let newList = []
      newList = subItemsList.filter(item => !selected.includes(item))
      setSubItemsList(newList)
    }
  }, [selected])

  useEffect(() => {
    if (searchQuery.length > 0) {
      setSubItemsList(
        subItemsList.filter(item =>
          item.fullName.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
      )
    }
  }, [searchQuery])

  //methods
  //   const _getSelectedItemsText = () => {
  //     return getJoinedNamesFromObj(subItemsList, initialSelectedItems)
  //   }

  const _onClickCard = () => {
    setOpen(!isOpen)
  }

  const _onItemSelection = items => {
    const array = subItemsList.filter(item => !items.includes(item))
    setSubItemsList(array)
    setSelectedItems([...selectedItems, ...items])
    setOpen(false)
  }

  const _onItemUnselection = item => {
    setSubItemsList([item, ...subItemsList])
    const array = selectedItems.filter(e => e != item)
    setSelectedItems(array)
  }

  const _onSetOpen = value => {
    setOpen(value)
  }

  const _onSubmit = values => {
    onSubmit(values)
  }

  return (
    <div className="overflow-visible relative" ref={dropdownRef}>
      <SubItemsComponent
        items={subItemsList}
        displayKey={displayKey}
        isOpen={isOpen}
        onClick={_onItemSelection}
        setOpen={_onSetOpen}
      />

      <div className="flex-col">
        <div
          className={`w-full h-12 ${options[theme].color} ${options[theme].hover} justify-center flex flex-col p-4 cursor-pointer`}
          onClick={_onClickCard}
        >
          <div className="flex justify-between items-center">
            <div className="flex w-full justify-left items-center">
              <div className={`${options[theme].titleColor}`}>
                {`${subItems.length > 0 ? title : 'No items available.'} ${
                  selectedItems.length > 0 ? `- (${selectedItems.length}) Selected` : ''
                }`}
              </div>
            </div>

            <FontAwesomeIcon
              className={`text-l ${options[theme].titleColor}`}
              icon={faChevronDown}
            />
          </div>
        </div>

        <SelectedItemsComponent
          items={selectedItems}
          displayKey={displayKey}
          onClick={_onItemUnselection}
        />
      </div>
    </div>
  )
}
export default MultiDropDownButtonComponent
