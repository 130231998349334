import { apiGet } from '../../api'

export const get_task_by_status = (start_date, end_date, event_category_id) => {
  return apiGet({
    endpoint: `events/by_status?${
      start_date && end_date && event_category_id
        ? `start_date=${start_date}&end_date=${end_date}&event_category_id=${event_category_id}`
        : start_date && end_date
        ? `start_date=${start_date}&end_date=${end_date}`
        : `${event_category_id ? `event_category_id=${event_category_id}` : ``}`
    }`
  })
}
