import React, { useEffect, useState } from 'react'

import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { setMainState } from '../../actions/main'
import { getFacilitators } from '../../asyncActions/facilitators'
import { initialPageNumber } from '../../constants/constants'
import ButtonControl from '../../controls/button/button'
import LoaderControl from '../../controls/loader/loader'
import NotificationControl from '../../controls/notification/notification'
import TableControl from '../../controls/table/table'
import { selectPaginateState } from '../../selectors/selectors'
import { selectNotificationState } from '../notifications/selectors'
import AddUserComponent from '../user/components/addUser'
import { actionButtonItems, facilitatorsColumn } from '../user/constants'
import { selectEntities, selectEntitiesLoaded, selectEntitiesState } from './selectors'
import { getEntities } from '../../asyncActions/entities'
import { MyTableControl } from '../../controls/myTableControl/myTableControl'
import ButtonComponent from '../customComponents/button'
import { faPen, faRedo } from '@fortawesome/pro-light-svg-icons'


const EntitiesComponent = ({ mainState }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const userType = 'ENTITY-ACC'
  const [ShowAddEntity, setShowAddEntity] = useState(false)
  const entityState = useSelector(selectEntitiesState)
  const entitiesLoaded = useSelector(selectEntitiesLoaded)
  const paginate = selectPaginateState(entityState)
  const notificationData = useSelector(selectNotificationState)
  const [isBusy, setIsBusy] = useState(false)
  const [selectedUser, setSelectedUser] = useState({})
  const e = useSelector(selectEntities)
  const entities = e.map(_ => ({
    ..._,
    full_name: `${_?.user_name} ${_?.user_surname}`,
    business_name: _?.entity.business_name,
    owner: _?.entity.owner,
    position: e.indexOf(_) + 1
  }))

  useEffect(() => {
    if (!entitiesLoaded) dispatch(getEntities())
  }, [entitiesLoaded])

  const _onChangeDropdown = (item, x) => {
    setSelectedUser(item)

    if (x.id == 1) {
      dispatch(
        setMainState({
          selectedUser: item
        })
      )
      history.push(`entity-details/${item._id}`)
    }

    if (x.id == 2) {
      setShowAddEntity(true)
    }
  }

  const _onRefresh = _ => {
    dispatch(getEntities())
  }

  const _handleAddEntity = _ => {
    setSelectedUser({})
    setShowAddEntity(true)
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-end items-end">
        <div className="flex-none mr-4">
          <ButtonComponent
            title="Add New Entity"
            icon={faPen}
            isBusy={false}
            type="primary"
            onClick={_handleAddEntity}
          />
        </div>
        <div className="flex-none w-40 gap-4">
          <ButtonComponent
            title="Refresh"
            icon={faRedo}
            isBusy={false}
            type="primary"
            onClick={_onRefresh}
          />
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="bg-white shadow-md p-4">
          <MyTableControl
            title={'Entities'}
            search={{ show: true, search_by: 'business_name' }}
            showPagination={true}
            data={entities}
            displayKeys={[
              { key: 'position', display: 'ID' },
              { key: 'business_name', display: 'Business Name' },
              { key: 'owner', display: 'Owner' },
              { key: 'user_cell_number', display: 'Phone Number' },
              { key: 'user_email', display: 'Email' },
              { key: 'status_code', display: 'Status' }
            ]}
            actionButton={{
              side: 'right',
              title: 'Details',
              items: actionButtonItems,
              isDropdown: true,
              onClick: _onChangeDropdown
            }}
          />
        </div>
      </div>

      <LoaderControl show={isBusy} />

      <AddUserComponent
        open={ShowAddEntity}
        setShow={setShowAddEntity}
        type={userType}
        user={selectedUser}
      />
      <NotificationControl notificationData={notificationData} />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    mainState: state.mainState,
    entityState: state.entityState
  }
}

export default connect(mapStateToProps)(EntitiesComponent)
