import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CheckBoxControl = (props) => {
  const {
    checked,
    placeholder,
    name = "",
    value,
    onChange,
    theme = "light",
    label
  } = props;

  //Did mount...
  useEffect(() => {}, []);

  //Will unmount...
  useEffect(() => {
    return () => {
      console.log("Cleaning up...");
    };
  }, []);

  const options = {
    light: {
      title: "text-dark",
      inputBg: "bg-textinput-light",
      inputBorder: "border-gray",
      placeholder: "",
      textColor: "text-black"

    },
    dark: {
      title: "text-white",
      inputBg: "bg-textinput-dark",
      inputBorder: "border-none",
      placeholder: "placeholder-gray",
      textColor: "text-white"
    },
    transparent: {
      title: "text-white",
      inputBg: "bg-transparent",
      inputBorder: "border-gray",
      placeholder: "",
    },
  };

  return (
   <div className="flex flex-row justify-start items-center gap-2">
     <input
      className={`
        h-6 ${options[theme].inputBg} w-6 text-gray
        border ${options[theme].inputBorder} ${options[theme].placeholder} 
        outline-none focus:outline-none rounded-full relative`}
      type="checkbox"
      name={name}
      checked={checked}
      placeholder={placeholder}
      value={value || ""}
      onChange={(e) => onChange(e)}
    />
    <div className={`text-sm ${options[theme].textColor}`}>{label}</div>
   </div>
  );
};

export default CheckBoxControl;
