import { RESET_NOTIFICATION, SET_NOTIFICATION } from '../../constants/redux_types'

export const INITIAL_STATE = { title: '', messages: [], type: 0 }

export function notificationStateReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_NOTIFICATION:
      return {
        ...action.payload
      }
    case RESET_NOTIFICATION:
      return INITIAL_STATE
    default:
      return state
  }
}
