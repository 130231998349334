import { EDIT_ENTITY_STATE, SET_ENTITY_STATE, UPDATE_ENTITY_STATE } from '../constants/redux_types'

export const setEntityState = payload => {
  return {
    type: SET_ENTITY_STATE,
    payload: payload
  }
}

export const editEntityState = payload => {
  return {
    type: EDIT_ENTITY_STATE,
    payload: payload
  }
}

export const updateEntityState = payload => {
  return {
    type: UPDATE_ENTITY_STATE,
    payload: payload
  }
}
