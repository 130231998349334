import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { setVenuesState } from '../../../actions'
import { saveVenue, setNotification } from '../../../asyncActions'
import ButtonControl from '../../../controls/button/button'
import ModalFormWrapper from '../../../controls/modalFormWrapper/modalFormWrapper'
import PopupControl from '../../../controls/popup/popup'
import { useFormActionHook } from '../../../hooks/useFormActionHook'
import { prepareFormFields } from '../../../utils'
import { selectNotificationState } from '../../notifications/selectors'
import { initialVenueValues, venueValidationConfig } from '../constants'
import { selectVenuesState } from '../selectors'
import { CreateControlFormFields } from '../utils'
import ButtonComponent from '../../customComponents/button'
import { faSave } from '@fortawesome/pro-light-svg-icons'

const AddVenuesModal = ({ initialValue, open = false, setShow, onSuccess }) => {
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState(initialVenueValues)
  const { handleSubmit } = useFormActionHook(formValues, venueValidationConfig)
  const notificationData = useSelector(selectNotificationState)
  const venues = useSelector(selectVenuesState)
  const _formFields = prepareFormFields({
    values: formValues,
    overrides: []
  })

  useEffect(() => {
    if (initialValue) {
      delete initialValue.createdAt
      delete initialValue.updatedAt
      setFormValues(initialValue)
    }
  }, [initialValue])

  
    const _onSave = () =>
      handleSubmit({
        formAction: () => {
          if (initialValue) {
            const id = formValues._id
            delete formValues._id
            dispatch(saveVenue({ ...formValues, id }))
            dispatch(
              setVenuesState({
                venues: { ...venues, formValues }
              })
            )
          } else {
            dispatch(saveVenue(formValues))
            dispatch(
              setVenuesState({
                venues: { ...venues, formValues }
              })
            )
          }
          setShow(false)
          //   setFormValues(initialVenueValues)
        },
        onFailedValidation: errors => {
          dispatch(
            setNotification({
              title: 'Validation Failed',
              messages: errors
            })
          )
        }
      })

    return (
      <div>
        <PopupControl
          title={`${initialValue ? 'Updating' : 'Adding'} Venues`}
          isOpen={open}
          close={{
            showClose: true,
            onClose: () => {
              setShow(false)
            }
          }}
        >
          <ModalFormWrapper notificationData={notificationData}>
            <div>
              {/* -------form fields */}
              <div className="flex flex-row gap-4">
                <div className="w-72 flex flex-col gap-4">
                  {/* -------form fields */}
                  <CreateControlFormFields
                    initialValues={_formFields}
                    stateValues={formValues}
                    onChange={setFormValues}
                  />
                </div>
              </div>
              <div className="flex justify-center items-center p-8">
                {/* -------button */}
                <ButtonComponent
                  title="Save Venues"
                  icon={faSave}
                  isBusy={false}
                  type="primary"
                  onClick={_onSave}
                />
              </div>
            </div>
          </ModalFormWrapper>
        </PopupControl>
      </div>
    )
}

export default AddVenuesModal
