import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const TextAreaControl = props => {
  const { title, icon, style, placeholder, value, onChange, theme = 'light' } = props

  const options = {
    light: {
      title: 'text-dark',
      inputBg: 'bg-transparent',
      inputBorder: 'border-gray',
      placeholder: ''
    },
    dark: {
      title: 'text-white',
      inputBg: 'bg-textinput-dark',
      inputBorder: 'border-none',
      placeholder: 'placeholder-gray'
    },
    transparent: {
      title: 'text-white',
      inputBg: 'bg-transparent',
      inputBorder: 'border-gray',
      placeholder: ''
    }
  }

  return (
    <div className="flex-1">
      <div className={`flex flex-col justify-start ${style}`}>
        {title && <div className={`${options[theme].title} capitalize`}>{title}</div>}
        <div className="flex flex-row ">
          {icon && (
            <div className="bg-black h-9 w-10 text-white flex justify-center items-center rounded-lg">
              <FontAwesomeIcon icon={icon} />
            </div>
          )}
          <textarea
            className={`
                        h-9 ${options[theme].inputBg} h-28 p-4 w-full text-gray
                        border ${options[theme].inputBorder} ${options[theme].placeholder} 
                        outline-none focus:outline-none ${
                          icon ? 'rounded-lg' : 'rounded-lg'
                        }`} 
            placeholder={placeholder}
            value={value || ''}
            rows={20}
            cols={20}
            onChange={e => onChange(e.currentTarget.value)}
          />
        </div>
      </div>
    </div>
  )
}

export default TextAreaControl
