import { SET_CATEGORIES_STATE, UPDATE_CATEGORIES_STATE } from '../constants/redux_types'

export const setCategoriesState = payload => {
  return {
    type: SET_CATEGORIES_STATE,
    payload: payload
  }
}

export const updateCategoriesState = payload => {
  return {
    type: UPDATE_CATEGORIES_STATE,
    payload: payload
  }
}
