import React, { useEffect, useState } from 'react'
import InputComponent from '../../customComponents/input'
import ButtonComponent from '../../customComponents/button'
import PopupControl from '../../../controls/popup/popup'
import { faSave } from '@fortawesome/pro-light-svg-icons'
import DatePickerComponent from '../../customComponents/datepicker'
import {
  paymentTransactionTypes,
  paymentTypes,
  recurringTypes,
  transactionTypes
} from '../../user/constants'
import DropdownButtonComponent from '../../customComponents/dropdownButton'
import moment from 'moment'
import { DATE_FORMAT } from '../../../constants/constants'
import { issue_fine } from '../../../api/calls/issue_fine'

const IssueFinePopUp = ({ show, setShow, data, showAlert }) => {
  const [formValues, setFormValues] = useState({ type: 'Fine' })
  const [canSave, setCanSave] = useState(false)

  const _onInputChange = e => {
    setFormValues({ ...formValues, ...e })
  }

  const _validateForm = () => {
    if (
      formValues?.date &&
      formValues.transaction_type &&
      formValues.type &&
      formValues.amount &&
      formValues.payment_type &&
      formValues.recurring == 'No'
    ) {
      setCanSave(true)
    } else {
      if (formValues.recurring != 'No' && formValues.recurring_start) {
        setCanSave(true)
      } else {
        setCanSave(false)
      }
    }
  }

  useEffect(() => {
    if (data) setFormValues({ ...formValues, ...data })
  }, [data])

  const _onSave = () => {
    //api call...
    //write what operation should take place here.
    issue_fine(formValues).then(e => {
      if (e.data.success) {
        showAlert(true)
        setShow(false)
      }
    })
  }

  useEffect(() => {
    _validateForm()
  }, [formValues])

  return (
    <div>
      <PopupControl
        title="Issue a fine"
        isOpen={show}
        close={{
          showClose: true,
          onClose: () => {
            setShow(false)
          }
        }}
      >
        <div className="w-full flex flex-col gap-4">
          <DropdownButtonComponent
            title="Transaction Type"
            placeholder="Select Transaction Type"
            isLoading={false}
            type="primary"
            theme="dark"
            displayKey="name"
            dropdownItems={transactionTypes}
            selectedItem={
              transactionTypes.find(
                x => x.id.toLowerCase() === formValues?.transaction_type?.toLowerCase()
              )?.name
            }
            onClick={item => {
              _onInputChange({
                transaction_type: item.id
              })
            }}
          />
          <DropdownButtonComponent
            title="Type"
            placeholder="Select Type"
            isLoading={false}
            type="primary"
            theme="dark"
            displayKey="name"
            dropdownItems={paymentTransactionTypes}
            selectedItem={
              paymentTransactionTypes.find(
                x => x.id.toLowerCase() === formValues.card_type?.toLowerCase()
              )?.name
            }
            onClick={item => {
              _onInputChange({
                card_type: item.id
              })
            }}
          />
          <InputComponent
            title="Amount"
            theme="dark"
            isNumber={true}
            value={formValues?.amount}
            onChange={amount =>
              _onInputChange({
                amount: amount
              })
            }
          />
          <DropdownButtonComponent
            placeholder="Select Payment Type"
            title="Payment Type"
            isLoading={false}
            type="primary"
            theme="dark"
            displayKey="name"
            dropdownItems={paymentTypes}
            selectedItem={
              paymentTypes.find(x => x.id.toLowerCase() === formValues?.payment_type?.toLowerCase())
                ?.name
            }
            onClick={item => {
              _onInputChange({
                payment_type: item.id
              })
            }}
          />
          <DropdownButtonComponent
            placeholder="Select Recurring Type"
            title="Recurring Type"
            isLoading={false}
            type="primary"
            theme="dark"
            displayKey="name"
            dropdownItems={recurringTypes}
            selectedItem={
              recurringTypes.find(x => x.id.toLowerCase() === formValues?.recurring?.toLowerCase())
                ?.name
            }
            onClick={item => {
              _onInputChange({
                recurring: item.id
              })
            }}
          />
          {/* <FilesComponent
            label="Proof of transaction"
            onChange={v => {
              upload_file({ file: v, name: v.name }).then(res => {
                if (res.data.success) {
                  _onInputChange({
                    proof: res.data.content.location,
                    etag: res.data.content.etag
                  })
                }
              })
            }}
          /> */}
          {formValues.recurring != 'No' && (
            <DatePickerComponent
              // theme="dark"
              // type="date"
              title="Recurring Date"
              date={formValues?.recurring_start}
              onChange={date => {
                _onInputChange({
                  recurring_start: moment(date).format(DATE_FORMAT)
                })
              }}
            />
          )}
          ;
          <DatePickerComponent
            //   theme="dark"
            //   type="date"
            title="Date"
            date={formValues?.date}
            onChange={date => {
              _onInputChange({
                date: moment(date).format(DATE_FORMAT)
              })
            }}
          />
          <ButtonComponent
            title="Save"
            icon={faSave}
            isBusy={false}
            type="primary"
            disabled={!canSave}
            onClick={_ => {
              _onSave(formValues)
            }}
          />
        </div>
      </PopupControl>
    </div>
  )
}

export default IssueFinePopUp
