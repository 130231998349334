import moment from 'moment'

const setInitialInteractionData = headers => {
  const initialInteractionData = []
  headers.forEach(e => {
    initialInteractionData.push({
      interactions: e,
      attendance: []
    })
  })

  return initialInteractionData
}

export function interactionHandler(learner, headers) {
  const tempDataSet = setInitialInteractionData(headers)

  learner.interactions.forEach(i => {
    tempDataSet.forEach(tempData => {
      if (i.code === tempData.interactions.code) {
        tempData.interactions.date = i.date
        tempData.attendance = i.attendance
      }
    })
  })
  return tempDataSet
}

export const generateTableHeaders = content => {
  const tempHeaders = []
  const uniqueIds = new Set()

  content.forEach(learner => {
    learner.interactions.forEach(e => {
      tempHeaders.push({
        code: e.code,
        date: e.date
      })
    })
  })

  //remove any duplicate interaction codes
  const unique = tempHeaders.filter(element => {
    const isDuplicate = uniqueIds.has(element.code)

    uniqueIds.add(element.code)

    if (!isDuplicate) {
      return true
    }

    return false
  })

  //sort headers by date
  const sortedHeaders = unique.sort(function (a, b) {
    var c = new Date(a.date)
    var d = new Date(b.date)

    return c - d
  })

  return sortedHeaders
}

export const manipulateDate = (date, type) => {
  var newDate
  var dateFrom
  var dateTo
  switch (type.toLowerCase()) {
    case 'custom':
      dateFrom = moment(date[0]).format('DD-MM-YYYY')
      dateTo = moment(date[1]).format('DD-MM-YYYY')
      newDate = { from: dateFrom, to: dateTo }
      break
    case 'day':
      newDate = moment(new Date()).subtract(1, 'd').format('DD-MM-YYYY')
      break
    case 'week':
      newDate = moment(new Date()).subtract(7, 'd').format('DD-MM-YYYY')

      break
    case 'year':
      newDate = moment(new Date()).subtract(1, 'y').format('DD-MM-YYYY')
      break
    default:
      break
  }

  return { date: newDate }
}
