import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { get_all_groups, get_all_users, get_venues } from '../../api/calls'
import { get_categories } from '../../api/calls/categories'
import { get_all_programs } from '../../api/calls/programs/get_all_programs'
import { save_event } from '../../api/calls/save_event'
import LoaderControl from '../../controls/loader/loader'
import NotificationControl from '../../controls/notification/notification'
import PopupControl from '../../controls/popup/popup'
import InputComponent from '../customComponents/input'
import DropdownButtonComponent from '../customComponents/dropdownButton'
import ButtonComponent from '../customComponents/button'
import { faInfo, faSave } from '@fortawesome/pro-light-svg-icons'
import DatePickerComponent from '../customComponents/datepicker'
import MultiDropDownButtonComponent from '../customComponents/multiDropdownButton/multiDropdownButton'
import OpenDayForm from './components/forms/openDayForm'
import OiWorkshopForm from './components/forms/oiWorkshopForm'
import CatchUpDayForm from './components/forms/catchUpDayForm'
import VebDayForm from './components/forms/vebDayForm'
import moment from 'moment'
import { formatDate } from '../../utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CreateEventComponent = props => {
  const { open = false, show, type = null, onCreateSuccess, startDate } = props

  const [notificationData, setNotificationData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [values, setValues] = useState({})
  const [category, setCategory] = useState()
  const [owner, setOwner] = useState({})
  const [program, setProgram] = useState({})
  const [venue, setVenue] = useState({})
  const [owners, setOwners] = useState([])
  const [categories, setCategories] = useState([])
  const [programs, setPrograms] = useState([])
  const [groups, setGroups] = useState([])
  const [venues, setVenues] = useState([])
  const [endDate, setEndDate] = useState('')
  const [selectedGroups, setSelectedGroups] = useState([])
  const [selecteOwners, setSelectedOwners] = useState([])

  useEffect(() => {
    //set values to default
    setValues({
      event_name: '',
      event_description: '',
      start_date: '',
      end_date: '',
      event_category_id: '',
      venue_id: '',
      program_ids: [],
      owner_ids: [],
      type: 'CUSTOM'
    })

    get_all_users(`type_code=FACILITATOR-ACC`)
      .then(response => {
        if (response.data.success) {
          setOwners(response.data.content)
        }
      })
      .catch(error => {})

    get_all_users(`type_code=ADMIN-ACC`)
      .then(response => {
        if (response.data.success) {
          setOwners([...response.data.content, ...owners])
        }
      })
      .catch(error => {})

    get_all_programs()
      .then(response => {
        if (response.data.success) {
          setPrograms(response.data.content)
        }
      })
      .catch(error => {})

    get_all_groups()
      .then(response => {
        if (response.data.success) {
          setGroups(response.data.content)
        }
      })
      .catch(error => {})

    get_categories()
      .then(response => {
        if (response.data.success) {
          setCategories(response.data.content)
        }
      })
      .catch(error => {})

    get_venues()
      .then(response => {
        if (response.data.success) {
          setVenues(response.data.content)
        }
      })
      .catch(error => {})
  }, [])

  useEffect(() => {
    setValues({
      ...values,
      event_category_id: category?.id,
      venue_id: venue.id
    })
  }, [category, program, venue, owner])

  useEffect(() => {
    if (type?.key == 'interview') {
      setValues({ ...values, type: type?.key?.toString().toUpperCase() })
    }
  }, [type])

  const _onSave = payload => {
    save_event(payload).then(response => {
      if (response.data.success) {
        setNotificationData({
          title: 'Success',
          messages: ['Event successfully created.']
        })
        onCreateSuccess(true)
      } else {
        setNotificationData({
          title: 'Problem',
          messages: [response.data.message]
        })
        onCreateSuccess(false)
      }
      setIsLoading(false)
    })
  }

  useEffect(() => {
    setValues({
      ...values,
      start_date: startDate,
      end_date: endDate
    })
  }, [startDate, endDate])

  useEffect(() => {
    if (type != null || type != undefined) {
      setValues({ ...values, type: type?.key?.toString().toUpperCase() })
    }
  }, [type])

  const _onEndDateSelected = e => {
    //check if end date is greater than or equal to start date
    if (e) {
      if (new Date(e) < new Date(startDate)) {
        setNotificationData({
          title: 'Problem',
          messages: ['End date cannot be less than start date.']
        })
        return
      }
    }
  }
  useEffect(() => {
    _onEndDateSelected(endDate)
  }, [endDate])

  useEffect(() => {
    if (category) {
      setValues({
        ...values,
        event_category_id: category.id,
        type: category.name ?? 'CUSTOM'
      })
    }
  }, [category])

  return (
    <div>
      <PopupControl
        title={`Create Event`}
        isOpen={open}
        close={{
          showClose: true,
          onClose: () => {
            show(false)
          }
        }}
      >
        <div className="flex flex-col gap-2">
          <div className="flex flex-row gap-2 items-center pb-10">
            <FontAwesomeIcon icon={faInfo} className="text-red-400" size="sm" />
            <div className="italic text-white">
              The start date of the event will be the date selected on the calendar:{' '}
              <span className="underline text-white">
                {' '}
                {formatDate(startDate, false, false, false)}
              </span>
            </div>
          </div>

          <InputComponent
            theme="dark"
            title="Event Name"
            value={values.event_name}
            onChange={v =>
              setValues({
                ...values,
                event_name: v
              })
            }
          />

          <InputComponent
            theme="dark"
            title="Event Description"
            value={values.event_description}
            onChange={v =>
              setValues({
                ...values,
                event_description: v
              })
            }
          />

          <MultiDropDownButtonComponent
            title="User(s) In Charge of This Event"
            theme={'dark'}
            subItems={owners}
            displayKey={'user_name'}
            selected={selecteOwners}
            onSubmit={owners => {
              setSelectedOwners(owners)
              setValues({
                ...values,
                owner_ids: owners.map(e => {
                  return e._id
                })
              })
            }}
          />

          <DropdownButtonComponent
            title="Category"
            placeholder="Select Category"
            isBusy={false}
            type="primary"
            theme="dark"
            selector="_id"
            displayKey="category_name"
            dropdownItems={categories}
            selectedItem={categories.find(x => x._id == category?.id)?.category_name}
            onClick={item => {
              setCategory({ name: item.category_name, id: item._id })
            }}
          />

          <MultiDropDownButtonComponent
            title="Programmes"
            theme={'dark'}
            subItems={programs}
            displayKey={'program_name'}
            selected={selectedGroups}
            onSubmit={programs => {
              setSelectedGroups(programs)
              setValues({
                ...values,
                program_ids: programs.map(e => {
                  return e.id
                })
              })
            }}
          />

          <DatePickerComponent
            title={'Select Start Time'}
            isTimeOnly={true}
            onChange={e => {
              console.log('e', e)
              const date = moment(startDate).format('YYYY-MM-DD')
              const time = moment(e).utcOffset('+02:00').format('THH:mm:ss.SSSZ')
              const dateTime = `${date}${time}`
              setValues({
                ...values,
                start_date: dateTime
              })
            }}
          />
          {/* <DatePickerComponent
            title={'Select Start Date & Time'}
            date={startDate}
            isDateOnly={false}
            onChange={e => {
              setEndDate(e)
            }}
          /> */}
          <DatePickerComponent
            title={'Select End Date & Time'}
            date={values?.end_date}
            isDateOnly={false}
            onChange={e => {
              setEndDate(e)
            }}
          />

          <DropdownButtonComponent
            title="Venue"
            placeholder="Select Venue"
            isBusy={false}
            type="primary"
            theme="dark"
            displayKey="name"
            dropdownItems={venues}
            selector="_id"
            selectedItem={venues?.find(x => x._id == venue.id)?.name}
            onClick={item => {
              setVenue({ name: item.name, id: item._id })
            }}
          />

          {type != null && type?.key === 'open_day' ? (
            <OpenDayForm
              onChange={e => {
                setValues({
                  ...values,
                  ...e
                })
              }}
              formValues={values}
              //   onSetFileUploads={() => {}}
            />
          ) : (
            <div></div>
          )}
          {type != null && type?.key === 'oi_workshop' ? (
            <OiWorkshopForm
              onChange={e => {
                setValues({
                  ...values,
                  ...e
                })
              }}
              formValues={values}
              //   onSetFileUploads={() => {}}
            />
          ) : (
            <div></div>
          )}

          {type != null && type?.key === 'catch_up_day' ? (
            <CatchUpDayForm
              onChange={e => {
                setValues({
                  ...values,
                  ...e
                })
              }}
              formValues={values}
              programs={programs}
              //   onSetFileUploads={() => {}}
            />
          ) : (
            <div></div>
          )}
          {type != null && type?.key === 'veb_day' ? (
            <VebDayForm
              onChange={e => {
                setValues({
                  ...values,
                  ...e
                })
              }}
              formValues={values}
              programs={programs}
              groups={groups}
              //   onSetFileUploads={() => {}}
            />
          ) : (
            <div></div>
          )}
        </div>
        <div className="flex justify-center items-center">
          <ButtonComponent
            title="Save"
            icon={faSave}
            isLoading={false}
            type="primary"
            onClick={v => {
              setIsLoading(true)
              _onSave(values)
            }}
          />
        </div>
      </PopupControl>
      <LoaderControl show={isLoading} />
      <NotificationControl notificationData={notificationData} />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    mainState: state.mainState,
    groupState: state.groupState,
    programsState: state.programsState
  }
}

export default connect(mapStateToProps)(CreateEventComponent)
