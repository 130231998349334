import axios from 'axios'
import Cookies from 'universal-cookie'

const _apiBaseURL = process.env.API_URL
const _mediaURL = process.env.MEDIA_URL
const _mediaToken = process.env.MEDIA_TOKEN

const getToken = () => {
  const cookies = new Cookies()
  const cookieJSON = cookies.get('veb-user-cookie') || {}
  return cookieJSON.token
}

const setLoader = () => {
  let loader = document.getElementById('loaderGlobal')
  loader.style.display = 'block'
}
const hideLoader = () => {
  let loader = document.getElementById('loaderGlobal')
  loader.style.display = 'none'
}

export const apiGet = async ({ endpoint }) => {
  setLoader()

  return await axios({
    method: 'GET',
    url: `${_apiBaseURL}/${endpoint}`,
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'content-type': 'application/json'
    }
  })
    .then(response => {
      //   console.log(`api -> apiGet -> calls -> ${endpoint} SUCCESS:`, response)
      return response
    })
    .catch(error => {
      //   console.log(`api -> apiGet -> calls -> ${endpoint} ERROR:`, error)
      return extractErrorMessage(error)
    })
    .finally(() => {
      hideLoader()
    })
}

export const apiPost = async ({ endpoint, data, contentType = undefined, type = undefined }) => {
  setLoader()
  return await axios({
    method: 'POST',
    url: `${_apiBaseURL}/${endpoint}`,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getToken()}`,
      'content-type': contentType ? contentType : 'application/json'
    },
    data: data
  })
    .then(response => {
      //   console.log(`api -> apiPost -> calls -> ${endpoint} SUCCESS:`, response)
      return response
    })
    .catch(error => {
      //   console.log(`api -> apiGet -> calls -> ${endpoint} ERROR:`, error)
      return extractErrorMessage(error)
    })
    .finally(() => {
      hideLoader()
    })
}

export const apiPut = async ({ endpoint, contentType, data }) => {
  setLoader()
  return await axios({
    method: 'PUT',
    url: `${_apiBaseURL}/${endpoint}`,
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'content-type': contentType ? contentType : 'application/json'
    },
    data: data
  })
    .then(response => {
      return response
    })
    .catch(error => {
      return extractErrorMessage(error)
    })
    .finally(() => {
      hideLoader()
    })
}

//Private Functions
const extractErrorMessage = error => {
  if (error && error.response && error.response.data && error.response.data.message) {
    let message = `<p>${error.response.data.message}.</p>`
    document.getElementById('globalNotification').style.display = 'inline-flex'

    if (error.response.data.errors && error.response.data.errors.length > 0) {
      message += '<p>Requred:</p>'
      error.response.data.errors.forEach(x => {
        message += `"${x.param.split('_').join(' ')}",`
      })
    }
    document.getElementById('globalNotificationMessage').innerHTML = message
  }
  return error.response
}
