export const mock = ['62f3c1f2ffd66af0f0a28ceb', '62f3c1f2ffd66af0f0a28ceb']

export const calendarPayloadMock = {
  program_id: '62f3c1f2ffd66af0f0a28ceb',
  curriculum_id: '63243a9bca5a6386c4950548',
  venue_ids: ['62ecca1861610da872b1fc25'],
  group_ids: ['62fb791affd66af0f0c76922', '62a98ee8d9b608ec60468205', '62fcb3d7ffd66af0f0cd748a'],
  learner_ids: ['6316e6b4db40d741faae717b'],
  facilitator_ids: ['62fb9fb69184a9f71fbf8c27'],
  interaction_ids: ['63243a9b446464fcee6002f4'],
  start_date: '2020-03-03T00:00:00.000Z',
  end_date: '2021-03-03T00:00:00.000Z',
  start_time: '1:00',
  end_time: '2:00'
}

export const initialCalendarValues = {
  program_id: '',
  curriculum_id: '',
  venue_ids: [],
  group_ids: [],
  learner_ids: [],
  facilitator_ids: [],
  interaction_ids: [],
  start_date: '',
  end_date: '',
  start_time: '',
  end_time: ''
}

export const calendarValidationConfig = [
  { field: 'program_id', types: ['required'] },
  { field: 'curriculum_id', types: ['required'] },
  { field: 'venue_ids', types: ['required'] },
  { field: 'group_ids', types: ['required'] },
  { field: 'learner_ids', types: ['required'] },
  { field: 'facilitator_ids', types: ['required'] },
  { field: 'interaction_ids', types: ['required'] },
  { field: 'start_date', types: ['required', 'date'] },
  { field: 'end_date', types: ['required', 'date'] },
  { field: 'start_time', types: ['required'] },
  { field: 'end_time', types: ['required'] }
]

export const eventsMock = [
  {
    id: 1,
    title: 'Calendar Event',
    start: '2022-09-06T00:00:00',
    end: '2022-09-16T00:00:00'
  }
]
