import React, { useState } from 'react'

import { connect, useDispatch } from 'react-redux'

import { saveGreenlightSurvey } from '../../asyncActions/greenlight'
import { setNotification } from '../../asyncActions/notification'
import { surveyIndicators } from '../../constants/constants'
import ButtonControl from '../../controls/button/button'
import { CircleIconText } from '../../controls/circleIconText/circleIconText'
import ImageAvatarControl from '../../controls/imageAvatar/imageAvatar'
import TableControl from '../../controls/table/table'
import TableContentWrapper from '../../controls/tableContentWrapper/tableContentWrapper'
import TextAreaControl from '../../controls/textarea/textarea'
import TextInputControl from '../../controls/textinput/textinput'
import { useFormActionHook } from '../../hooks/useFormActionHook'
import { getDataWithIndicatorsIcons, getQuestionsAndIndicators } from '../../utils/surveyUtils'
import { greenlightColumns, greenlightValidationConfig, initialGreenlightValues } from './constants'
import AddDimensionsModal from './modals/addDimensionsModal'
import AddPhotoModal from './modals/addPhotoModal'
import ImportCsvModal from './modals/importCsvModal'
import InputComponent from '../customComponents/input'
import AvatarComponent from '../customComponents/avatar'
import { faFileImport, faPlus, faSave, faSearch } from '@fortawesome/pro-light-svg-icons'
import ButtonComponent from '../customComponents/button'
import DatePickerComponent from '../customComponents/datepicker'

const AddGreenlightSurvey = ({ notificationState: notificationData }) => {
  const dispatch = useDispatch()
  const [showAddDimensions, setShowAddDimensions] = useState(false)
  const [showImportCsv, setShowImportCsv] = useState(false)
  const [showAddPhoto, setShowAddPhoto] = useState(false)
  const [formValues, setFormValues] = useState(initialGreenlightValues)
  const { handleSubmit } = useFormActionHook(formValues, greenlightValidationConfig)

  const greenlightTableData = getDataWithIndicatorsIcons({
    data: formValues.dimensions,
    iconConfig: surveyIndicators,
    lookup: 'questions.indicator'
  })
  const tableData = getQuestionsAndIndicators({ data: greenlightTableData, lookup: 'indicator' })

  const _toggleAddDimensions = () => {
    setShowAddDimensions(!showAddDimensions)
  }
  const _toggleAddPhoto = () => {
    setShowAddPhoto(!showAddPhoto)
  }

  const _toggleImportCsv = () => {
    setShowImportCsv(!showImportCsv)
  }

  const _onInputChange = value => {
    setFormValues({ ...formValues, ...value })
  }

  const _onSubmitDimensionResults = values => {
    setFormValues({ ...formValues, dimensions: [...formValues.dimensions, values] })
  }
  const _onSubmitCsv = values => {
    setFormValues({ ...formValues, dimensions: [...formValues.dimensions, ...values] })
  }
  const _onSubmitPhoto = photo => {
    setFormValues({ ...formValues, photo })
  }

  const _saveSurvey = () =>
    handleSubmit({
      formAction: () => {
        dispatch(saveGreenlightSurvey(formValues))
      },
      onFailedValidation: errors => {
        dispatch(
          setNotification({
            title: 'Validation Failed',
            messages: errors
          })
        )
      }
    })

  return (
    <TableContentWrapper title="Greenlight Results" notificationData={notificationData}>
      {/* ----------Forms */}
      <div className="w-full ">
        <div className="flex w-full flex-col items-center justify-center gap-16">
          <div className="flex flex-row gap-4">
            {surveyIndicators.map((item, i) => (
              <div key={i} className="">
                <CircleIconText
                  text={item.name}
                  className={item.color}
                  iconWithText={true}
                  iconColor={item.iconColor}
                  iconText={item.iconText}
                />
              </div>
            ))}
          </div>
          <div className="flex flex-col w-full gap-12 px-52">
            <div className="flex justify-between w-full gap-4">
              <div className="flex flex-col gap-2 w-full ">
                <InputComponent
                  title="Name"
                  theme="form"
                  value={formValues.name}
                  onChange={name => _onInputChange({ name })}
                />
                <InputComponent
                  title="Identity Card"
                  theme="form"
                  value={formValues.identity_card}
                  onChange={identity_card => _onInputChange({ identity_card })}
                />
                <DatePickerComponent
                  title="Date"
                  date={formValues.date}
                  onChange={date => _onInputChange({ date })}
                />
              </div>
              <div className="flex flex-col gap-2 w-full">
                <InputComponent
                  title="Office"
                  theme="form"
                  value={formValues.office}
                  onChange={office => _onInputChange({ office })}
                />
                <InputComponent
                  title="Survey timeline"
                  theme="form"
                  value={formValues.survey_timeline}
                  onChange={survey_timeline => _onInputChange({ survey_timeline })}
                />
              </div>
            </div>
            <div className="flex gap-8 w-full">
              <ImageAvatarControl imgUrl={formValues.photo} onClick={_toggleAddPhoto} />

              <InputComponent
                size={'lg'}
                title="Narrative"
                theme="form"
                value={formValues.narrative}
                onChange={narrative => _onInputChange({ narrative })}
              />
            </div>
            <InputComponent
              theme={'form'}
              icon={faSearch}
              type="search"
              placeholder="Search"
              onChange={v => {}}
            />
            <TableControl
              columns={greenlightColumns}
              data={tableData}
              hideEmptyTableData={false}
              width="full"
              rowClicked={id => {}}
            />
            <div className="flex flex-row justify-center items-center w-full gap-4">
              <ButtonComponent
                title="Save"
                icon={faSave}
                onClick={_saveSurvey}
                isBusy={false}
                type="primary"
              />
              <ButtonComponent
                title="Add Dimensions"
                icon={faPlus}
                onClick={_toggleAddDimensions}
                isBusy={false}
                type="primary"
              />
              <ButtonComponent
                onClick={_toggleImportCsv}
                icon={faFileImport}
                title="Import CSV"
                isBusy={false}
                type="primary"
              />
            </div>
          </div>
        </div>
      </div>

      <AddDimensionsModal
        onSuccess={_onSubmitDimensionResults}
        open={showAddDimensions}
        setShow={_toggleAddDimensions}
      />
      <AddPhotoModal onSuccess={_onSubmitPhoto} open={showAddPhoto} setShow={_toggleAddPhoto} />
      <ImportCsvModal onSuccess={_onSubmitCsv} open={showImportCsv} setShow={_toggleImportCsv} />
    </TableContentWrapper>
  )
}

const mapStateToProps = state => {
  return {
    notificationState: state.notificationState,
    greenlightState: state.greenlightState
  }
}

export default connect(mapStateToProps)(AddGreenlightSurvey)
