import { editFacilitatorState, setFacilitatorState } from '../actions/facilitator'
import { get_all_users } from '../api/calls/get_all_users'
import { get_facilitator_programs } from '../api/calls/users/get_facilitator_programs'
import { get_facilitator_teaching_days } from '../api/calls/users/get_facilitator_teaching_days'
import { initialPageNumber, itemsPerPage } from '../constants/constants'

export const getFacilitators = (pageNumber = initialPageNumber, search = '') => {
  const filters = `type_code=FACILITATOR-ACC&search=${search}`
  return dispatch => {
    get_all_users(`${filters}`)
      .then(response => {
        if (response.data.success) {
          dispatch(
            setFacilitatorState({
              facilitatorsLoaded: true,
              facilitators: response.data.content,
              paginate: response.data.paginate
            })
          )
        }
      })
      .catch(error => {})
  }
}

export const getFacilitatorsPrograms = user => {
  return dispatch => {
    get_facilitator_programs(`id=${user._id}`)
      .then(res => {
        if (res.data.success) {
          dispatch(
            editFacilitatorState({
              ...user,
              programs: res.data.content
            })
          )
        }
      })
      .catch(error => {})
  }
}

export const getFacilitatorTeachingDays = user => {
  return dispatch => {
    get_facilitator_teaching_days(`id=${user._id}`)
      .then(res => {
        if (res.data.success) {
          dispatch(
            editFacilitatorState({
              ...user,
              teachingDays: res.data.content
            })
          )
        }
      })
      .catch(error => {})
  }
}
