import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { editEntityState, updateEntityState } from '../../../actions/entities'

import { editFacilitatorState, updateFacilitatorState } from '../../../actions/facilitator'
import { editLearnerState, updateLearnerState } from '../../../actions/learner'
import { editRecruiterState, updateRecruiterState } from '../../../actions/recruiter'
import { upload_file } from '../../../api/calls/upload_file'
import { addUser, setNotification, updateUser } from '../../../asyncActions'
import { VALIDATION_FAILED_MSG } from '../../../constants/constants'
import ButtonControl from '../../../controls/button/button'
import DropdownControl from '../../../controls/dropdown/dropdown'
import FileInputControl from '../../../controls/fileinput/fileinput'
import ModalFormWrapper from '../../../controls/modalFormWrapper/modalFormWrapper'
import PopupControl from '../../../controls/popup/popup'
import TabControl from '../../../controls/tab/tab'
import TextInputControl from '../../../controls/textinput/textinput'
import { useFormActionHook } from '../../../hooks/useFormActionHook'
import { selectNotificationState } from '../../notifications/selectors'
import { initialUserValues, userValidationConfig } from '../constants'
import InputComponent from '../../customComponents/input'
import DropdownButtonComponent from '../../customComponents/dropdownButton'
import ButtonComponent from '../../customComponents/button'
import FilesComponent from '../../customComponents/files'
import { faSave } from '@fortawesome/pro-light-svg-icons'
import CheckBoxControl from '../../../controls/checkbox/checkbox'
import { nationalities } from '../../studentApplication/constants'
import { get_groups } from '../../../api/calls/studentApplications/get_groups'
import { get_all_tasks } from '../../../api/calls'

const AddUserComponent = ({
  open = false,
  setShow,
  type = '',
  user = {},
  onsuccess = () => {}
}) => {
  const dispatch = useDispatch()
  const notificationData = useSelector(selectNotificationState)

  const [formValues, setFormValues] = useState(initialUserValues)
  const { handleSubmit } = useFormActionHook(formValues, userValidationConfig)
  const [userType, setUserType] = useState('User')
  const [isLoading, setIsLoading] = useState(false)
  const [groups, setGroups] = useState([])
  const [events, setEvents] = useState([])

  const statuses = [
    { name: 'Active', value: 'ACTIVE', id: '1' },
    { name: 'Inactive', value: 'INACTIVE', id: '2' },
    { name: 'In-Review', value: 'IN-REVIEW', id: '3' }
  ]

  const _onInputChange = value => {
    setFormValues({ ...formValues, ...value })
  }

  useEffect(() => {
    setFormValues({ ...initialUserValues, ...user, type_code: type })
    //fetch groups
    get_groups().then(e => {
      if (e.data.success) {
        setGroups(e.data.content)
      }
    })

    //fetch events
    get_all_tasks().then(e => {
      if (e.data.success) {
        setEvents(e.data.content)
      }
    })
  }, [user])

  useEffect(() => {
    if (type) {
      switch (type) {
        case 'RECRUITER-ACC':
          return setUserType('Recruitment')
        case 'LEARNER-ACC':
          return setUserType('Learner')
        case 'FACILITATOR-ACC':
          return setUserType('Facilitator')
        case 'ENTITY-ACC':
          return setUserType('entity')
      }
    }
  }, [open, user])

  const _saveUser = () =>
    handleSubmit({
      formAction: () => {
        if (formValues._id) {
          formValues.user_id = formValues._id
          dispatch(updateUser(formValues))
          editData(formValues)
        } else {
          dispatch(addUser(formValues))
          addData(formValues)
        }
      },
      onFailedValidation: errors => {
        dispatch(
          setNotification({
            title: VALIDATION_FAILED_MSG,
            messages: errors
          })
        )
      }
    })

  const editData = data => {
    switch (type) {
      case 'RECRUITER-ACC':
        return dispatch(editRecruiterState(data))
      case 'LEARNER-ACC':
        return dispatch(editLearnerState(data))
      case 'FACILITATOR-ACC':
        return dispatch(editFacilitatorState(data))
      case 'ENTITY-ACC':
        return dispatch(editEntityState(data))
    }
  }

  const addData = data => {
    switch (type) {
      case 'RECRUITER-ACC':
        return dispatch(updateRecruiterState(data))
      case 'LEARNER-ACC':
        return dispatch(updateLearnerState(data))
      case 'FACILITATOR-ACC':
        return dispatch(updateFacilitatorState(data))
      case 'ENTITY-ACC':
        return dispatch(updateEntityState(data))
    }
  }

  const disabilityOptions = [
    {
      name: 'Yes',
      value: true
    },
    {
      name: 'No',
      value: false
    }
  ]

  //   useEffect(() => {}, [userType])

  return (
    <div>
      <PopupControl
        title={`${user._id ? 'Update' : 'Add'} ${userType}`}
        isOpen={open}
        close={{
          showClose: true,
          onClose: () => {
            setShow(false)
          }
        }}
      >
        <ModalFormWrapper notificationData={notificationData}>
          <div className="flex flex-col gap-4">
            <TabControl titles={['General', 'Upload Documents']}>
              <div className="flex flex-row gap-4">
                <div className="w-full flex flex-col gap-4">
                  <InputComponent
                    theme="dark"
                    rounded="lg"
                    placeholder="Name"
                    value={formValues.user_name}
                    onChange={v =>
                      _onInputChange({
                        user_name: v
                      })
                    } //
                  />

                  <InputComponent
                    theme="dark"
                    rounded="lg"
                    placeholder="Surname"
                    value={formValues.user_surname}
                    onChange={v =>
                      _onInputChange({
                        user_surname: v
                      })
                    }
                  />

                  <InputComponent
                    theme="dark"
                    rounded="lg"
                    placeholder="Email / ID Number"
                    value={formValues.user_email}
                    onChange={v =>
                      _onInputChange({
                        user_email: v
                      })
                    }
                  />

                  <InputComponent
                    theme="dark"
                    rounded="lg"
                    placeholder="Mobile Number"
                    value={formValues.user_cell_number}
                    onChange={v =>
                      _onInputChange({
                        user_cell_number: v
                      })
                    }
                  />

                  <InputComponent
                    theme="dark"
                    placeholder="Training Provider"
                    value={formValues?.training_provider}
                    onChange={training_provider => {
                      _onInputChange({ training_provider })
                    }}
                  />

                  <InputComponent
                    theme="dark"
                    placeholder="Municipality"
                    value={formValues?.municipality}
                    onChange={municipality => {
                      _onInputChange({ municipality })
                    }}
                  />
                  <InputComponent
                    theme="dark"
                    placeholder="Age"
                    isNumber={true}
                    value={formValues?.age}
                    onChange={age => {
                      _onInputChange({ age })
                    }}
                  />

                  <DropdownButtonComponent
                    placeholder="Select Race"
                    isBusy={false}
                    type="primary"
                    theme="dark"
                    displayKey="name"
                    dropdownItems={[
                      {
                        name: 'Black',
                        value: 'Black'
                      },
                      {
                        name: 'Coloured',
                        value: 'Coloured'
                      },
                      {
                        name: 'Indian',
                        value: 'Indian'
                      },
                      {
                        name: 'White',
                        value: 'White'
                      },
                      {
                        name: 'Asian',
                        value: 'Asian'
                      }
                    ]}
                    selectedItem={formValues.race}
                    onClick={item => {
                      _onInputChange({ race: item.value })
                    }}
                  />

                  <div className="flex w-full gap-4">
                    <div className="flex gap-2 items-center">
                      <CheckBoxControl
                        theme="dark"
                        name="gender"
                        checked={formValues.gender == 'Male'}
                        type="radio"
                        value="Male"
                        onChange={e => {
                          _onInputChange({ gender: e.target.value })
                        }}
                      />
                      <p className="text-white">Male</p>
                    </div>
                    <div className="flex gap-2 items-center">
                      <CheckBoxControl
                        theme="dark"
                        name="gender"
                        checked={formValues.gender == 'Female'}
                        type="radio"
                        value="Female"
                        onChange={e => {
                          _onInputChange({ gender: e.target.value })
                        }}
                      />
                      <p className="text-white">Female</p>
                    </div>
                  </div>

                  <div className="flex w-full gap-2">
                    <DropdownButtonComponent
                      placeholder="Disability"
                      isBusy={false}
                      type="primary"
                      theme="dark"
                      displayKey="name"
                      dropdownItems={disabilityOptions}
                      selectedItem={`Disabled (${formValues.disabled ? 'Yes' : 'No'})`}
                      onClick={item => {
                        _onInputChange({ disabled: item.value })
                      }}
                    />
                    {formValues.disabled && (
                      <InputComponent
                        theme="dark"
                        placeholder="Add disabilities (sep. by comma)"
                        value={formValues?.disability}
                        onChange={disability => {
                          _onInputChange({ disability })
                        }}
                      />
                    )}
                  </div>

                  <DropdownButtonComponent
                    placeholder="Select Nationality"
                    isBusy={false}
                    type="primary"
                    theme="dark"
                    displayKey="name"
                    showSearch={true}
                    dropdownItems={nationalities}
                    selectedItem={formValues.nationality}
                    onClick={item => {
                      _onInputChange({ nationality: item?.name })
                    }}
                  />

                  <div className="flex flex-row gap-2">
                    <DropdownButtonComponent
                      placeholder="Select Group"
                      isBusy={false}
                      type="primary"
                      theme="dark"
                      displayKey="name"
                      dropdownItems={groups}
                      selectedItem={groups.find(e => e.id == formValues.group_id)?.name}
                      onClick={item => {
                        _onInputChange({ group_id: item?.id })
                      }}
                    />
                    <DropdownButtonComponent
                      placeholder="Select Event"
                      isBusy={false}
                      type="primary"
                      theme="dark"
                      displayKey="event_name"
                      dropdownItems={events.filter(e => e.type == 'OPEN_DAY')}
                      selectedItem={events.find(e => e._id == formValues.event_id)?.event_name}
                      onClick={item => {
                        _onInputChange({ event_id: item?._id })
                      }}
                    />
                  </div>

                  <DropdownButtonComponent
                    placeholder="Select Current Status"
                    theme="dark"
                    rounded="lg"
                    displayKey="name"
                    dropdownItems={statuses}
                    selectedItem={statuses.find(x => x.value == formValues.status_code)?.name}
                    onClick={item => {
                      _onInputChange({
                        status_code: item.value
                      })
                    }}
                  />

                  {formValues.status_code == 'INACTIVE' && (
                    <InputComponent
                      theme="dark"
                      rounded="lg"
                      size="lg"
                      placeholder="Reason for Inactive"
                      value={formValues.inactive_reason}
                      onChange={v =>
                        _onInputChange({
                          inactive_reason: v
                        })
                      }
                    />
                  )}

                  {userType === 'entity' ? (
                    <div>
                      <InputComponent
                        theme="dark"
                        rounded="lg"
                        title="Business Name"
                        value={formValues.entity.business_name}
                        onChange={v =>
                          _onInputChange({
                            entity: { ...formValues.entity, business_name: v }
                          })
                        }
                      />

                      <br />

                      <InputComponent
                        theme="dark"
                        rounded="lg"
                        title="Owner"
                        value={formValues.entity.owner}
                        onChange={v =>
                          _onInputChange({
                            entity: { ...formValues.entity, owner: v }
                          })
                        }
                      />
                    </div>
                  ) : null}

                  <InputComponent
                    theme="dark"
                    rounded="lg"
                    title="Password"
                    value={formValues.user_password}
                    onChange={v =>
                      _onInputChange({
                        user_password: v
                      })
                    }
                  />
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <FilesComponent
                  label={'Document 1'}
                  onChange={v => {
                    setIsLoading(true)
                    upload_file({ file: v, name: v.name }).then(res => {
                      if (res.data.success) {
                        _onInputChange({
                          etag: res.data.content.etag,
                          document_1: res.data.content.location
                        })
                      }
                      setIsLoading(false)
                    })
                  }}
                />
                <FilesComponent
                  label={'Document 2'}
                  onChange={v => {
                    setIsLoading(true)
                    upload_file({ file: v, name: v.name }).then(res => {
                      if (res.data.success) {
                        _onInputChange({
                          etag: res.data.content.etag,
                          document_2: res.data.content.location
                        })
                      }
                      setIsLoading(false)
                    })
                  }}
                />
                <FilesComponent
                  label={'Document 3'}
                  onChange={v => {
                    setIsLoading(true)
                    upload_file({ file: v, name: v.name }).then(res => {
                      if (res.data.success) {
                        _onInputChange({
                          etag: res.data.content.etag,
                          document_3: res.data.content.location
                        })
                      }
                      setIsLoading(false)
                    })
                  }}
                />
              </div>
            </TabControl>
            <ButtonComponent
              title={`${user._id ? 'Update' : 'Add'} ${userType}`}
              isLoading={isLoading}
              icon={faSave}
              type="primary"
              onClick={_saveUser}
            />
          </div>
        </ModalFormWrapper>
      </PopupControl>
    </div>
  )
}

export default AddUserComponent
