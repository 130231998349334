import React from 'react'

import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

import { theme } from '../../../../../tailwind.config'
import TitleCardControl from '../../../../controls/cards/titlecard'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
)

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top'
    },
    title: {
      display: true,
      text: ''
    }
  }
}

const { extend } = theme
const labels = ['item1', 'item2', 'item3', 'item4', 'item5']
const data = {
  labels,
  datasets: [
    {
      label: 'Doing Well',
      data: [418, 797, 748, 920, 583],
      backgroundColor: extend.textColor.green
    },
    {
      label: 'Struggling',
      data: [418, 583, 595, 338, 77],
      backgroundColor: extend.textColor.yellow
    },
    {
      label: 'Stuck',
      data: [418, 797, 583, 595, 338],
      backgroundColor: extend.textColor.orange
    }
  ]
}

const BarchartWidget = ({ items = data }) => {
  return (
    <TitleCardControl style="rounded-2xl shadow-xs  border border-homeBorder ">
      <div className="items-center w-full h-full">
        <Bar options={options} data={data} />;
      </div>
    </TitleCardControl>
  )
}

export default BarchartWidget
