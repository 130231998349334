import { SET_VENUES_STATE } from '../../constants/redux_types'

const INITIAL_STATE = { venues: [], venuesLoaded: false }

export function venueStateReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_VENUES_STATE:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
