import React, { useEffect, useState } from 'react'

import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

import { setNotification } from '../../../asyncActions'
import { DATE_FORMAT } from '../../../constants/constants'
import ButtonControl from '../../../controls/button/button'
import CalendarController from '../../../controls/calendar/calendar'
import { useFormActionHook } from '../../../hooks/useFormActionHook'
import { selectGroups } from '../../groups/selectors'
import { extractByIds, extractLearnersIdsByGroupIds } from '../../programs/utils'
import { calendarValidationConfig, initialCalendarValues } from '../constants'
import { selectDataWithIds } from '../selectors'
import { extractRangeFromEvents, getTimeList } from '../utils/utils'
import MultiDropDownButtonComponent from '../../customComponents/multiDropdownButton/multiDropdownButton'
import ButtonComponent from '../../customComponents/button'
import { faSave } from '@fortawesome/pro-light-svg-icons'

const EventCalendarTab = ({
  curriculum,
  facilitators,
  groups,
  venues,
  learners,
  interactions,
  onAddToCalendar
}) => {
  const dispatch = useDispatch()
  const [eventRange, setEventRange] = useState(new Date())
  const [formValues, setFormValues] = useState(initialCalendarValues)
  const { handleSubmit } = useFormActionHook(formValues, calendarValidationConfig)
  // -----
  const allGroups = useSelector(selectGroups)
  const learnersIds = extractLearnersIdsByGroupIds(allGroups, 'learners')(formValues.group_ids)
  const learnersByGroup = extractByIds(learners)(learnersIds)
  // -----
  const timeList = selectDataWithIds(getTimeList())
  /* 
    on mount, 
    attach required props to formValues 
  */
  useEffect(() => {
    _onChangeFields({
      program_id: curriculum?.program_id,
      curriculum_id: curriculum?._id,
      start_date: curriculum?.start_date,
      end_date: curriculum?.end_date
    })
    if (curriculum) _updateRange(new Date())
  }, [curriculum])

  const _updateRange = (activeDate = new Date()) => {
    const ranges = extractRangeFromEvents([curriculum], activeDate)
    setEventRange(ranges)
  }

  const _onChangeFields = value => {
    setFormValues({ ...formValues, ...value })
  }
  const _onChangeLearnerFields = learner_ids => {
    _onChangeFields({ learner_ids })
  }

  const _onDateChange = value => {
    const payload = {
      start_date: moment(value[0]).format(DATE_FORMAT),
      end_date: moment(value[1]).format(DATE_FORMAT)
    }
    setEventRange(value)
    _onChangeFields(payload)
  }

  const _onSubmit = () =>
    handleSubmit({
      formAction: () => {
        onAddToCalendar(formValues)
      },
      onFailedValidation: errors =>
        dispatch(
          setNotification({
            title: 'Validation Failed',
            messages: errors
          })
        )
    })

  // useEffect(() => {
  //   console.log('formValues', formValues)
  // }, [formValues])

  return (
    <div>
      <div className="rounded-xl p-3 shadow-md hover:bg-card h-fit w-80 gap-2 flex flex-col">
        <MultiDropDownButtonComponent
          title="Venues"
          displayKey={'name'}
          subItems={venues}
          selected={formValues.venue_ids}
          onSubmit={venue_ids => _onChangeFields({ venue_ids })}
        />
        <MultiDropDownButtonComponent
          title="Interactions"
          displayKey={'name'}
          subItems={interactions}
          selected={formValues.interaction_ids}
          onSubmit={interaction_ids => _onChangeFields({ interaction_ids })}
        />
        <div className="rounded-xl pb-4">
          <CalendarController
            selectRange={true}
            displayKey={'name'}
            value={eventRange}
            onChange={_onDateChange}
            onActiveStartDateChange={({ activeStartDate }) => _updateRange(activeStartDate)}
          />
        </div>
        <MultiDropDownButtonComponent
          title="Start Time"
          isMultiple={false}
          displayKey={'name'}
          selected={formValues.start_time}
          onSubmit={start_time => _onChangeFields({ start_time })}
          subItems={timeList}
        />
        <MultiDropDownButtonComponent
          title="End Time"
          isMultiple={false}
          displayKey={'name'}
          selected={formValues.end_time}
          onSubmit={end_time => _onChangeFields({ end_time })}
          subItems={timeList}
        />
        <MultiDropDownButtonComponent
          title="Facilitators"
          subItems={facilitators}
          displayKey={'name'}
          selected={formValues.facilitator_ids}
          onSubmit={facilitator_ids => _onChangeFields({ facilitator_ids })}
        />
        <MultiDropDownButtonComponent
          title="Groups"
          displayKey={'name'}
          subItems={groups}
          selected={formValues.group_ids}
          onSubmit={group_ids => _onChangeFields({ group_ids })}
        />
        <MultiDropDownButtonComponent
          title="Learners"
          subItems={learners ?? []}
          displayKey={'fullName'}
          selected={formValues.learner_ids}
          onSubmit={_onChangeLearnerFields}
        />
      </div>
      <div className="flex justify-center items-center mt-6">
        <ButtonComponent title="Add to Calendar" icon={faSave} type="primary" onClick={_onSubmit} />
      </div>
    </div>
  )
}

export default EventCalendarTab
