import {
  EDIT_LEARNER_STATE,
  SET_LEARNER_STATE,
  UPDATE_LEARNER_STATE
} from '../constants/redux_types'

export const setLearnerState = payload => {
  return {
    type: SET_LEARNER_STATE,
    payload: payload
  }
}

export const editLearnerState = payload => {
  return {
    type: EDIT_LEARNER_STATE,
    payload: payload
  }
}

export const updateLearnerState = payload => {
  return {
    type: UPDATE_LEARNER_STATE,
    payload: payload
  }
}
