import React from 'react'

import { faPencil } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ButtonControl from '../../../controls/button/button'
import ButtonTabControl from '../../../controls/buttonTab/buttonTab'
import TableContentWrapper from '../../../controls/tableContentWrapper/tableContentWrapper'
import BarchartWidget from './components/barchartWidget'
import DateRangeWidget from './components/dateRangeWidget'

const _tabs = ['Tab1', 'Tab2', 'Tab3', 'Tab4']
const _csvData = [
  { name: 'test', col1: '50%', col2: '50%', col3: '50%' },
  { name: 'test1', col1: '50%', col2: '50%', col3: '50%' },
  { name: 'test2', col1: '50%', col2: '50%', col3: '50%' }
]
const StoplightGraph = props => {
  return (
    <TableContentWrapper title={''}>
      {/* --------- tabs */}
      <ButtonTabControl titles={_tabs}>
        <div className="flex flex-row gap-4">
          <div className="w-72 flex flex-col gap-4"></div>
        </div>
        <div className="flex flex-col gap-4"></div>
      </ButtonTabControl>

      {/* --------- import csv */}
      <div className="place-content-center mt-10 flex">
        <div className="w-1/2 rounded-md shadow-lg drop-shadow-lg">
          <div className="flex justify-end mr-5">
            <div className="px-3 py-1 bg-gray rounded-t-md text-sm text-white">Import CSV</div>
          </div>

          <div className="shadow-lg drop-shadow-lg p-5">
            <DateRangeWidget />
            <div className="flex flex-row">
              <div className="w-full">
                {_csvData.map((row, i) => (
                  <div key={i} className="grid grid-cols-5 my-5 mr-5 gap-4">
                    <div className="text-gray-500 place-self-start col-span-2">{row.name}</div>
                    <div className="text-green place-self-end">{row.col1}</div>
                    <div className="text-yellow place-self-end">{row.col2}</div>
                    <div className="text-red-600 place-self-end">{row.col3}</div>
                  </div>
                ))}
              </div>
              <div className="grid grid-cols-1 my-5 gap-4 pl-5 border-l border-gray">
                {_csvData.map((row, i) => (
                  <FontAwesomeIcon className="text-l flex text-dark" icon={faPencil} />
                ))}
              </div>
            </div>
            <div className="flex mt-10 justify-start">
              <div className="w-40">
                <ButtonControl
                  style="bg-cardIcon4"
                  placeholder="Add a line"
                  isBusy={false}
                  type="primary"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* --------- chart */}
      <div className="flex mt-10 justify-start">
        <BarchartWidget />
      </div>
    </TableContentWrapper>
  )
}

export default StoplightGraph
