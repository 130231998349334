import React, { useState, useEffect } from "react";

const TabControl = props => {
    const [ visibility, setVisibility ] = useState(false);
    const { titles, pages } = props;

    const [ visiblePageIndex, setVisiblePageIndex ] = useState(0);
    const [ tabTitles, setTabTitles ] = useState(titles);
    // const [ tabPages, setTabPages ] = useState(pages);

    //Did mount...
    useEffect(() => {
        if (!(props.children && titles) && !(props.children.length == titles.length)) {
            throw new Error("The children's length does not match the titles's length.");
        }
    }, []);

    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-row gap-4 border-b-2 border-blue-400">
                {tabTitles.map((tabTitle, index) => {
                    return (
                        <div key={index}
                            className={`px-4 py-2 text-white rounded-t-xl cursor-pointer ${index == visiblePageIndex && "bg-green"} 
                            ${index != visiblePageIndex && "hover:underline"}
                            `}
                            onClick={() => {
                                setVisiblePageIndex(index);
                            }}
                        >
                            {tabTitle}
                        </div>
                    )
                })}
            </div>

            {props.children.map(
                (child, i) => {
                    if (visiblePageIndex == i) {
                        return child;
                    }
                }
            )}
        </div>
    )
    
}

export default TabControl;