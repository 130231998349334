import React, { useEffect, useState } from 'react'

import moment from 'moment'
import { connect, useDispatch, useSelector } from 'react-redux'

import { editProgramState } from '../../../actions/programs'
import ButtonControl from '../../../controls/button/button'
import DropdownControl from '../../../controls/dropdown/dropdown'
import LoaderControl from '../../../controls/loader/loader'
import PopupControl from '../../../controls/popup/popup'
import { DATE_FORMAT } from '../../../constants/constants'
import { add_learner_program } from '../../../api/calls'
import { selectLearners } from '../selectors'
import DropdownButtonComponent from '../../customComponents/dropdownButton'
import { faSave } from '@fortawesome/pro-light-svg-icons'
import ButtonComponent from '../../customComponents/button'

const AddLearnerComponent = ({ learnerState, open = false, setShow, program = {} }) => {
  const dispatch = useDispatch()

  const [isBusy, setIsBusy] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [learner, setLearner] = useState({})
  const [programLearner, setProgramLearner] = useState([])
  const learners = useSelector(selectLearners).map(_ => ({
    ..._,
    fullName: `${_?.user_name} ${_?.user_surname}`
  }))

  useEffect(() => {
    setIsOpen(open)
    if (program.learners) {
      setProgramLearner(program.learners)
    }
  }, [open])

  return (
    <div>
      <PopupControl
        title="Adding A Learner"
        isOpen={open}
        close={{
          showClose: true,
          onClose: () => {
            setShow(false)
          }
        }}
      >
        <div className="flex flex-col gap-8">
          <div className="flex flex-row gap-4">
            <div className="w-72 flex flex-col gap-4">
              {/* <DropdownControl
                title="Select Learner"
                isBusy={false}
                type="primary"
                theme="dark"
                displayKey="fullName"
                subItems={learners.filter(x => !programLearner.map(x => x._id).includes(x._id))}
                selectedItem={learners.find(x => x._id == learner._id)}
                onClick={item => {
                  setLearner(item)
                }}
              /> */}
              <DropdownButtonComponent
                title={'Learner'}
                placeholder={'Please select learner'}
                theme="dark"
                dropdownItems={learners.filter(
                  x => !programLearner.map(x => x._id).includes(x._id)
                )}
                displayKey="fullName"
                selectedItem={learners.find(x => x._id == learner._id)?.fullName}
                onClick={item => {
                  setLearner(item)
                }}
              />

              {/* <ButtonControl
                    placeholder={'Save'}
                    isBusy={false}
                    type="primary"
                    onClick={v => {
                      const learnerStartDate = moment().format(DATE_FORMAT)
                      add_learner_program({
                        user_id: learner._id,
                        program_id: program._id,
                        start_date: learnerStartDate
                      }).then(res => {
                        if (res.data.success) {
                          setShow(false)
                          let _leaners = program.learners ? program.learners : []
                          program.learners = [learner, ..._leaners]
                          dispatch(editProgramState(program))
                        }
                      })
                    }}
                  /> */}
              <ButtonComponent
                title="Save"
                icon={faSave}
                onClick={v => {
                  const learnerStartDate = moment().format(DATE_FORMAT)
                  add_learner_program({
                    user_id: learner._id,
                    program_id: program._id,
                    start_date: learnerStartDate
                  }).then(res => {
                    if (res.data.success) {
                      setShow(false)
                      let _leaners = program.learners ? program.learners : []
                      program.learners = [learner, ..._leaners]
                      dispatch(editProgramState(program))
                    }
                  })
                }}
              />
            </div>
          </div>
        </div>
      </PopupControl>
      <LoaderControl show={isBusy} />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    learnerState: state.learnerState,
    mainState: state.mainState,
    userState: state.userState
  }
}

export default connect(mapStateToProps)(AddLearnerComponent)
