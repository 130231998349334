import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Loader from '../../assets/images/loader.png'

const ButtonComponent = props => {
  const {
    title,
    icon,
    theme = 'light',
    disabled,
    onClick,
    isLoading = false,
    isLink = false,
    sizes = 'md'
  } = props
  const options = {
    light: {
      titleColor: 'text-white',
      placeholder: 'text-white',
      color: !disabled && !isLoading ? 'bg-theme' : 'bg-gray cursor-not-allowed',
      hover: !disabled && !isLoading ? 'hover:bg-blue-400' : 'null'
    },
    dark: {
      titleColor: 'text-white',
      placeholder: 'text-gray',
      color: !disabled && !isLoading ? 'bg-textinput-dark' : 'bg-gray cursor-not-allowed',
      hover: !disabled && !isLoading ? 'hover:bg-blue-400' : null
    }
  }

  const size = {
    sm: 'text-sm px-5 py-1.5 font-medium',
    md: 'text-md px-5 py-2.5 font-medium',
    lg: 'text-lg px-5 py-4 font-medium'
  }

  return (
    <button
      onClick={onClick}
      type="button"
      className={`w-full ${options[theme].color} ${options[theme].hover} ${
        options[theme].titleColor
      } gap-2 ${isLink ? 'hover:underline' : 'hover:none'} text-white ${
        size[sizes]
      } rounded-md h-12 text-center hover:rounded-md inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`}
      disabled={disabled}
    >
      {isLoading == true ? (
        <div className="flex justify-center items-center gap-2 w-full">
          <img src={Loader} className="h-5 w-5 animate-spin" />
          <div>Loading...</div>
        </div>
      ) : (
        <div className="flex justify-center items-center gap-2 w-full">
          {icon && <FontAwesomeIcon icon={icon} />}
          {title}
        </div>
      )}
    </button>
  )
}

export default ButtonComponent
