import React from 'react'
import PopupControl from '../../../controls/popup/popup'
import ButtonComponent from '../../customComponents/button'
import { useDispatch } from 'react-redux'
import { makeGroupDefault } from '../../../asyncActions'

const MakeDefaultGroupPopUp = ({ openPopUp, onOpenPopUp, group_id }) => {
  const dispatch = useDispatch()
  return (
    <PopupControl
      title="Make Default Group"
      isOpen={openPopUp}
      close={{
        showClose: true,
        onClose: () => {
          onOpenPopUp(false)
        }
      }}
    >
      <div className="flex flex-col gap-2">
        <ButtonComponent
          title={'Yes, make default group.'}
          isLoading={false}
          type="primary"
          onClick={v => {
            dispatch(makeGroupDefault(group_id))
            onOpenPopUp(false)
          }}
        />
        <ButtonComponent
          title={'Cancel'}
          isBusy={false}
          type="primary"
          onClick={v => {
            onOpenPopUp(false)
          }}
        />
      </div>
    </PopupControl>
  )
}

export default MakeDefaultGroupPopUp
