import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { editLearnerState } from '../../../actions/learner'
import { upload_file } from '../../../api/calls'
import { save_payment } from '../../../api/calls/documents/save_payment'
import { getPrograms, setNotification } from '../../../asyncActions'
import LoaderControl from '../../../controls/loader/loader'
import NotificationControl from '../../../controls/notification/notification'
import PopupControl from '../../../controls/popup/popup'
import { selectNotificationState } from '../../notifications/selectors'
import { selectPrograms, selectProgramsLoaded } from '../../programs/selectors'
import InputComponent from '../../customComponents/input'
import DropdownButtonComponent from '../../customComponents/dropdownButton'
import FilesComponent from '../../customComponents/files'
import ButtonComponent from '../../customComponents/button'
import { faSave } from '@fortawesome/pro-light-svg-icons'
import DatePickerComponent from '../../customComponents/datepicker'

const AddPaymentComponent = ({ fileState, open = false, setShow, user = {} }) => {
  const dispatch = useDispatch()

  const programsLoaded = useSelector(selectProgramsLoaded)
  const programs = useSelector(selectPrograms)
  const notificationData = useSelector(selectNotificationState)
  const [values, setValues] = useState({})

  useEffect(() => {
    if (!programsLoaded) {
      dispatch(getPrograms())
    }

    if (user && fileState) {
      setValues({
        ...values,
        user_id: user?._id,
        document: fileState?.content?.location,
        etag: fileState?.content?.etag
      })
    }
  }, [programsLoaded])

  return (
    <div>
      <PopupControl
        title="Add Repayment"
        isOpen={open}
        close={{
          showClose: true,
          onClose: () => {
            setShow(false)
          }
        }}
      >
        <div className="w-full flex flex-col gap-4 justify-center items-center">
          <InputComponent
            theme="dark"
            title="Amount"
            value={values.amount}
            onChange={v =>
              setValues({
                ...values,
                amount: v
              })
            }
          />

          <InputComponent
            theme="dark"
            title="Description"
            showContent={true}
            value={values.description}
            onChange={v =>
              setValues({
                ...values,
                description: v
              })
            }
          />

          <DropdownButtonComponent
            title="Program"
            placeholder="Select Program"
            isLoading={false}
            theme="dark"
            displayKey="program_name"
            dropdownItems={programs}
            selectedItem={programs?.find(x => x._id == values.program_id)?.program_name}
            onClick={program => {
              setValues({
                ...values,
                program_id: program._id
              })
            }}
          />

          <DatePickerComponent
            title="Date"
            date={values.payment_date}
            onChange={v =>
              setValues({
                ...values,
                payment_date: v
              })
            }
          />

          <FilesComponent
            label="Attach Document"
            // theme="light"
            // placeholder={values.document ? values.document : 'Upload a file'}
            onChange={v => {
              upload_file({ file: v, name: v.name }).then(res => {
                if (res.data.success) {
                  setValues({
                    ...values,
                    document: res.data.content.location,
                    etag: res.data.content.etag
                  })
                }
              })
            }}
          />

          <ButtonComponent
            title={'Save'}
            icon={faSave}
            isLoading={false}
            // type="primary"
            onClick={v => {
              save_payment({
                ...values,
                user: user._id
              }).then(res => {
                if (res.data.success) {
                  setShow(false)
                  let _payments = user.payments ? user.payments : []
                  user.payments = [values, ..._payments].map(payment => ({
                    ...payment,
                    date: Date.now(),
                    amount: values?.amount
                  }))
                  dispatch(editLearnerState({ ...user }))
                  dispatch(
                    setNotification({
                      title: 'Success',
                      messages: ["Re-payment added to learner's account"]
                    })
                  )
                  setValues({})
                }
              })
            }}
          />
        </div>
      </PopupControl>
      <NotificationControl notificationData={notificationData} />
      <LoaderControl show={false} />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    mainState: state.mainState,
    userState: state.userState,
    fileState: state.fileState
  }
}

export default connect(mapStateToProps)(AddPaymentComponent)
