import React, { useEffect, useState } from 'react'
import InputComponent from '../../customComponents/input'
import { save_update_configurations } from '../../../api/calls/configurations/save_update_configurations'
import { get_configurations } from '../../../api/calls/configurations/get_configurations'
import { get_me } from '../../../api/calls/me'
import ButtonComponent from '../../customComponents/button'
import { faSave } from '@fortawesome/pro-light-svg-icons'

const CreateSms = () => {
  const [user, setUser] = useState({})
  const [formValues, setFormValues] = useState({
    yes_sms: '',
    no_sms: ''
  })

  useEffect(() => {
    _getUserInformation()
    _getConfigurations()
  }, [])

  const _onInputChange = value => {
    setFormValues({ ...formValues, ...value })
  }

  const _saveConfigurations = () => {
    save_update_configurations(formValues).then(res => {
      if (res.data.success) {
        _getConfigurations()
      }
    })
  }

  useEffect(() => {
    _onInputChange({ updated_by: user.user?._id })
  }, [user])

  const _getConfigurations = () => {
    get_configurations().then(res => {
      if (res.data.success) {
        setFormValues({ ...formValues, ...res.data.content })
      }
    })
  }

  const _getUserInformation = () => {
    get_me()
      .then(res => {
        if (res.data.success) {
          setUser(res.data.content)
        }
      })
      .catch(err => console.log(err))
  }
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row gap-2 w-full ">
        <InputComponent
          title="Success SMS"
          theme="form"
          size="lg"
          value={formValues.yes_sms}
          onChange={yes_sms => _onInputChange({ yes_sms })}
        />
        <InputComponent
          title="Failure SMS"
          theme="form"
          size="lg"
          value={formValues.no_sms}
          onChange={no_sms => _onInputChange({ no_sms })}
        />
      </div>

      <div>
        <div className="font-semibold italic pb-2">
          Note: If you would like the learner or recruitment details to be displayed in the sms,
          simply include the following strings
        </div>
        <div className="pb-2 text-xs">
          1. $full_name will be replaced with the learner's full name in the SMS
        </div>
        <div className="pb-2 text-xs">
          2. '$description' will be replaced with the recruitment's description in the SMS
        </div>
        <div className="pb-2 text-xs">
          3. '$date' will be replaced with the recruitment's date in the SMS
        </div>
        <br />
        <div className="font-semibold text-sm italic pb-2">Example:</div>
        <div className="text-sm pb-2">
          This: Congratulations, $full_name. You have been accepted for $description on $date.
        </div>
        <div className="font-semibold text-sm pb-2">
          Becomes: Congratulations, John Doe. You have been accepted for Open Day on 2021-01-01.
        </div>
      </div>
      <ButtonComponent
        title="Save"
        icon={faSave}
        onClick={_saveConfigurations}
        isBusy={false}
        type="primary"
      />
    </div>
  )
}

export default CreateSms
