import React, { useState } from 'react'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { makeStyles } from '@mui/styles'
import moment from 'moment'
import { TimePicker } from '@mui/x-date-pickers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-light-svg-icons'

const useStyles = makeStyles(theme => ({
  picker: {
    // Set your desired background color here
    background: 'darkgray',
    borderRadius: '4px',
    font: 'text-sm',
    '& input': {
      // If you also want to change the input field's background color
      background: 'lightblue',
      //   borderRadius: '4px',
      font: 'text-sm text-white',
      fontStyle: 'text-white'
    }
  }
}))

const DatePickerComponent = props => {
  const classes = useStyles()
  const {
    title,
    onChange,
    isRange = false,
    isDateOnly = true,
    isTimeOnly = false,
    isStatic = false,
    disablePast = true,
    date,
    disabled,
    showYearOnly = false,
    showErrorMessage = false,
    errorMessage = '',
    required = false
  } = props

  return (
    <div className="w-full border-gray flex flex-col">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DateTimePicker', 'DatePicker']}>
          {isTimeOnly ? (
            <TimePicker
              label={title}
              className={`${classes.picker}`}
              //   slotProps={{ textField: { variant: 'filled' } }}
              onChange={e => {
                const date = moment(e.$d).toISOString(true)
                onChange(date)
              }}
            />
          ) : showYearOnly ? (
            <DatePicker
              disableFuture={true}
              disablePast={false}
              disabled={disabled}
              className={`${classes.picker}`}
              label={title}
              views={['year']}
              //   slotProps={{ textField: { variant: 'filled' } }}
              onChange={e => {
                const date = moment(e.$d).toISOString(true)
                onChange(date)
              }}
            />
          ) : isDateOnly ? (
            <DatePicker
              disablePast={disablePast}
              disabled={disabled}
              className={`${classes.picker}`}
              label={title}
              //   slotProps={{ textField: { variant: 'filled' } }}
              onChange={e => {
                const date = moment(e.$d).toISOString(true)
                onChange(date)
              }}
            />
          ) : (
            <DateTimePicker
              disablePast={disablePast}
              disabled={disabled}
              className={`${classes.picker}`}
              label={title}
              slotProps={{ textField: { variant: 'filled' } }}
              onChange={e => {
                const date = moment(e.$d).toISOString(true)
                onChange(date)
              }}
            />
          )}
        </DemoContainer>
      </LocalizationProvider>
      {/* {date && <div className="text-sm italic text-white">{moment(date).format('LLLL')}</div>} */}
      {date && (
        <div className="text-sm italic text-white">
          {isDateOnly ? moment(date).format('LL') : moment(date).format('LLLL')}
        </div>
      )}
      {showErrorMessage && <div className="text-sm italic text-red-400">{errorMessage}</div>}

      {/* <div
        className="flex text-sm italic hover:underline hover:text-blue-600 cursor-pointer"
        onClick={() => {
          const now = new Date()
          //format to 08/29/2024
          const date = moment(now).format('YYYY-MM-DD')
          onChange(date)
        }}
      >
        Choose today's date.
      </div> */}
    </div>
  )
}
export default DatePickerComponent
