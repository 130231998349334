const entityName = 'Entity'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { selectNotificationState } from '../../notifications/selectors'
import { faUser } from '@fortawesome/pro-light-svg-icons'
import { saveBilling, getFacilitatorTransactions } from '../../../asyncActions'
import { getEntityPrograms, getEntityTransactions } from '../../../asyncActions/entities'
import { selectSelectedUser } from '../../../selectors/selectors'
import { extractById } from '../../../utils/entityUtils'
import { getTransactionTableData } from '../../facilitators/utils'
import { initialRateValues, programColumn, transactionsColumn } from '../../user/constants'
import { selectEntities } from '../selectors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BreadCrumb from '../../../controls/breadcrumb/breadcrumb'
import ButtonControl from '../../../controls/button/button'
import TitleCardControl from '../../../controls/cards/titlecard'
import EntityTable from '../../../controls/entityTable/entityTable'
import TableContentWrapper from '../../../controls/tableContentWrapper/tableContentWrapper'
import AddRateModal from '../../facilitators/modals/addRate'
import ValueExchange from '../../user/components/valueExchange'
import AddPaymentComponent from '../../user/components/addPayment'
import AddTransactionComponent from '../../user/components/addTransactions'
import AddDocumentComponent from '../../user/components/UploadDocuments'
import { connect, useDispatch, useSelector } from 'react-redux'
import DetailsBlock from '../../user/components/detailsBlock'
import Pills from './pills'
import { MyTableControl } from '../../../controls/myTableControl/myTableControl'

const EntityDetails = props => {
  const { id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const notificationData = useSelector(selectNotificationState)
  const [showAddPayment, setShowAddPayment] = useState(false)
  const [showAddRate, setShowAddRate] = useState(false)
  const [showAddDocument, setShowAddDocument] = useState(false)
  const [showAddTransaction, setShowAddTransaction] = useState(false)
  const [selectedVebProgram, setSelectedVebProgram] = useState()

  const selectedUser = useSelector(selectSelectedUser)

  const entities = useSelector(selectEntities)
  const entity = extractById(entities)(selectedUser._id)

  const programs = entity?.programs || []
  const transactionsTableData = getTransactionTableData(entity?.transactions)

  const [formValues] = useState({
    ...initialRateValues,
    user_id: id
  })

  useEffect(() => {
    if (selectedUser?._id) {
      if (!entity?.programs) {
        dispatch(getEntityPrograms(entity))
      }
      if (entity?.programs && !entity?.transactions) {
        dispatch(getEntityTransactions(entity))
      }
    } else {
      history.goBack()
    }
  }, [entity, selectedUser])

  const _onViewProgram = item => {
    history.push(`/dashboard/program-details/${item._id}`)
  }

  const _onEditRate = () => {
    setShowAddRate(!showAddRate)
  }

  const _onViewTeachingDays = () => {}

  const _onSubmitHandler = values => {
    entity['billingInfo'] = values
    dispatch(saveBilling(values))
  }

  const _onSelectVebProgram = item => {
    setSelectedVebProgram(item)
    dispatch(getFacilitatorTransactions(entity, item))
  }

  return (
    <TableContentWrapper
      title={`${entityName} - ${entity?.user_name} ${entity?.user_surname}`}
      notificationData={notificationData}
      actionButtons={[]}
    >
      <div className="flex flex-col gap-4">
        <BreadCrumb name={entityName} />
        <div className="flex flex-col gap-4">
          <div className="bg-white shadow-md p-4">
            <div className="flex gap-4">
              <div className="flex-grow">
                <TitleCardControl css={{ height: '150px' }}>
                  <div className="grid grid-cols-2 gap-8">
                    <div className="flex flex-row space-x-8">
                      <div
                        className="p-4 flex bg-indigo rounded-full justify-center"
                        style={{ height: '100px', width: '100px' }}
                      >
                        <FontAwesomeIcon
                          className="fa-4x text-white ml-8 mr-8 align-baseline"
                          icon={faUser}
                        />
                      </div>
                      <div className="mx-10 items-start">
                        <p>{`${entity?.user_name} ${entity?.user_surname}`}</p>
                        <p>{entity?.user_email}</p>
                      </div>
                    </div>

                    <div className="flex justify-end">
                      <Pills data={entity?.status_code} />
                    </div>
                  </div>
                </TitleCardControl>

                <MyTableControl
                  title={'Programs'}
                  search={{ show: true, search_by: '' }}
                  showPagination={true}
                  data={programs ?? []}
                  displayKeys={programColumn}
                  actionButton={{
                    side: 'right',
                    title: 'View',
                    isDropdown: true,
                    onClick: _onViewProgram
                  }}
                />

                <div className="py-10">
                  <ValueExchange
                    items={programs}
                    selected={selectedVebProgram}
                    onSelect={_onSelectVebProgram}
                  />
                  <MyTableControl
                    title={'Transactions'}
                    search={{ show: true, search_by: '' }}
                    showPagination={true}
                    data={transactionsTableData ?? []}
                    displayKeys={transactionsColumn}
                  />
                </div>
              </div>
              {entity && <DetailsBlock data={entity} addRate={_onEditRate} userType={undefined} />}
            </div>
          </div>
        </div>
        <AddPaymentComponent open={showAddPayment} setShow={setShowAddPayment} user={entity} />
        {/* <AddTransactionComponent
          open={showAddTransaction}
          setShow={setShowAddTransaction}
          user={entity}
        /> */}
        <AddRateModal
          initialValue={entity?.billingInfo || formValues}
          open={showAddRate}
          setShow={setShowAddRate}
          onSuccess={_onSubmitHandler}
        />
        <AddDocumentComponent open={showAddDocument} setShow={setShowAddDocument} user={entity} />
      </div>
    </TableContentWrapper>
  )
}

export default EntityDetails
