import React, { useEffect, useState } from 'react'
import PopupControl from '../../../controls/popup/popup'
import ButtonComponent from '../../customComponents/button'
import { delete_event } from '../../../api/calls/tasks/delete_task'
import NotificationControl from '../../../controls/notification/notification'
import { faSave, faTimes } from '@fortawesome/pro-light-svg-icons'
import { send_sms } from '../../../api/calls/recruitment/send_sms'

export default function SendSMSPopUp({ show, close, onSmsSuccess, smsBody, recruitmentDetails }) {
  const [notificationData, setNotificationData] = useState({})

  const _sendSMS = data => {
    send_sms(data).then(response => {
      if (response.data.success) {
        setNotificationData({
          title: 'Success',
          messages: [response.data.message]
        })
        close(false)
        onSmsSuccess(true)
      } else {
        setNotificationData({
          title: 'Failed',
          messages: [response.data.message]
        })
        onSmsSuccess(false)
      }
    })
  }

  return (
    <div>
      <PopupControl
        title="Send SMS"
        isOpen={show}
        close={{
          showClose: true,
          onClose: () => {
            close(false)
          }
        }}
      >
        <div className="flex flex-col gap-12 items-center justify-center">
          <div className="text-l text-white">"Do you want to send SMS?"</div>
          <div className="flex flex-row w-full justify-between gap-2">
            <div className="w-full">
              <ButtonComponent
                title={'Send'}
                icon={faSave}
                onClick={() => {
                  const newSmsBody = {
                    ...smsBody,
                    message: smsBody.message
                      .replace('$full_name', recruitmentDetails.full_name ?? '')
                      .replace('$description', recruitmentDetails.description ?? '')
                      .replace('$date', recruitmentDetails.date ?? '')
                  }
                  _sendSMS(newSmsBody)
                }}
              />
            </div>
            <div className="w-full">
              <ButtonComponent
                title={'Cancel'}
                icon={faTimes}
                onClick={() => {
                  close(false)
                }}
              />
            </div>
          </div>
        </div>
      </PopupControl>
      <NotificationControl notificationData={notificationData} />
    </div>
  )
}
