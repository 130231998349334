
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArcPercentageControl from "../arcpercentage/arcpercentage";
import TitleCardControl from "./titlecard";

const CardArcDetailControl = props => {
    const {
        title,
        icon,
        percentage,
        details = [ { name: "Items", value: 0 } ],
    } = props;
    //Did mount...
    useEffect(() => {
        
    }, []);

    //Will unmount...
    useEffect(() => {
        return () => {
            console.log("Cleaning up...");
        }
    }, []);

    return (
        <TitleCardControl title={title}>
            <div className="flex flex-row w-full">
                <ArcPercentageControl percentage={percentage} icon={icon} />
                <div className="flex-auto flex flex-col">
                    {details.map((item, index) => {
                        return (
                            <div key={index} className="text-right text-sm">
                                {item.value} {item.name}
                            </div>
                        )
                    })}
                </div>
            </div>
        </TitleCardControl>
    )
}

export default CardArcDetailControl;