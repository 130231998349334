import React, { useEffect, useState } from 'react'

import { faArrowLeft, faSave } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { get_all_tasks, save_application, upload_file } from '../../api/calls'
import { saveApplication } from '../../asyncActions/application'
import { setNotification } from '../../asyncActions/notification'
import ButtonControl from '../../controls/button/button'
import FileInputControl from '../../controls/fileinput/fileinput'
import TableContentWrapper from '../../controls/tableContentWrapper/tableContentWrapper'
import { useFormActionHook } from '../../hooks/useFormActionHook'
import logo from './../../assets/images/black_logo.png'
import { applicationValidationConfig, initialApplicationData, nationalities } from './constants'
import Cookies from 'universal-cookie'
import InputComponent from '../customComponents/input'
import FilesComponent from '../customComponents/files'
import ButtonComponent from '../customComponents/button'
import { get_groups } from '../../api/calls/studentApplications/get_groups'
import DropdownButtonComponent from '../customComponents/dropdownButton'
import CheckBoxControl from '../../controls/checkbox/checkbox'
import { get_application_events } from '../../api/calls/tasks/get_applicatio_events'

const disabilityOptions = [
  {
    name: 'Yes',
    value: true
  },
  {
    name: 'No',
    value: false
  }
]

const ApplicationFormComponent = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [canSave, setCanSave] = useState(false)
  const [groups, setGroups] = useState([])
  const [events, setEvents] = useState([])
  const [formValues, setFormValues] = useState(initialApplicationData)
  const cookies = new Cookies()

  useEffect(() => {
    //fetch groups
    get_groups().then(e => {
      if (e.data.success) {
        setGroups(e.data.content)
      }
    })

    //fetch events
    get_application_events().then(e => {
      if (e.data.success) {
        setEvents(e.data.content)
      }
    })
  }, [])

  const _onInputChange = value => {
    setFormValues({ ...formValues, ...value })
  }

  const _onSave = () => {
    setIsLoading(true)
    save_application(formValues)
      .then(res => {
        if (res.data.success) {
          setNotification({
            title: 'Success',
            messages: ['You have applied successfully.']
          })
          setIsLoading(false)
          if (cookies.get('veb-user-cookie') != null && cookies.get('veb-user-cookie').token) {
            history.push(`/dashboard/application-portal`)
          } else {
            history.replace('/thank-you')
          }
        } else {
          dispatch(
            setNotification({
              title: 'Failure',
              messages: [res.data.message]
            })
          )
        }
        setIsLoading(false)
      })
      .catch(error => {
        dispatch(
          setNotification({
            title: 'Failure',
            messages: [error.message]
          })
        )
      })
  }

  const _onVerify = () => {
    if (
      formValues.address &&
      formValues.event_id &&
      formValues.group_id &&
      formValues.name &&
      formValues.phone_number &&
      formValues.surname &&
      formValues.training_provider &&
      formValues.age &&
      formValues.race &&
      formValues.gender &&
      formValues.nationality &&
      formValues.municipality
    ) {
      setCanSave(true)
    } else {
      setCanSave(false)
    }
  }

  useEffect(() => {
    _onVerify()
  }, [formValues])

  return (
    <div className="h-full px-52">
      <div className="flex shadow-md justify-center items-center p-4">
        <div className="flex flex-col gap-4 bg-white shadow-md px-8 pt-8">
          <img
            onClick={() => history.goBack()}
            src={logo}
            className="object-contain h-20 pb-8 pt-4"
          />

          <div className="flex items-center pb-8">
            <FontAwesomeIcon
              className="text-2xl cursor-pointer"
              icon={faArrowLeft}
              onClick={() => history.goBack()}
            />

            <div className="text-2xl w-full flex justify-center">Application Form</div>
          </div>

          <div className="flex flex-col gap-4 w-full">
            <div className="flex flex-col w-full">
              <div className="font-semibold my-4 pb-2 border-b border-gray">
                GENERAL INFORMATION
              </div>
              <div className="flex justify-between gap-8">
                <div className="flex flex-col gap-2 w-full">
                  <div className="flex flex-row gap-2">
                    <InputComponent
                      theme="form"
                      placeholder="Name"
                      value={formValues.name}
                      onChange={name => {
                        _onInputChange({ name })
                      }}
                    />
                    <InputComponent
                      theme="form"
                      placeholder="Surname"
                      value={formValues.surname}
                      onChange={surname => {
                        _onInputChange({ surname })
                      }}
                    />
                  </div>
                  <div className="flex flex-row gap-2">
                    <InputComponent
                      theme="form"
                      placeholder="ID Number (optional)"
                      value={formValues?.id_number}
                      onChange={id_number => {
                        _onInputChange({ id_number })
                      }}
                    />
                    <InputComponent
                      theme="form"
                      placeholder="Training Provider"
                      value={formValues?.training_provider}
                      onChange={training_provider => {
                        _onInputChange({ training_provider })
                      }}
                    />
                  </div>
                  <div className="flex flex-row gap-2">
                    <InputComponent
                      theme="form"
                      placeholder="Municipality"
                      value={formValues?.municipality}
                      onChange={municipality => {
                        _onInputChange({ municipality })
                      }}
                    />
                    <InputComponent
                      theme="form"
                      placeholder="Age"
                      isNumber={true}
                      value={formValues?.age}
                      onChange={age => {
                        _onInputChange({ age })
                      }}
                    />
                  </div>

                  <div className="flex flex-row gap-2">
                    <DropdownButtonComponent
                      placeholder="Select Race"
                      isBusy={false}
                      type="primary"
                      theme="form"
                      displayKey="name"
                      dropdownItems={[
                        {
                          name: 'Black',
                          value: 'Black'
                        },
                        {
                          name: 'Coloured',
                          value: 'Coloured'
                        },
                        {
                          name: 'Indian',
                          value: 'Indian'
                        },
                        {
                          name: 'White',
                          value: 'White'
                        },
                        {
                          name: 'Asian',
                          value: 'Asian'
                        }
                      ]}
                      selectedItem={formValues.race}
                      onClick={item => {
                        _onInputChange({ race: item.value })
                      }}
                    />
                    <div className="flex w-full gap-4">
                      <div className="flex gap-2 items-center">
                        <CheckBoxControl
                          theme="light"
                          name="gender"
                          checked={formValues.gender == 'Male'}
                          type="radio"
                          value="Male"
                          onChange={e => {
                            _onInputChange({ gender: e.target.value })
                          }}
                        />
                        <p>Male</p>
                      </div>
                      <div className="flex gap-2 items-center">
                        <CheckBoxControl
                          theme="light"
                          name="gender"
                          checked={formValues.gender == 'Female'}
                          type="radio"
                          value="Female"
                          onChange={e => {
                            _onInputChange({ gender: e.target.value })
                          }}
                        />
                        <p>Female</p>
                      </div>
                    </div>
                  </div>

                  <div className="flex w-full gap-2">
                    <DropdownButtonComponent
                      placeholder="Disability"
                      isBusy={false}
                      type="primary"
                      theme="form"
                      displayKey="name"
                      dropdownItems={disabilityOptions}
                      selectedItem={`Disabled (${formValues.disabled ? 'Yes' : 'No'})`}
                      onClick={item => {
                        _onInputChange({ disabled: item.value })
                      }}
                    />
                    {formValues.disabled && (
                      <InputComponent
                        theme="form"
                        placeholder="Add disabilities (sep. by comma)"
                        value={formValues?.disability}
                        onChange={disability => {
                          _onInputChange({ disability })
                        }}
                      />
                    )}
                  </div>

                  <DropdownButtonComponent
                    placeholder="Select Nationality"
                    isBusy={false}
                    type="primary"
                    theme="form"
                    displayKey="name"
                    showSearch={true}
                    dropdownItems={nationalities}
                    selectedItem={formValues.nationality}
                    onClick={item => {
                      _onInputChange({ nationality: item?.name })
                    }}
                  />

                  <div className="flex flex-row gap-2">
                    <DropdownButtonComponent
                      placeholder="Select Group"
                      isBusy={false}
                      type="primary"
                      theme="form"
                      displayKey="name"
                      dropdownItems={groups}
                      selectedItem={groups.find(e => e.id == formValues.group_id)?.name}
                      onClick={item => {
                        _onInputChange({ group_id: item?.id })
                      }}
                    />
                    <DropdownButtonComponent
                      placeholder="Select Event"
                      isBusy={false}
                      type="primary"
                      theme="form"
                      displayKey="event_name"
                      dropdownItems={events.filter(
                        event => event.type.toString().toLowerCase() == 'open day'
                      )}
                      selectedItem={
                        events.find(event => event._id == formValues.event_id)?.event_name
                      }
                      onClick={item => {
                        _onInputChange({ event_id: item?._id })
                      }}
                    />
                  </div>

                  <FilesComponent
                    label="Proof of ID (optional)"
                    theme="dark"
                    placeholder={
                      formValues?.proof_of_id ? formValues.proof_of_id : 'Upload proof of ID'
                    }
                    onChange={v => {
                      upload_file({ file: v, name: v.name }).then(res => {
                        if (res.data.success) {
                          _onInputChange({
                            proof_of_id: res.data.content.location,
                            etag: res.data.content.etag
                          })
                        }
                      })
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full">
              <div className="font-semibold my-4 pb-2 border-b border-gray">CONTACT</div>
              <div className="flex flex-row gap-2">
                <InputComponent
                  placeholder="Phone Number"
                  theme="form"
                  value={formValues.phone_number}
                  onChange={phone_number => {
                    _onInputChange({ phone_number })
                  }}
                />
                <InputComponent
                  placeholder="Email (optional)"
                  theme="form"
                  value={formValues.email}
                  onChange={email => {
                    _onInputChange({ email })
                  }}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col">
            <div className="font-semibold my-4 pb-2 border-b border-gray">ADDRESS</div>
            <div className="flex flex-row gap-4 justify-between w-full">
              <div className="flex flex-col gap-2 w-full">
                <InputComponent
                  theme="form"
                  placeholder="Address Line 1:"
                  value={formValues.address.address_line_1}
                  onChange={address_line_1 => {
                    _onInputChange({ address: { ...formValues.address, address_line_1 } })
                  }}
                />
                <InputComponent
                  theme="form"
                  placeholder="City"
                  value={formValues.address.city}
                  onChange={city => {
                    _onInputChange({ address: { ...formValues.address, city } })
                  }}
                />
                <InputComponent
                  theme="form"
                  placeholder="Country"
                  value={formValues.address.country}
                  onChange={country => {
                    _onInputChange({ address: { ...formValues.address, country } })
                  }}
                />
              </div>
              <div className="flex flex-col gap-2 w-full">
                <InputComponent
                  theme="form"
                  placeholder="Address Line 2:"
                  value={formValues.address.address_line_2}
                  onChange={address_line_2 => {
                    _onInputChange({ address: { ...formValues.address, address_line_2 } })
                  }}
                />
                <InputComponent
                  theme="form"
                  placeholder="Province"
                  value={formValues.address.province}
                  onChange={province => {
                    _onInputChange({ address: { ...formValues.address, province } })
                  }}
                />

                <InputComponent
                  theme="form"
                  placeholder="Postal Code"
                  value={formValues.address.postal_code}
                  onChange={postal_code => {
                    _onInputChange({ address: { ...formValues.address, postal_code } })
                  }}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col">
            <div className="font-semibold my-4 pb-2 border-b border-gray">DISCLAIMER</div>
            <div className="p-2 w-full shadow-lg ">
              <div className="text-sm text-gray">
                In accordance with the Protection of Personal Information Act (POPI Act) of South
                Africa, we would like to remind you that any personal information you provide us may
                be subject to 'processing' as defined by the Act. This includes but is not limited
                to, your name, address, email address, phone number, and other personal details. The
                term 'processing' encompasses the collection, reception, recording, organization,
                retrieval, usage, distribution, or sharing of such information. We are committed to
                ensuring that your personal information is handled in accordance with the highest
                standards of privacy and security set forth by the POPI Act. Your personal
                information will not be used for purposes beyond those necessary for our services
                and as described in our Privacy Policy without your explicit consent.
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center">
            <div className="py-8">
              <ButtonComponent
                title="Save & Continue"
                disabled={!canSave}
                icon={faSave}
                isLoading={isLoading}
                type="primary"
                onClick={_onSave}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    userState: state.userState,
    notificationState: state.notificationState
  }
}

export default connect(mapStateToProps)(ApplicationFormComponent)
