import { SET_MAIN_STATE } from '../../constants/redux_types'

export function mainStateReducer(
  state = {
    home: {},
    recruits: [],
    homeLoaded: false,
    loading: true,
    selectedUser: {}
  },
  action
) {
  if (action.type == SET_MAIN_STATE) {
    state = action.payload
  }
  return state
}
