import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faMinus } from '@fortawesome/pro-light-svg-icons';

const TextInputControl = props => {
  const { onTimeSelection, title, icon, style, type, placeholder, value, onChange, theme = 'light', disabled = false } = props

  const [selected, setSelected] = useState(false)
  const [selectedTime, setSelectedTime] = useState('')

  const options = {
    light: {
      title: 'text-dark',
      inputBg: 'bg-transparent',
      inputBorder: 'border-gray',
      placeholder: ''
    },
    dark: {
      title: 'text-white',
      inputBg: 'bg-textinput-dark',
      inputBorder: 'border-none',
      placeholder: 'placeholder-gray'
    },
    transparent: {
      title: 'text-white',
      inputBg: 'bg-transparent',
      inputBorder: 'border-gray',
      placeholder: ''
    }
  }

  return (
    <div className="flex-1">
      <div className={`flex flex-col justify-center ${style}`}>
        {title && <div className={`${options[theme].title} capitalize`}>{title} {type=='time' && selectedTime? <span className='text-xs'>(Please click on the check mark to capture the time)</span>:null}</div>}
        <div className="flex flex-row justify-center items-center gap-2">
          {icon && (
            <div className="bg-black h-9 w-10 text-white flex justify-center items-center rounded-l-full">
              <FontAwesomeIcon icon={icon} />
            </div>
          )}
           
          <input
            className={`h-9 ${options[theme].inputBg} p-4 w-full text-gray
                        border ${options[theme].inputBorder} ${options[theme].placeholder} 
                        outline-none focus:outline-none ${
                          icon ? 'rounded-r-full' : 'rounded-full'
                        }`}
            type={type}
            placeholder={placeholder}
            value={value || ''}
            onChange={e => {
                setSelected(false)
                onChange(e.currentTarget.value)
                // console.log(e.currentTarget.value)

                if(selectedTime){
                    setSelectedTime('')
                }
                setSelectedTime(e.currentTarget.value)
            }}
            disabled={disabled}
          />
          {type=="time" && (
            <div onClick={()=>{
                if(selectedTime){
                    setSelected(true)
                    onTimeSelection(selectedTime)
                }
            }} className={`${selected? 'bg-main-green' : 'bg-black' } h-6 w-6 text-white flex justify-center items-center rounded-full`}
            >
              <FontAwesomeIcon icon={faCheck} />
            </div>
          )}
        </div>
        {/* {type=="time" && selected && (
            <div className='flex text-xs'>
             selected time: {value}
            </div>
          )} */}
      </div>
    </div>
  )
}

export default TextInputControl
