import React, { useState } from 'react'
import InputComponent from '../../customComponents/input'
import ButtonComponent from '../../customComponents/button'
import { faSave } from '@fortawesome/pro-light-svg-icons'
import DropdownButtonComponent from '../../customComponents/dropdownButton'
import { add_category } from '../../../api/calls/tasks/add_category'
import { setNotification } from '../../../asyncActions/notification'
import NotificationControl from '../../../controls/notification/notification'
import TableContentWrapper from '../../../controls/tableContentWrapper/tableContentWrapper'

const owners = [
  { _id: 1, owner: 'user' },
  { _id: 2, owner: 'system' }
]

const CreateEventCategory = () => {
  const [values, setValues] = useState({
    category_name: '',
    category_description: '',
    owner: ''
  })

  const [notificationData, setNotificationData] = useState({})

  const [owner, setSelectedOwner] = useState({})

  const _saveCategory = () => {
    console.log('values', values)
    add_category(values).then(res => {
      console.log('res', res)
      if (res.data.success) {
        setNotificationData({
          type: 'success',
          message: 'Category Added Successfully'
        })
      } else {
        setNotificationData({
          title: 'Failure',
          messages: [res.data.messages]
        })
      }
    })
  }

  return (
    <TableContentWrapper>
      <InputComponent
        theme="form"
        title="Event Category Name"
        value={values.category_name}
        onChange={v =>
          setValues({
            ...values,
            category_name: v
          })
        }
      />

      <InputComponent
        theme="form"
        title="Event Description"
        value={values.category_description}
        onChange={v =>
          setValues({
            ...values,
            category_description: v
          })
        }
      />

      <DropdownButtonComponent
        title="Owner"
        placeholder="Select Owner of the Category"
        isBusy={false}
        type="primary"
        theme="form"
        selector="_id"
        displayKey="owner"
        dropdownItems={owners}
        selectedItem={owner.owner ?? 'No owner selected'}
        onClick={item => {
          setSelectedOwner(item)
          setValues({
            ...values,
            owner: item.owner
          })
        }}
      />
      <br />
      <ButtonComponent
        title="Save"
        icon={faSave}
        onClick={_saveCategory}
        isBusy={false}
        type="primary"
      />

      <NotificationControl notificationData={notificationData} />
    </TableContentWrapper>
  )
}

export default CreateEventCategory
