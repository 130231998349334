import {
  SAVE_GREENLIGHT_STATE,
  SET_GREENLIGHT_STATE,
  DELETE_GREENLIGHT_STATE
} from '../constants/redux_types'

export const setGreenlightState = payload => {
  return {
    type: SET_GREENLIGHT_STATE,
    payload: payload
  }
}

export const saveGreenlightState = payload => {
  return {
    type: SAVE_GREENLIGHT_STATE,
    payload: payload
  }
}

export const deleteGreenlightState = payload => {
  return {
    type: DELETE_GREENLIGHT_STATE,
    payload: payload
  }
}
