import { faSave } from '@fortawesome/pro-light-svg-icons'
import React, { useEffect, useState } from 'react'
import { useDispatch, connect } from 'react-redux'
import { allApplications, saveApplication, setNotification } from '../../asyncActions'
import PopupControl from '../../controls/popup/popup'
import ButtonComponent from '../customComponents/button'
import InputComponent from '../customComponents/input'

const EditApplicationComponent = ({ data, open = false, setShow }) => {
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState({
    id: '',
    email: ''
  })

  useEffect(() => {
    if (data != null) {
      const id = data._id
      delete data._id
      _onChange({ id: id, ...data })
    }
  }, [data])

  const _onSave = async values => {
    if (values.email != '') {
      await dispatch(saveApplication(values))
      await dispatch(allApplications())
      setShow(false)
    } else {
      dispatch(
        setNotification({
          show: true,
          success: false,
          message: 'Please enter email address.'
        })
      )
    }
  }

  const _onChange = e => {
    setFormValues({ ...formValues, ...e })
  }

  //   useEffect(() => {
  //     console.log('formValues', formValues)
  //   }, [formValues])

  return (
    <div>
      <PopupControl
        title="Please add email address"
        isOpen={open}
        close={{
          showClose: true,
          onClose: () => {
            setShow(false)
          }
        }}
      >
        <div className="flex flex-col gap-4 w-64">
          <InputComponent
            theme="dark"
            title="Email"
            value={formValues.email}
            onChange={v => _onChange({ email: v })}
          />
          <div className="flex justify-center items-center">
            <ButtonComponent
              title="Save"
              icon={faSave}
              isBusy={false}
              type="primary"
              onClick={_ => {
                _onSave(formValues)
              }}
            />
          </div>
        </div>
      </PopupControl>
    </div>
  )
}

export default EditApplicationComponent
