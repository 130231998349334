import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import Img from '../../../../assets/images/Signin-bg-image.png'
import logo from '../../../../assets/images/black_logo.png'
import ButtonControl from '../../../../controls/button/button'
import TextInputControl from '../../../../controls/textinput/textinput'

const SentComponent = props => {
  const [emailAddress, setemailAddress] = useState('')

  const history = useHistory()
  let { path, url } = useRouteMatch()

  //Did mount...
  useEffect(() => {
    console.log('it works')
    return () => {
      console.log('Cleaning up...')
    }
  }, [])

  //Will unmount...
  useEffect(() => {
    return () => {
      console.log('Cleaning up...')
    }
  }, [])

  return (
    <div className="p-8 flex flex-col items-center justify-center">
      <img className="object-contain max-w-lg px-20 pb-20 pt-10" src="" />

      <div className="flex flex-col space-y-4 px-4">
        <div className="text-center">
          A link has been sent to your email address to confirm you have access to the emaill
          address.
        </div>
        <div className="text-center">
          Once you press the link a temporary password will be generated, which will allow you to
          set a new password.
        </div>
        <div className="text-center">Please keep it safe and secure.</div>
      </div>

      <div className="grid gap-y-10 w-full max-w-lg pt-20">
        <ButtonControl
          placeholder="RETURN"
          isBusy={false}
          type="primary"
          onClick={v => {
            history.push(`/`)
          }}
        />
      </div>
    </div>
  )
}

export default SentComponent
