import React, { useEffect, useState } from 'react'

import { connect, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { setMainState } from '../../actions'
import { getGroups } from '../../asyncActions'
import TableContentWrapper from '../../controls/tableContentWrapper/tableContentWrapper'
import { getArrayByObjKey } from '../../utils'
import { selectNotificationState } from '../notifications/selectors'
import { AddGroups } from './components'
import { groupColumns } from './constants'
import { selectGroups, selectGroupsLoaded } from './selectors'
import { MyTableControl } from '../../controls/myTableControl/myTableControl'
import PopupControl from '../../controls/popup/popup'
import { set_default_group } from '../../api/calls/groups/default_group'
import ButtonComponent from '../customComponents/button'
import DeleteGroupPopUp from './components/deleteGroupPopUp'
import MakeDefaultGroupPopUp from './components/makeDefaultGroupPopUp'

const GroupsComponent = ({ facilitatorState }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const notificationData = useSelector(selectNotificationState)
  const [showAddGroup, setShowAddGroup] = useState(false)
  const groupsLoaded = useSelector(selectGroupsLoaded)
  const groups = useSelector(selectGroups)
  const [showDefaultGroupPopup, setShowDefaultGroupPopup] = useState(false)
  const [showDeleteGroupPopup, setShowDeleteGroupPopup] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState({})
  const facilitators = facilitatorState?.facilitators?.map(e => {
    return {
      ...e,
      full_name: `${e?.user_name} ${e?.user_surname}`
    }
  })

  const tableData =
    groups != null
      ? groups.map(group => ({
          ...group,
          facilitators: getArrayByObjKey(group.facilitators, 'full_name'),
          learners: getArrayByObjKey(group.learners, 'full_name')
        }))
      : {}

  useEffect(() => {
    if (!groupsLoaded) dispatch(getGroups())
  }, [groupsLoaded])

  const _onClickActionButton = (item, _) => {
    setSelectedGroup(item)
    console.log('selected group', item)

    if (_.id == 1) {
      dispatch(
        setMainState({
          selectedGroup: item
        })
      )
      history.push(`group-details/${item._id}`)
    }
    if (_.id == 2) {
      //edit group
      setIsEdit(true)
      _toggleAddGroup()
    }
    if (_.id == 3) {
      setShowDefaultGroupPopup(true)
    }
    if (_.id == 4) {
      setShowDeleteGroupPopup(true)
    }
  }

  const _toggleAddGroup = () => {
    setShowAddGroup(!showAddGroup)
  }

  return (
    <TableContentWrapper
      notificationData={notificationData}
      actionButtons={[
        <ButtonComponent
          title="Add A New Group"
          isBusy={false}
          type="primary"
          onClick={() => {
            setIsEdit(false)
            _toggleAddGroup()
          }}
        />
        // <ButtonControl
        //   placeholder="Select Default Group"
        //   isBusy={false}
        //   type="primary"
        //   onClick={_toggleAddGroup}
        // />
      ]}
    >
      {/* ----------Table */}
      <MyTableControl
        title={'Groups'}
        type={'groups'}
        search={{ show: true, search_by: '' }}
        showPagination={true}
        data={tableData}
        displayKeys={groupColumns}
        actionButton={{
          title: 'Details',
          isDropdown: 'true',
          side: 'right',
          items: [
            {
              name: 'View',
              id: 1
            },
            {
              name: 'Edit',
              id: 2
            },
            {
              name: 'Make Default',
              id: 3
            },
            {
              name: 'DELETE GROUP',
              id: 4
            }
          ],
          onClick: _onClickActionButton
        }}
      />

      <AddGroups
        initialValues={selectedGroup}
        open={showAddGroup}
        setShow={_toggleAddGroup}
        setIsEdit={setIsEdit}
        isEdit={isEdit}
      />
      <MakeDefaultGroupPopUp
        openPopUp={showDefaultGroupPopup}
        onOpenPopUp={setShowDefaultGroupPopup}
        group_id={selectedGroup._id}
      />
      <DeleteGroupPopUp
        openPopUp={showDeleteGroupPopup}
        onOpenPopUp={setShowDeleteGroupPopup}
        group_id={selectedGroup._id}
      />
    </TableContentWrapper>
  )
}

const mapStateToProps = state => {
  return {
    facilitatorState: state.facilitatorState
  }
}

export default connect(mapStateToProps)(GroupsComponent)
  
