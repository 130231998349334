import React from 'react'
import { connect } from 'react-redux'
import RecruitmentStatusTable from './recruitment_table'

const RecruitmentDetails = props => {
  const { learner } = props
  return (
    <div className="flex flex-col gap-4">
      <RecruitmentStatusTable tableTitle={'Recruitment'} learner={learner} />
    </div>
  )
}

export default RecruitmentDetails