import React, { useEffect, useState } from 'react'

import { faCalendar } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect, useDispatch } from 'react-redux'

import { get_performance } from '../../api/calls/performance_tracker'
import { getGroups, getPrograms } from '../../asyncActions'
import TableContentWrapper from '../../controls/tableContentWrapper/tableContentWrapper'
import DateFilter from './components/dateFilter'
import PerformanceTable from './components/performanceTable'
import { dateValues } from './constants/constants'
import DropdownButtonComponent from '../customComponents/dropdownButton'

const PerformanceTracker = ({ groupState, programsState }) => {
  const [init, setInit] = useState(true)
  const [formValues, setFormValues] = useState({})
  const [selectedValue, setSelectedValue] = useState('')
  const [content, setContent] = useState([])
  const [programs, setPrograms] = useState([])
  const [groups, setGroups] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [dateType, setDateType] = useState('')
  const [date, setDate] = useState()
  const [searchItem, setSearchItem] = useState('')
  const [showCalendar, setShowCalendar] = useState(false)
  const dispatch = useDispatch()
  const list = []

  useEffect(() => {
    if (init) {
      dispatch(getPrograms())
      dispatch(getGroups())
      setInit(false)
    }
    setPrograms(programsState.programs)
    setGroups(groupState.groups)
  }, [programsState, groupState])

  const _performFilter = query => {
    get_performance(query).then(response => {
      if (response.data.success) {
        setContent(response.data.content)
      }
    })
  }

  //callback functions
  const handleDate = d => {
    setDate(d)
  }

  const handleSearch = v => {
    setSearchItem(v)
  }

  useEffect(() => {
    if (date) {
      _performFilter(`start_date=${date.date.from}&end_date=${date.date.to}`)
    }
  }, [date])

  useEffect(() => {
    filteredData.splice(0, filteredData.length)

    if (content.length > 0) {
      if (Object.keys(searchItem).length != 0) {
        content.forEach(e => {
          if (
            e.user_name.toString().toLowerCase().includes(searchItem.toLowerCase()) ||
            e.user_surname.toString().toLowerCase().includes(searchItem.toLowerCase())
          ) {
            list.push(e)
          }

          e.interactions.forEach(interaction => {
            if (interaction.code.toString().toLowerCase().includes(searchItem.toLowerCase())) {
              if (list.length == 0) {
                list.push(e)
              } else {
                list.forEach(element => {
                  if (element !== e) {
                    list.push(e)
                  }
                })
              }
            }
          })
        })
      } else {
        filteredData.splice(0, filteredData.length)
        setFilteredData([])
      }
    }
    setFilteredData(list)
  }, [searchItem])

  return (
    <TableContentWrapper title="Attendance and Performance Tracker">
      <div className="flex flex-col gap-8">
        <div className="grid grid-cols-3">
          <div className="flex flex-col gap-4">
            <DropdownButtonComponent
              placeholder="Select Programme"
              isBusy={false}
              type="primary"
              theme="light"
              displayKey="program_name"
              dropdownItems={programs}
              selectedItem={
                programs?.length > 0
                  ? programs?.find(x => x.program_name == formValues.program_name)?.program_name
                  : ''
              }
              onClick={item => {
                setFormValues({ program_name: item.program_name })
                _performFilter(`program_ids=${item._id}`)
              }}
            />
            <DropdownButtonComponent
              placeholder="Select Group"
              isBusy={false}
              type="primary"
              theme="light"
              displayKey="name"
              dropdownItems={groups}
              selectedItem={
                groups.length > 0 ? groups.find(x => x.name == formValues.group_name)?.name : ''
              }
              onClick={item => {
                setFormValues({ group_name: item.name })
                _performFilter(`group_ids=${item._id}`)
              }}
            />
          </div>
        </div>
        <div className="flex flex-col px-2">
          <div className="flex flex-row gap-4 items-center justify-end">
            <FontAwesomeIcon size="sm" icon={faCalendar} />
            {dateValues.map((value, i) => (
              <div
                onClick={() => {
                  switch (value) {
                    case 'Custom':
                      setSelectedValue(value)
                      setDateType(value)
                      setShowCalendar(true)
                      break
                    case 'Day':
                    case 'Week':
                    case 'Month':
                    case 'Year':
                      _performFilter(`date_filter=${value}`)
                      setSelectedValue(value)
                      break

                    default:
                      break
                  }
                }}
                className={selectedValue === value ? `bg-main p-2 rounded-full` : `bg-white`}
                key={i}
              >
                {value}
              </div>
            ))}
          </div>
          <div className="flex flex-col gap-4">
            <div className="bg-white shadow-md p-4">
              <PerformanceTable
                content={filteredData.length > 0 ? filteredData : content}
                filterBySearchCallback={handleSearch}
              ></PerformanceTable>
            </div>
          </div>
        </div>
      </div>
      <DateFilter
        open={showCalendar}
        setShow={setShowCalendar}
        handleCalendarCallback={handleDate}
        type={dateType}
      />
    </TableContentWrapper>
  )
}
const mapStateToProps = state => {
  return {
    groupState: state.groupState,
    programsState: state.programsState
  }
}

export default connect(mapStateToProps)(PerformanceTracker)
