import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Calendar from 'react-calendar'
import { connect } from 'react-redux'
import ButtonControl from '../../../controls/button/button'
import CalendarController from '../../../controls/calendar/calendar'
import PopupControl from '../../../controls/popup/popup'
import { manipulateDate } from '../functions'
import { faSave } from '@fortawesome/pro-light-svg-icons'
import ButtonComponent from '../../customComponents/button'

const DateFilter = ({ type, open, setShow, handleCalendarCallback, taskState }) => {
  const [date, setDate] = useState()
  const [filterData, setFilterData] = useState({ dateRange: new Date() })
  const [tasks, setTasks] = useState([])

  useEffect(() => {
    filter()
  }, [filterData])

  const filter = () => {
    let _tasks = taskState.tasks.filter(task => {
      let _passed = true
      let _now = new Date()

      if (filterData.all) return task

      if (_passed && Array.isArray(filterData.dateRange) && filterData.dateRange.length > 1) {
        _passed = moment(task.due_date).isBetween(filterData.dateRange[0], filterData.dateRange[1])
      }

      if (_passed && filterData.today) {
        let _diff = moment(_now).diff(moment(task.due_date), 'days')
        // console.log('_diff', _diff)
        if (_diff != 0) _passed = false
      }

      if (_passed && filterData.tomorrow) {
        let _diff = moment(_now).diff(moment(task.due_date), 'days')
        // console.log(task.due_date, _diff)
        if (_diff != 1) _passed = false
      }

      if (_passed) return task
    })

    setTasks(_tasks)
  }

  return (
    <div>
      <PopupControl
        title={`Select Date (${type})`}
        isOpen={open}
        close={{
          showClose: true,
          onClose: () => {
            setShow(false)
          }
        }}
      >
        <div className="flex flex-col gap-8">
          <div className="px-2 flex flex-col">
            <CalendarController
              selectRange={
                type.toLowerCase() == 'day' ||
                type.toLowerCase() == 'week' ||
                type.toLowerCase() == 'year'
                  ? false
                  : true
              }
              minDate={new Date(moment(new Date()).subtract(1, 'y').format('DD-MM-YYYY'))}
              onChange={v =>
                setFilterData({
                  ...filterData,
                  all: false,
                  dateRange: v
                })
              }
              value={filterData.dateRange}
            />
          </div>
          <div className="flex flex-row justify-center items-center">
            <ButtonComponent
              title="Save"
              icon={faSave}
              isBusy={false}
              type="primary"
              onClick={v => {
                handleCalendarCallback(manipulateDate(filterData.dateRange, type))
                setShow(false)
              }}
            />
          </div>
        </div>
      </PopupControl>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    taskState: state.taskState
  }
}

export default connect(mapStateToProps)(DateFilter)
