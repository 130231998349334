import React from 'react'
import { useDispatch } from 'react-redux'
import { upload_file } from '../../../../api/calls'
import { setAlert } from '../../../../asyncActions/alert'
import FilesComponent from '../../../customComponents/files'
import InputComponent from '../../../customComponents/input'

const OiWorkshopForm = ({ formValues, onChange }) => {
  const dispatch = useDispatch()
  return (
    <div>
      <div className="flex flex-col gap-2 ">
        <InputComponent
          theme="dark"
          title="stationary"
          value={formValues?.stationary_required}
          onChange={v => {
            // console.log('value', v)
            onChange({
              ...formValues,
              stationary_required: v
            })
          }}
        />

        <FilesComponent
          label="Attach Form (optional)"
          theme="light"
          onChange={v => {
            upload_file({ file: v, name: v.name }).then(res => {
              const document = {
                name: v?.name,
                url: res.data.content.location,
                etag: res.data.content.etag
              }
              if (res.data.success) {
                // onSetFileUploads({ oiwForm: document, ...fileUploads })
                onChange({ ...formValues, form: document })
                dispatch(
                  setAlert({
                    success: true,
                    show: true,
                    message: 'Upload successful.'
                  })
                )
              } else {
                dispatch(
                  setAlert({
                    success: false,
                    show: true,
                    message: 'Upload failed.'
                  })
                )
              }
            })
          }}
        />
        <br />
      </div>
    </div>
  )
}

export default OiWorkshopForm
