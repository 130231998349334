import React from 'react'

import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const CircleIconText = (props) => {
   const { text, className, iconWithText=false, iconText='', iconColor=''  }=props
  return (
    <div className="flex flex-row justify-center items-center">
     {iconWithText? <div className={`flex h-5 w-5 ${iconColor} rounded-full justify-center items-center text-white`}>
     {iconText}
     </div> : <FontAwesomeIcon className={className} icon={faCircle} />}
      <p className={'text-xs'}>&nbsp;{text}</p>
    </div>
  )
}
