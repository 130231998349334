import React from 'react'

import moment from 'moment'
import { useHistory } from 'react-router-dom'

import { DATE_FORMAT } from '../../../constants/constants'
import { MyTableControl } from '../../../controls/myTableControl/myTableControl'
import ButtonComponent from '../../customComponents/button'
import { useDispatch } from 'react-redux'
import { setCurriculumState } from '../../../actions'

const CurriculumTable = ({ curriculums, programId }) => {
  const history = useHistory()
  const curriculumMutated = curriculums?.map(curriculum => ({
    ...curriculum,
    documents: (
      <>
        {curriculum.documents.map((document, index) => (
          <div key={index} className="grid grid-cols-2 py-1 space-x-8">
            <p className="text-sm font-bold">{document.name}</p>
            <a href={document.url} className="text-sm text-indigo font-bold">
              Download
            </a>
          </div>
        ))}
      </>
    ),
    createdAt: moment(document?.createdAt).format(DATE_FORMAT)
  }))

  const _onViewRow = item => {
    history.push(`/dashboard/upload-programme-curriculum/${programId}/${item._id}`)
  }
  const _onAddCurriculum = item => {
    history.push(`/dashboard/upload-programme-curriculum/${programId}`)
  }

  return (
    <div>
      <div className="bg-white shadow-md">
        <MyTableControl
          title={'Curriculum'}
          search={{ show: true, search_by: '' }}
          showPagination={true}
          data={curriculumMutated ?? []}
          displayKeys={[
            { key: 'name', display: 'Name' },
            { key: 'documents', display: 'Required Documents' },
            { key: 'createdAt', display: 'Date Uploaded' }
          ]}
          actionButton={{
            side: 'right',
            title: 'View',
            isButton: true,
            onClick: _onViewRow
          }}
          mainButton={
            <ButtonComponent
              title="Add Curriculum"
              isBusy={false}
              type="primary"
              onClick={_onAddCurriculum}
            />
          }
        />
      </div>
    </div>
  )
}

export default CurriculumTable
