import React, { useEffect, useState } from 'react'
import InputComponent from '../../customComponents/input'
import ButtonComponent from '../../customComponents/button'
import PopupControl from '../../../controls/popup/popup'
import { faSave } from '@fortawesome/pro-light-svg-icons'
import { issue_ved_days } from '../../../api/calls/issue_ved_days'

const IssueVedDays = ({ show, setShow, data, showAlert, onSuccess = () => {} }) => {
  const [formValues, setFormValues] = useState({ type: 'VED Day' })
  const [canSave, setCanSave] = useState(false)

  const _onInputChange = e => {
    setFormValues({ ...formValues, ...e })
  }

  const _validateForm = () => {
    if (formValues?.ved_days) {
      setCanSave(true)
    } else {
      setCanSave(false)
    }
  }

  const _onSave = async () => {
    //api call...
    await issue_ved_days(formValues).then(async res => {
      if (res?.data.success) {
        showAlert({
          type: 'success',
          message: 'Ved Days added successfully'
        })
        setShow(false)
        onSuccess()
      } else {
        showAlert({
          type: 'error',
          message: 'Failed to add Ved Days'
        })
      }
    })
  }

  useEffect(() => {
    _onInputChange({
      ...data
    })
  }, [data])

  useEffect(() => {
    _validateForm()
  }, [formValues])

  return (
    <div>
      <PopupControl
        title="Add Ved Days"
        isOpen={show}
        close={{
          showClose: true,
          onClose: () => {
            setShow(false)
          }
        }}
      >
        <div className="w-full flex flex-col gap-4">
          <InputComponent
            isNumber={true}
            theme="dark"
            title="Please confirm the number of VED days to be issued"
            value={formValues?.ved_days}
            onChange={e =>
              _onInputChange({
                ved_days: e
              })
            }
          />
          <ButtonComponent
            title="Save"
            disabled={!canSave}
            icon={faSave}
            isBusy={false}
            type="primary"
            onClick={_ => {
              _onSave()
            }}
          />
        </div>
      </PopupControl>
    </div>
  )
}

export default IssueVedDays
