import { faPlus } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import ButtonControl from '../../../controls/button/button'
import CheckBoxControl from '../../../controls/checkbox/checkbox'
import TextInputControl from '../../../controls/textinput/textinput'

const TaxInvoice = () => {
  const [form, setForm] = useState('')
  const [date, setDate] = useState('')
  const [orderNumber, setOrderNumber] = useState('')
  const [to, setTo] = useState('')
  const [vat, setVat] = useState('')
  const [amount, setAmount] = useState('')
  const [quantity, setQuantity] = useState('')
  const [description, setDescription] = useState('')
  const [unitPrice, setUnitPrice] = useState('')
  const [subTotal, setSubTotal] = useState('')
  const [inclusive, setInclusive] = useState('')
  const [total, setTotal] = useState('')
  const history = useHistory()
  const [module, setModule] = useState({})

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row">
        <div className="flex-auto text-3xl font-bold">Tax Invoice</div>
      </div>
      <div className="py-4 px-4 bg-white ">
        <div className="grid grid-row-5 space-y-4">
          <div className="grid grid-cols-2 space-x-2 px-4">
            <TextInputControl
              title="Order Form:"
              value={form}
              onChange={v => {
                setForm(v)
              }}
            />
            <div className="grid grid-cols-2 space-x-2">
              <TextInputControl
                type="date"
                title="Date:"
                value={date}
                onChange={v => {
                  setDate(v)
                }}
              />

              <TextInputControl
                title="Order No:"
                value={orderNumber}
                onChange={v => {
                  setOrderNumber(v)
                }}
              />
            </div>
          </div>

          <div className="grid grid-cols-2 space-x-2 px-4">
            <TextInputControl
              title="to:"
              value={to}
              onChange={v => {
                setTo(v)
              }}
            />
            <TextInputControl
              title="VAT.Reg No:"
              value={vat}
              onChange={v => {
                setVat(v)
              }}
            />
          </div>
          <div className="grid grid-cols-5 space-x-2 px-4">
            <TextInputControl
              title="Quantity:"
              value={quantity}
              onChange={v => {
                setQuantity(v)
              }}
            />
            <span className="col-span-2">
              <TextInputControl
                title="Description"
                value={description}
                onChange={v => {
                  setDescription(v)
                }}
              />
            </span>
            <TextInputControl
              title="Unit Price"
              value={unitPrice}
              onChange={v => {
                setUnitPrice(v)
              }}
            />

            <div className="flex flex-col place-items-center justify-items-center space-x-2">
              <div className="flex flex-col">
                <p>Amount</p>
                <div className="grid grid-cols-2 space-x-2">
                  <TextInputControl
                    //   title="Unit Price"
                    value={amount}
                    onChange={v => {
                      setAmount(v)
                    }}
                  />
                  <div className="grid border-2 border-gray rounded-full py-2 px-2 w-10 place-items-center justify-items-center">
                    <FontAwesomeIcon icon={faPlus} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-3 space-x-4">
            <div className="h-40 col-span-2 grid grid-rows-3 px-4">
              <div className="flex justify-center items-center bg-gray max-w-full xw">NOTE</div>
              <div className="flex justify-center items-center bg-green row-span-2">
                Delete as applicable
              </div>
              <div className="flex flex-row space-x-2 gap-2 py-2">
                <CheckBoxControl
                  theme="light"
                  name="optimization_type"
                  checked={module.optimization_type == 'TsAndCs'}
                  type="radio"
                  value="TsAndCs"
                  onChange={e => {
                    setModule({
                      ...module,
                      optimization_type: e.target.value
                    })
                  }}
                />

                <p>Ts & Cs</p>
              </div>
            </div>

            <div className="grid grid-rows-3 w-80 place-content-center">
              <div className="grid grid-cols-2">
                <div>Subtotal</div>
                <TextInputControl
                  value={subTotal}
                  onChange={v => {
                    setSubTotal(v)
                  }}
                />
              </div>
              <div className="grid grid-cols-2">
                <div>Inclusive</div>
                <TextInputControl
                  value={inclusive}
                  onChange={v => {
                    setInclusive(v)
                  }}
                />
              </div>
              <div className="grid grid-cols-2">
                <div>TOTAL</div>
                <TextInputControl
                  value={total}
                  onChange={v => {
                    setTotal(v)
                  }}
                />
              </div>
            </div>
          </div>
          <div className="grid grid-rows-2 space-y-4">
            <div className="flex flex-row justify-center">
              <div className="flex flex-row w-96 space-x-2">
                <ButtonControl
                  placeholder="Save"
                  isBusy={false}
                  type="primary"
                  // onClick={v => {
                  //   setAhowAddGroup(true)
                  // }}
                />
                <ButtonControl
                  placeholder="Back"
                  isBusy={false}
                  type="primary"
                  onClick={() => {
                    history.goBack()
                  }}
                />
              </div>
            </div>

            <div className="flex flex-row  justify-center">
              <div className="flex flex-row w-96 space-x-2">
                <ButtonControl
                  placeholder="Upload Tax Invoice"
                  isBusy={false}
                  type="primary"
                  // onClick={v => {
                  //   setAhowAddGroup(true)
                  // }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TaxInvoice
