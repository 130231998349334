import React, { useEffect, useState } from 'react'

import { connect, useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { get_all_groups, get_program_users, get_venues, save_register } from '../../api/calls'
import { get_program_interactions } from '../../api/calls/programs/get_program_interactions'
import { getGroups, getLearners, getPrograms } from '../../asyncActions'
import { saveRegister } from '../../asyncActions/attendance'
import CheckBoxControl from '../../controls/checkbox/checkbox'
import { CircleIconText } from '../../controls/circleIconText/circleIconText'
import TableContentWrapper from '../../controls/tableContentWrapper/tableContentWrapper'
import { useFormActionHook } from '../../hooks/useFormActionHook'
import AttendanceTable from './components/attendanceTable'
import { attendanceValidationConfig, initialAttendanceData } from './components/contants'
import ButtonComponent from '../customComponents/button'
import InputComponent from '../customComponents/input'
import { faSave } from '@fortawesome/pro-light-svg-icons'
import DropdownButtonComponent from '../customComponents/dropdownButton'
import { convertDateTimeFromISO } from '../../utils'
import NotificationControl from '../../controls/notification/notification'
import moment from 'moment'
import { get_draft_register } from '../../api/calls/get_register_draft'
import { useHistory } from 'react-router-dom'
import { setAlert } from '../../asyncActions/alert'
import DatePickerComponent from '../customComponents/datepicker'
import { get_curriculum_interactions } from '../../api/calls/curriculums/get_curriculum_interactions'

const RemedialAction = ({
  venueState,
  learnerState,
  notificationState: notificationData,
  programsState,
  userState
}) => {
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState(initialAttendanceData)
  const [init, setInit] = useState(true)
  const [complete, setComplete] = useState(false)
  const [users, setUsers] = useState([])
  const [venues, setVenues] = useState([])
  const [selectedGroup, setSelectedGroup] = useState()
  const [learners, setLearners] = useState()
  const { program_id } = useParams()
  const [selectedProgram, setSelectedProgram] = useState({})
  const [attendanceForm, setAttendanceForm] = useState({})
  const [interactions, setInteractions] = useState([])
  const history = useHistory()
  const [dayType, setDayType] = useState('Standard')
  const [groups, setGroups] = useState([])

  //   const notificationData = useSelector(selectNotificationState)

  const _onSave = draft => {
    const payload = { ...formValues, draft: draft }
    save_register(payload).then(res => {
      if (res.data.success) {
        dispatch(
          setAlert({
            show: true,
            success: true,
            message: `You have successfully saved register ${draft === true ? 'as draft' : ''}.`
          })
        )
        setComplete(true)
      } else {
        dispatch(
          setAlert({
            show: true,
            success: false,
            message: 'The register could not be saved.'
          })
        )
      }
    })

    //set back to default
    dispatch(
      setAlert({
        show: false,
        success: false
      })
    )
  }

  useEffect(() => {
    dispatch(
      setAlert({
        show: false,
        success: false,
        message: 'You have successfully saved register.'
      })
    )
    get_draft_register(program_id).then(e => {
      if (e.data.success && e.data.content != null) {
        const id = e.data.content._id
        delete e.data.content._id
        delete e.data.content.learners
        setFormValues({ ...formValues, id, ...e.data.content })
      }
    })
  }, [])

  useEffect(() => {
    console.log('formValues', formValues)
  }, [formValues])

  useEffect(() => {
    if (complete === true) {
      history.goBack()
    }
  }, [complete])

  useEffect(() => {
    if (program_id)
      get_program_interactions(program_id)
        .then(response => {
          if (response.data.success) {
            setInteractions(response.data.content)
          }
        })
        .catch(error => {})
  }, [program_id])

  useEffect(() => {
    if (Object.keys(attendanceForm).length > 0) {
      _onInputChange({
        attendance_form: {
          name: '',
          url: attendanceForm.attendance_form.location,
          etag: attendanceForm.attendance_form.etag
        }
      })
    }
  }, [attendanceForm])

  const _onInputChange = value => {
    setFormValues({ ...formValues, ...value })
  }

  const handleLearners = value => {
    setLearners(value)
  }

  useEffect(() => {
    _onInputChange({ learners })
  }, [learners])

  useEffect(() => {
    if (!programsState.programsLoaded) {
      dispatch(getPrograms())
    }
    if (init && learnerState.learners.length == 0) {
      dispatch(getLearners())
      setInit(false)
    }

    if (init && groups.length == 0) {
      get_all_groups().then(res => {
        if (res.data.success) {
          setGroups(res.data.content)
        }
      })
      setInit(false)
    }

    let _program = programsState.programs.find(x => x._id === program_id)
    if (_program) {
      setSelectedProgram(_program)
    }

    get_venues()
      .then(response => {
        if (response.data.success) {
          setVenues(response.data.content)
        }
      })
      .catch(error => {})

    _onInputChange({ program_id: selectedProgram._id, facilitator_id: userState.id })
  }, [learnerState, programsState, selectedProgram, venueState, userState, groups])

  useEffect(() => {
    if (Object.keys(selectedProgram).length > 0) {
      get_program_users(`id=${selectedProgram._id}`).then(res => {
        if (res.data.success) {
          setUsers(res.data.content.length)
        }
      })
    }
  }, [selectedProgram])

  useEffect(() => {
    if (selectedGroup != null) {
      if (Object.keys(selectedProgram).length > 0) {
        get_program_users(`id=${selectedProgram._id}&group_id=${selectedGroup?._id}`).then(res => {
          if (res.data.success) {
            setUsers(res.data.content)
          }
        })
      }
    }
  }, [selectedGroup])

  useEffect(() => {
    fetchInteractions()
  }, [])

  const fetchInteractions = () => {
    console.log('program_id', program_id)
    console.log('dayType', dayType)
    if (program_id)
      get_curriculum_interactions({ program_id, day_type: dayType })
        .then(response => {
          if (response.data.success) {
            setInteractions(response.data.content)
          }
        })
        .catch(error => {})
  }

  return (
    <TableContentWrapper title="Attendance">
      <div className="w-full ">
        <div className="flex w-full flex-col items-center justify-center">
          <div className="flex w-full gap-4 italic font-sans font-extrabold text-lg px-12">
            Attendance Register for {moment(formValues.date).format('dddd, MMMM Do YYYY')}
          </div>
          <hr className="text-black" />

          <div className="flex flex-col w-full gap-4 px-12">
            <hr className="text-black" />
            <div className="grid grid-cols-3 gap-4">
              <div className="grid grid-cols-3 gap-2">
                <InputComponent
                  required={true}
                  title="Registers Used"
                  theme="form"
                  value={formValues.number_of_registers}
                  onChange={number_of_registers => {
                    _onInputChange({ number_of_registers })
                  }}
                />
                <div className="col-span-2">
                  <InputComponent
                    required={true}
                    title="Name"
                    theme="form"
                    value={formValues.name}
                    onChange={name => {
                      _onInputChange({ name })
                    }}
                  />
                </div>
              </div>

              <div className="col-span-2 grid grid-cols-3 gap-2">
                <DropdownButtonComponent
                  required={true}
                  theme={'form'}
                  title="Group"
                  placeholder={'Select Group'}
                  isBusy={false}
                  selector="_id"
                  displayKey="name"
                  dropdownItems={groups}
                  selectedItem={groups.find(x => x._id == selectedGroup?._id)?.name}
                  onClick={item => {
                    setSelectedGroup(item)
                    _onInputChange({ group_id: item?._id })
                  }}
                />
                <DropdownButtonComponent
                  required={true}
                  theme={'form'}
                  title="Interaction Code"
                  placeholder={'Select Interaction Code'}
                  isBusy={false}
                  selector="_id"
                  displayKey="interaction_name"
                  dropdownItems={interactions.filter(e => {
                    if (dayType === 'Catch Up') {
                      return e
                    } else {
                      return !e.attended
                    }
                  })}
                  showSearch={true}
                  selectedItem={
                    interactions.find(x => x._id == formValues.interaction_id)?.interaction_name
                  }
                  onClick={item => {
                    _onInputChange({ interaction_id: item._id })
                  }}
                />
                <DropdownButtonComponent
                  required={true}
                  theme={'form'}
                  isBusy={false}
                  title="Venue"
                  placeholder="Select Venue"
                  selector="_id"
                  displayKey="name"
                  dropdownItems={venues}
                  selectedItem={venues.find(x => x._id == formValues.venue_id)?.name}
                  onClick={item => {
                    _onInputChange({ venue_id: item._id })
                  }}
                />
              </div>
            </div>
            <hr className="text-black" />
            <div className="grid grid-cols-8 gap-12">
              <div className="col-span-2 flex flex-col gap-4">
                <div className="text-sm font-extrabold">Please select date (required)</div>
                <DatePickerComponent
                  title={'Select Date'}
                  required={true}
                  //   type="date"
                  //   theme="dark"
                  disablePast={false}
                  date={formValues.date}
                  onChange={date => _onInputChange({ date })}
                />
              </div>
              <div className="col-span-3">
                <div className="flex flex-col gap-2">
                  <div className="text-sm font-extrabold">Please select OI (required)</div>
                  <div className="flex flex-col gap-2">
                    <CheckBoxControl
                      label="Human OI"
                      theme="light"
                      name="level_type"
                      checked={formValues.level_type == 'Human'}
                      value="Human"
                      onChange={e => {
                        _onInputChange({ level_type: e.target.value })
                      }}
                    />
                  </div>
                  {/* <CheckBoxControl
                    label="Business OI"
                    theme="light"
                    name="level_type"
                    checked={formValues.level_type == 'Business'}
                    value="Business"
                    onChange={e => {
                      _onInputChange({ level_type: e.target.value })
                    }}
                  /> */}
                  <CheckBoxControl
                    label="Technical OI"
                    theme="light"
                    name="level_type"
                    checked={formValues.level_type == 'Technical'}
                    value="Technical"
                    onChange={e => {
                      _onInputChange({ level_type: e.target.value })
                    }}
                  />
                  {/* <CheckBoxControl
                    label="Induction OI"
                    theme="light"
                    name="level_type"
                    checked={formValues.level_type == 'Induction'}
                    value="Induction"
                    onChange={e => {
                      _onInputChange({ level_type: e.target.value })
                    }}
                  /> */}
                  {/* <CheckBoxControl
                    label="VED OI"
                    theme="light"
                    name="level_type"
                    checked={formValues.level_type == 'VED'}
                    value="VED"
                    onChange={e => {
                      _onInputChange({ level_type: e.target.value })
                    }}
                  /> */}
                </div>
              </div>
              <div className="col-span-3">
                <div className="flex flex-col gap-2">
                  <div className="text-sm font-extrabold">Please day type (required)</div>
                  <div className="flex flex-col gap-2">
                    <CheckBoxControl
                      label="Standard Day"
                      theme="light"
                      name="day_type"
                      checked={dayType == 'Standard'}
                      value="Standard"
                      onChange={e => {
                        setDayType('Standard')
                      }}
                    />
                  </div>
                  <CheckBoxControl
                    label="Catch Up Day"
                    theme="light"
                    name="day_type"
                    checked={dayType == 'Catch Up'}
                    value="Catch Up"
                    onChange={e => {
                      setDayType('Catch Up')
                      fetchInteractions()
                    }}
                  />
                  <CheckBoxControl
                    label="Value Exchange Day"
                    theme="light"
                    name="day_type"
                    checked={dayType == 'VED'}
                    value="VED"
                    onChange={e => {
                      setDayType('VED')
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="w-full flex-col gap-2">
              <div className="flex flex-row justify-between gap-2 py-4">
                <div className="w-full rounded-lg">
                  <div className="bg-gray flex justify-center p-2">Attendance Consequences</div>
                  <div className="bg-card1 flex flex-col justify-start items-start p-2 gap-2">
                    <CircleIconText
                      text="Not Present - Gets a dismissed after 3 mistakes (reason)"
                      className="text-red-600"
                    />
                    <CircleIconText
                      text="Late - Gets a warning for reason"
                      className="text-yellow"
                    />
                    <CircleIconText text="Present - No consequences" className="text-green" />
                  </div>
                </div>
                <div className="w-full">
                  <div className="bg-gray flex justify-center p-2">KPIs to be met</div>
                  <div className="bg-card1 flex flex-col justify-start items-start p-2 gap-2">
                    <CircleIconText text="0-49 %" className="text-red-600" />
                    <CircleIconText text="50-69% orange" className="text-orange" />
                    <CircleIconText text="70-100% green" className="text-green" />
                  </div>
                </div>
              </div>
            </div>
            <hr className="text-gray" />

            <AttendanceTable
              users={users.length > 0 ? users : []}
              date={convertDateTimeFromISO(formValues.date).date}
              onChange={handleLearners}
              program={selectedProgram}
              dayType={dayType}
            ></AttendanceTable>

            <TableContentWrapper>
              <div className="flex flex-col">
                <div className="text-lg font-extrabold">Note:</div>
                <div className="text-sm">
                  Saving register as draft will not save it with learners and group.
                </div>
              </div>
            </TableContentWrapper>

            <div className="flex flex-row justify-center items-center w-full gap-4">
              <ButtonComponent
                title="Save as Draft"
                icon={faSave}
                onClick={() => {
                  _onSave(true)
                }}
                isBusy={false}
                type="primary"
              />
              <ButtonComponent
                title="Save"
                icon={faSave}
                onClick={() => {
                  _onSave(false)
                }}
                isBusy={false}
                type="primary"
              />
            </div>
          </div>
        </div>
      </div>
      <NotificationControl notificationData={notificationData} />
    </TableContentWrapper>
  )
}

const mapStateToProps = state => {
  return {
    learnerState: state.learnerState,
    venueState: state.venueState,
    notificationState: state.notificationState,
    programsState: state.programsState,
    facilitatorState: state.facilitatorState,
    userState: state.userState,
    alertState: state.alertState
  }
}

export default connect(mapStateToProps)(RemedialAction)
