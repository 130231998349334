import React, { useEffect, useState } from 'react'

import { faArrowLeft, faUser, faUserGroup } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import {
  getFacilitators,
  getFacilitatorsPrograms,
  getFacilitatorTeachingDays,
  getFacilitatorTransactions,
  saveBilling
} from '../../../asyncActions'
import { getModules } from '../../../asyncActions/modules'
import BreadCrumb from '../../../controls/breadcrumb/breadcrumb'
import ButtonControl from '../../../controls/button/button'
import TitleCardControl from '../../../controls/cards/titlecard'
import EntityTable from '../../../controls/entityTable/entityTable'
import TableContentWrapper from '../../../controls/tableContentWrapper/tableContentWrapper'
import { selectSelectedUser } from '../../../selectors/selectors'
import { extractById } from '../../../utils/entityUtils'
import { selectModules, selectModulesLoaded } from '../../modules/selectors'
import { selectNotificationState } from '../../notifications/selectors'
import AddPaymentComponent from '../../user/components/addPayment'
import AddTransactionComponent from '../../user/components/addTransactions'
import DetailsBlock from '../../user/components/detailsBlock'
import AddDocumentComponent from '../../user/components/UploadDocuments'
import ValueExchange from '../../user/components/valueExchange'
import {
  initialRateValues,
  programColumn,
  teachingDaysColumn,
  transactionsColumn
} from '../../user/constants'
import AddRateModal from '../modals/addRate'
import { selectFacilitators, selectFacilitatorsLoaded } from '../selectors'
import { extractWithModules, getTransactionTableData } from '../utils'
import { MyTableControl } from '../../../controls/myTableControl/myTableControl'
import ButtonComponent from '../../customComponents/button'
import { Link } from '@material-ui/core'
import TableComponent from '../../UIComponents/components/tables/table'
import ButtonComponentOutlined from '../../UIComponents/components/buttons/buttonOutlined'
import moment from 'moment'
import { selectProgramsLoaded } from '../../programs/selectors'
import { get_facilitator_programs } from '../../../api/calls'

const entityName = 'Facilitator'

const FacilitatorDetails = props => {
  const { id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const [showAddPayment, setShowAddPayment] = useState(false)
  const [showAddRate, setShowAddRate] = useState(false)
  const [showAddDocument, setShowAddDocument] = useState(false)
  const [showAddTransaction, setShowAddTransaction] = useState(false)
  const [programs, setPrograms] = useState([])

  const facilitators = useSelector(selectFacilitators)
  const facilitator = extractById(facilitators)(id)

  const modulesLoaded = useSelector(selectModulesLoaded)
  const modules = useSelector(selectModules)

  const teachingDays = facilitator?.teachingDays || []
  const teachingDayModules = extractWithModules(teachingDays, modules)

  const formValues = initialRateValues

  const getFacilitatorPrograms = async facilitator => {
    await get_facilitator_programs(facilitator._id)
      .then(res => {
        if (res.data.success) {
          const programs = res.data.content.map(program => {
            return {
              ...program,
              start_date: moment(program.start_date).format('LL'),
              end_date: moment(program.end_date).format('LL')
            }
          })
          setPrograms(programs)
        }
      })
      .catch(error => {})
  }

  useEffect(() => {
    if (!modulesLoaded) dispatch(getModules())
  }, [modulesLoaded])

  useEffect(() => {
    dispatch(getFacilitators())
  }, [])

  useEffect(() => {
    console.log('facilitator', facilitator)
    if (facilitator) {
      getFacilitatorPrograms(facilitator)

      if (facilitator?.programs && !facilitator?.transactions) {
        dispatch(getFacilitatorTransactions(facilitator))
      }

      if (facilitator?.transactions && !facilitator?.teachingDays) {
        dispatch(getFacilitatorTeachingDays(facilitator))
      }
    }
  }, [facilitator])

  const _onViewProgram = item => {
    history.push(`/dashboard/program-details/${item._id}`)
  }

  const _onSubmitHandler = values => {
    facilitator['billingInfo'] = values
    dispatch(saveBilling(values))
  }

  return (
    <TableContentWrapper
      title={`${entityName} - ${facilitator?.user_name} ${facilitator?.user_surname}`}
    >
      <div className="flex flex-col gap-4">
        <div className="flex gap-4">
          <div className="flex flex-1 flex-col gap-2">
            <TitleCardControl css={{ height: '150px' }}>
              <div className="flex flex-col gap-4 justify-between">
                <div className="flex flex-row gap-8">
                  <div
                    className="p-4 flex bg-indigo rounded-full justify-center"
                    style={{ height: '100px', width: '100px' }}
                  >
                    <FontAwesomeIcon
                      className="fa-4x text-white ml-8 mr-8 align-baseline"
                      icon={faUser}
                    />
                  </div>
                  <div className="flex flex-col justify-center gap-2">
                    <div className="font-extrabold text-lg font-sans">{`${facilitator?.user_name} ${facilitator?.user_surname}`}</div>
                    <div className="text-sm text-gray">{facilitator?.user_email}</div>
                  </div>
                </div>
              </div>
            </TitleCardControl>
            <TableComponent
              title="Programs"
              data={programs ?? []}
              displayKeys={programColumn}
              onRowClick={e => {
                _onViewProgram(e)
              }}
            />

            <TableComponent
              title="Teaching Days"
              data={teachingDayModules}
              displayKeys={teachingDaysColumn}
            />
          </div>
        </div>
      </div>

      <AddPaymentComponent open={showAddPayment} setShow={setShowAddPayment} user={facilitator} />
      <AddTransactionComponent
        open={showAddTransaction}
        setShow={setShowAddTransaction}
        user={facilitator}
        programs={undefined}
      />
      <AddRateModal
        initialValue={facilitator?.billingInfo || formValues}
        open={showAddRate}
        setShow={setShowAddRate}
        onSuccess={_onSubmitHandler}
      />
      <AddDocumentComponent
        open={showAddDocument}
        setShow={setShowAddDocument}
        user={facilitator}
      />
    </TableContentWrapper>
  )
}

export default FacilitatorDetails
