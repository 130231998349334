import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { saveGroup } from '../../../api/calls/groups/saveGroup'
import { getFacilitators, getLearners, setNotification } from '../../../asyncActions'
import { selectFacilitators, selectFacilitatorsLoaded } from '../../facilitators'
import { selectLearnersLoaded } from '../../learner/selectors'
import { initialGroupValues } from '../constants'
import { faSave } from '@fortawesome/pro-light-svg-icons'
import { upload_file } from '../../../api/calls'
import MultipleSelectComponent from '../../UIComponents/components/select/multiSelect'
import TextFieldComponent from '../../UIComponents/components/textfield/textfiled'
import FileUploadButton from '../../UIComponents/components/buttons/fileUploadButton'
import DatePickerComponent from '../../UIComponents/components/calendar/date'
import BackDropComponent from '../../UIComponents/components/form/backdrop'
import { validateForm } from '../../studentApplication/form/components/constants'
import ButtonComponentContained from '../../UIComponents/components/buttons/buttonContained'
import NotificationControl from '../../../controls/notification/notification'
import ButtonComponentOutlined from '../../UIComponents/components/buttons/buttonOutlined'
import { resetAlert, setAlert } from '../../../asyncActions/alert'

const validationData = [
  {
    name: 'name',
    required: true
  },
  {
    name: 'description',
    required: true
  },
  {
    name: 'facilitator_ids',
    required: true
  },
  {
    name: 'image',
    required: false
  },
  {
    name: 'allocation_code',
    required: true
  },
  {
    name: 'graduation_date',
    required: true
  }
]

const AddGroupsComponent = ({
  open = false,
  data = {},
  isEdit = false,
  setIsEdit,
  onSuccess = () => {}
}) => {
  const dispatch = useDispatch()
  const facilitatorsLoaded = useSelector(selectFacilitatorsLoaded)
  const learnersLoaded = useSelector(selectLearnersLoaded)
  const facilitators = useSelector(selectFacilitators).map(_ => ({
    ..._,
    fullName: `${_?.user_name} ${_?.user_surname}`
  }))

  const [isOpen, setIsOpen] = useState(false)
  const [formValues, setFormValues] = useState({})
  const [canSave, setCanSave] = useState(false)
  const [loading, setLoading] = useState(false)
  const [notificationData, setNotificationData] = useState({})

  const _onInputChange = values => {
    setFormValues({ ...formValues, ...values })
  }

  useEffect(() => {
    if (!facilitatorsLoaded) dispatch(getFacilitators())
    if (!learnersLoaded) dispatch(getLearners())
  }, [facilitatorsLoaded, learnersLoaded])

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  useEffect(() => {
    if (isEdit) {
      const id = data._id
      delete data._id
      setFormValues({ id, ...data })
    } else {
      setFormValues(initialGroupValues)
    }
  }, [isEdit])

  const handleSaveGroup = async payload => {
    delete payload.learners
    delete payload.facilitators
    delete payload.groups
    setLoading(true)

    await saveGroup(payload).then(response => {
      if (response.data.success) {
        setIsOpen(!open)
        setIsEdit(false)
        dispatch(setAlert({ show: true, success: true, message: 'Group saved successfully' }))
      } else {
        dispatch(setAlert({ show: true, success: false, message: 'Group could not be saved' }))
      }
    })
    dispatch(resetAlert())
    setLoading(false)
    onSuccess()
  }

  useEffect(() => {
    const valid = validateForm(validationData, formValues)
    setCanSave(valid)
  }, [formValues])

  const handleMenuItemClick = selectedItems => {
    setFormValues(prevValues => ({
      ...prevValues,
      facilitators: selectedItems.map(e => e.fullName)
    }))
    _onInputChange({
      facilitator_ids: selectedItems.map(e => e._id)
    })
  }

  return (
    <BackDropComponent
      isOpen={isOpen}
      onBackDropClose={() => {
        setIsOpen(!open)
        setIsEdit(false)
      }}
      title="Create a group"
      description="Please fill in the form to create a group."
    >
      <div className="flex flex-col gap-2">
        <TextFieldComponent
          label="Group Name"
          required={true}
          value={formValues.name}
          onChange={name =>
            _onInputChange({
              name
            })
          }
          helperText={undefined}
          error={undefined}
        />
        <TextFieldComponent
          label="Group Description"
          required={true}
          value={formValues.description}
          onChange={description =>
            _onInputChange({
              description
            })
          }
          helperText={undefined}
          error={undefined}
        />
        <MultipleSelectComponent
          title="Facilitators"
          required={true}
          selectedItems={data?.facilitators?.map(e => ({ fullName: e }))}
          data={facilitators}
          displayKey={'fullName'}
          onMenuItemClick={handleMenuItemClick}
        />

        <FileUploadButton
          title={'Upload Image'}
          required={false}
          onChange={v => {
            upload_file({ file: v, name: v.name }).then(res => {
              if (res.data.success) {
                _onInputChange({
                  image: {
                    etag: res.data.content.etag,
                    url: res.data.content.location
                  }
                })
              }
            })
          }}
          selected={undefined}
        />
        <TextFieldComponent
          label="Allocation Code"
          value={formValues?.allocation_code}
          required={true}
          onChange={allocation_code =>
            _onInputChange({
              allocation_code
            })
          }
          helperText={undefined}
          error={undefined}
        />
        <DatePickerComponent
          title="Graduation Date"
          value={formValues?.graduation_date}
          onChange={graduation_date =>
            _onInputChange({
              graduation_date
            })
          }
          required={true}
        />
        <hr className="text-gray" />
        <div className="flex justify-end items-center gap-2">
          <ButtonComponentOutlined
            title="Cancel"
            onClick={_ => {
              setIsOpen(!open)
              setIsEdit(false)
            }}
            startIcon={undefined}
            endIcon={undefined}
            children={undefined}
          />
          <ButtonComponentContained
            title="Save"
            startIcon={faSave}
            loading={loading}
            disabled={!canSave || loading}
            onClick={_ => {
              handleSaveGroup(formValues)
            }}
            endIcon={undefined}
            children={undefined}
          />
        </div>
      </div>
      <NotificationControl notificationData={notificationData} />
    </BackDropComponent>
  )
}

const mapStateToProps = state => {
  return {
    mainState: state.mainState,
    groupState: state.groupState,
    noticationState: state.notificationState
  }
}

export default connect(mapStateToProps)(AddGroupsComponent)
