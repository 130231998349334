import React, { useState, useEffect } from "react";
import Gif from "../../assets/loaders/animation_500_klm80ad8.gif";

const LoaderGlobalControl = (props) => {
  return (
    <div
      id="loaderGlobal" style={{display: "none"}} className="fixed w-full h-full top-0 left-0 z-50"
    >
      <div
        className="h-full flex justify-center items-center"
      >
        <img src={Gif} className="w-20 rounded-full" />
      </div>
    </div>
  );
};

export default LoaderGlobalControl;
