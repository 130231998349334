import React, { useState } from 'react'
import ButtonControl from '../../../controls/button/button'
import PopupControl from '../../../controls/popup/popup'
import { useEffect } from 'react'
import { upload_file } from '../../../api/calls'
import FileInputControl from '../../../controls/fileinput/fileinput'

const Form = ({ open, setShow, onClick }) => {
  const [formDetails, setFormDetails] = useState({})

  useEffect(() => {
    if (Object.keys(formDetails).length > 0) {
      onClick(formDetails)
    }
  }, [formDetails])

  return (
    <div>
      <PopupControl
        title="Upload Attendance Form"
        isOpen={open}
        close={{
          showClose: true,
          onClose: () => {
            setShow(false)
          }
        }}
      >
        <div className="flex flex-col gap-8">
          <div className="flex justify-center">
            <div className="grid grid-rows-2 space-y-10">
              <FileInputControl
                theme="light"
                placeholder={formDetails.attendance_form ? 'Form Uploaded' : 'Upload a form'}
                onChange={v => {
                  upload_file({ file: v, name: v.name }).then(res => {
                    if (res.data.success) {
                      setFormDetails({ attendance_form: res.data.content })
                    }
                  })
                }}
              />
              <div className="flex flex-row justify-center">
                <div className="w-40">
                  <ButtonControl
                    placeholder="Save"
                    isBusy={false}
                    type="primary"
                    onClick={v => {
                      setShow(false)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </PopupControl>
    </div>
  )
}

export default Form
