import React, { useState, useEffect } from "react";
// import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
// import GoogleMap from 'google-map-react';
// import Marker from "./marker";
import { LoadScript, GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
// import { GoogleMap, Marker, withGoogleMap, withScriptjs, InfoWindow } from "react-google-maps";

const MapControl = props => {
    // const { isLoaded } = useJsApiLoader({
    //     id: 'google-map-script',
    //     googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY,
    // });

    const mapsAPIKey = process.env.GOOGLE_MAPS_API_KEY;

    const { 
        markers,
        center = /*props.markers.length > 0? props.markers[0]: */{ lat: -26.02479, lng: 28.00515, }, //Default center
        zoom = 8,
     } = props;

    const [mapMarkers, setMapMarkers ] = useState([]);



    //Did mount...
    useEffect(() => {
        setMapMarkers(markers);
        // console.log("markers", markers);
    }, [markers]);

    // const hasBothCoords = markers && markers.length > 0 && (markers[0].latitude && markers[0].longitude)
    // // let qwe = hasBothCoords ? [27, -26] : [26, -27];
    // let qwe = [-26, 27];
    // console.log("qwe", qwe);

    const onToggleOpen = (index) => {
        let marker = markers[index];
        marker.isOpen = !marker.isOpen;
        setMapMarkers([...markers]);
    }

    return (
        <div className="relative h-full w-full">
            {/* <LoadScript
                googleMapsApiKey={mapsAPIKey}
                language="en"
            > */}
                
                <GoogleMap
                    // mapContainerClassName="react-google-maps-api"
                    mapContainerStyle={{
                        width: "100%",
                        height: "100%",
                    }}
                    center={center}
                    onLoad={(map) => {
                        // console.log("onLoad", map);
                        // mapRef.current = map;
                    }}
                    zoom={zoom}
                    version="weekly"
                    // on
                    options={{
                        controlSize: 22,
                      //  mapTypeId: "satellite"
                    }}>
                    {mapMarkers.map((marker, index) => {
                        if (marker) {
                            let latLng = { lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) };
                            // console.log("latLng", latLng);
                            return <Marker key={index} position={latLng}
                                    onClick={() => onToggleOpen(index)}
                                >
                                {/* {marker.isOpen && */}
                                {marker.isOpen &&
                                <InfoWindow onClick={() => onToggleOpen(index)}>
                                    <div>
                                        <p>{`${marker.text}`}</p>
                                        <p>{`${marker.latitude} - ${marker.longitude}`}</p>
                                    </div>
                                </InfoWindow>}
                            </Marker>;
                        }
                    })}
                </GoogleMap>
            {/* </LoadScript> */}
        </div>
    )
}

export default MapControl;