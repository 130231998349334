import { faRedo } from '@fortawesome/pro-light-svg-icons'
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getLearners } from '../../asyncActions'
import TableContentWrapper from '../../controls/tableContentWrapper/tableContentWrapper'
import { selectPaginateState } from '../../selectors/selectors'
import ButtonComponent from '../customComponents/button'
import { selectLearnersState, selectLearnersLoaded, selectLearners } from '../learner/selectors'
import { selectNotificationState } from '../notifications/selectors'
import { useHistory } from 'react-router-dom'
import InductionTable from '../induction/components/table'

const InductionComponent = () => {
  const notificationData = useSelector(selectNotificationState)
  const dispatch = useDispatch()
  const learnersLoaded = useSelector(selectLearnersLoaded)

  useEffect(() => {
    if (!learnersLoaded) {
      dispatch(getLearners())
    }
  }, [learnersLoaded])

  return (
    <TableContentWrapper
      notificationData={notificationData}
      title="Reports"
      actionButtons={[
        <div className="flex-none w-40 gap-4">
          <ButtonComponent
            title="Refresh"
            icon={faRedo}
            onClick={v => {
              dispatch(getLearners())
            }}
          />
        </div>
      ]}
    >
      <div className="flex flex-col gap-4">
        <InductionTable />
      </div>
    </TableContentWrapper>
  )
}

export default InductionComponent
