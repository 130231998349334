import React, { useEffect, useState } from "react";

import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NotificationGlobalControl = ({
  messages = ["First Message"],
  title,
  type,
  close = true,
  onConfirm,
}) => {
  const [visibility, setVisibility] = useState(true);

  //Did mount...
  useEffect(() => {
    //  setVisibility(messages.length > 0);
  }, [messages]);

  //Will unmount...
  useEffect(() => {
    return () => {
      // console.log("Cleaning up...");
    };
  }, []);

  const toggleVisibilityFalse = () => {
     document.getElementById('globalNotification').style.display = 'none';
  }

  return visibility ? (
    <div id="globalNotification" style={{display: 'none'}} className="fixed bg-overlay z-50 w-full h-full top-0 left-0 flex justify-center items-center">
      <div className="flex flex-col bg-white rounded-lg">
        <div className="flex flex-row items-center justify-between pl-4">
          <div className="">{title}</div>
          {close && (
            <div
              className="cursor-pointer p-4 rounded-tr-lg rounded-bl-lg hover:bg-green transition"
              onClick={() => toggleVisibilityFalse()}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
          )}
        </div>
        <div className="p-4 border-b">
        <div id="globalNotificationMessage">
        </div>
        </div>

        {(() => {
          switch (type) {
            case "confirm":
              return (
                <div className={`flex flex-row`}>
                  <div
                    className="p-4"
                    onClick={() => {
                      toggleVisibilityFalse();
                      if (onConfirm) {
                        onConfirm();
                      }
                    }}
                  >
                    Confirm
                  </div>
                  <div className="p-4" onClick={() => toggleVisibilityFalse()}>
                    Cancel
                  </div>
                </div>
              );
            case "yesNo":
              return (
                <div className={`flex flex-row`}>
                  <div
                    className="p-4"
                    onClick={() => {
                      toggleVisibilityFalse();
                      if (onConfirm) {
                        onConfirm();
                      }
                    }}
                  >
                    Yes
                  </div>
                  <div className="p-4" onClick={() => toggleVisibilityFalse()}>
                    No
                  </div>
                </div>
              );
            case "ok":
            default:
              return (
                <div className={`flex `}>
                  <div
                    className="flex-auto p-2 text-center cursor-pointer hover:bg-green transition rounded-b-lg"
                    onClick={() => {
                      toggleVisibilityFalse();
                      if (onConfirm) {
                        onConfirm();
                      }
                    }}
                  >
                    OK
                  </div>
                </div>
              );
          }
        })()}
      </div>
    </div>
  ) : (
    <div className="hidden" />
  );
};

export default NotificationGlobalControl;
