import { editLearnerState } from '../actions/learner'
import { all_transactions } from '../api/calls'
import { all_payments } from '../api/calls/documents/all_payments'
export const getUserPayments = (user, program) => {
  return dispatch => {
    all_transactions(`user=${user._id}&transaction_type=LOAN_REPAYMENT`)
      .then(res => {
        if (res.data.success) {
          dispatch(
            editLearnerState({
              ...user,
              payments: res.data.content
            })
          )
        }
      })
      .catch(error => {})
  }
}
