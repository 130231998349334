import React, { useEffect, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import CreateEventComponent from '../createEvent'
import EventCategories from '../eventCategories'
import { useDispatch } from 'react-redux'
import { getAllTasks } from '../../../asyncActions'
import EditEventComponent from '../components/editEvent'

moment.locale('en-GB')
const localizer = momentLocalizer(moment)

const eventSkeleton = {
  id: '',
  title: '',
  start: Date,
  end: Date
}

const CalendarComponent = ({ events }) => {
  const [eventsData, setEventsData] = useState([eventSkeleton])
  const [showCreate, setShowCreate] = useState(false)
  const [showOptions, setShowOptions] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [option, setOption] = useState()
  const [startDate, setStartDate] = useState('')
  const [selectedEvent, setSelectedEvent] = useState({})
  const dispatch = useDispatch()

  useEffect(() => {
    const data = []
    for (const event of events) {
      event.start = new Date(event.start_date)
      event.end = new Date(event.end_date)
      data.push({
        id: event._id,
        title: event.event_name,
        start: event.start,
        end: event.end
      })
    }

    setEventsData([...data])
  }, [events])

  const _onCreateSuccess = e => {
    if (e) {
      setShowCreate(false)
      dispatch(getAllTasks())
    }
  }

  const _showOptions = e => {
    setStartDate(new Date(e.start).toISOString())
    setShowOptions(true)
  }

  const _onOptionSelected = option => {
    setOption(option)
    setShowCreate(true)
  }

  //   useEffect(() => {
  //     if (option) {
  //       setShowCreate(true)
  //     }
  //   }, [option, events])

  return (
    <div className="CalendarTheme">
      <Calendar
        views={['day', 'agenda', 'work_week', 'month']}
        selectable
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="month"
        startAccessor="start"
        endAccessor="end"
        events={eventsData}
        style={{ height: 'calc(100vh - 10rem)' }}
        onSelectEvent={event => {
          //find event in events and open edit modal
          const selectedTask = events.find(e => e._id === event.id)
          setSelectedEvent(selectedTask)
          setShowEdit(true)
        }}
        onSelectSlot={e => {
          //   _showOptions(e)
          setStartDate(
            moment(e.start, 'YYYY-MM-DD HH:mm:ss')
              .utcOffset('+02:00')
              .format('YYYY-MM-DDTHH:mm:ss.SSSZ')
              .toString()
          )
          setShowCreate(true)
        }}
      />
      {/* <AddCategoryComponent
          open={showAddCategory}
          setShowAddCategory={_onEditCategory}
          isEdit={isEditCategory}
          category={selectedCategory}
        /> */}

      <CreateEventComponent
        open={showCreate}
        show={setShowCreate}
        type={option}
        onCreateSuccess={_onCreateSuccess}
        startDate={startDate}
      />
      {/* <EventCategories open={showOptions} show={setShowOptions} onClick={_onOptionSelected} /> */}
      <EditEventComponent open={showEdit} show={setShowEdit} data={selectedEvent} isEdit={false} />
    </div>
  )
}

export default CalendarComponent
