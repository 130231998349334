import React from 'react'

import { CURRENCY, EMPTY_TEXT } from '../../constants/constants'
import { CircleIconText } from '../../controls/circleIconText/circleIconText'
import { extractById } from '../../utils/entityUtils'

export const extractWithModules = (data, modules) =>
  data.map(item => {
    const module = extractById(modules)(item.module_id)
    return {
      ...item,
      module_name: module?.module_name || EMPTY_TEXT,
      description: module?.module_name || EMPTY_TEXT
    }
  })

export const getTransactionTableData = data =>
  data?.map(item => ({
    ...item,
    value: (
      <div className="w-auto place-items-center">
        <div className="text-sm">{item?.value?.status}</div>
        <div>{`${CURRENCY}${item?.value?.amount || 0}`}</div>
      </div>
    )
  })) || []
