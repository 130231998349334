import {
  DELETE_GREENLIGHT_STATE,
  SAVE_GREENLIGHT_STATE,
  SET_GREENLIGHT_STATE,
  RESET_GREENLIGHT_STATE
} from '../../constants/redux_types'

export function greenlightStateReducer(
  state = {
    greenlightSurveysLoaded: false,
    greenlightSurveys: []
  },
  action
) {
  switch (action.type) {
    case SET_GREENLIGHT_STATE:
      return {
        ...state,
        ...action.payload
      }
    case SAVE_GREENLIGHT_STATE:
      return {
        ...state
      }
    case DELETE_GREENLIGHT_STATE:
      return {
        ...state,
        greenlightSurveys: state.greenlightSurveys.map(x => {
          if (x._id !== action.payload._id) {
            return action.payload
          } else {
            return x
          }
        })
      }
    case RESET_GREENLIGHT_STATE:
      return state
    default:
      return state
  }
}
