import React, { useState, useEffect } from "react";

import { Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faEllipsisV } from "@fortawesome/pro-light-svg-icons";

const ButtonControl = (props) => {
  const {
    id,
    theme = "light",
    subItems,
    onClick,
    disabled,
    style,
    type,
    isBusy,
    title,
    placeholder,
    dropdownOpen = false,
    allItem,
    inverseColor = "orange",
    size,
  } = props;

  const [isOpen, setIsOpen] = useState(dropdownOpen);

  //Did mount...
  useEffect(() => {}, []);

  //Will unmount...
  useEffect(() => {
    return () => {
      console.log("Cleaning up...");
    };
  }, []);

  const options = {
    light: {
      title: "text-white",
      placeholder: "text-white",
      buttonBg: "bg-theme",
    },
    dark: {
      title: "text-white",
      placeholder: "text-gray",
      buttonBg: "bg-textinput-dark",
    },
  };

  return (
    <div className="controls-button w-full text-center">
      {title && (
        <div className={`${options[theme].title} text-left text-lg`}>
          {title}
        </div>
      )}

      {type == "primary" && (
        <button
          id={`button-${id}`}
          className={`rounded-full ${options[theme].buttonBg} ${style}
                    w-full ${size === "small" ? "h-6" : "h-9"} px-4 ${
            options[theme].title
          } focus:outline-none`}
          onClick={onClick}
          disabled={disabled}
          onBlur={() => {
            setTimeout(() => {
              setIsOpen(false);
            }, 200);
          }}
        >
          {isBusy ? (
            "BUSY ..."
          ) : (
            <div
              className={`flex justify-center items-center`}
              style={{ fontSize: size === "small" && "10px" }}
            >
              <span className={`whitespace-nowrap ${options[theme].placeholder}`}>
                {placeholder}
              </span>
            </div>
          )}
        </button>
      )}

      {type == "secondary" && (
        <button
          id={`button-${id}`}
          className={`rounded-full bg-theme
                    w-full ${size === "small" ? "h-6" : "h-9"} px-4 ${
            options[theme].title
          } focus:outline-none`}
          onClick={onClick}
          disabled={disabled}
          onBlur={() => {
            setTimeout(() => {
              setIsOpen(false);
            }, 200);
          }}
        >
          {isBusy ? (
            "BUSY ..."
          ) : (
            <div
              className={`flex justify-center items-center`}
              style={{ fontSize: size === "small" && "10px" }}
            >
              <span className={`whitespace-nowrap ${options[theme].placeholder}`}>
                {placeholder}
              </span>
            </div>
          )}
        </button>
      )}

      {type == "link" && (
        <button
          id={`button-${id}`}
          className={`rounded ${
            size === "small" ? "h-6" : "h-9"
          } py-2 px-4 text-green text-sm hover:underline focus:outline-none `}
          onClick={onClick}
          disabled={disabled}
          onBlur={() => {
            setTimeout(() => {
              setIsOpen(false);
            }, 200);
          }}
        >
          {isBusy ? (
            "BUSY ..."
          ) : (
            <div style={{ fontSize: size === "small" && "10px" }}>
              <span>{placeholder}</span>
            </div>
          )}
        </button>
      )}

      {type == "inverse" && (
        <button
          id={`button-${id}`}
          className={`rounded py-2 px-4 text-${inverseColor} text-sm focus:outline-none border borde-${inverseColor}`}
          onClick={onClick}
          disabled={disabled}
          onBlur={() => {
            setTimeout(() => {
              setIsOpen(false);
            }, 200);
          }}
        >
          {isBusy ? (
            "BUSY ..."
          ) : (
            <div style={{ fontSize: size === "small" && "10px" }}>
              <span>{placeholder}</span>
            </div>
          )}
        </button>
      )}
    </div>
  );
};

export default ButtonControl;
