import React, { useState, useEffect } from 'react'
import ButtonControl from '../../../../controls/button/button'
import TextInputControl from '../../../../controls/textinput/textinput'
import logo from '../../../../assets/images/black_logo.png'
import { useHistory, useRouteMatch, useParams } from 'react-router-dom'
import Img from '../../../../assets/images/Signin-bg-image.png'
import { reset_password, verify_token } from '../../../../api/calls/reset_password'
import { useDispatch } from 'react-redux'
import NotificationControl from '../../../../controls/notification/notification'

const ResetPasswordComponent = props => {
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const dispatch = useDispatch()
  const [notificationData, setNotificationData] = useState({
    title: '',
    messages: [],
    // @ts-ignore
    type: 'ok' | 'confirm'
  })
  let { path, url } = useRouteMatch()
  const { token } = useParams()
  let history = useHistory()

  //Did mount...
  useEffect(() => {
    verifyToken()
  }, [token])

  //Will unmount...
  useEffect(() => {
    return () => {
      console.log('Cleaning up...')
    }
  }, [])

  const verifyToken = async () => {
    verify_token(token).then(response => {
      if (response.data.success) {
        // console.log(response.data)
      } else {
        // setNotificationData({
        //   title: 'OOPS!',
        //   messages: ['Session has expired. Please try again.']
        // })
      }
    })
  }

  return (
    <div className="component-login lg:flex lg:flex-column">
      <div className="flex flex-auto justify-center items-center">
        <img className="lg:w-full lg:h-screen object-cover" src={Img} />
        <div className="absolute lg:text-6xl text-5xl text-white text-left lg:bottom-5 left-5 font-bold p-4">
          Value Exchange Bank.
        </div>
      </div>
      <div className="grid gap-y-4 w-full max-w-lg px-4">
        <img className="object-contain max-w-lg px-20 pb-20 pt-10" src={logo} />

        <div>
          <p>Please enter your new password</p>
          <br />
          <br />

          <div className="flex gap-4">
            <TextInputControl
              title="New Password"
              value={newPassword}
              placeholder="New Password"
              onChange={v => setNewPassword(v)}
            />
            <TextInputControl
              title="Confirm New Password"
              value={confirmPassword}
              placeholder="Confirm Password"
              onChange={v => setConfirmPassword(v)}
            />
          </div>
        </div>

        <ButtonControl
          style="bg-buttonColor"
          placeholder="SEND"
          isBusy={false}
          type="primary"
          onClick={async v => {
            if (newPassword === confirmPassword) {
              await reset_password({
                reset_token: token,
                user_password: newPassword
              }).then(response => {
                if (response.data.success) {
                  history.push('/')
                }
              })
            } else {
              setNotificationData({
                title: 'OOPS!',
                messages: ['Passwords do not match.']
              })
            }
          }}
        />
      </div>
      <NotificationControl notificationData={notificationData} />
    </div>
  )
}

export default ResetPasswordComponent
