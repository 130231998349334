import React from 'react'

import Calendar from 'react-calendar'

import 'react-calendar/dist/Calendar.css'
import './calender.scss'

const CalendarController = ({
  selectRange = false,
  minDate,
  onChange,
  value,
  ...props
}) => {
  return (
    <div>
      <Calendar
        minDate={minDate??new Date()}
        selectRange={selectRange}
        onChange={onChange}
        value={value}
        {...props}
      />
    </div>
  )
}

export default CalendarController
