import { editProgramState, setProgramState } from '../actions/programs'
import { get_all_programs } from '../api/calls/programs/get_all_programs'
import { get_program_users } from '../api/calls/programs/get_program_users'
import { get_program_venues } from '../api/calls/programs/get_program_venues'

export const getPrograms = () => {
  return dispatch => {
    get_all_programs()
      .then(res => {
        if (res.data.success) {
          dispatch(
            setProgramState({
              programsLoaded: true,
              programs: res.data.content
            })
          )
        }
      })
      .catch(error => {})
  }
}

export const getProgramUsers = program => {
  return dispatch => {
    get_program_users(`id=${program._id}`)
      .then(res => {
        if (res.data.success) {
          dispatch(
            editProgramState({
              ...program,
              learners: res.data.content
            })
          )
        }
      })
      .catch(error => {})
  }
}

export const getProgramVenues = program => {
  return dispatch => {
    get_program_venues(`id=${program._id}`)
      .then(res => {
        if (res.data.success) {
          dispatch(editProgramState({ ...program, venues: res.data.content }))
        }
      })
      .catch(error => {})
  }
}
