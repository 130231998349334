import Cookies from 'universal-cookie'

import { SET_USER_STATE } from '../../constants/redux_types'

let cookies = new Cookies()
let cookieJSON = cookies.get('veb-user-cookie')

export function userStateReducer(
  state = {
    user_email: cookieJSON ? cookieJSON.user_email : '',
    id: cookieJSON ? cookieJSON._id : '',
    token: cookieJSON ? cookieJSON.token : '',
    user_name: cookieJSON ? cookieJSON.user_name : '',
    user_surname: cookieJSON ? cookieJSON.user_surname : '',
    fullName: cookieJSON ? cookieJSON.fullName : ''
  },
  action
) {
  switch (action.type) {
    case SET_USER_STATE:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
