import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setVenuesState } from '../../actions'
import { deleteVenue, getVenues } from '../../asyncActions'
import { CONFIRM_DELETE_MSG } from '../../constants/constants'
import ButtonControl from '../../controls/button/button'
import { useFormActionHook } from '../../hooks/useFormActionHook'
import { selectNotificationState } from '../notifications/selectors'
import {
  actionButtonItems,
  initialVenueValues,
  venueColumns,
  venueValidationConfig
} from './constants'
import AddVenuesModal from './modals/addVenuesModal'
import { selectVenues, selectVenuesLoaded } from './selectors'
import { MyTableControl } from '../../controls/myTableControl/myTableControl'
import TableContentWrapper from '../../controls/tableContentWrapper/tableContentWrapper'
import ButtonComponent from '../customComponents/button'
import { faPen } from '@fortawesome/pro-light-svg-icons'
import AddEditVenueComponent from './components/addEditVenue'

const Venues = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [showAddVenues, setShowAddVenues] = useState(false)
  const [selectedVenue, setSelectedVenue] = useState({})
  const venuesLoaded = useSelector(selectVenuesLoaded)
  const venues = useSelector(selectVenues, shallowEqual)
  const notificationData = useSelector(selectNotificationState)
  const [formValues, setFormValues] = useState(initialVenueValues)
  const { handleSubmit } = useFormActionHook(formValues, venueValidationConfig)
  const [isEdit, setIsEdit] = useState(false)

  useEffect(() => {
    if (!venuesLoaded) dispatch(getVenues())
  }, [venuesLoaded])

  const _toggleAddVenues = () => {
    setShowAddVenues(!showAddVenues)
  }

  const _onInputChange = value => {
    setFormValues({ ...formValues, ...value })
  }

  const _onClickActionButton = (item, _) => {
    setSelectedVenue(item)

    if (_.id == 1) {
      history.push(`venue-details/${item._id}`)
    }
    if (_.id == 2) {
      setIsEdit(true)
    }
    if (_.id == 3 && confirm(CONFIRM_DELETE_MSG + item.name)) {
      dispatch(deleteVenue(item))
      dispatch(
        setVenuesState({
          venues: venues.filter(venue => venue._id !== item._id)
        })
      )
    }
  }

  useEffect(() => {
    if (isEdit) {
      _toggleAddVenues()
    }
  }, [isEdit])

  return (
    <TableContentWrapper
      notificationData={notificationData}
      actionButtons={[
        <ButtonComponent
          title="Add a New Venue"
          icon={faPen}
          isBusy={false}
          type="primary"
          onClick={_toggleAddVenues}
        />
      ]}
    >
      {/* ----------Table */}
      <MyTableControl
        title={'Venues'}
        search={{ show: true, search_by: 'name' }}
        showPagination={true}
        data={venues}
        displayKeys={venueColumns}
        actionButton={{
          side: 'right',
          title: 'Details',
          items: actionButtonItems,
          isDropdown: true,
          onClick: _onClickActionButton
        }}
      />

      {/* --------- modals */}
      {/* <AddVenuesModal
        initialValue={selectedVenue}
        onSuccess={_onInputChange}
        open={showAddVenues}
        setShow={_toggleAddVenues}
      /> */}
      <AddEditVenueComponent
        isEdit={isEdit}
        open={showAddVenues}
        setShow={setShowAddVenues}
        formData={selectedVenue}
      />
    </TableContentWrapper>
  )
}

export default Venues
