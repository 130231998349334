export const selectFileState = ({ fileState }) => {
  return fileState
}
export const selectSelectedUser = ({ mainState }) => {
  return mainState.selectedUser
}

export const selectPaginateState = state => {
  return state.paginate
}
