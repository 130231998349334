import {
  EDIT_PROGRAM_STATE,
  SET_PROGRAM_STATE,
  UPDATE_PROGRAM_STATE
} from '../constants/redux_types'

export const setProgramState = payload => {
  return {
    type: SET_PROGRAM_STATE,
    payload: payload
  }
}

export const editProgramState = payload => {
  return {
    type: EDIT_PROGRAM_STATE,
    payload: payload
  }
}

export const updateProgramState = payload => {
  return {
    type: UPDATE_PROGRAM_STATE,
    payload: payload
  }
}
