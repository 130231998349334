import { TableCell, TableRow, styled } from '@material-ui/core'
import { tableCellClasses } from '@mui/material'

export const InductionTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 40
  }
}))

export const InductionTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

export const InductionTableHeader = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.selected
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

export const tableColumns = [
  { key: 'name', display: 'Activity' },
  { key: 'year', display: 'Year' },
  {
    key: 'quarters',
    display: '% Start of Quarter | % End of Quarter'
  },

  { key: 'year_totals', display: 'Year Totals (Actual inducted learners)' },
  { key: 'graduate', display: 'Graduate %' }
]
