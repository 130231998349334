import React, { useState, useEffect, useRef, useCallback } from "react";
import { Transition } from "@headlessui/react";
const NotificationPanelControl = ({data, showNotifications}) => {
  const [notifications, setNotifications] = useState(data);

  const removeNotification = (id) => {
    let newNotifications = notifications.filter(x => x.id != id);
    setNotifications(newNotifications);
  }
  return (
    <div className="flex-1 absolute top-12 right-20"style={{ width: "400px"}}>
    <div className="relative z-20">
      <Transition
        id={`subitems`}
        show={showNotifications}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="bg-white rounded p-5 shadow">
          <div className="text-sm text-gray mb-4">
            Notifications
          </div>
        {notifications.map((notification) => (
          <div className="flex mb-4 pb-4 border-b" key={notification.id}>
            <div className="mx-2">
              <div className="bg-indigo-400 w-2 h-2 rounded-full relative top-1/2"></div>
            </div>
            <div className="" style={{ width: "80%"}}>
              <div className="flex gap-4">
                <span className="text-blue-400 text-sm">
                  {notification.title}
                </span>
                <span className="text-xs text-gray">{notification.time}</span>
              </div>
              <div className="text-sm text-gray">{notification.message}</div>
            </div>
            <div className="mx-2 w-5 text-right" >
              <div className="text-gray h-2 relative top-1/2 text-xs cursor-pointer" onClick={() => removeNotification(notification.id)}>x</div>
            </div>
            <hr/>
          </div>
        ))}
        </div>
      </Transition>
    </div>
    </div>
  );
};
export default NotificationPanelControl;
