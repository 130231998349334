import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { SketchPicker } from 'react-color'
import { useDispatch, useSelector } from 'react-redux'
import { updateProgramState } from '../../../actions'
import { create_program, upload_file } from '../../../api/calls'
import { getFacilitators, getPrograms } from '../../../asyncActions'
import { DATE_FORMAT } from '../../../constants/constants'
import CheckBoxControl from '../../../controls/checkbox/checkbox'
import LoaderControl from '../../../controls/loader/loader'
import PopupControl from '../../../controls/popup/popup'
import TabControl from '../../../controls/tab/tab'
import { selectFacilitators, selectFacilitatorsLoaded } from '../../facilitators'
import InputComponent from '../../customComponents/input'
import DatePickerComponent from '../../customComponents/datepicker'
import FilesComponent from '../../customComponents/files'
import DropdownButtonComponent from '../../customComponents/dropdownButton'
import ButtonComponent from '../../customComponents/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { update_program } from '../../../api/calls/programs/update_program'

const AddProgramComponent = ({ open = false, setShow, program = {}, onsuccess = () => {} }) => {
  const dispatch = useDispatch()
  const [isBusy, setIsBusy] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [pickColor, setPickColor] = useState(false)
  const [values, setValues] = useState({ modules: [] })
  const [module, setModule] = useState({})
  const [modules, setModules] = useState([])
  const [facilitatorName, setFacilitatorName] = useState('- select facilitator -')

  const facilitatorsLoaded = useSelector(selectFacilitatorsLoaded)
  const facilitators = useSelector(selectFacilitators).map(_ => ({
    ..._,
    fullName: `${_?.user_name} ${_?.user_surname}`
  }))
  const [status, setStatus] = useState({
    id: 1,
    status: 'To Do'
  })
  const refresh = () => window.location.reload()

  const statuses = [
    { name: 'Active', value: 'ACTIVE', id: '1' },
    { name: 'Inactive', value: 'INACTIVE', id: '2' },
    { name: 'Blocked', value: 'BLOCKED', id: '2' }
  ]

  const programs = [
    { name: 'Program 1', value: 'Program 1', id: '1' },
    { name: 'Program 2', value: 'Program 2', id: '2' },
    { name: 'Program 3', value: 'Program 3', id: '3' }
  ]

  useEffect(() => {
    if (!facilitatorsLoaded) dispatch(getFacilitators())
  }, [facilitatorsLoaded])

  useEffect(() => {
    setIsOpen(open)
    if (program._id) {
      setValues(program)
      if (program.modules && program.modules.length > 0) {
        setModules(program.modules)
      }
    } else {
      setValues({})
    }
  }, [open])

  useEffect(() => {
    facilitators.find(e => {
      if (e._id === values?.facilitator_id) {
        setFacilitatorName(e.fullName)
      }
    })
    console.log('values', values)
  }, [values, facilitators])

  const colorPickRemoveBtn = {
    background: 'white',
    width: '30px',
    height: '30px',
    color: '#000',
    textAlign: 'center',
    borderRadius: '3px',
    position: 'relative',
    left: '-5',
    paddingTop: '3px',
    cursor: 'pointer'
  }

  const removeModule = e => {
    const index = modules.indexOf(e)
    let list = []
    if (index !== -1) {
      list = modules.splice(index, 1)
    }

    setModule(list)
  }

  return (
    <div>
      <PopupControl
        title={`${program._id ? 'Update' : 'Add'} Programme`}
        isOpen={open}
        close={{
          showClose: true,
          onClose: () => {
            setShow(false)
          }
        }}
      >
        <div className="flex flex-col w-72">
          <TabControl titles={['General', 'Modules']}>
            <div className="w-full flex flex-col gap-2">
              <InputComponent
                theme="dark"
                size="h-10"
                title="Name"
                size="h-10"
                value={values.program_name}
                onChange={v =>
                  setValues({
                    ...values,
                    program_name: v
                  })
                } //
              />

              <InputComponent
                theme="dark"
                size="lg"
                title="Description"
                value={values.program_description}
                onChange={v =>
                  setValues({
                    ...values,
                    program_description: v
                  })
                }
              />

              <InputComponent
                theme="dark"
                size="h-10"
                title="Value Exchange Days"
                value={values.value_exchange_days}
                onChange={v =>
                  setValues({
                    ...values,
                    value_exchange_days: v
                  })
                }
              />

              <DropdownButtonComponent
                title="Facilitator"
                placeholder={'Select Facilitator'}
                isBusy={false}
                type="primary"
                theme="dark"
                displayKey="fullName"
                dropdownItems={facilitators}
                selectedItem={facilitatorName}
                onClick={item => {
                  setValues({
                    ...values,
                    facilitator_id: item._id
                  })
                }}
              />

              <InputComponent
                theme="dark"
                size="h-10"
                title="Purchase target per month"
                value={values.purchase_target_per_month}
                onChange={v =>
                  setValues({
                    ...values,
                    purchase_target_per_month: v
                  })
                }
              />

              <InputComponent
                theme="dark"
                size="h-10"
                title="Income/Profit target month"
                value={values.income_profit_target_per_month}
                onChange={v =>
                  setValues({
                    ...values,
                    income_profit_target_per_month: v
                  })
                }
              />

              <InputComponent
                theme="dark"
                size="h-10"
                title="Start-Up loan amount"
                value={values.startup_loan_amount}
                onChange={v =>
                  setValues({
                    ...values,
                    startup_loan_amount: v
                  })
                }
              />

              <InputComponent
                theme="dark"
                size="h-10"
                title="Loan repayments"
                value={values.loan_repayments}
                onChange={v =>
                  setValues({
                    ...values,
                    loan_repayments: v
                  })
                }
              />

              <InputComponent
                theme="dark"
                size="h-10"
                title="Length of loan repayments"
                value={values.length_of_loan_repayments}
                onChange={v =>
                  setValues({
                    ...values,
                    length_of_loan_repayments: v
                  })
                }
              />

              <InputComponent
                theme="dark"
                size="h-10"
                title="Month loan repayment start"
                value={values.month_loan_repayment_start}
                onChange={v =>
                  setValues({
                    ...values,
                    month_loan_repayment_start: v
                  })
                }
              />

              <div className="flex flex-col gap-2 justify-between">
                <div className="">
                  <DatePickerComponent
                    title="Start Date"
                    value={values.start_date}
                    onChange={v =>
                      setValues({
                        ...values,
                        start_date: moment(v).format(DATE_FORMAT)
                      })
                    }
                  />
                </div>

                <div className="">
                  <DatePickerComponent
                    title="End Date"
                    value={values.end_date}
                    onChange={v =>
                      setValues({
                        ...values,
                        end_date: moment(v).format(DATE_FORMAT)
                      })
                    }
                  />
                </div>
              </div>

              <FilesComponent
                label="Programme Artwork"
                onChange={v => {
                  upload_file({ file: v, name: v.name }).then(res => {
                    if (res.data.success) {
                      setValues({
                        ...values,
                        location: res.data.content.location,
                        etag: res.data.content.etag
                      })
                    }
                  })
                }}
              />

              <div className="">
                <p className="text-white mb-4">Program Colour</p>
                {pickColor ? (
                  <dict className="Flex">
                    <div className="">
                      <SketchPicker
                        onClick={() => {}}
                        color={values.program_color ? values.program_color : '#000000'}
                        onChange={v => {
                          setValues({
                            ...values,
                            program_color: v.hex
                          })
                        }}
                      />
                    </div>
                    <div className="">
                      <p
                        className=""
                        onClick={() => setPickColor(false)}
                        style={colorPickRemoveBtn}
                      >
                        X
                      </p>
                    </div>
                  </dict>
                ) : (
                  <div className="flex justify-between">
                    <div
                      className=""
                      title="Click to select a colour"
                      onClick={v => {
                        setPickColor(true)
                      }}
                      style={{
                        backgroundColor: values.program_color ? values.program_color : '#000000',
                        width: '90px',
                        height: '40px'
                      }}
                    ></div>
                    <div className="" style={{ width: '40%' }}>
                      <InputComponent
                        theme="dark"
                        value={values.program_color ? values.program_color : '#000000'}
                        onChange={v =>
                          setValues({
                            ...values,
                            program_color: v
                          })
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex w-full flex-col gap-4">
              <InputComponent
                theme="dark"
                title="Module Name"
                value={module.module_name}
                onChange={v =>
                  setModule({
                    ...module,
                    module_name: v
                  })
                }
              />

              <div className="flex flex-col gap-4">
                <div className="text-white">Optimization Type</div>
                <div className="flex flex-col gap-2">
                  <div className="flex">
                    <CheckBoxControl
                      theme="light"
                      name="optimization_type"
                      checked={module.optimization_type == 'Human'}
                      type="radio"
                      value="Human"
                      onChange={e => {
                        setModule({
                          ...module,
                          optimization_type: e.target.value
                        })
                      }}
                    />
                    <div className="text-white mx-2">Human</div>
                  </div>

                  <div className="flex">
                    <CheckBoxControl
                      theme="light"
                      name="optimization_type"
                      value="Business"
                      checked={module.optimization_type == 'Business'}
                      type="radio"
                      onChange={e => {
                        setModule({
                          ...module,
                          optimization_type: e.target.value
                        })
                      }}
                    />
                    <div className="text-white mx-2">Business</div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col justify-between">
                <DatePickerComponent
                  title="Start Date"
                  value={module.start_date}
                  onChange={v =>
                    setModule({
                      ...module,
                      start_date: moment(v).format(DATE_FORMAT)
                    })
                  }
                />

                <DatePickerComponent
                  title="End Date"
                  value={module.end_date}
                  onChange={v =>
                    setModule({
                      ...module,
                      end_date: moment(v).format(DATE_FORMAT)
                    })
                  }
                />
              </div>

              <div className="flex w-full justify-center items-center">
                <ButtonComponent
                  title="Add module"
                  isLoading={false}
                  type="secondary"
                  onClick={v => {
                    let newModules = [...modules, module]
                    setModules(newModules)
                    setModule({})
                  }}
                />
              </div>
              <hr />

              <div className="font-bold text-sm text-white flex w-full justify-center items-center">
                Added Modules
              </div>

              <div className="flex flex-col gap-1">
                {modules.map((module, i) => (
                  <div
                    key={i}
                    className="flex flex-row w-full py-1 px-4 rounded-full border border-white hover:bg-theme justify-between"
                  >
                    <div className="text-white">{module.module_name}</div>
                    <div
                      className="h-6 w-6 flex justify-center rounded-full bg-white items-center b-white cursor-pointer"
                      onClick={() => {
                        removeModule(module)
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} color="red" />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </TabControl>
          <div className="flex justify-center">
            <div className="w-40 pt-8 flex justify-center items-center">
              <ButtonComponent
                title={`${program._id ? 'Update' : 'Add'} Programme`}
                isLoading={false}
                onClick={v => {
                  if (program._id) {
                    //update
                    update_program({ id: program._id, ...values, modules }).then(res => {
                      if (res.data.success) {
                        setShow(false)
                        dispatch(getPrograms())
                        // refresh()
                      }
                    })
                  } else {
                    //create
                    create_program({ ...values, modules }).then(res => {
                      if (res.data.success) {
                        setShow(false)
                        dispatch(getPrograms())
                        // refresh()
                      }
                    })
                  }
                }}
              />
            </div>
          </div>
        </div>
      </PopupControl>
      <LoaderControl show={isBusy} />
    </div>
  )
}

export default AddProgramComponent
