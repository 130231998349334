import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import { connect } from 'react-redux'

import ButtonControl from '../../../controls/button/button'
import MapControl from '../../../controls/map/map'
import TableControl from '../../../controls/table/table'

const ActivityLogComponent = ({ mainState }) => {
  const [markers, setMarkers] = useState([])
  const [notificationData, setNotificationData] = useState({
    title: '',
    messages: [],
    type: 'ok' | 'confirm'
  })
  const [isBusy, setIsBusy] = useState(false)
  const [csvData, setCSVData] = useState([])
  const [tableData, setTableData] = useState([])

  //Did mount...
  useEffect(() => {
    configdata()
  }, [mainState])

  const configdata = () => {
    let _data = []
    let _csvData = [['Name', 'User Type', 'Last Activity Logged']]
    let _activities = mainState.crop_activities.map((cropActivity, i) => {
      cropActivity.activity = mainState.activities.find(
        x => x.id === cropActivity.activity_template.id
      )
      cropActivity.crop = mainState.crops.find(x => x.id === cropActivity.linked_crop.id)

      if (cropActivity.crop)
        cropActivity.farm = mainState.farms.find(x => x.id === cropActivity.crop.farm.id)

      let obj = {
        id: cropActivity.id,
        name: cropActivity.activity ? cropActivity.activity.activity_name : 'None',
        user_type: 'Farmer',
        farm: cropActivity.farm ? cropActivity.farm.farm_name : '',
        last_activity_logged: cropActivity.date_updated ? cropActivity.date_updated : '',
        date: '',
        sop: cropActivity.activity
          ? cropActivity.activity.sop
            ? cropActivity.activity.sop.sop_name
            : ''
          : '',
        crop: cropActivity.crop ? cropActivity.crop.crop_name : ''
      }

      _data.push(obj)
      _csvData.push([
        obj.name,
        obj.user_type,
        obj.farm,
        obj.last_activity_logged,
        obj.date,
        obj.sop,
        obj.crop
      ])
      return cropActivity
    })

    setTableData(_data)
    setCSVData(_csvData)
  }
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row">
        <div className="flex-auto text-3xl font-bold">Activity Log</div>
        <div className="flex-none w-40">
          <CSVLink data={csvData}>
            <ButtonControl placeholder="Export" isBusy={false} type="primary" onClick={v => {}} />
          </CSVLink>
        </div>
      </div>
      <div className="h-72 w-100">
        <MapControl markers={markers} />
      </div>
      <div className="flex flex-col gap-4">
        <div className="bg-white shadow-md p-4">
          <TableControl
            title="Activity Logs"
            search={true}
            actionButton={{
              side: 'right',
              title: 'Action',
              isDropdown: true,
              onClick: () => {}
            }}
            width={`${100 / 7}%`}
            columns={[
              { key: 'name', display: 'Name' },
              ,
              { key: 'user_type', display: 'User Type' },
              { key: 'last_activity_logged', display: 'Last Activity Logged' }
            ]}
            data={tableData}
            rowClicked={id => {}}
          />
        </div>
      </div>

      {/* <LoaderControl show={isBusy} /> */}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    mainState: state.mainState
  }
}

export default connect(mapStateToProps)(ActivityLogComponent)
