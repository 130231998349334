export const selectProgramsState = state => state.programsState

export const selectProgramsLoaded = ({ programsState }) => programsState.programsLoaded
export const selectPrograms = ({ programsState }) => programsState.programs

export const selectProgramsWithIds = ({ programsState }) =>
  programsState.programs.map(program => ({ id: program.id, name: program.program_name }))

export const selectProgramById =
  ({ programsState }) =>
  id =>
    programsState.programs.find(program => id === program.id)

export const selectLearnersFromProgram =
  ({ programsState }) =>
  id => {
    const program = programsState.programs.find(_ => _.id === id)
    return program?.learners || []
  }
