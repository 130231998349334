import React from 'react'

import { faPlus, faUser } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ImageAvatarControl = ({ imgUrl, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="relative w-44 h-44 flex bg-milky-white rounded-full place-content-center"
    >
      <div className="absolute z-40 top-1 right-1 bg-white shadow-md p-2 rounded-full">
        <FontAwesomeIcon className="text-dark" icon={faPlus} />
      </div>
      {imgUrl ? (
        <img src={imgUrl} className="w-44 rounded-full bg-contain bg-center" />
      ) : (
        <FontAwesomeIcon
          icon={faUser}
          size="6x"
          className="place-self-center text-gray rounded-full"
        />
      )}
    </div>
  )
}

export default ImageAvatarControl
