import React, { useEffect, useState } from 'react'

import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import {
  getAllCurriculums,
  getCategories,
  getFacilitators,
  getPrograms,
  getVenues,
  saveCurriculum
} from '../../asyncActions'
import TableContentWrapper from '../../controls/tableContentWrapper/tableContentWrapper'
import { selectNotificationState } from '../notifications/selectors'
import {
  actionButtonItems,
  curriculumTableColumns,
  eventsTableColumns,
  initialCurriculumValues
} from './constants'

import UploadCurriculum from './modals/uploadCurriculum'
import { selectCurriculumById, selectCurriculumsLoaded } from './selectors'
import moment from 'moment'
import { DATE_FORMAT } from '../../constants/constants'
import { MyTableControl } from '../../controls/myTableControl/myTableControl'
import ButtonComponent from '../customComponents/button'
import { faInfo, faSave, faTimes } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { get_program } from '../../api/calls/programs/get_program'
import { get_program_curriculums } from '../../api/calls/curriculums/get_program_curriculums'
import { content } from '../programs/components/constants'

const ProgrammeCurriculumComponent = ({
  categoriesState,
  venuesState,
  facilitatorState,
  curriculumState
}) => {
  const { program_id, curriculum_id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const notificationData = useSelector(selectNotificationState)
  const [showUploadCurriculum, setShowUploadCurriculum] = useState(false)
  const curriculumsLoaded = useSelector(selectCurriculumsLoaded)
  const curriculum = useSelector(selectCurriculumById)(curriculum_id)
  const [formValues, setFormValues] = useState(initialCurriculumValues)
  const [payload, setPayload] = useState({})
  const [events, setEvents] = useState([])

  useEffect(() => {
    _fetch()
  }, [])

  useEffect(() => {
    if (!program_id) _onCancelForm()
    if (!curriculumsLoaded) dispatch(getAllCurriculums())
  }, [curriculumsLoaded])

  useEffect(() => {
    if (program_id) _setFormValues({ ...formValues, program_id })
    if (curriculum) _setFormValues({ ...formValues, ...curriculum })
  }, [curriculum])

  const dateFormat = date => {
    return moment(`${date}`, `${DATE_FORMAT}THH:mm:ss.SSSZ`)
      .utcOffset('+02:00')
      .format(`${DATE_FORMAT}THH:mm:ss.SSSZ`)
  }

  const _onCancelForm = () => history.goBack()
  const _onSaveCurriculum = () => {
    dispatch(saveCurriculum(payload))
  }

  const _setFormValues = value => setFormValues({ ...formValues, ...value })

  const _toggleUploadCurriculum = () => {
    setShowUploadCurriculum(!showUploadCurriculum)
  }

  useEffect(() => {
    console.log('formValues.interactions', formValues.interactions)
    for (const interaction of formValues.interactions) {
      interaction.program_id = formValues.program_id
      interaction.start_date = dateFormat(interaction.start_date)
      interaction.end_date = dateFormat(interaction.end_date)
    }
  }, [formValues.interactions])

  useEffect(() => {
    console.log('facilitatorState', facilitatorState)
  }, [facilitatorState])

  //-----------------------------------------------------------------------------------------------------------
  const [addToEvent, setAddToEvent] = useState(false)
  const [selectedInteraction, setSelectedInteraction] = useState({})
  const [programCurriculums, setProgramCurriculums] = useState([])

  const _onClickActionButton = (item, e) => {
    if (e.id == 1) {
      //on view, go to learner profile
      setAddToEvent(true)
      setSelectedInteraction(item)
    }
  }

  const _fetch = () => {
    dispatch(getCategories())
    dispatch(getPrograms())
    dispatch(getFacilitators())
    dispatch(getVenues())
    get_program_curriculums(program_id).then(res => {
      if (res.data.success) {
        setProgramCurriculums(res.data.content)
      }
    })
  }

  const _sanitiseFormValues = () => {
    let events = []
    let interactions = []

    // console.log('categoriesState.categories', categoriesState.categories)

    for (const interaction of formValues.interactions) {
      const category = categoriesState.categories?.find(
        e =>
          e?.category_name?.toString().toLowerCase() ==
          interaction?.category?.toString().toLowerCase()
      )
      const venue = venuesState.venues?.find(
        e => e?.name?.toString().toLowerCase() == interaction?.venue?.toString().toLowerCase()
      )

      const facilitator = facilitatorState.facilitators?.find(
        e =>
          e?.user_name.toString().toLowerCase() ==
            interaction?.facilitator_name?.toString().toLowerCase().split(' ')[0] &&
          e?.user_surname.toString().toLowerCase() ==
            interaction?.facilitator_name?.toString().toLowerCase().split(' ')[1]
      )

      console.log('category', category)
      console.log('facilitator', facilitator)
      console.log('venue', venue)

      events.push({
        type: category?.category_name?.toString().toUpperCase() ?? 'CUSTOM',
        program_ids: [interaction?.program_id],
        event_name: interaction?.interaction_name,
        event_description: interaction?.description,
        event_category_id: category?._id,
        venue_id: venue?._id,
        owner_ids: [facilitator?._id],
        start_date: dateFormat(interaction?.start_date),
        end_date: dateFormat(interaction?.end_date)
      })

      interactions.push({
        banner: interaction?.banner,
        stream: interaction?.stream,
        num_interactions: interaction?.num_interactions,
        cost: interaction?.cost,
        codes: interaction?.codes,
        interaction_name: interaction?.interaction_name,
        start_date: dateFormat(interaction?.start_date),
        end_date: dateFormat(interaction?.end_date),
        program_id: interaction?.program_id
      })
    }

    setEvents(events)
    setPayload({
      curriculum: { ...formValues, interactions },
      events
    })
  }

  useEffect(() => {
    _sanitiseFormValues()
  }, [formValues])

  return (
    <TableContentWrapper title="Upload Programme Curriculum" notificationData={notificationData}>
      <div className="flex flex-row gap-8 w-full">
        <div className="flex flex-col gap-8 items-end">
          <div className="flex-col flex justify-between w-80 h-full rounded-md shadow-sm bg-indigo p-2 gap-8">
            <div className="flex flex-1 gap-2 h-full justify-center">
              <div className="flex flex-row gap-2 items-center justify-center">
                <FontAwesomeIcon size="sm" icon={faInfo} color="white" />
                <div className="text-white">
                  {programCurriculums.length < 1
                    ? 'No curriculums available.'
                    : 'Curriculum available'}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2 text-white">{programCurriculums.length}/4</div>
          </div>
        </div>
        <div className="flex-1 px-2 py-4">
          {/* --------- add curriculum / table */}
          <MyTableControl
            title={'Curriculum'}
            search={{ show: true, search_by: '' }}
            showPagination={true}
            data={formValues.interactions ?? []}
            displayKeys={curriculumTableColumns}
            // actionButton={{
            //   side: 'right',
            //   title: 'Upload',
            //   isDropdown: false,
            //   onClick: _toggleInteractionDocumentModal
            // }}
            actionButton={{
              side: 'right',
              title: 'Details',
              items: actionButtonItems,
              isDropdown: true,
              onClick: _onClickActionButton
            }}
            mainButton={
              !curriculum_id ? (
                <ButtonComponent
                  disabled={curriculumState.isDetails == true}
                  title="Upload Curriculum"
                  isBusy={false}
                  type="primary"
                  onClick={_toggleUploadCurriculum}
                />
              ) : null
            }
          />

          {!curriculum_id && events.length > 0 && (
            <MyTableControl
              search={{ show: true, search_by: '' }}
              title={'Events To Be Created'}
              showPagination={true}
              data={events}
              displayKeys={eventsTableColumns}
            />
          )}

          {/* -----------save/cancel buttons */}
          {!curriculum_id && (
            <div className="flex gap-2 py-8 justify-end">
              <ButtonComponent
                onClick={_onCancelForm}
                title="Cancel"
                isBusy={false}
                type="primary"
                icon={faTimes}
              />
              <ButtonComponent
                icon={faSave}
                title="Save"
                isBusy={false}
                type="primary"
                onClick={_onSaveCurriculum}
              />
            </div>
          )}
        </div>
        {/* -----------modals */}
        <UploadCurriculum
          csvValues={_setFormValues}
          open={showUploadCurriculum}
          setShow={_toggleUploadCurriculum}
        />
      </div>
    </TableContentWrapper>
  )
}

const mapStateToProps = state => {
  return {
    categoriesState: state.categoriesState,
    programsState: state.programsState,
    venuesState: state.venuesState,
    facilitatorState: state.facilitatorState,
    curriculumState: state.curriculumState
  }
}

export default connect(mapStateToProps)(ProgrammeCurriculumComponent)
