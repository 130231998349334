import React, { useEffect, useState } from 'react'

import TableContentWrapper from '../../../controls/tableContentWrapper/tableContentWrapper'
import InputComponent from '../../customComponents/input'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import DropdownButtonComponent from '../../customComponents/dropdownButton'
import { AttendanceTypes, OILevel } from './contants'

const ITEMS_PER_PAGE = 5
const defaultSearchByValue = { key: 'user_name' }
const tableColumns = ['Name', 'Surname', 'Contact', 'Attendance', 'OI Rating', 'Comment']

const AttendanceTable = ({ users, onChange, date, program, dayType }) => {
  const [learners, setLearners] = useState([])
  const [selectedLearners, setSelectedLearners] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [searchByItemValue, setSearchByItemValue] = useState(defaultSearchByValue)
  const [filteredData, setFilteredData] = useState([])

  const init = () => {
    if (users.length > 0) {
      const newUsers = users.map(user => ({
        ...user,
        attendance: user.attendance || {},
        comment: user.comment || ''
      }))
      setSearchByItemValue({ key: 'fullName' })
      setFilteredData(newUsers)
    } else {
      setFilteredData([])
    }
  }

  useEffect(() => {
    init()
  }, [users])

  useEffect(() => {
    const updatedItems = searchQuery
      ? users.filter(user =>
          user[searchByItemValue.key].toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
      : users

    selectedLearners.forEach(learner => {
      const index = updatedItems.findIndex(item => item._id === learner._id)
      if (index > -1) updatedItems[index] = learner
    })

    setFilteredData(updatedItems)
  }, [searchQuery, selectedLearners])

  const handleSearchChange = query => {
    setSearchQuery(query)
  }

  const handleFormValuesChange = updatedLearner => {
    const updatedItems = filteredData.map(item =>
      item._id === updatedLearner._id ? updatedLearner : item
    )
    setFilteredData(updatedItems)
    handleSelectLearner(updatedLearner)
  }

  const handleSelectLearner = learner => {
    const updatedLearners = selectedLearners.some(item => item._id === learner._id)
      ? selectedLearners.map(item => (item._id === learner._id ? learner : item))
      : [...selectedLearners, learner]

    setSelectedLearners(updatedLearners)
  }

  useEffect(() => {
    if (selectedLearners.length > 0) {
      const newLearners = selectedLearners.map(item => ({
        user_id: item._id,
        program_id: program._id,
        attendance: {
          user_id: item._id,
          attendance_date: date,
          ...item.attendance
        }
      }))
      onChange(newLearners)
    }
  }, [selectedLearners])

  return (
    <TableContentWrapper title="">
      <div className="pb-4 w-full">
        <InputComponent
          theme="form"
          value={searchQuery}
          type="search"
          placeholder="Search by Name and Surname"
          onChange={handleSearchChange}
        />
      </div>
      <Table className="flex min-w-full">
        <TableHead className="bg-main text-lg">
          <TableRow>
            {tableColumns.map(col => (
              <TableCell key={col} className="text-gray-600 uppercase text-sm leading-normal">
                {col}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className="text-gray-600 text-sm font-normal">
          {filteredData.map((item, idx) => (
            <TableRow
              key={idx}
              className={`border-b border-gray-200 hover:bg-gray-100 border-dark font-light h-8 ${
                item.attendance?.oi_level && item.attendance?.attendance_status && dayType
                  ? 'bg-gray'
                  : 'bg-white'
              }`}
            >
              <TableCell className="text-gray-600 text-xs leading-normal font-light">
                <div className="flex flex-col">{item.user_name}</div>
              </TableCell>
              <TableCell className="text-gray-600 text-xs leading-normal font-light">
                <div className="flex flex-col">{item.user_surname}</div>
              </TableCell>
              <TableCell className="text-gray-600 text-xs leading-normal font-light">
                <div className="flex flex-col">{item.user_cell_number}</div>
              </TableCell>
              <TableCell className="text-gray-600 text-xs leading-normal font-light">
                <DropdownButtonComponent
                  placeholder="Attendance"
                  type="primary"
                  theme="form"
                  displayKey="name"
                  buttonBg={
                    item.attendance?.attendance_status === 'Present'
                      ? 'bg-main-green'
                      : item.attendance?.attendance_status === 'Late'
                      ? 'bg-yellow-400'
                      : item.attendance?.attendance_status === 'Absent'
                      ? 'bg-red-600'
                      : 'bg-white text-gray'
                  }
                  dropdownItems={AttendanceTypes}
                  selectedItem={item.attendance?.attendance_status}
                  onClick={e =>
                    handleFormValuesChange({
                      ...item,
                      attendance: { ...item.attendance, attendance_status: e.name }
                    })
                  }
                />
              </TableCell>
              <TableCell className="text-gray-600 text-xs leading-normal font-light">
                <DropdownButtonComponent
                  placeholder="Select Level"
                  type="primary"
                  theme="form"
                  displayKey="level"
                  dropdownItems={OILevel}
                  selectedItem={item.attendance?.oi_level}
                  onClick={e =>
                    handleFormValuesChange({
                      ...item,
                      attendance: { ...item.attendance, oi_level: e.level, day_type: dayType }
                    })
                  }
                />
              </TableCell>
              <TableCell className="text-gray-600 text-xs leading-normal font-light">
                <div className="w-full">
                  <InputComponent
                    theme="form"
                    placeholder="Add your comment here..."
                    value={item.attendance?.comment || ''}
                    onChange={v =>
                      handleFormValuesChange({
                        ...item,
                        attendance: { ...item.attendance, comment: v }
                      })
                    }
                  />
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContentWrapper>
  )
}

export default AttendanceTable
