
import { faMinusCircle, faPlusCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from "react";
import { arrayMove } from "../../utils/utils";
import ButtonControl from "../button/button";
import DropdownControl from "../dropdown/dropdown";
import LoaderControl from "../loader/loader";
import TextInputControl from "../textinput/textinput";

const TreeControl = props => {
    const {
        title,
        columns = [],
        items,
        search,
        canMoveItems,
        retrieveOrder,
        isBusy,
    } = props;

    const [ searchValue, setSearchValue ] = useState("");
    const [ treeItems, setTreeItems ] = useState();

    //Did mount...
    useEffect(() => {
        // if (treeItems != items) {
            setTreeItems(items);
        // }
    }, [items]);

    const expander = (item) => {
        if ((item.items && item.items.length > 0) || item.details) {
            return (
                <div className="w-8 cursor-pointer p-2 rounded-lg transition-colors text-indigo-400
                    hover:bg-green hover:text-white"
                    onClick={() => {
                        // console.log("itemitemitem", item);
                        //Mutate this item by changing its expanded property.
                        item.expanded = !item.expanded;
                        //The above mutation requires a forceUpdate to reflect.
                        // forceUpdate();
                        setTreeItems([
                            ...treeItems
                        ]);
                    }}>
                    <FontAwesomeIcon className="text-lg" icon={item.expanded ? faMinusCircle : faPlusCircle} />
                </div>
            )
        }
    }

    const generateRecursively = (items, columns, iteration = 0) => {
        return (items && items.length > 0) && items.map((item, index) => {
            return (
                <div key={index} className={`item-${iteration}`}>
                    <div className={`flex flex-row`}>
                        {(() => {
                            let lines = [];
                            for (let i = 0; i < iteration; i++) {
                                lines.push(
                                    i == (iteration - 1) ?
                                    <div key={i} className={`line-${i} flex-none w-8 relative`}>
                                        <div className="verti h-full left-1/2 border-l border-dark ml-4" />
                                        <div className="hori absolute w-1/2 top-1/2 left-1/2 border-b border-dark" />
                                    </div>
                                    :
                                    <div key={i} className={`line-${i} flex-none w-8 relative`}>
                                        <div className="verti h-full left-1/2 border-l border-dark ml-4" />
                                    </div>
                                );
                            }
                            return lines;
                        })()}
                        <div className="flex-auto flex flex-row items-center shadow-md border border-dark rounded-lg mt-1 group">
                            <div className="">
                                {expander(item)}
                            </div>
                            <div className="flex-auto flex flex-row justify-start pl-2 pt-1 pb-1 text-lg">
                                <div className="">
                                    {item.name}
                                </div>
                                {item.menu && <div className="flex items-center pl-4 transition opacity-0 group-hover:opacity-100">
                                    <DropdownControl
                                        type="ellipsis"
                                        displayKey="name"
                                        subItems={canMoveItems ? item.menu.concat(
                                            index != 0 ? {
                                                name: "Move Up", 
                                                onClick: () => {
                                                    // console.log("items", items);
                                                    arrayMove(items, index, (index - 1));

                                                    setTreeItems([
                                                        ...treeItems,
                                                    ]);
                                                }
                                            } : undefined,
                                            (index != (items.length - 1)) ? {
                                                name: "Move Down", 
                                                onClick: () => {
                                                    arrayMove(items, index, (index + 1));

                                                    setTreeItems([
                                                        ...treeItems,
                                                    ]);
                                                }
                                            } : undefined,
                                        ) : item.menu}
                                        onClick={(menuItem) => {
                                            if (menuItem.onClick) {
                                                menuItem.onClick();
                                            }
                                        }}
                                        // onClick={() => {
                                        //     item.qweClick();
                                        // }}
                                    />
                                </div>}
                            </div>
                            <div className="flex flex-row">
                                {item.columns && item.columns.map((column, index) => {
                                    return (
                                        <div key={index} className="w-36">
                                            {item.columns[index]}
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="">
                                {item.actions && item.actions.map((action, index) => {
                                    return (
                                        <span key={index}>
                                            {action}
                                        </span>
                                    )
                                })}
                            </div>                        
                        </div>
                    </div>
                    
                    {(item.items && item.expanded) ? generateRecursively(item.items, null, iteration + 1) : undefined}

                    {(item.details && item.expanded) && 
                        <div className={`item-${iteration + 1}`}>
                            <div className={`flex flex-row`}>
                                {(() => {
                                    let lines = [];
                                    for (let i = 0; i < iteration + 1; i++) {
                                        lines.push(
                                            i == (iteration) ?
                                            <div key={i} className={`line-${i} flex-none w-8 relative`}>
                                                <div className="verti h-full left-1/2 border-l border-dark ml-4" />
                                                <div className="hori absolute w-1/2 top-1/2 left-1/2 border-b border-dark" />
                                            </div>
                                            :
                                            <div key={i} className={`line-${i} flex-none w-8 relative`}>
                                                <div className="verti h-full left-1/2 border-l border-dark ml-4" />
                                            </div>
                                        );
                                    }
                                    return lines;
                                })()}
                                <div className="flex-auto flex flex-row shadow-md border border-dark rounded-lg mt-1 p-2">
                                    <div className="flex-auto mr-4 flex flex-col gap-4">
                                        <table className="">
                                            <tbody>
                                                {item.details.fields.map((item, index) => {
                                                    return (
                                                        <tr key={index} className="h-8 border-b border-dark">
                                                            <td>
                                                                {Object.keys(item)[0]}
                                                            </td>
                                                            <td>
                                                                {Object.values(item)[0]}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>

                                        {item.details.table && item.details.table}
                                    </div>

                                    <div className="flex-none grid w-80">
                                        {item.details.map && item.details.map}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            );
        });
    }

    const generateOrder = (items) => {
        //Generate a tree order based off the current treeItems state.
        let order = [];
        for (let item of items) {
            let o = {
                order: items.indexOf(item),
                id: item.id,
                name: item.name,
                // items: [],
            };
            
            if (item.items) {
                o.items = [];
                o.items.push(...generateOrder(item.items));
            }
            
            order.push(o);
        }

        return order;
    }

    return (
        <div className="controls-tree flex flex-col w-full gap-4">
            {/* TODO: search not finished yet */}
            {search && <div className="flex flex-row items-center">
                <div className="font-bold">
                    {title}
                </div>
                <div className="flex-auto ml-20 mr-20">
                    <TextInputControl
                        // icon={faSearch}
                        value={searchValue}
                        placeholder="Search"
                        onChange={(v) => setSearchValue(v)}
                    />
                </div>
                <div className="">
                    Custom Day Week Month Year
                </div>
            </div>}
            <div className="flex flex-row">
                {canMoveItems &&
                <div className="flex-none w-40">
                    <ButtonControl
                        placeholder="Save Order"
                        isBusy={false}
                        type="primary"
                        onClick={(v) => {
                            if (retrieveOrder) {
                                let order = generateOrder(treeItems);
                                // console.log("generateOrder", order);
                                retrieveOrder(order);
                            }
                        }} />
                </div>}
                <div className="flex-auto flex justify-end">
                    {/* Columns */}
                    {columns.map((column, index) => {
                        return (
                            <div key={index} className="w-36 font-bold">
                                {column}
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="flex flex-col gap-1">
                {isBusy? 
            <LoaderControl show={true} inline /> : generateRecursively(treeItems, columns)}
            </div>
        </div>
    )
}

export default TreeControl;