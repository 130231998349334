import { EDIT_TASK_STATE, SET_TASK_STATE, UPDATE_TASK_STATE } from '../constants/redux_types'

export const setTaskState = payload => {
  return {
    type: SET_TASK_STATE,
    payload: payload
  }
}

export const editTaskState = payload => {
  return {
    type: EDIT_TASK_STATE,
    payload: payload
  }
}

export const updateTaskState = payload => {
  return {
    type: UPDATE_TASK_STATE,
    payload: payload
  }
}
