import React from 'react'
import logo from './../../assets/images/black_logo.png'
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'

const ThankYouComponent = () => {
  const history = useHistory()
  let { path } = useRouteMatch()
  let isSelected = history.location.pathname == `${path}/'`

  return (
    <div className="flex flex-col w-full items-center justify-center">
      <img src={logo} className="object-contain h-60 pb-8 pt-4" />
      <h1 className="text-center text-4xl font-bold">Thank you for applying</h1>
      <div className="text-center py-4">We'll be in touch</div>
    </div>
  )
}

export default ThankYouComponent
