import { setLearnerState } from '../actions/learner'
import { get_all_users } from '../api/calls/get_all_users'
import { delete_learner } from '../api/calls/users/delete_learner'
import { initialPageNumber, itemsPerPage } from '../constants/constants'

export const getLearners = (pageNumber = initialPageNumber, search = '') => {
  //   const filters = `type_code=LEARNER-ACC&page=${pageNumber}&limit=${itemsPerPage}&search=${search}`
  const filters = `type_code=LEARNER-ACC&search=${search}`
  return dispatch => {
    get_all_users(`${filters}`)
      .then(response => {
        if (response.data.success) {
          dispatch(
            setLearnerState({
              learnersLoaded: true,
              learners: response.data.content,
              paginate: response.data.paginate
            })
          )
        } else {
        }
      })
      .catch(error => {})
  }
}

export const deleteLearner = payload => {
  return dispatch => {
    delete_learner(payload)
      .then(response => {
        dispatch(getLearners())
      })
      .catch(error => {})
  }
}
