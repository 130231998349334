import React from 'react'
import { useHistory } from 'react-router-dom'
import { MyTableControl } from '../../../controls/myTableControl/myTableControl'

const HistoricalRegisters = ({ data }) => {
  const history = useHistory()

  return (
    <div>
      <div className="bg-white shadow-md">
        <MyTableControl
          title={'Register History'}
          search={{ show: true, search_by: '' }}
          showPagination={true}
          data={data ?? []}
          displayKeys={[
            { key: 'name', display: 'Name' },
            { key: 'date', display: 'Date' },
            {
              key: 'number_of_registers',
              display: 'Number of Registers Taken (Cumulatively per interaction)'
            }
          ]}
          actionButton={{
            side: 'right',
            title: 'Download',
            isButton: true,
            onClick: e => {
              history.push(`/dashboard/register-download/${e._id}`)
            }
          }}
        />
      </div>
    </div>
  )
}

export default HistoricalRegisters
