import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setGroupState } from '../../../actions/group'
import { saveGroup } from '../../../api/calls/groups/saveGroup'
import { getFacilitators, getLearners, setNotification } from '../../../asyncActions'
import PopupControl from '../../../controls/popup/popup'
import { selectFacilitators, selectFacilitatorsLoaded } from '../../facilitators'
import { selectLearnersLoaded } from '../../learner/selectors'
import { selectLearners } from '../../user/selectors'
import { initialGroupValues } from '../constants'
import { faSave } from '@fortawesome/pro-light-svg-icons'
import InputComponent from '../../customComponents/input'
import ButtonComponent from '../../customComponents/button'
import MultiDropDownButtonComponent from '../../customComponents/multiDropdownButton/multiDropdownButton'
import FilesComponent from '../../customComponents/files'
import { upload_file } from '../../../api/calls'
import DatePickerComponent from '../../customComponents/datepicker'

const AddGroupsComponent = ({
  groupState,
  open = false,
  setShow,
  initialValues = {},
  isEdit = false,
  setIsEdit
}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const facilitatorsLoaded = useSelector(selectFacilitatorsLoaded)

  const learnersLoaded = useSelector(selectLearnersLoaded)
  const learners = useSelector(selectLearners).map(_ => ({
    ..._,
    fullName: `${_?.user_name} ${_?.user_surname}`
  }))

  const facilitators = useSelector(selectFacilitators).map(_ => ({
    ..._,
    fullName: `${_?.user_name} ${_?.user_surname}`
  }))

  const [isBusy, setIsBusy] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useState([])
  const [formValues, setFormValues] = useState({})

  const _onInputChange = values => {
    setFormValues({ ...formValues, ...values })
  }

  useEffect(() => {
    if (!facilitatorsLoaded) dispatch(getFacilitators())
    if (!learnersLoaded) dispatch(getLearners())
  }, [facilitatorsLoaded, learnersLoaded])

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  useEffect(() => {
    if (isEdit) {
      const id = initialValues._id
      delete initialValues._id
      setFormValues({ id, ...initialValues })
    } else {
      setFormValues(initialGroupValues)
    }
  }, [isEdit])

  const handleSaveGroup = payload => {
    delete payload.learners
    delete payload.facilitators
    delete payload.groups
    setIsBusy(true)

    saveGroup(payload).then(response => {
      setIsBusy(false)

      if (response.data.success) {
        const { token, user, profile, permissions } = response.data.content
        dispatch(
          setNotification({
            title: 'Success',
            messages: [response.data.message]
          })
        )
        setIsOpen(!open)
        setIsEdit(false)
      } else {
        dispatch(
          setNotification({
            title: 'Problem',
            messages: [response.data.message]
          })
        )
      }
    })
  }

  const addGroup = (newGroup = formValues) => {
    let groups = groupState.groups
    groups.push(newGroup)
    dispatch(
      setGroupState({
        ...groupState,
        groups
      })
    )
  }

  return (
    <div>
      <PopupControl
        title="Adding a new group"
        isOpen={open}
        close={{
          showClose: true,
          onClose: () => {
            setShow(false)
          }
        }}
      >
        <div className="flex flex-col gap-4 w-64">
          <InputComponent
            theme="dark"
            title="Group Name"
            value={formValues.name}
            onChange={name =>
              _onInputChange({
                name
              })
            }
          />

          <InputComponent
            theme="dark"
            title="Group Description"
            size="lg"
            value={formValues.description}
            onChange={description =>
              _onInputChange({
                description
              })
            }
          />

          <MultiDropDownButtonComponent
            title="Learners"
            subItems={learners}
            displayKey={'user_name'}
            selected={selected}
            onSubmit={learners => {
              setSelected(learners)
              _onInputChange({
                learner_ids: learners.map(e => {
                  return e._id
                })
              })
            }}
          />
          <MultiDropDownButtonComponent
            title="Facilitators"
            subItems={facilitators}
            displayKey={'user_name'}
            selected={selected}
            onSubmit={facilitators => {
              setSelected(facilitators)
              _onInputChange({
                facilitator_ids: facilitators.map(e => {
                  return e._id
                })
              })
            }}
          />

          <FilesComponent
            label="Group Image"
            onChange={v => {
              upload_file({ file: v, name: v.name }).then(res => {
                if (res.data.success) {
                  _onInputChange({
                    image: {
                      etag: res.data.content.etag,
                      url: res.data.content.location
                    }
                  })
                }
              })
            }}
          />

          {/* <MultiDropdownButtonComponent
            name="Facilitators"
            backgroundColor="bg-dark"
            textColor="text-white"
            subItems={facilitators}
            displayKey={'user_name'}
            selected={formValues.facilitators ?? []}
            onSubmit={facilitators => {
              _onInputChange({
                facilitators,
                facilitator_ids: facilitators.map(facilitator => facilitator._id)
              })
            }}
          />
          <MultiDropdownButtonComponent
            backgroundColor="bg-dark"
            textColor="text-white"
            name="Learners"
            subItems={learners}
            displayKey={'fullName'}
            selected={formValues.learners ?? []}
            onSubmit={learners => {
              _onInputChange({
                learners,
                learner_ids: learners.map(learner => learner._id)
              })
            }}
          /> */}

          <InputComponent
            theme="dark"
            title="Allocation Code"
            value={formValues.allocation_code}
            onChange={allocation_code =>
              _onInputChange({
                allocation_code
              })
            }
          />
          <DatePickerComponent
            title="Graduation Date"
            value={formValues.graduation_date}
            date={formValues.graduation_date}
            onChange={graduation_date =>
              _onInputChange({
                graduation_date
              })
            }
          />
          <div className="flex justify-center items-center">
            <ButtonComponent
              title="Save"
              icon={faSave}
              isBusy={false}
              type="primary"
              onClick={_ => {
                handleSaveGroup(formValues)
              }}
            />
          </div>
        </div>
      </PopupControl>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    mainState: state.mainState,
    groupState: state.groupState
  }
}

export default connect(mapStateToProps)(AddGroupsComponent)
