export const mockPrograms = [
  {
    name: 'program A'
  },
  {
    name: 'program B'
  }
]

export const mockGroups = [
  {
    name: 'Avengers'
  },
  {
    name: 'Ultimate Team'
  }
]

export const initialPerformanceValues = {
  program_name: '',
  group_name: ''
}

export const dateValues = ['Custom', 'Day', 'Month', 'Week', 'Year']

export const tableValues = [
  {
    user_name: 'Jakes',
    user_surname: 'Gerwel',
    admin_day: 'A1',
    bt2: 'B8',
    fsm3_and_lsm2: 'A1',
    msm1_and_lsm3: 'A1'
  },
  {
    user_name: 'Simon',
    user_surname: 'Cowell',
    admin_day: 'A1',
    bt2: 'B8',
    fsm3_and_lsm2: 'A1',
    msm1_and_lsm3: 'A1'
  },
  {
    user_name: 'Phillip',
    user_surname: 'Nell',
    admin_day: 'A1',
    bt2: 'B8',
    fsm3_and_lsm2: 'A1',
    msm1_and_lsm3: 'A1'
  },
  {
    user_name: 'Imran',
    user_surname: 'Solomon',
    admin_day: 'A0',
    bt2: 'BT12',
    fsm3_and_lsm2: 'B3',
    msm1_and_lsm3: 'A3'
  }
]

export const mockTableValues = {
  success: true,
  message: 'Found',
  content: [
    {
      _id: '62740443e101517d9b1eaa72',
      user_name: 'Chris',
      user_surname: 'Nelson',
      interactions: [
        {
          code: 'BT1',
          date: '2022-09-16T00:00:00.000Z',
          attendance: [
            {
              attendance_date: '2022-09-16T00:00:00.000Z',
              attendance_status: 'absent',
              oi_level: 'A3'
            }
          ]
        },
        {
          code: 'BT2',
          date: '2022-09-16T00:00:00.000Z',
          attendance: [
            {
              attendance_date: '2022-09-16T00:00:00.000Z',
              attendance_status: 'absent',
              oi_level: 'A3'
            }
          ]
        },
        {
          code: 'LSM',
          date: '2022-09-16T00:00:00.000Z',
          attendance: [
            {
              attendance_date: '2022-09-16T00:00:00.000Z',
              attendance_status: 'absent',
              oi_level: 'A3'
            }
          ]
        },
        {
          code: 'LSM & MSM',
          date: '2022-10-16T00:00:00.000Z',
          attendance: [
            {
              attendance_date: '2022-10-16T00:00:00.000Z',
              attendance_status: 'absent',
              oi_level: 'B1'
            }
          ]
        },
        {
          code: 'LSM & FSM2',
          date: '2022-08-15T22:00:00.000Z',
          attendance: [
            {
              attendance_date: '2022-08-15T22:00:00.000Z',
              attendance_status: 'absent',
              oi_level: 'B2'
            }
          ]
        },
        {
          code: 'CN5',
          date: '2022-09-11T00:00:00.000Z',
          attendance: [
            {
              attendance_date: '2022-09-11T00:00:00.000Z',
              attendance_status: 'present',
              oi_level: 'A0'
            },
            {
              attendance_date: '2022-09-14T00:00:00.000Z',
              attendance_status: 'absent',
              oi_level: '-'
            },
            {
              attendance_date: '2022-09-14T00:00:00.000Z',
              attendance_status: 'absent',
              oi_level: '-'
            }
          ]
        }
      ]
    },
    {
      _id: '62740443e101517d9b1eaa72',
      user_name: 'Kagiso',
      user_surname: 'Mautla',
      interactions: [
        {
          code: 'CN6',
          date: '2022-09-16T00:00:00.000Z',
          attendance: [
            {
              attendance_date: '2022-09-16T00:00:00.000Z',
              attendance_status: 'absent',
              oi_level: 'A3'
            }
          ]
        },
        {
          code: 'BT3',
          date: '2022-09-16T00:00:00.000Z',
          attendance: [
            {
              attendance_date: '2022-09-16T00:00:00.000Z',
              attendance_status: 'absent',
              oi_level: 'A0'
            }
          ]
        },
        {
          code: 'LSM',
          date: '2022-09-16T00:00:00.000Z',
          attendance: [
            {
              attendance_date: '2022-09-16T00:00:00.000Z',
              attendance_status: 'absent',
              oi_level: 'A1'
            }
          ]
        },
        {
          code: 'LSM & MSM',
          date: '2022-10-16T00:00:00.000Z',
          attendance: [
            {
              attendance_date: '2022-10-16T00:00:00.000Z',
              attendance_status: 'absent',
              oi_level: 'B1'
            }
          ]
        },
        {
          code: 'CN5',
          date: '2022-09-20T00:00:00.000Z',
          attendance: [
            {
              attendance_date: '2022-09-11T00:00:00.000Z',
              attendance_status: 'present',
              oi_level: 'A3'
            },
            {
              attendance_date: '2022-09-14T00:00:00.000Z',
              attendance_status: 'absent',
              oi_level: '-'
            },
            {
              attendance_date: '2022-09-14T00:00:00.000Z',
              attendance_status: 'absent',
              oi_level: '-'
            }
          ]
        }
      ]
    }
  ]
}
