import { apiPost } from '../api'
import { apiGet } from '../api'

export const reset_password = data => {
  return apiPost({
    endpoint: 'user/reset',
    data: data
  })
}

export const verify_token = token => {
  return apiGet({
    endpoint: `user/reset?reset_token=${token}`
  })
}
