import React, { useEffect, useState } from 'react'

import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { getFacilitators, getPrograms } from '../../asyncActions'
import ButtonControl from '../../controls/button/button'
import TableControl from '../../controls/table/table'
import TableContentWrapper from '../../controls/tableContentWrapper/tableContentWrapper'
import { formatDate } from '../../utils/utils'
import { selectFacilitators, selectFacilitatorsLoaded } from '../facilitators'
import { selectNotificationState } from '../notifications/selectors'
import AddProgramComponent from './components/addProgram'
import { MyTableControl } from '../../controls/myTableControl/myTableControl'
import PopupControl from '../../controls/popup/popup'
import { set_default_program } from '../../api/calls/programs/default_program'
import ButtonComponent from '../customComponents/button'

const ProgramsComponent = ({ programsState, facilitatorState }) => {
  const history = useHistory()

  const [addProgram, setAddProgram] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const dispatch = useDispatch()
  const notificationData = useSelector(selectNotificationState)
  const [selectedProgram, setSelectedProgram] = useState({})
  const [tableDate, setTableData] = useState([])
  const [init, setInit] = useState(true)
  const [showDefaultProgramPopup, setShowDefaultProgramPopup] = useState(false)

  useEffect(() => {
    if (!programsState.programsLoaded) {
      dispatch(getPrograms())
    }
    let data = []
    data = getTableData()
    data.length > 0 ? setTableData(data) : setTableData([])
  }, [programsState])

  const getTableData = () => {
    let data = []
    if (programsState?.programs)
      for (let program of programsState?.programs) {
        data.push({
          ...program,
          startDate: formatDate(program.start_date),
          endDate: formatDate(program.end_date)
        })
      }

    return data
  }

  return (
    <TableContentWrapper
      notificationData={notificationData}
      actionButtons={[
        <ButtonComponent
          title="Add a New Programme"
          isBusy={false}
          type="primary"
          onClick={v => {
            setSelectedProgram({})
            setAddProgram(true)
          }}
        />
      ]}
    >
      <MyTableControl
        title={'Programmes'}
        type={'programs'}
        search={{ show: true, search_by: 'program_name' }}
        showPagination={true}
        data={tableDate}
        displayKeys={[
          { key: 'program_name', display: 'Name' },
          { key: 'startDate', display: 'Start Date' },
          { key: 'endDate', display: 'End Date' }
        ]}
        actionButton={{
          title: 'Details',
          isDropdown: 'true',
          side: 'right',
          items: [
            {
              name: 'View',
              id: 1
            },
            {
              name: 'Edit',
              id: 2
            },
            // {
            //   name: 'Delete',
            //   id: 3
            // },
            {
              name: 'Make Default',
              id: 4
            }
          ],
          onClick: (item, x) => {
            setSelectedProgram(item)

            if (x.id == 1) {
              history.push(`program-details/${item._id}`)
            }

            if (x.id == 2) {
              setSelectedProgram(item)
              setAddProgram(true)
            }

            if (x.id == 4) {
              setShowDefaultProgramPopup(true)
            }
          }
        }}
      />

      <AddProgramComponent open={addProgram} program={selectedProgram} setShow={setAddProgram} />
      <PopupControl
        title="Make Default Program"
        isOpen={showDefaultProgramPopup}
        close={{
          showClose: true,
          onClose: () => {
            setShowDefaultProgramPopup(false)
          }
        }}
      >
        <div className="flex flex-row gap-2">
          <ButtonComponent
            title={'Accept'}
            isBusy={false}
            type="primary"
            onClick={v => {
              set_default_program(selectedProgram._id).then(() => {
                setShowDefaultProgramPopup(false)
                window.location.reload()
              })
            }}
          />
          <ButtonComponent
            title={'Cancel'}
            isBusy={false}
            type="primary"
            onClick={v => {
              setShowDefaultProgramPopup(false)
            }}
          />
        </div>
      </PopupControl>
    </TableContentWrapper>
  )
}

const mapStateToProps = state => {
  return {
    facilitatorState: state.facilitatorState,
    programsState: state.programsState,
    userState: state.userState
  }
}

export default connect(mapStateToProps)(ProgramsComponent)
