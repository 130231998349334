import { faMinus, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'

const SelectedItemsComponent = props => {
    const {items, displayKey, onClick}=props
    const [selectedItems, setSelectedItems] = useState([])

    useEffect(()=>{
        setSelectedItems(items)
    },[items])

    const _onClick = item =>{
        const array = selectedItems.filter(e=> e!=item)
        setSelectedItems(array);

        //pass item back to parent
        onClick(item)
    }

  return (
    <div>
      {selectedItems.length > 0 ? (
        <div className="w-full p-2 border rounded-b-lg border-t-0 border-main">
          {selectedItems.map((item, index) => {
            return (
              <div
                key={index}
                className="flex flex-row p-1 w-full cursor-pointer shadow bg-white justify-between items-center gap-2 mr-2 hover:bg-blue-400 pb-1"
              >
                <div className="text-sm text-gray mr-4 hover:text-white">
                  {item[`${displayKey}`]}
                </div>
                <div
                  className="items-center h-5 w-5 p-1 flex justify-center rounded-full bg-red-400"
                  onClick={() => {
                    _onClick(item)
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} className="text-white" />
                </div>
              </div>
            )
          })}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  )
}

export default SelectedItemsComponent