import TableContentWrapper from '../../controls/tableContentWrapper/tableContentWrapper'
import { MyTableControl } from '../../controls/myTableControl/myTableControl'
import { actionButtonItems } from '../user/constants'
import { absenteesColumns, dateValues } from './constants'
import React, { useEffect, useState } from 'react'
import DropdownButtonComponent from '../customComponents/dropdownButton'
import { connect, useDispatch } from 'react-redux'
import { absentee_history, get_absent_learners } from '../../api/calls'
import { getGroups, getPrograms } from '../../asyncActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faEye, faFilter, faPen, faTimes } from '@fortawesome/pro-light-svg-icons'
import { useHistory } from 'react-router-dom'
import ButtonComponent from '../customComponents/button'
import { toTitleCase } from '../../utils'
import { get_catch_up_days } from '../../api/calls/get_catch_up_days'
import { get_flagged_learners } from '../../api/calls/get_flagged_learners'
import { resolve_flagged_learner } from '../../api/calls/resolve_flagged_learner'
import TableComponent from '../UIComponents/components/tables/table'
import ButtonComponentOutlined from '../UIComponents/components/buttons/buttonOutlined'
import TabControl from '../../controls/tab/tab'
import { Paper } from '@material-ui/core'
import SingleSelectComponent from '../UIComponents/components/select/singleSelect'
import DatePickerComponent from '../UIComponents/components/calendar/date'

const AbsenteesComponent = ({ programsState, groupState }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [formValues, setFormValues] = useState()
  const [tableData, setTableData] = useState([])
  const [historyData, setHistoryData] = useState([])
  const [index, setIndex] = useState(0)
  const [catchUpDays, setCatchUpDays] = useState([])
  const [flaggedLearners, setFlaggedLearners] = useState([])

  useEffect(() => {
    dispatch(getGroups())
    dispatch(getPrograms())
    _fetchCatchUpDays()
    _fetchFlaggedLearners()
  }, [])

  const _onFormValuesChange = e => {
    setFormValues({ ...formValues, ...e })
  }

  const _fetchAbsentees = async () => {
    //write operations here
    await get_absent_learners(formValues).then(response => {
      if (response.data.success) {
        let attendances =
          response.data.content.map(elem => {
            return {
              _id: elem.user?._id,
              name: elem.user?.user_name,
              surname: elem.user?.user_surname,
              cell_number: elem.user?.user_cell_number,
              group: elem.group?.name,
              interaction: elem.register.interaction ? toTitleCase(elem.register.interaction) : '-',
              date: elem.register?.date,
              oi_type: elem.register?.level_type,
              is_in_history: elem.is_in_history
            }
          }) || []

        setTableData(attendances)
      }
    })
  }

  const _fetchAbsenteeHistory = () => {
    //write operations here
    absentee_history().then(response => {
      if (response.data.success) {
        //map out the data so that it is accepted by the table
        let absentees =
          response.data.content.map(elem => {
            return {
              _id: elem.user?._id,
              name: elem.user?.user_name,
              surname: elem.user?.user_surname,
              cell_number: elem.user?.user_cell_number,
              group: elem.group?.name,
              interaction: elem.interaction?.interaction?.codes,
              date: elem.date,
              oi_type: elem.level_type
            }
          }) || []

        setHistoryData(absentees)
      }
    })
  }

  const _fetchCatchUpDays = async () => {
    //write operations here
    await get_catch_up_days({}).then(response => {
      if (response.data.success) {
        let learners =
          response.data.content.map(elem => {
            return {
              _id: elem.user?._id,
              name: elem.user?.user_name,
              surname: elem.user?.user_surname,
              cell_number: elem.user?.user_cell_number,
              email: elem.user?.user_email,
              group: elem.group?.name,
              interaction: elem?.interaction.interaction_name
                ? toTitleCase(elem?.interaction.interaction_name)
                : '-',
              program: elem.program?.program_name,
              level_type: elem.register?.level_type
            }
          }) || []
        setCatchUpDays(learners)
      }
    })
  }

  const _fetchFlaggedLearners = async () => {
    //write operations here
    await get_flagged_learners().then(response => {
      if (response.data.success) {
        let learners =
          response.data.content.map(elem => {
            return {
              _id: elem.user?._id,
              name: elem.user?.user_name,
              surname: elem.user?.user_surname,
              cell_number: elem.user?.user_cell_number,
              email: elem.user?.user_email,
              group: elem.group?.name,
              interaction: elem?.interaction.interaction_name
                ? toTitleCase(elem?.interaction.interaction_name)
                : '-',
              interaction_id: elem.interaction?._id,
              program: elem.program?.program_name,
              program_id: elem.program?._id,
              level_type: elem.register?.level_type,
              register_id: elem.register?._id,
              attendance_id: elem.attendance?._id,
              comment: elem.comment
            }
          }) || []
        setFlaggedLearners(learners)
      }
    })
  }

  const resolveFlaggedLearner = async item => {
    //write operations here
    console.log('resolveFlaggedLearner', item)

    await resolve_flagged_learner(item).then(response => {
      if (response.data.success) {
        _fetchFlaggedLearners()
      }
    })
  }

  const _onClickActionButton = (item, e) => {
    if (e.id == 1) {
      resolveFlaggedLearner(item)
    }
  }

  const _onViewHistoryChange = index => {
    setIndex(index)
  }

  useEffect(() => {
    if (formValues) {
      _fetchAbsentees()
    }
  }, [formValues])

  useEffect(() => {
    if (index == 1) {
      historyData.splice
      tableData.splice
      _fetchAbsenteeHistory()
    }
  }, [index])

  useEffect(() => {
    console.log('tableData', tableData)
  }, [tableData])

  return (
    <TableContentWrapper
      title={'Admin'}
      description={
        'Below you will see which learners were absent, flagged and need to be caught up.'
      }
    >
      {/* ----------Table */}
      <TabControl titles={['Absent Learners', 'Catching Up Learners', 'Flagged Learners']}>
        <div className="w-full flex flex-col gap-2">
          {/* <div className="flex flex-row gap-8 items-end">
            {index == 0 && (
              <Paper className="flex flex-row gap-4 items-center p-2 bg-indigo w-full">
                <div className="flex gap-2">
                  <div className="flex flex-row gap-1">
                    <span>
                      <FontAwesomeIcon size="sm" icon={faFilter} color="white" />
                      <div className="text-white">Filter</div>
                    </span>
                  </div>

                  {formValues?.program?.program_name || formValues?.group?.group_name ? (
                    <div className="h-30 rounded-md bg-main flex items-center justify-end">
                      <div className="flex flex-row gap-4 items-center justify-end p-2">
                        <FontAwesomeIcon size="sm" icon={faCalendar} />
                        {dateValues.map((value, i) => (
                          <div
                            onClick={() => {
                              switch (value) {
                                case 'Custom':
                                  // _onFormValuesChange({
                                  //     date_filter: {
                                  //       _id: item._id,
                                  //       program_name: item.program_name
                                  //     }
                                  //   })
                                  break
                                case 'Day':
                                case 'Week':
                                case 'Month':
                                case 'Year':
                                  _onFormValuesChange({
                                    date_filter: value
                                  })
                                  break

                                default:
                                  break
                              }
                            }}
                            className={
                              formValues?.date_filter === value
                                ? 'cursor-pointer underline text-blue-400'
                                : 'cursor-pointer'
                            }
                            key={i}
                          >
                            {value}
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}

                  <div
                    className="h-30 flex justify-center items-center p-2 bg-red-500 rounded-full gap-3 cursor-pointer hover:bg-red-300"
                    onClick={v => {
                      setFormValues(undefined)
                    }}
                  >
                    <FontAwesomeIcon size="sm" icon={faTimes} color="white" />
                    <div className="text-white">Clear</div>
                  </div>
                </div>
              </Paper>
            )}
          </div> */}
          {index == 0 && (
            <TableComponent
              title="Absentees"
              search={true}
              data={tableData}
              displayKeys={absenteesColumns}
              actions={[
                {
                  label: 'View Details',
                  action: item => {
                    //push to the learner info screen
                    history.push(`learner-info/${item._id}/LEARNER-ACC`)
                  },
                  icon: faPen
                }
              ]}
            >
              <SingleSelectComponent
                title="Select Programme"
                errorMessage={'Programmes Not Found'}
                displayKey="program_name"
                data={programsState?.programs}
                onClick={item => {
                  _onFormValuesChange({
                    program: {
                      _id: item._id,
                      program_name: item.program_name
                    }
                  })
                }}
              />

              <SingleSelectComponent
                title="Select Group"
                errorMessage={'Groups Not Found'}
                displayKey="name"
                data={groupState?.groups}
                onClick={item => {
                  _onFormValuesChange({
                    group: {
                      _id: item._id,
                      group_name: item.name
                    }
                  })
                }}
              />
              <ButtonComponentOutlined
                title={'History'}
                style={{
                  width: '100%'
                }}
                onClick={v => {
                  _onViewHistoryChange(1)
                }}
                startIcon={faEye}
              />
            </TableComponent>
          )}

          {index == 1 && (
            <TableComponent
              title={'Absentees'}
              search={true}
              data={historyData}
              displayKeys={absenteesColumns}
            >
              <ButtonComponentOutlined
                title={'Reload Absentees'}
                onClick={v => {
                  _onViewHistoryChange(0)
                }}
                startIcon={faEye}
              />
            </TableComponent>
          )}
        </div>

        <div className="flex w-full flex-col gap-4">
          <TableComponent
            title="Catching Up Learners"
            search={true}
            data={catchUpDays}
            displayKeys={[
              { value: 'name', label: 'Name' },
              { value: 'surname', label: 'Surname' },
              { value: 'cell_number', label: 'Cell Number' },
              { value: 'email', label: 'Email' },
              { value: 'group', label: 'Group' },
              { value: 'interaction', label: 'Interaction' },
              { value: 'program', label: 'Program' },
              { value: 'level_type', label: 'Level Type' }
            ]}
            actions={[
              {
                label: 'View Details',
                action: item => {
                  //push to the learner info screen
                  history.push(`learner-info/${item._id}/LEARNER-ACC`)
                },
                icon: faPen
              }
            ]}
          />
        </div>

        <div className="flex w-full flex-col gap-4">
          <TableComponent
            title="Flagged Learners"
            search={true}
            data={flaggedLearners}
            displayKeys={[
              { value: 'name', label: 'Name' },
              { value: 'surname', label: 'Surname' },
              { value: 'cell_number', label: 'Cell Number' },
              { value: 'email', label: 'Email' },
              { value: 'group', label: 'Group' },
              { value: 'interaction', label: 'Interaction' },
              { value: 'program', label: 'Program' },
              { value: 'level_type', label: 'Level Type' },
              { value: 'comment', label: 'Comment' }
            ]}
            actions={[
              {
                label: 'Resolve',
                action: item => {
                  //push to the learner info screen
                  resolveFlaggedLearner(item)
                },
                icon: faPen
              }
            ]}
          />
        </div>
      </TabControl>
    </TableContentWrapper>
  )
}
const mapStateToProps = state => {
  return {
    mainState: state.mainState,
    groupState: state.groupState,
    programsState: state.programsState
  }
}

export default connect(mapStateToProps)(AbsenteesComponent)
