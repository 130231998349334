import React from 'react'

import { faArrowLeft } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

const BreadCrumb = ({name}) => (
  <div className="">
    <Link
      to={`/dashboard/${name.toLowerCase()}s`}
      className="mt-4 mb-4 text-green hover:underline focus:outline-none cursor-pointer"
    >
      <FontAwesomeIcon className="text-sm" icon={faArrowLeft} /> Back to all {name.toLowerCase()}s
    </Link>
  </div>
)
export default BreadCrumb