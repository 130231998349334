import React from 'react'
import TextInputControl from '../../controls/textinput/textinput'
import { useState } from 'react'
import ButtonControl from '../../controls/button/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom'

const OrderForm = () => {
  const [form, setForm] = useState('')
  const [date, setDate] = useState('')
  const [orderNumber, setOrderNumber] = useState('')
  const [to, setTo] = useState('')
  const [placeholder, setPlaceholder] = useState('')
  const [quantity, setQuantity] = useState('')
  const [description, setDescription] = useState('')
  const [unitPrice, setUnitPrice] = useState('')
  const history = useHistory()
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row">
        <div className="flex-auto text-3xl font-bold">Order Form</div>
      </div>
      <div className="py-4 px-4 bg-white ">
        <div className="grid grid-row-3 space-y-4">
          <div className="grid grid-cols-2 space-x-2 px-4">
            <TextInputControl
              title="Order Form:"
              value={form}
              onChange={v => {
                setForm(v)
              }}
            />
            <div className="grid grid-cols-2 space-x-2">
              <TextInputControl
                type="date"
                title="Date:"
                value={date}
                onChange={v => {
                  setDate(v)
                }}
              />

              <TextInputControl
                title="Order No:"
                value={orderNumber}
                onChange={v => {
                  setOrderNumber(v)
                }}
              />
            </div>
          </div>
          <div className="grid grid-cols-2 space-x-2 py-10 px-4">
            <TextInputControl
              title="To:"
              value={to}
              onChange={v => {
                setTo(v)
              }}
            />

            <div className="flex flex-col">
              <div className="flex bg-yellow-50 h-10 items-center justify-center">
                PLEASE SUPPLY
              </div>
              <div className="flex h-full bg-green py-4 px-8">
                <p>
                  the undermentioned Goods per
                  <div className="w-40">
                    <TextInputControl
                      value={placeholder}
                      onChange={v => {
                        setPlaceholder(v)
                      }}
                    />
                  </div>
                  to be accompanied by involce or Delivery Note bearing above Order No.
                </p>
              </div>
            </div>
          </div>
          <div className="w-full grid grid-cols-1 divide-y-2" />
          <div className="grid grid-cols-4 space-x-2 px-4">
            <TextInputControl
              title="Quantity:"
              value={quantity}
              onChange={v => {
                setQuantity(v)
              }}
            />
            <span className="col-span-2">
              <TextInputControl
                title="Description"
                value={description}
                onChange={v => {
                  setDescription(v)
                }}
              />
            </span>

            <div className="grid grid-cols-2 place-items-center justify-items-center space-x-2">
              <div className="flex flex-col">
                <p>Unit Price</p>
                <div className="grid grid-cols-2 space-x-2">
                  <TextInputControl
                    //   title="Unit Price"
                    value={unitPrice}
                    onChange={v => {
                      setUnitPrice(v)
                    }}
                  />
                  <div className="grid border-2 border-gray rounded-full py-2 px-2 w-10 place-items-center justify-items-center">
                    <FontAwesomeIcon icon={faPlus} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 place-items-center justify-items-center">
            <div className="grid grid-rows-2 space-y-2">
              <div className="grid grid-cols-2 space-x-2">
                <ButtonControl
                  style="bg-buttonColor"
                  placeholder="Save"
                  // isBusy='false'
                  type="primary"
                  // onClick={(v) => {
                  //   history.push(`${path}/sent`);
                  // }}
                />
                <ButtonControl
                  style="bg-buttonColor"
                  placeholder="Back"
                  // isBusy='false'
                  type="primary"
                  // onClick={(v) => {
                  //   history.push(`${path}/sent`);
                  // }}
                />
              </div>
              <div className="grid grid-cols-2 space-x-2">
                <ButtonControl
                  style="bg-buttonColor"
                  placeholder="Upload order form"
                  // isBusy='false'
                  type="primary"
                  // onClick={(v) => {
                  //   history.push(`${path}/sent`);
                  // }}
                />
                <ButtonControl
                  style="bg-buttonColor"
                  placeholder="Tax Invoice"
                  // isBusy='false'
                  type="primary"
                  onClick={v => {
                    history.push(`tax-invoice`)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderForm
